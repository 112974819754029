import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import {
  Button,
  Loader,
  Badge,
  // Tooltip,
  useNotify,
  Tooltip,
} from "ebs-design";

import {
  combineQueryPagesData,
  hasNextPage,
  makeBEM,
  notifyErrors,
  formattedDateTime,
} from "@aeo/core/utils";
import { Flex, Icon } from "@aeo/core/components";

import {
  useIntersectionObserver,
  usePopupClose,
  useQueryPagination,
} from "../hooks";
import api, { querykeys } from "../api";
import models from "../models";
import { REFETCH_NOTIFICATION_INTERVAL } from "../app-constants";

interface NotificationsProps {
  count?: number;
}

const bem = makeBEM("notifications");

export const Notifications = ({ count }: NotificationsProps) => {
  const loadMore = React.useRef<null>(null);
  const overlayRef = React.useRef<HTMLDivElement>(null);
  const loadMoreRootRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [badgeCount, setBadgeCount] = React.useState(count);

  const notify = useNotify();
  const queryClient = useQueryClient();
  const pagination = useQueryPagination();
  const queryParams = { ...pagination.queryParams };

  usePopupClose(overlayRef, () => setIsOpen(false));

  const query = useInfiniteQuery(
    querykeys.notifications({ ...queryParams }),
    ({ pageParam = 1 }) =>
      api.notifications.get({
        ...queryParams,
        page: pageParam,
      }),
    {
      refetchOnMount: false,
      refetchInterval: REFETCH_NOTIFICATION_INTERVAL,
      getNextPageParam: (lastPage) =>
        hasNextPage(lastPage) ? (lastPage.page || 0) + 1 : undefined,
    },
  );

  useIntersectionObserver({
    rootRef: loadMoreRootRef,
    targetRef: loadMore,
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage,
  });

  const data = combineQueryPagesData(query.data);

  const { mutate: mutateNotification } = useMutation(
    ({ id, ...rest }: Pick<models.Notification, "id" | "is_read">) =>
      api.notifications.patch(id, rest),
    {
      onSuccess: (_, vars) => {
        queryClient.invalidateQueries(querykeys.notifications());

        // If the external count exists, we need to update it to not invalidate the external query
        if (count) {
          setBadgeCount((prevCount) => {
            if (typeof prevCount === "number") {
              return !vars.is_read ? ++prevCount : --prevCount;
            }
            return prevCount;
          });
        }
      },
      onError: (errors) => {
        notifyErrors(notify, errors);
      },
    },
  );

  return (
    <>
      <div className={bem("trigger")}>
        <Badge
          count={badgeCount || query.data?.pages[0]?.unread}
          type="danger"
          className={bem("badge")}
        >
          <Button
            type="text"
            size="small"
            onClick={() => setIsOpen(true)}
            icon={() => <Icon type="notification" />}
          />
        </Badge>
      </div>
      {isOpen && <div className={cn(bem("overlay"))} ref={overlayRef}></div>}
      <div className={cn(bem("surface", { open: isOpen }), "surface")}>
        <Flex justify="space-between" className={bem("header")}>
          <h4 className={bem("header-title")}>Notificări</h4>
        </Flex>
        <div className={bem("container")} ref={loadMoreRootRef}>
          {data?.map((notification: models.Notification) => {
            const withLink = notification?.action
              ? new URL(notification.action)
              : null;

            return (
              <div
                className={bem("item", { unread: !notification.is_read })}
                key={notification.id}
                style={!notification.is_read ? { background: "#01689a10" } : {}}
                onClick={() => {
                  if (!notification.is_read) {
                    mutateNotification({
                      id: notification.id,
                      is_read: true,
                    });
                  }
                }}
              >
                <Flex justify="space-between">
                  {withLink ? (
                    <Link
                      to={
                        withLink.hash
                          ? withLink.pathname + withLink.hash
                          : withLink.pathname
                      }
                      className={bem("item-title")}
                    >
                      {notification.title}
                    </Link>
                  ) : (
                    notification.title
                  )}

                  <Tooltip
                    placement="top"
                    title={
                      notification.is_read
                        ? "Marchează ca necitit"
                        : "Marchează ca citit"
                    }
                    trigger="hover"
                    delayHide={300}
                  >
                    <div onClick={(e) => e.stopPropagation()}>
                      <Icon
                        className="icon"
                        type={notification.is_read ? "open-eye" : "closed-eye"}
                        onClick={() =>
                          mutateNotification({
                            id: notification.id,
                            is_read: !notification.is_read,
                          })
                        }
                      />
                    </div>
                  </Tooltip>
                </Flex>

                <Flex justify="space-between">
                  <h5 className={bem("item-date")}>
                    {formattedDateTime(notification.created_at)}
                  </h5>
                </Flex>
                <p className="mb-10 mt-5">{notification.body}</p>
              </div>
            );
          })}
          <div ref={loadMore} className="text-center m-15">
            <Button
              onClick={() => query.fetchNextPage()}
              disabled={!query.hasNextPage || query.isFetchingNextPage}
            >
              {query.isFetchingNextPage
                ? "Se încarcă..."
                : query.hasNextPage
                ? "Încărcați mai multe"
                : "Nimic de încărcat"}
            </Button>
          </div>
          <Loader loading={query.isFetching && !query.isFetchingNextPage} />
        </div>
      </div>
    </>
  );
};
