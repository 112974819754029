import { Card, Form, useForm } from "ebs-design";

import {
  Flex,
  FormList,
  FormListProps,
  LoadingButton,
} from "@aeo/core/components";

import { ApplicationValidatePath } from "api/application";
import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";

import { PersonFormFields } from ".";

interface Props {
  title: string;
  subTitle: string;
  path: ApplicationValidatePath;
  nextPath?: string;
  info?: string;
  formListProps?: Pick<FormListProps, "componentProps">;
}

export const PersonForm = ({
  title,
  subTitle,
  path,
  nextPath,
  info,
  formListProps,
}: Props) => {
  const [form] = useForm();

  const [save, saveMutation] = useAutoSave(path, form, "persons");
  useDraftApplicationQuery(path, form, "persons");
  const validateMutation = useValidateMutation(path, form, nextPath, "persons");

  return (
    <Form
      form={form}
      preserve={false}
      onFinish={(values) => {
        saveMutation.mutate(values?.persons, {
          onSuccess: () => validateMutation.mutate(values?.persons),
        });
      }}
      onValuesChange={() => {
        setTimeout(() => save(form.getFieldsValue()?.persons), 0);
      }}
    >
      <div className="container-card">
        <Card>
          <Card.Header>
            <TitleWithLoading
              info={info}
              title={title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body className="pt-5">
            <FormList
              subTitle={subTitle}
              name="persons"
              Component={PersonFormFields}
              {...formListProps}
            />
          </Card.Body>
          <Card.Footer>
            <Flex justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Card>
      </div>
    </Form>
  );
};
