import { ReactNode } from "react";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import cn from "classnames";

import { WhiteSpace } from ".";
import models from "../models";

interface Props {
  tabItems: models.TabsEntity[];
  className?: string;
  type?: "badges" | "tabs";
}

export const TabsWithRoute: React.FC<Props> = ({
  tabItems,
  className,
  type = "tabs",
  children,
}) => {
  const { url, path } = useRouteMatch();

  const clsBase = type === "badges" ? "badge-tabs" : "tabs";

  return (
    <div className={clsBase}>
      <div className={cn(`${clsBase}__header`, className)}>
        {tabItems?.map?.((item) => {
          return (
            <CustomLink
              to={`${url}/${item.route}`}
              key={item.route}
              label={item.label}
              clsBase={clsBase}
              disabled={"access" in item ? !item.access : false}
            />
          );
        })}
        {children && (
          <div className={cn(`${clsBase}__content`)}>{children}</div>
        )}
      </div>
      <Switch>
        {tabItems?.map?.((item) => {
          if (item.hasOwnProperty("access")) {
            return item.access ? (
              <Route path={`${path}/${item.route}`} key={item.route}>
                <WhiteSpace v="2rem" />
                {item.content}
              </Route>
            ) : null;
          }

          return (
            <Route path={`${path}/${item.route}`} key={item.route}>
              <WhiteSpace v="2rem" />
              {item.content}
            </Route>
          );
        })}
        {tabItems.length && <Redirect to={`${path}/${tabItems[0].route}`} />}
      </Switch>
    </div>
  );
};

interface CustomLinkProps {
  to: string;
  label: ReactNode;
  clsBase: string;
  disabled: boolean;
}

const CustomLink = ({ label, to, clsBase, disabled }: CustomLinkProps) => {
  let match = useRouteMatch({
    path: to,
  });

  return (
    <Link
      className={cn(`${clsBase}__header__item`, {
        [`${clsBase}__header__item--active`]: match,
        [`${clsBase}__header__item--disabled`]: disabled,
      })}
      to={to}
    >
      {label}
    </Link>
  );
};
