import React from "react";
import { Button, Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { useDownloadFile, useModalState } from "@aeo/core/hooks";
import {
  AttachmentsUploadModal,
  Badge,
  Flex,
  Icon,
  ViewPdfModal,
  WhiteSpace,
} from "@aeo/core/components";
import { FormControl } from "@aeo/core/types";
import models from "@aeo/core/models";

import { checkFileType } from "../../utils";

export interface AttachmentsFormFieldProps
  extends Omit<FormFieldProps, "children"> {
  children?: React.ReactNode;
  multiple?: boolean;
  downloadOnly?: boolean;
}

export const AttachmentsFormField = ({
  multiple = true,
  children = `Atașează ${multiple ? "documente" : "document"}`,
  name = "attachments",
  downloadOnly,
  ...restProps
}: AttachmentsFormFieldProps) => {
  const modal = useModalState();
  const modalPdf = useModalState<models.Attachment>();
  const downloadFile = useDownloadFile();

  return (
    <Form.Field name={name} initialValue={multiple ? [] : null} {...restProps}>
      {(_control) => {
        const { value, onChange } = _control as FormControl<
          models.Attachment[] | models.Attachment | null
        >;

        const arrValue = Array.isArray(value)
          ? value
          : ([value] as models.Attachment[]);

        return (
          <>
            <div>
              <Button onClick={modal.open}>{children}</Button>
            </div>

            <WhiteSpace v="0.5rem" />
            <Flex wrap>
              {arrValue
                ?.filter((a) => a)
                .map?.((attachment) => (
                  <Badge
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      checkFileType(attachment?.file_upload) && !downloadOnly
                        ? modalPdf.openWith(attachment)
                        : downloadFile(attachment)
                    }
                    variant="subtle"
                    size="lg"
                    key={attachment?.id}
                    className="mb-5 mr-7"
                    suffix={
                      <div onClick={(e) => e.stopPropagation()}>
                        <Button
                          className="btn-xsm"
                          round
                          icon={() => <Icon size="1em" type="close" />}
                          type="text"
                          onClick={() =>
                            onChange?.(
                              multiple
                                ? arrValue?.filter?.(
                                    (a) => a.id !== attachment.id,
                                  )
                                : null,
                            )
                          }
                        />
                      </div>
                    }
                  >
                    {attachment?.title}
                  </Badge>
                ))}
            </Flex>
            {modalPdf.isOpen && (
              <ViewPdfModal
                title={modalPdf.data?.title}
                fileTitle={modalPdf.data?.title}
                file={modalPdf.data?.file_upload}
                open={modalPdf.isOpen}
                onClose={modalPdf.close}
                size="large"
              />
            )}
            {modal.isOpen && (
              <AttachmentsUploadModal
                initialAttachments={arrValue}
                open={modal.isOpen}
                onClose={modal.close}
                onFinish={(_, all) => onChange?.(multiple ? all : all[0])}
                multiple={multiple}
              />
            )}
          </>
        );
      }}
    </Form.Field>
  );
};
