import { Icon as EBSIcon } from "ebs-design";

import { DebouncedInput, Flex, SortBy, SortByProps, WhiteSpace } from ".";

export interface TableTitleProps {
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  filters?: React.ReactNode;
  sortOptions?: SortByProps["options"];
  onSortChange?: SortByProps["onChange"];
  title?: React.ReactNode;
  extra?: React.ReactNode;
}

export const TableTitle = ({
  search,
  setSearch,
  extra,
  filters,
  sortOptions = [],
  onSortChange = () => {},
  title,
}: TableTitleProps) => {
  return (
    <>
      <Flex justify="space-between" align="center">
        <Flex align="center">
          {title && (
            <>
              {title}
              <WhiteSpace h="1rem" />
            </>
          )}
          {setSearch && (
            <>
              <DebouncedInput
                value={search}
                onChange={setSearch}
                prefix={<EBSIcon type="search" />}
                size="large"
                placeholder="Caută"
              />
              <WhiteSpace h="1rem" />
            </>
          )}
          {filters && (
            <>
              {filters}
              <WhiteSpace h="1rem" />
            </>
          )}
          {sortOptions.length ? (
            <>
              <SortBy options={sortOptions} onChange={onSortChange} />
              <WhiteSpace h="1rem" />
            </>
          ) : null}
        </Flex>
        {extra}
      </Flex>
    </>
  );
};
