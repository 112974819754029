import { useMutation, useQueryClient } from "react-query";
import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import {
  AlertErrors,
  Flex,
  InputFormField,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import { notifyErrors } from "@aeo/core/utils";

import api, { querykeys } from "api";

export const ComplaintFormModal = (props: ModalProps) => {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const { mutate, isLoading, error } = useMutation(
    (complaint: string) => api.complaints.post(complaint),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.complaints.many());
        props?.onClose?.();
        form.resetFields();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const handleForm = (complaint: string) => {
    mutate(complaint);
  };

  return (
    <Modal title="Adaugă sesizare" size="small" {...props}>
      <Form form={form} onFinish={handleForm}>
        <Modal.Content>
          <InputFormField textarea name="body" label="Sesizare" />
          <AlertErrors error={error} />
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={props.onClose}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton submit type="primary" loading={isLoading}>
              Adaugă sesizarea
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
