import { useQuery } from "react-query";
import { NumberParam } from "use-query-params";

import { Loader } from "ebs-design";

import { TabsWithRoute } from "@aeo/core/components/TabsWithRoute";
import { ApplicationContext } from "@aeo/core/contexts";
import { useParam } from "@aeo/core/hooks";

import { applicationDetailsTabs } from "features/application/config";
import api, { querykeys } from "api";
import { useRedirectToNote } from "hooks";

export const ApplicationDetails = () => {
  const id = useParam("id", NumberParam.decode) || 0;

  const query = useQuery(
    querykeys.applications.details(id),
    () => api.application.getNotes(id),
    {
      enabled: !!id,
    },
  );

  useRedirectToNote({ data: query.data });

  return (
    <>
      {query.isLoading ? (
        <Loader loading />
      ) : (
        <ApplicationContext.Provider value={query.data}>
          <TabsWithRoute tabItems={applicationDetailsTabs} />
        </ApplicationContext.Provider>
      )}
    </>
  );
};
