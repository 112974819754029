import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import { Button, Input, Space } from "ebs-design";

import { DocumentProps } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { ACCESS_TOKEN_KEY } from "../app-constants";
import { makeBEM, throttle } from "../utils";
import { WhiteSpace } from "./WhiteSpace";

const bem = makeBEM("pdf-viewer");

export const PDFViewer = ({
  className,
  renderMode = "svg",
  ...props
}: DocumentProps) => {
  const pdfWrapper = useRef<HTMLDivElement>(null);
  const [pageWidth, setPageWidth] = useState<number | undefined>();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [inputPage, setInputPage] = useState<number>();

  useEffect(() => {
    window.addEventListener("resize", throttle(setPDFWidth, 500));
    setPDFWidth();

    return () => {
      window.removeEventListener("resize", throttle(setPDFWidth, 500));
    };
  }, []);

  // Resize the PDF wrapper to fit the window width
  useEffect(() => setPDFWidth());
  useEffect(() => {
    setInputPage(pageNumber);
  }, [pageNumber]);

  const setPDFWidth = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setPageWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const value = (e.target as HTMLInputElement).value;
    const key = e.key;

    if (key !== "Enter" || !value) return;

    setPageNumber(+value);
  };

  return (
    <div className={cn(bem(), className)} ref={pdfWrapper}>
      <Document
        onLoadSuccess={onDocumentLoadSuccess}
        options={{
          // http headers to access files from the server
          httpHeaders: {
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
            "Service-Token": process.env.REACT_APP_API_SERVICE_TOKEN,
          },
        }}
        {...props}
      >
        <Page width={pageWidth} pageNumber={pageNumber} />
      </Document>

      <WhiteSpace v="2rem" />

      <Space justify="center">
        <Button
          type="primary"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Precedentă
        </Button>
        <p>
          Pagina{" "}
          {inputPage || numPages ? (
            <Input
              containerClass={bem("page-input")}
              type="number"
              min={1}
              max={numPages}
              value={inputPage}
              onKeyDown={handleKeyDown}
              onChange={(value) => setInputPage(value ? +value : undefined)}
              onBlur={(e) => setInputPage(pageNumber)}
            />
          ) : (
            "--"
          )}{" "}
          din {numPages || "--"}
        </p>
        <Button
          type="primary"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Următoare
        </Button>
      </Space>
    </div>
  );
};
