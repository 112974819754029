import { useMutation, useQuery } from "react-query";
import { Button, Card, Space, useNotify, Loader } from "ebs-design";

import { Flex, Icon, PDFViewer, TooltipButton } from "@aeo/core/components";
import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

import { RoleGuard } from "components";
import api, { querykeys } from "api";

export const Signing = () => {
  const { user } = useAuth();
  const notify = useNotify();

  const activeApplication = user?.organization?.active_application;

  const { data, isLoading } = useQuery(
    querykeys.applications.documents(activeApplication?.id!),
    () => api.application.getDocument(activeApplication?.id!),
    {
      enabled: !!activeApplication?.id,
    },
  );

  const { data: application } = useQuery<models.ActiveApplication>(
    querykeys.applications.many(["application", activeApplication?.id!]),
    () => api.application.getById(activeApplication?.id!),
    {
      enabled: !!activeApplication?.id,
      refetchOnMount: false,

      // Disable cache
      cacheTime: 0,
      staleTime: Infinity,
    },
  );

  const signMutation = useMutation(
    async () =>
      api.application.sign(activeApplication?.id, {
        type: application?.type,
      }),
    {
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Card>
      <Card.Header>
        <Flex justify={"space-between"} align={"center"}>
          <span>
            Cererea Nr. <strong>{activeApplication?.id}</strong> cu statutul{" "}
            <strong>{activeApplication?.type}</strong>
          </span>
          <Space>
            <a
              href={data}
              download={`Cerere_Nr_${activeApplication?.id}_statut_${activeApplication?.type}`}
            >
              <Button prefix={<Icon type="download" />}>Descarcă</Button>
            </a>
            <RoleGuard restritedTo={["user"]}>
              <TooltipButton
                type="primary"
                onClick={signMutation.mutate}
                loading={signMutation.isLoading}
                disabled={!application?.is_valid}
                tooltip={
                  !application?.is_valid && {
                    tooltip: "Cererea este incompleta",
                    placement: "top",
                  }
                }
              >
                Semnează
              </TooltipButton>
            </RoleGuard>
          </Space>
        </Flex>
      </Card.Header>
      <Card.Body>
        <Loader loading={isLoading}>
          <PDFViewer file={data} options={{}} />
        </Loader>
      </Card.Body>
    </Card>
  );
};
//
