export const user = {
  last_name: {
    name: "last_name",
    label: "Nume",
  },
  first_name: {
    name: "first_name",
    label: "Prenume",
  },
  phone: {
    name: "phone",
    label: "Telefon",
  },
  idnp: {
    name: "idnp",
    label: "IDNP",
  },
  email: {
    name: "email",
    label: "E-mail",
  },
  username: {
    name: "username",
    label: "E-mail",
  },
  domain: {
    name: ["profile", "domain"],
    label: "Compartiment",
  },
  function: {
    name: ["profile", "function"],
    label: "Funcția",
  },
  idno: {
    name: ["organization", "idno"],
    label: "IDNO",
  },
  organization_name: {
    name: ["organization", "name"],
    label: "Nume organizație",
  },
  juridic_form: {
    name: ["organization", "juridic_form", "name"],
    label: "Forma juridică",
  },
};
