import React from "react";
import { UseQueryResult } from "react-query";

import { Loader } from "ebs-design";

import { AlertErrors } from "@aeo/core/components";
import models from "@aeo/core/models";

export interface QueryListProps<
  RecordType,
  Data = models.WithResults<RecordType>,
> {
  query: UseQueryResult<Data>;
  renderItems: (data: RecordType[] | undefined) => React.ReactNode;
  getItems: (data: Data | undefined) => RecordType[] | undefined;
  emptyText?: React.ReactNode;
}

// TODO: add pagination

export const QueryList = <RecordType, Data = models.WithResults<RecordType>>({
  query,
  renderItems,
  getItems,
  emptyText = <h3 className="text-center py-3">Nu sunt date disponibile</h3>,
}: QueryListProps<RecordType, Data>) => {
  const items = getItems(query.data);
  return (
    <>
      <AlertErrors error={query.error} />
      {query.isLoading && <Loader loading />}
      {!query.isLoading &&
        !query.isError &&
        (items?.length ? renderItems(items) : emptyText)}
    </>
  );
};
