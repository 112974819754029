import RcTable from "rc-table";
import { TableProps as RcTableProps } from "rc-table/lib/Table";

import { createClassWithModifiers } from "@aeo/core/utils";

export type TableVariant =
  | "bordered"
  | "stripped"
  | "stripped-even"
  | "black-header"
  | "dense"
  | "highlight-hover"
  | "elevated";

export interface TableProps<RecordType = unknown>
  extends RcTableProps<RecordType> {
  variants?: TableVariant[];
}

function Table<RecordType = unknown>({
  className = "",
  variants = [],
  ...props
}: TableProps<RecordType>) {
  return (
    <RcTable
      rowKey="id"
      prefixCls="aeo-table"
      className={`${className} ${createClassWithModifiers(
        "aeo-table",
        variants,
        {
          "has-title": props.title,
          "has-footer": props.footer,
        },
      )}`}
      {...props}
    />
  );
}

Table.Column = RcTable.Column;
Table.ColumnGroup = RcTable.ColumnGroup;
Table.Summary = RcTable.Summary;

export { Table };
