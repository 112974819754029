import { Grid, WhiteSpace } from "@aeo/core/components";
import { modeOptions } from "@aeo/core/components/form-utils/utils";

import fields from "@aeo/core/fields";
import {
  NotApplicableBooleanField,
  NotApplicableFormField,
  NotApplicableIDNOFormField,
} from "../..";

export const RepresentativeFields = ({ index }: { index: number }) => {
  const represent = fields.representStatistic;

  return (
    <>
      <p>
        b) Sunteţi reprezentat de cineva în ceea ce priveşte formalităţile
        vamale, dacă da, de către cine şi în ce mod (direct sau indirect)?
        Indicați denumirea, adresa şi codul fiscal al reprezentantului.
      </p>
      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <NotApplicableFormField {...represent.name} name={[index, "name"]} />
        <NotApplicableIDNOFormField
          {...represent.idno}
          name={[index, "idno"]}
        />
      </Grid>
      <NotApplicableFormField
        {...represent.address}
        name={[index, "address"]}
      />

      <WhiteSpace />

      <NotApplicableBooleanField
        options={modeOptions}
        {...represent.direct_mode}
        name={[index, "direct_mode"]}
      />
    </>
  );
};
