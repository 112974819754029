import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Button, Space } from "ebs-design";

import { WhiteSpace } from "@aeo/core/components";
import messages from "@aeo/core/messages";
import models from "@aeo/core/models";
import { arrIncludes, getProperty } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

import api, { querykeys } from "api";

import { ItemProps } from "./SAQTabs/Item";
import { SAQTabs } from ".";

export interface TabSwitchesByTypeProps {
  type?: models.AEOType;
}

export const TabSwitchesByType = ({ type }: TabSwitchesByTypeProps) => {
  const { user } = useAuth();
  const applicationId = React.useMemo(
    () => user?.organization?.active_application?.id || 0,
    [user?.organization?.active_application?.id],
  );

  const history = useHistory();

  React.useEffect(() => {
    history.listen(() => query.refetch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const query = useQuery(
    querykeys.applications.one(applicationId),
    () => api.application.getById(applicationId),
    { enabled: !!applicationId },
  );

  const application = query.data;
  const saq = messages.SAQTabs;
  const saqContent = messages.SAQTabsContent;

  let current = 1;
  const next = () => current++;

  const getProps = (key: any): Pick<ItemProps, "tabKey" | "status"> => {
    const error = getProperty(application?.errors?.saq, key);

    return {
      tabKey: key,
      status: error ? "error" : error === undefined ? "success" : undefined,
    };
  };

  return (
    <>
      <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
        {saqContent.economicAgentInfo.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        static_
        tabKey={String(current)}
        indent={saqContent.companyGeneralInfo.indent}
      >
        {saqContent.companyGeneralInfo.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.economicAgent.key)}
        indent={saqContent.economicAgent.indent}
      >
        {saqContent.economicAgent.title}
      </SAQTabs.Item>

      <SAQTabs.Item
        nr={next()}
        static_
        indent={saqContent.shareholders.indent}
        tabKey={String(current)}
      >
        {saqContent.shareholders.title}
      </SAQTabs.Item>

      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.shareholdersOne.key)}
        indent={saqContent.shareholdersOne.indent}
      >
        {saqContent.shareholdersOne.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.directors.key)}
        indent={saqContent.directors.indent}
      >
        {saqContent.directors.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.advisory.key)}
        indent={saqContent.advisory.indent}
      >
        {saqContent.advisory.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.contactPerson.key)}
        indent={saqContent.contactPerson.indent}
      >
        {saqContent.contactPerson.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        indent={saqContent.customer.indent}
        {...getProps(saqContent.customer.key)}
      >
        {saqContent.customer.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        indent={saqContent.commercial.indent}
        {...getProps(saqContent.commercial.key)}
      >
        {saqContent.commercial.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        indent={saqContent.structure.indent}
        {...getProps(saqContent.structure.key)}
      >
        {saq.structure.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.turnover.key)}
        indent={saqContent.turnover.indent}
      >
        {saqContent.turnover.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.statistic.key)}
        indent={saqContent.statistic.indent}
      >
        {saqContent.statistic.title}
      </SAQTabs.Item>
      <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
        {saqContent.legislation.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.legislationOne.key)}
        indent={saqContent.legislationOne.indent}
      >
        {saqContent.legislationOne.title}
      </SAQTabs.Item>
      <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
        {saqContent.logistic.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.logisticOne.key)}
        indent={saqContent.logisticOne.indent}
      >
        {saqContent.logisticOne.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.logisticTwo.key)}
        indent={saqContent.logisticTwo.indent}
      >
        {saqContent.logisticTwo.title}
      </SAQTabs.Item>
      <SAQTabs.Item
        nr={next()}
        {...getProps(saqContent.logisticThree.key)}
        indent={saqContent.logisticThree.indent}
      >
        {saqContent.logisticThree.title}
      </SAQTabs.Item>
      <SAQTabs.Item nr={next()} {...getProps(saqContent.solvency.key)}>
        {saqContent.solvency.title}
      </SAQTabs.Item>

      {arrIncludes([models.AEOType.AEOC, models.AEOType.AEOF], type) && (
        <>
          <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
            {saqContent.standard.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.standardOne.key)}
            indent={saqContent.standardOne.indent}
          >
            {saqContent.standardOne.title}
          </SAQTabs.Item>
        </>
      )}

      {arrIncludes([models.AEOType.AEOS, models.AEOType.AEOF], type) && (
        <>
          <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
            {saq.security.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.securityOne.key)}
            indent={saqContent.securityOne.indent}
          >
            {saqContent.securityOne.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.securityTwo.key)}
            indent={saqContent.securityTwo.indent}
          >
            {saqContent.securityTwo.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.securityThree.key)}
            indent={saqContent.securityThree.indent}
          >
            {saqContent.securityThree.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.securityFour.key)}
            indent={saqContent.securityFour.indent}
          >
            {saqContent.securityFour.title}
          </SAQTabs.Item>
        </>
      )}
      <WhiteSpace v="1rem" />
      <Space justify="end">
        <Button
          onClick={() => history.push("/application/signing")}
          type="primary"
        >
          Spre semnare
        </Button>
      </Space>
    </>
  );
};
