import { notApplicable } from "../../components/form-utils/utils";
import models from "../../models";

export const complianceLegislation = {
  has_breaches_legislation: {
    name: "has_breaches_legislation",
    label:
      "2.1. În compania dvs. au fost depistate încălcări ale legislaţiei vamale și fiscale sau de către autoritățile vamale și/sau fiscale în ultimii trei ani? În cazul unei companii noi, indicați „Nu se aplică”.",
    dependencies: [
      "violations_description",
      "notify_violations",
      "introduced_measures",
      "register_measures",
    ],
    info: "2.1 Informația trebuie să reflecte înregistrările din ultimii 3 ani anteriori depunerii cererii de autorizare.",
    defaultDepsValue: [
      { violations_description: notApplicable },
      {
        notify_violations: {
          true: null,
          false: notApplicable,
        },
      },
      {
        introduced_measures: {
          true: null,
          false: notApplicable,
        },
      },
      { register_measures: null },
    ],
  },
  violations_description: {
    name: "violations_description",
    label: "Dacă răspunsul este da, descrieţi succint aceste încălcări.",
    textarea: true,
    reverse: true,
    dependencies: ["has_breaches_legislation"],
    nullValue: true,
  },
  notify_violations: {
    name: "notify_violations",
    label: "a) Cum aţi notificat încălcările către autoritățile relevante?",
    textarea: true,
    nullValue: true,
    reverse: true,
    dependencies: ["has_breaches_legislation"],
  },
  introduced_measures: {
    name: "introduced_measures",
    label:
      "b) Ce măsuri de asigurare a calităţii au fost introduse pentru a evita astfel de încălcări pe viitor?",
    textarea: true,
    nullValue: true,
    reverse: true,
    dependencies: ["has_breaches_legislation"],
  },
  register_measures: {
    name: "register_measures",
    label: "c) Păstraţi registre privind măsurile de asigurare a calităţii?",
    textarea: true,
    nullValue: true,
    reverse: true,
    dependencies: ["has_breaches_legislation"],
  },
  convicted_economic_crimes: {
    name: "convicted_economic_crimes",
    label:
      "A fost condamnată societatea dvs. pentru infracțiuni economice legate de activitatea dvs?",
    dependencies: ["economic_crimes"],
  },
  economic_crimes: {
    name: "economic_crimes",
    label:
      "Dacă da, descrieți infracțiunea și data cînd a fost săvîrșită. De asemenea, faceți trimiteri la hotărîrea instanței definitivă și irevocabilă.",
    dependencies: ["convicted_economic_crimes"],
  },
  apply_other_customs: {
    name: "apply_other_customs",
    label:
      "2.2. a) Intenționați să depuneți o cerere sau ați depus deja o cerere pentru orice altă autorizație vamală?",
    dependencies: ["other_customs"],
    info: "2.2. Se menționează dacă au fost depuse alte cereri pentru alte autorizații.",
  },
  other_customs: {
    name: "other_customs",
    label: "Dacă răspunsul este da, furnizați detalii.",
    dependencies: ["apply_other_customs"],
  },
  any_refused_applications: {
    name: "any_refused_applications",
    label:
      "b) Au fost cererile pentru autorizare/certificate refuzate, iar autorizațiile existente suspendate sau retrase ca urma a încălcărilor legislației vamale în ultimii trei ani?",
    dependencies: ["refused_applications"],
  },
  refused_applications: {
    name: "refused_applications",
    label: "Dacă răspunsul este da, de câte ori și care au fost motivele?",
    dependencies: ["any_refused_applications"],
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
