import { NamePath } from "rc-field-form/es/interface";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

export const makeNamePath = (...paths: (NamePath | undefined)[]) =>
  paths
    .map((p) => (p !== undefined ? (p instanceof Array ? p : [p]) : []))
    .flat(1);

export const prefFieldName = (
  field: FormFieldProps,
  prefName: NamePath,
): FormFieldProps => ({
  ...field,
  name: makeNamePath(prefName, field.name),
});
