import { UserRole } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

interface Props {
  restritedTo: UserRole[];
  children: JSX.Element;
  fallback?: JSX.Element | null;
}

export const RoleGuard = ({
  restritedTo = [],
  children,
  fallback = null,
}: Props) => {
  const { role } = useAuth();

  if (!role) {
    return null;
  }

  if (restritedTo.includes(role)) {
    return fallback;
  }

  return children;
};
