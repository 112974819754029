import { Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import models from "@aeo/core/models";

interface Props extends ModalProps {
  type?: models.AEOType;
}
export const WarningModal = ({ type, ...props }: Props) => {
  return (
    <Modal {...props}>
      <Modal.Content className="mt-30 mb-30">
        <h3 style={{ textAlign: "justify" }}>
          Pentru a depune cererea pentru {type}, este nevoie de completat în
          "Alte cereri", Etapa de aplicare:{" "}
          <span className="color-mid-gray">
            Cererea de retragere a Cererii de obținere a statutului AEO
          </span>{" "}
          sau{" "}
          <span className="color-mid-gray">
            Cererea de suspendare a cererii
          </span>
          .
        </h3>
      </Modal.Content>
    </Modal>
  );
};
