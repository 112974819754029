import { Form, Input } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { xor } from "@aeo/core/utils";

interface Props extends FormFieldProps {
  type?: string;
  placeholder?: string;
  reverse?: boolean;
  name?: string | (string | number)[];
  disableException?: boolean;
}

interface FormControl {
  value: string;
  onChange: React.Dispatch<string>;
}

const normalizeIDNO = (value: string) => {
  const isNumber = /^\d+$/.test(value.charAt(value.length - 1));
  if (!isNumber) {
    return value.slice(0, value.length - 1);
  }

  return value;
};

export const NotApplicableIDNOFormField = ({
  dependencies,
  type,
  initialValue,
  reverse = false,
  disableException,
  ...props
}: Props) => {
  return (
    <Form.Field
      dependencies={dependencies}
      initialValue={initialValue}
      {...props}
    >
      {(_control, _, form) => {
        const { value, onChange } = _control as FormControl;

        const disabled =
          !!xor(
            reverse,
            !!dependencies?.every((dep) => form.getFieldValue(dep)),
          ) || disableException;

        const inpProps = {
          className: disabled ? "input-hide-value" : undefined,
          value,
          disabled,
          placeholder: props.placeholder,
        };

        return (
          <Input
            size="large"
            maxLength={13}
            onChange={(value) => onChange(normalizeIDNO(String(value)))}
            {...inpProps}
          />
        );
      }}
    </Form.Field>
  );
};
