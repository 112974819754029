import { Button } from "ebs-design";
import { FilterCollapse } from ".";

export interface ChoiceFilterProps {
  title: React.ReactNode;
  value?: (string | null)[] | null | undefined;
  setValue?: React.Dispatch<
    React.SetStateAction<(string | null)[] | null | undefined>
  >;
  choice?: { key: string; value: React.ReactNode }[];
}

export const ChoiceFilter = ({
  title,
  value,
  setValue,
  choice,
}: ChoiceFilterProps) => {
  return (
    <FilterCollapse title={title} count={value?.length}>
      {choice?.map((c, i) => (
        <Button
          key={i}
          className="mb-5 mr-5"
          type={value?.includes(c.key) ? "primary" : undefined}
          onClick={() =>
            setValue?.((prev) =>
              prev?.includes(c.key)
                ? prev?.filter((p) => p !== c.key)
                : [...(prev || []), c.key],
            )
          }
        >
          {c.value}
        </Button>
      ))}
    </FilterCollapse>
  );
};
