import React from "react";
import { capitalize } from "ebs-design";

import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { useScrollTo } from "@aeo/core/hooks";

import { KeyValueCard } from "features/application/components/KeyValueCard";
import { NotesCardForm } from "../components";

export const RequestValues = () => {
  const request = fields.request;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <KeyValueCard {...request.applicant_name} />
      <KeyValueCard {...request.applicant_idno} />
      <KeyValueCard {...request.juridic_form} type="nomenclature" />
      <KeyValueCard {...request.applicant_created} />
      <KeyValueCard {...request.applicant_address} />
      <KeyValueCard {...request.activity_address} />
      <KeyValueCard {...request.postal_address} />
      <KeyValueCard {...request.activity_sector} type="nomenclature-list" />
      <KeyValueCard {...request.activity_states} type="nomenclature-list" />
      <KeyValueCard {...request.crossing_customs} type="nomenclature-list" />
      <KeyValueCard {...request.facility} />
      <KeyValueCard {...request.management_customs} />
      <KeyValueCard {...request.accounting_office} />

      <h4>{capitalize(messages.titles.contactPerson)}</h4>
      <WhiteSpace v="0.5rem" />

      <KeyValueCard {...request.contact_name} />
      <KeyValueCard {...request.contact_phone} />
      <KeyValueCard {...request.contact_email} />
      <KeyValueCard {...request.contact_fax} />

      <h4>{capitalize(messages.titles.signatory)}</h4>
      <WhiteSpace v="0.5rem" />

      <KeyValueCard {...request.signer_name} />
      <KeyValueCard {...request.signer_function} />
      <KeyValueCard {...request.license_attachments} type="attachments" />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...request.attachments} type="attachments" />
    </>
  );
};

export const RequestDetails = () => {
  return (
    <NotesCardForm
      title={messages.requestConfig.title}
      path="request"
      content={<RequestValues />}
    />
  );
};
