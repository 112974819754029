import $axios from "axios";

import { API_POST_CONTENT_TYPE } from "@aeo/core/app-constants";

export const authAxios = $axios.create();

if (!authAxios.defaults.headers) authAxios.defaults.headers = {};
authAxios.defaults.headers["Content-Type"] = API_POST_CONTENT_TYPE;
if (process.env.REACT_APP_API_SERVICE_TOKEN)
  authAxios.defaults.headers["Service-Token"] =
    process.env.REACT_APP_API_SERVICE_TOKEN;
authAxios.defaults.baseURL = process.env.REACT_APP_BASE_API;
authAxios.defaults.withCredentials = true;
