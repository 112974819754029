import { Space } from "ebs-design";

import models from "@aeo/core/models";
import { WhiteSpace } from "@aeo/core/components";
import { checkApplicationState } from "@aeo/core/utils";

import { AEODraftTrigger } from ".";
import { viewStates } from "./config";

export interface AEOApplicationTriggerTriggerProps {
  activeApplication?: models.ActiveApplication;
}

interface ActionMessageType {
  [key: string]: string;
}

export const AEOApplicationTrigger = ({
  activeApplication,
}: AEOApplicationTriggerTriggerProps) => {
  const actionMessage: ActionMessageType = {
    rejected: "respinsă",
    withdraw: "retrasă",
    suspend: "suspendată",
  };

  const isSuspend = checkApplicationState(
    viewStates.suspend,
    activeApplication?.state as models.ApplicationState,
  );

  const isReject = checkApplicationState(
    viewStates.reject,
    activeApplication?.state as models.ApplicationState,
  );

  const isWithdraw = checkApplicationState(
    viewStates.withdraw,
    activeApplication?.state as models.ApplicationState,
  );

  return (
    <>
      <h3 className="text-center">
        Cererea pentru acordarea statutului {activeApplication?.type} a fost{" "}
        {Object.keys(actionMessage).map((action) =>
          activeApplication?.state &&
          action === activeApplication?.state.split("_")[0] ? (
            <span>{actionMessage[action]}</span>
          ) : null,
        )}
        .
      </h3>

      <WhiteSpace v="1rem" />
      {isSuspend && (
        <h3 className="text-center">
          Pentru a continua, scrieţi o cerere de reluare.
        </h3>
      )}
      {(isReject || isWithdraw) && (
        <h3 className="text-center">Completează cererea pentru:</h3>
      )}
      <WhiteSpace v="1rem" />
      {(isReject || isWithdraw) && (
        <Space>
          {Object.values(models.AEOType).map((type, i) => (
            <AEODraftTrigger type={type} key={i} />
          ))}
        </Space>
      )}
    </>
  );
};
