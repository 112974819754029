import { Link } from "react-router-dom";
import { Auth } from "@aeo/core/components/auth";
import { RegisterForm } from "../components";

export const Register = () => {
  return (
    <Auth
      title="AEO"
      subTitle="Creează cont nou"
      footer={
        <h4 className="fw-500">
          Ai deja un cont?
          <span>
            <Link to="/login" className="auth-layout__link">
              Logare.
            </Link>
          </span>
        </h4>
      }
    >
      <RegisterForm />
    </Auth>
  );
};
