import { Card } from "ebs-design";

import { Flex } from "@aeo/core/components";
import models from "@aeo/core/models";
import { ApplicationPathContext } from "@aeo/core/contexts";

interface Props {
  title?: string;
  content: React.ReactNode;
  path: models.ApplicationValidatePath;
}

export const NotesCardForm = ({ title, content, path }: Props) => {
  return (
    <Card style={{ maxWidth: 900 }}>
      <Card.Header>
        <Flex align="center" justify="space-between">
          <h3>{title}</h3>
        </Flex>
      </Card.Header>
      <Card.Body>
        <ApplicationPathContext.Provider value={path}>
          {content}
        </ApplicationPathContext.Provider>
      </Card.Body>
    </Card>
  );
};
