import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  AttachmentsFormField,
  LoadingButton,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import models from "@aeo/core/models";
import { useAuth } from "@aeo/core/hooks";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableFormList } from "..";

export const PracticalStandardForm = () => {
  const standard = fields.practicalStandard;
  const { user } = useAuth();
  const [form] = useForm();

  const handleNextStep = () => {
    return user?.organization?.active_application?.type === models.AEOType.AEOC
      ? "/application/signing"
      : "security_one";
  };

  const [save, saveMutation] = useAutoSave("saq/standard", form);
  useDraftApplicationQuery("saq/standard", form);
  const validateMutation = useValidateMutation(
    "saq/standard",
    form,
    handleNextStep(),
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.standard.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <TitleTooltip
              title={<h4>{capitalize(messages.titles.practicalStandard)}</h4>}
              info={`<p>Această secţiune vizează condiția privind standardele practice de competenţă sau calificările profesionale. Aceasta va fi completată numaidacă depuneţi cerere pentru o autorizaţie AEOC, AEOF.</p>
    <p>Pentru a îndeplini condiția menţionată la articolul 195.3, alineatul (1) punctul 5) din Codul vamal, sau persoana responsabilă de domeniul vamal trebuie să respecte unul dintre următoarele standarde practice de competenţă: o experienţă practică dovedită de cel puţin trei ani în domeniul vamal.</p>
    <p>Alternativ, este obligatoriu ca solicitantul sau persoana responsabilă de domeniul vamal să fi absolvit un curs de instruire în domeniul legislației vamale corespunzător implicării  în activităţile din domeniul vamal.</p>
    `}
            />

            <WhiteSpace v="1rem" />
            <ExtraTextareaFormField
              radioField={standard.has_experience}
              textareaField={standard.experience}
            />
            <NotApplicableFormList
              dependencies={[standard.has_experience.name]}
              name={standard.experience_attachments.name}
            >
              <AttachmentsFormField {...standard.experience_attachments}>
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>
            <WhiteSpace />
            <ExtraTextareaFormField
              radioField={standard.has_quality_standard}
              textareaField={standard.quality_standard}
            />
            <NotApplicableFormList
              dependencies={[standard.has_quality_standard.name]}
              name={standard.quality_standard_attachments.name}
            >
              <AttachmentsFormField {...standard.quality_standard_attachments}>
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <WhiteSpace />
            <h4>{capitalize(messages.titles.professionalQualifications)}</h4>
            <WhiteSpace />

            <ExtraTextareaFormField
              radioField={standard.graduated_training_course}
              textareaField={standard.training_course}
            />
            <NotApplicableFormList
              dependencies={[standard.graduated_training_course.name]}
              name={standard.training_course_attachments.name}
            >
              <AttachmentsFormField {...standard.training_course_attachments}>
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <WhiteSpace />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
