import { Button, Card, Space } from "ebs-design";
import { Link } from "react-router-dom";

import { makeBEM } from "@aeo/core/utils";

const bem = makeBEM("dashboard");

export const InfoCards = () => {
  return (
    <div className={bem("row-3")}>
      <div className={bem("row-group")}>
        <Card>
          <Card.Body>
            <Space justify="space-between" align="start">
              <h5 className="fw-600">INFORMAȚII GENERALE DESPRE AEO</h5>
              <a
                href="https://trade.gov.md/ro/articles/aeo-en"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="ghost">Accesează</Button>
              </a>
            </Space>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Space justify="space-between" align="start">
              <h5 className="fw-600">
                GHIDUL DE COMPLETARE A CERERII DE AUTORIZAȚIE ȘI AL
                CHESTIONARULUI DE AUTOEVALUARE
              </h5>
              <a
                href="https://trade.gov.md/media/16/04/2024/Chestionar_de_autoevaluare_Anexa_nr._1.docx"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="ghost">Accesează</Button>
              </a>
            </Space>
          </Card.Body>
        </Card>
      </div>
      <div className={bem("row-group")}>
        <Card>
          <Card.Body>
            <Space justify="space-between" align="start">
              <h5 className="fw-600">PAGINĂ FAQ</h5>
              <Link to="/faq">
                <Button type="ghost">Accesează</Button>
              </Link>
            </Space>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body className="contacts">
            <h5 className="contacts__phone fw-600">
              TELEFON:{" "}
              <a href="tel:+373 22 78 88 88" target="_blank" rel="noreferrer">
                +373 22 78 88 88
              </a>
            </h5>
            <h5 className="contacts__email fw-600">
              EMAIL:{" "}
              <a
                href="mailto:callcenter@customs.gov.md"
                target="_blank"
                rel="noreferrer"
              >
                callcenter@customs.gov.md
              </a>
            </h5>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
