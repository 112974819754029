/**
 * XOR logical operator
 * @param n1 - boolean
 * @param n2 - boolean
 * @returns - xor of n1 and n2
 * @example
 * xor(true, true) // false
 * xor(true, false) // true
 * xor(false, true) // true
 * xor(false, false) // false
 */
export const xor = (n1: any, n2: any) => Number(n1) ^ Number(n2);

export const tryToNumber = (any: any) =>
  isNaN(Number(any)) ? any : Number(any);

export function toRoman(n: number) {
  if (isNaN(n)) return NaN;
  let digits = String(+n).split(""),
    key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
    ],
    roman = "",
    i = 3;

  while (i--) roman = (key[+(digits.pop() || 0) + i * 10] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
}

export const calculatePercentage = (val?: number, total?: number) => {
  if (
    typeof val === "undefined" ||
    typeof total === "undefined" ||
    total === 0
  ) {
    return 0;
  }

  return (val / total) * 100;
};

export const inputFormatIntegers = (e: React.KeyboardEvent) => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  exceptThisSymbols.includes(e.key) && e.preventDefault();
};
