import React, { SetStateAction } from "react";

export function useControlledState<T>(
  value: T | undefined,
  onChange: React.Dispatch<T> | undefined,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>];

export function useControlledState<T>(
  value: T | undefined,
  onChange: React.Dispatch<T | undefined> | undefined,
  defaultValue: T | undefined
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>];

/**
 * Hook for providing a fallback to a internal state when a component doesn't have
 * a controlled value and/or change listener in props
 */
export function useControlledState<T>(
  value: T | undefined,
  onChange: React.Dispatch<T | undefined> | undefined,
  defaultValue: T | undefined
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] {
  //
  const [internalState, setInternalState] = React.useState(defaultValue);
  const resultValue = value === undefined ? internalState : value;

  return [
    resultValue,
    onChange === undefined
      ? value === undefined
        ? setInternalState
        : () => { }
      : value === undefined
        ?
        (v) => {
          setInternalState(v);
          onChange(dispatch(v, resultValue));
        }
        : (v) => {
          onChange(dispatch(v, resultValue)!)
        },
  ];
}

const dispatch = <T,>(v: SetStateAction<T>, prev: T) =>
  v instanceof Function ? v(prev) : v;
