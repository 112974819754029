import { Alert } from "ebs-design";
import messages from "@aeo/core/messages";
import { TabBuilder } from "@aeo/core/utils";
import {
  EconomicAgentForm,
  PersonForm,
  CommercialActivityForm,
  ShareholdersForm,
  PersonContactForm,
  PersonCustomerForm,
  CustomsStatisticForm,
  OrganizationStructureForm,
  TurnoverForm,
  LogisticOneForm,
  LogisticTwoForm,
  LogisticThreeForm,
  FinancialSolvencyForm,
  PracticalStandardForm,
  SecurityOneForm,
  SecurityTwoForm,
  ComplianceLegislationForm,
  SecurityThreeForm,
  SecurityFourForm,
  AdvisoryForm,
} from "./components";

const saq = messages.SAQTabs;

export const saqFormsTabs = new TabBuilder()
  .add(saq.economicAgent.key, <EconomicAgentForm />, true)
  .add(saq.shareholders.key, <ShareholdersForm />)
  .add(
    saq.directors.key,
    <PersonForm
      info={saq.directors.info}
      title={saq.directors.title}
      subTitle={saq.directors.subTitle}
      formListProps={{
        componentProps: {
          informationBox: (
            <Alert type="info" closable>
              Pentru a adăuga lista completă a datelor consiliului de
              administrație, puteți adăuga manual sau să atașați lista.
            </Alert>
          ),
          attachmentsLabel:
            "Documente confirmative privind numele, prenumele membrilor consiliului de administrație.",
        },
      }}
      path="saq/directors"
      nextPath="advisory"
    />,
  )
  .add(saq.advisory.key, <AdvisoryForm />)
  .add(saq.contact.key, <PersonContactForm />)
  .add(saq.customer.key, <PersonCustomerForm />)
  .add(saq.commercial.key, <CommercialActivityForm />)
  .add(saq.statistic.key, <CustomsStatisticForm />)
  .add(saq.structure.key, <OrganizationStructureForm />)
  .add(saq.turnover.key, <TurnoverForm />)
  .add(saq.legislation.key, <ComplianceLegislationForm />)
  .add(saq.logisticOne.key, <LogisticOneForm />)
  .add(saq.logisticTwo.key, <LogisticTwoForm />)
  .add(saq.logisticThree.key, <LogisticThreeForm />)
  .add(saq.solvency.key, <FinancialSolvencyForm />)
  .add(saq.standard.key, <PracticalStandardForm />)
  .add(saq.securityOne.key, <SecurityOneForm />)
  .add(saq.securityTwo.key, <SecurityTwoForm />)
  .add(saq.securityThree.key, <SecurityThreeForm />)
  .add(saq.securityFour.key, <SecurityFourForm />)
  .getTabs();
