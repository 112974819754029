import { Modal, Space } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";

import { Flex, Icon, LoadingButton, PDFViewer } from "@aeo/core/components";

interface Props extends ModalProps {
  file?: string;
  fileTitle?: string;
  mutation?: UseMutationResult<unknown, AxiosError, any, unknown>;
}

export const SignModal = ({ file, fileTitle, mutation, ...props }: Props) => {
  return (
    <Modal title="Semnare" className="view-pdf-modal" size="large" {...props}>
      <Modal.Content>
        <PDFViewer file={file} />
      </Modal.Content>
      <Modal.Footer>
        <Flex justify="flex-end">
          <Space>
            <a href={file} download={fileTitle || "Document"}>
              <LoadingButton type="primary" prefix={<Icon type="download" />}>
                Descarcă
              </LoadingButton>
            </a>
            <LoadingButton
              type="primary"
              loading={mutation?.isLoading}
              onClick={() => mutation?.mutate({})}
            >
              Semnează
            </LoadingButton>
          </Space>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
