import models from ".";

export interface WithResultsDeprecated<T> {
  count?: number;
  next?: number;
  previous?: number;
  results?: T[];
}

export interface WithResults<T> {
  count?: number;
  num_pages?: number;
  page?: number;
  per_page?: number;
  results?: T[];
  unread?: number;
}

export interface PaginationQueryParams {
  page_size: number;
  page: number;
}

export interface IdName<ID = number> {
  id?: ID;
  name?: string;
}

export interface IdTitle<ID = number> {
  id?: ID;
  title?: string;
}

export interface Country extends IdName {
  full_name?: string;
}

export interface IdDescription<ID = number> {
  id?: ID;
  description?: string;
}

export type ErrorResponse =
  | string[]
  | ErrorResponse[]
  | { [key: string]: ErrorResponse };

export enum RiskLevel {
  high = "HIGH",
  medium = "MEDIUM",
  low = "LOW",
}

export interface Declaration {
  value?: number;
  number?: number;
}

export enum StateAction {
  PRE_AUDIT = "pre-audit",
  ANNUL = "annul",
  REJECT = "reject",
  RESUME = "resume",
  REVOKE = "revoke",
  SUSPEND = "suspend",
  WITHDRAW = "withdraw",
  AUDIT = "audit",
  // Audit authorization
  AUDIT_ACTIVE = "report-order/active",
  AUDIT_REJECT = "report-order/reject",

  // To re-audit
  RE_AUDIT_CONTROL_ORDER = "control-order",

  // Re-audit authorization
  RE_AUDIT_ACTIVE = "report-order/active",
  RE_AUDIT_REVOKE = "report-order/revoke",
  RE_AUDIT_ANNUL = "report-order/annul",

  //INFO
  SUSPEND_INFO = "suspend-info",
  REVOKE_INFO = "revoke-info",
  WITHDRAW_INFO = "withdraw-info",
  TRANSFORM_INFO = "transform-info",
}

export interface ContentEntity {
  content?: string;
}
export interface ContentEntityWithHeader extends ContentEntity {
  header?: string;
}

export interface ContentEntityWithAttachment extends ContentEntity {
  attachments?: models.Attachment[];
}

export type FieldValueType = string | boolean | string[] | null | undefined;
export interface DefaultDepsValue {
  [key: string]:
    | string
    | boolean
    | string[]
    | DefaultDepsValueObj
    | null
    | undefined;
}

export interface DefaultDepsValueObj {
  true?: null | string | [];
  false?: null | string | [];
}
