import { Card, Form, Space, useForm } from "ebs-design";
import {
  Grid,
  WhiteSpace,
  InputFormField,
  DatePickerFormField,
  AttachmentsFormField,
  LoadingButton,
  NomenclaturesSelect,
} from "@aeo/core/components";
import { useAuth } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import models from "@aeo/core/models";
import { capitalize } from "@aeo/core/utils";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableBooleanField, NotApplicableFormField } from "..";

export const EconomicAgentForm = () => {
  const [form] = useForm();
  const { user } = useAuth();
  const economicAgent = fields.economicalAgent;

  const [save, saveMutation] = useAutoSave("saq/economic_agent", form);
  useDraftApplicationQuery("saq/economic_agent", form);

  const validateMutation = useValidateMutation(
    "saq/economic_agent",
    form,
    "shareholders",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.economicAgent.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <h4>{capitalize(messages.titles.companyInfo)}</h4>
            <WhiteSpace v="1em" />
            <TitleTooltip
              title={
                <h4>{capitalize(messages.titles.economicAgentSubsection)}</h4>
              }
              info={capitalize(messages.titles.autorizationsInfo)}
            />

            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField
                {...economicAgent.name}
                initialValue={user?.organization?.name}
              />

              <InputFormField {...economicAgent.address} />
            </Grid>

            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <DatePickerFormField {...economicAgent.created} />
            </Grid>

            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <NomenclaturesSelect
                entityName={models.Nomenclatures.Juridics}
                {...economicAgent.juridic_form}
              />

              <InputFormField initialValue={null} {...economicAgent.site} />
            </Grid>

            <WhiteSpace />

            <NotApplicableBooleanField {...economicAgent.part_of_group} />
            <NotApplicableFormField
              {...economicAgent.detail_group}
              textarea
              reverse
            />
            <WhiteSpace />

            <NotApplicableBooleanField
              {...economicAgent.license_group}
              reverse
            />

            <NotApplicableFormField
              {...economicAgent.license_name_group}
              reverse
              textarea
            />

            <NotApplicableBooleanField
              {...economicAgent.license_audit}
              reverse
            />

            <NotApplicableFormField
              {...economicAgent.license_audit_group}
              reverse
              textarea
            />

            <InputFormField
              {...economicAgent.reorganization}
              nullValue
              textarea
            />

            <WhiteSpace v="20px" />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />

            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
