import { Icon } from "@aeo/core/components/icons";
import { Link } from "react-router-dom";
import { makeBEM } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

const bem = makeBEM("top-bar");

export const TopBarTooltip = () => {
  const auth = useAuth();

  return (
    <div className={bem("tooltip")}>
      <Link to="/settings" className={bem("tooltip__item")}>
        <Icon type="settings" /> Setări
      </Link>
      <Link to="/" className={bem("tooltip__item")} onClick={auth.logout}>
        <Icon type="log-out" /> Ieși din sistem
      </Link>
    </div>
  );
};
