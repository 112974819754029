import { Card, Form, Space, Row, Col, useForm } from "ebs-design";
import {
  Grid,
  WhiteSpace,
  InputFormField,
  AttachmentsFormField,
  LoadingButton,
  NomenclaturesSelect,
  CheckboxFormField,
} from "@aeo/core/components";
import { useAuth } from "@aeo/core/hooks";
import { capitalize, inputFormatIntegers } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import models from "@aeo/core/models";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableFormField } from "..";

export const OrganizationStructureForm = () => {
  const organizationStructure = fields.organizationStructure;
  const { user } = useAuth();
  const [form] = useForm();
  const notAEOC =
    user?.organization?.active_application?.type !== models.AEOType.AEOC;

  const [save, saveMutation] = useAutoSave("saq/structure", form);
  useDraftApplicationQuery("saq/structure", form);
  const validateMutation = useValidateMutation(
    "saq/structure",
    form,
    "turnover",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(
            {
              ...values,
              partial_agreement_publication:
                values.partial_agreement_publication || false,
            },
            {
              onSuccess: () =>
                validateMutation.mutate({
                  ...values,
                  partial_agreement_publication:
                    values.partial_agreement_publication || false,
                }),
            },
          );
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.structure.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <InputFormField
              {...organizationStructure.departament_description}
            />
            <InputFormField {...organizationStructure.employee_description} />
            <Row style={{ alignItems: "flex-end" }}>
              <Col>
                <NomenclaturesSelect
                  entityName={models.Nomenclatures.Categories}
                  {...organizationStructure.category}
                />
              </Col>
              <Col>
                <InputFormField
                  type="number"
                  onKeyDown={inputFormatIntegers}
                  nullValue
                  {...organizationStructure.employee_count}
                />
              </Col>
            </Row>
            <div className="divider mt-20 mb-20" />
            <p>
              1.1.10. a) În cazul în care sunteți de acord cu publicarea
              informațiilor din autorizația AEO în lista titularilor de agenți
              economici autorizați pe site-ul web al Serviciului Vamal, dați
              consimțămîntul în formă scrisă conform Anexei nr. 1 la prezentul
              chestionar de autoevaluare;
            </p>
            <WhiteSpace v="10px" />
            <TitleTooltip
              title={<h4>Anexa nr. 1 la Chestionarul de autoevaluare</h4>}
              info={organizationStructure.agreement_publication.info}
            />

            <WhiteSpace v="10px" />
            <p>
              Acordul de a publica informații privind AEO pe site-ul web al
              Serviciului Vamal
            </p>
            <WhiteSpace v="10px" />
            <CheckboxFormField
              {...organizationStructure.agreement_publication}
            />
            <WhiteSpace v="10px" />
            {notAEOC && (
              <>
                <p>
                  1.1.10. b) În cazul în care vă dați consimțămîntul cu privire
                  la schimbul de informații din autorizația AEO pentru a permite
                  punerea în aplicare a acordurilor internaționale cu state
                  terțe privind recunoașterea reciprocă a statutului de AEO și a
                  măsurilor legate de securitate, completați anexa nr. 2 la
                  prezentul chestionar de autoevaluare.
                </p>
                <WhiteSpace v="10px" />
                <h4>Anexa nr. 2 la Chestionarul de autoevaluare</h4>{" "}
                <WhiteSpace v="10px" />
                <p>
                  Acordul privind schimbul informațiilor cuprinse în autorizația
                  AEO pentru a asigura punerea în aplicare a acordurilor
                  internaționale privind recunoașterea reciprocă a statutului de
                  agent economic autorizat, precum și a măsurilor legate de
                  securitate
                </p>
                <WhiteSpace v="10px" />
                <CheckboxFormField
                  {...organizationStructure.partial_agreement_publication}
                />
              </>
            )}

            <Form.Field>
              {(_control) => {
                const disabled =
                  _control.value.agreement_publication ||
                  _control.value.partial_agreement_publication;

                if (!disabled) {
                  form.resetFields([
                    organizationStructure.employee_name.name,
                    organizationStructure.employee_function.name,
                  ]);
                }
                return (
                  <Grid rows="1fr" cols="1fr 1fr" gap="20px">
                    <NotApplicableFormField
                      {...organizationStructure.employee_name}
                      disableException={!disabled}
                    />

                    <NotApplicableFormField
                      {...organizationStructure.employee_function}
                      disableException={!disabled}
                    />
                  </Grid>
                );
              }}
            </Form.Field>

            <h4>{`${capitalize(
              messages.titles.requiredDocuments,
            )} (de ex.: organigrama, regulamentul de ordine interioară)`}</h4>

            <WhiteSpace />
            <AttachmentsFormField />
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
