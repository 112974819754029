import { Button, Tooltip } from "ebs-design";
import { ButtonProps } from "ebs-design/dist/components/atoms/Button/Button";
import { TooltipProps } from "ebs-design/dist/components/atoms/Tooltip/Tooltip";

import { makeBEM } from "@aeo/core/utils";

export interface TooltipButtonProps extends ButtonProps {
  tooltip?: TooltipProps | boolean;
}

const bem = makeBEM("tooltip-button");

export const TooltipButton: React.FC<TooltipButtonProps> = ({
  tooltip,
  ...props
}) => {
  return tooltip ? (
    <Tooltip hideArrow={true} className={bem()} {...tooltip}>
      <Button {...props} />
    </Tooltip>
  ) : (
    <Button {...props} />
  );
};
