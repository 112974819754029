import {
  Grid,
  WhiteSpace,
  InputFormField,
  DatePickerFormField,
  AttachmentsFormField,
  InputPhoneFormField,
  InputIDNPFormField,
} from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";

interface PersonProps {
  index: number;
  showPercentageShares?: boolean;
  informationBox?: React.ReactNode;
  attachmentsLabel?: string;
}

export const PersonFormFields = ({
  index,
  showPercentageShares,
  informationBox,
  attachmentsLabel,
}: PersonProps) => {
  const person = fields.person;

  return (
    <>
      {informationBox ? (
        <>
          <WhiteSpace />
          {informationBox}
          <WhiteSpace />
        </>
      ) : null}

      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField
          {...person.first_name}
          name={[index, person.first_name.name]}
        />

        <InputFormField
          {...person.last_name}
          name={[index, person.last_name.name]}
        />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <DatePickerFormField
          {...person.birthday}
          name={[index, person.birthday.name]}
        />

        <InputIDNPFormField
          {...person.idnp}
          maxLength={100}
          name={[index, person.idnp.name]}
        />
      </Grid>

      <Grid cols="1fr 1fr" gap="20px">
        <InputPhoneFormField
          {...person.phone}
          name={[index, person.phone.name]}
          maxLength={100}
        />

        <InputFormField {...person.email} name={[index, person.email.name]} />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField
          {...person.address}
          name={[index, person.address.name]}
        />

        {showPercentageShares ? (
          <InputFormField
            {...person.percentage_shares}
            name={[index, person.percentage_shares.name]}
            suffix={<>%</>}
          />
        ) : null}
      </Grid>

      {!attachmentsLabel && (
        <>
          <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
          <WhiteSpace />
        </>
      )}

      <AttachmentsFormField
        label={attachmentsLabel}
        name={[index, "attachments"]}
      >
        Atașează documente
      </AttachmentsFormField>
    </>
  );
};
