export const declaration = (
  name: string,
  dependency?: string,
  placeholder?: string,
) => ({
  number: {
    name: [name, "number"],
    dependencies: dependency ? [dependency] : undefined,
    placeholder,
  },
  value: {
    name: [name, "value"],
    dependencies: dependency ? [dependency] : undefined,
    placeholder,
  },
});

export const declarationDeps = (name: string) => [
  [name, "number"],
  [name, "value"],
];
