import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  AttachmentsFormField,
  LoadingButton,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import { notNARadioOptions } from "@aeo/core/components/form-utils/utils";

import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableBooleanField, NotApplicableFormField } from "..";

export const ComplianceLegislationForm = () => {
  const complianceLegislation = fields.complianceLegislation;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/legislation", form);
  useDraftApplicationQuery("saq/legislation", form);
  const validateMutation = useValidateMutation(
    "saq/legislation",
    form,
    "logistic_one",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.legislation.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <h4>{messages.titles.legislationSubsection}</h4>
            <WhiteSpace v="1rem" />
            <ExtraTextareaFormField
              radioField={complianceLegislation.has_breaches_legislation}
              textareaField={complianceLegislation.violations_description}
              options={notNARadioOptions}
            />
            <NotApplicableFormField
              {...complianceLegislation.notify_violations}
            />
            <NotApplicableFormField
              {...complianceLegislation.introduced_measures}
            />
            <NotApplicableBooleanField
              {...complianceLegislation.register_measures}
            />
            <WhiteSpace v="2rem" />

            <ExtraTextareaFormField
              radioField={complianceLegislation.convicted_economic_crimes}
              textareaField={complianceLegislation.economic_crimes}
            />
            <WhiteSpace v="1rem" />
            <ExtraTextareaFormField
              radioField={complianceLegislation.apply_other_customs}
              textareaField={complianceLegislation.other_customs}
            />
            <WhiteSpace v="1rem" />
            <ExtraTextareaFormField
              radioField={complianceLegislation.any_refused_applications}
              textareaField={complianceLegislation.refused_applications}
            />

            <WhiteSpace v="2rem" />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField />
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
