import models from ".";
import { AEOType } from "./authorizations";
import { SAQTabKey } from "./saq";
import { IdName } from "./common";
import { Attachment } from "./attachments";
import { Profile } from "./profile";

export type ApplicationValidatePath =
  | "request"
  | "request/value"
  | `saq/${SAQTabKey}`
  | `saq/${SAQTabKey}/value`;

export interface Application {
  id?: number;
  created_at?: string;
  organization?: models.Organization;
  state?: models.ApplicationState;
  type?: string;
}

export enum ApplicationLogoState {
  DRAFT = "draft",
  REVIEW = "review",
  APPROVED = "approved",
}

export enum RequestType {
  UPGRADE = "upgrade",
  DOWNGRADE = "downgrade",
}
export interface ApplicationLogo {
  application?: number;
  email?: string;
  id?: number;
  image_url?: string;
  phone?: string;
  signed_document?: models.Attachment;
  state?: ApplicationLogoState;
  active_date?: string;
}
export interface ActiveApplication {
  id?: number;
  state?: ApplicationState;
  type?: AEOType;
  is_valid?: boolean;
  logo?: ApplicationLogo;
  is_manager?: boolean;
  created_at?: string;
  activation_date?: string;
  register_date?: string;
  approved_date?: string;
  revoked_date?: string;
  modified_at?: string;
  request?: ApplicationRequest;
  organization?: models.Organization;
  can_request?: boolean;
  errors?: {
    request?: any;
    saq?: any;
  };
  verification_list?: VerificationAction;
  permissions?: Permissions;
  order?: Order;
  plan?: VerificationAction;
  report?: ApplicationReport;
  re_order?: Order;
  re_report?: ApplicationReport;
  re_plan?: VerificationAction;
  authorization?: models.Attachment;
  report_order?: Order;
  saq?: SaqEntity;
  signed_document?: Attachment;
  documents?: Attachment[];
  will_be_active_date?: string;
  manager?: Profile;
}
export interface SaqEntity {
  economic_agent: SaqEconomicAgent;
}

export interface SaqEconomicAgent {
  address?: string;
  agreement_publication?: boolean;
  attachments?: models.Attachment[];
  created?: string;
  created_at?: string;
  detail_group?: string;
  employee_function?: string;
  employee_name?: string;
  id?: number;
  juridic_form?: JuridicForm;
  license_group?: boolean;
  license_name_group?: string;
  modified_at?: string;
  name?: string;
  part_of_group?: boolean;
  partial_agreement_publication?: boolean;
  reorganization?: string;
  site?: string;
}
export interface JuridicForm {
  id?: number;
  date_deleted?: string;
  name?: string;
}
export interface Order {
  id?: number;
  signed_document?: models.Attachment;
  team?: models.Profile[];
  modified_at?: string;
  created_at?: string;
  start_date?: string;
  subject?: string;
  location?: string;
  type?: string;
  application?: number;
  based?: number;
  can_edit_audit?: boolean;
  can_edit_pre_audit?: boolean;
  can_edit_re_order?: boolean;
  content?: string;
}

export interface OrderFormValues extends Omit<models.Order, "team"> {
  team?: number[];
}

export interface Permissions {
  audit?: boolean;
  audit_report?: boolean;
  pre_audit?: boolean;
  pre_order?: boolean;
  re_audit?: boolean;
  re_audit_report?: boolean;
  can_re_order?: boolean;
  can_create_re_report?: boolean;
  can_re_plan?: boolean;
}

export interface VerificationAction {
  application?: number;
  created_at?: string;
  id?: number;
  modified_at?: string;
  signed_document: models.Attachment;
}

export interface ApplicationRequest {
  id?: number;
  contact_name?: string;
  contact_phone?: string;
  contact_fax?: string;
  contact_email?: string;
  created_at?: string;
  applicant_address?: string;
  applicant_idno?: string;
  attachments?: models.Attachment[];
  activity_states?: IdName[];
  management_customs?: string;
  juridic_form?: IdName;
  activity_sector?: IdName[];
  modified_at?: string;
  applicant_name?: string;
  applicant_created?: string;
  postal_address?: string;
  activity_address?: string;
  crossing_customs?: IdName[];
  facility?: string;
  accounting_office?: string;
  signer_name?: string;
  signer_function?: string;
}

export enum ApplicationState {
  draft = "draft",

  registered = "registered",
  suspend_registered = "suspend_registered",
  withdraw_registered = "withdraw_registered",
  rejected_registered = "rejected_registered",

  pre_audit = "pre_audit",
  suspend_pre_audit = "suspend_pre_audit",
  withdraw_pre_audit = "withdraw_pre_audit",
  rejected_pre_audit = "rejected_pre_audit",

  audit = "audit",
  suspend_audit = "suspend_audit",
  withdraw_audit = "withdraw_audit",
  approved = "approved",
  rejected_audit = "rejected_audit",

  active = "active",
  suspend_active = "suspend_active",
  withdraw_active = "withdraw_active",
  revoked = "revoked",
  annul = "annul",

  re_audit = "re_audit",
  suspend_re_audit = "suspend_re_audit",
  withdraw_re_audit = "withdraw_re_audit",
  rejected_re_audit = "rejected_re_audit",
  upgraded = "upgraded",
}

export interface ManagerOrganization {
  manager?: number;
}

export interface Logo {
  application?: number;
  email?: string;
  id?: number;
  image?: string;
  phone?: string;
  signed_document?: string;
}

export interface NoteResponse {
  id: string;
  description: string;
  response?: string;
  attachment?: models.Attachment;
}

export interface ControlPlan {
  application?: number;
}

export interface ApplicationId {
  application?: number;
}

export interface ApplicationReport {
  application?: number;
  attachments?: models.Attachment[];
  conclusion_details?: string;
  created_at?: string;
  id?: number;
  modified_at?: string;
  signed_document?: models.Attachment;
  can_create_re_report?: boolean;
  can_create_project_re_report?: boolean;
  can_create_report?: boolean;
  can_create_monitoring_document?: boolean;
  monitoring_document?: models.Attachment;
  project_document?: models.Attachment;
  can_create_project_report?: boolean;
  can_move_to_active?: boolean;
}

export interface Recommendation {
  id?: number;
  description?: string;
  response?: string;
  attachment?: models.Attachment;
  can_response?: boolean;
}
export interface ReAuditRecommendation {
  conclusion_details?: string;
  id?: number;
  recommendations?: Recommendation[];
  re_audit_date?: string;
}

export interface ChangeApplicationStatusEntity {
  state?: models.StateAction;
  method: "post" | "patch";
  endpoint?: string;
  body?: {
    content?: string;
    period?: string | number;
  };
}

export interface TabsEntity {
  label?: JSX.Element;
  route: string;
  content?: JSX.Element;
  states?: string[];
  excludeStates?: string[];
  access?: boolean;
}

export interface CreateRequestEntity {
  title?: string;
  requests?: {
    name?: string;
    reason?: boolean;
    suspensionPeriod?: boolean;
    documents?: boolean;
    entity?: string;
    preview?: boolean;
  }[];
  states?: string[];
  excludeStates?: string[];
  type?: string;
  access?: boolean;
}
