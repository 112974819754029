import {
  Grid,
  WhiteSpace,
  InputFormField,
  InputPhoneFormField,
  DatePickerFormField,
  InputIDNPFormField,
} from "@aeo/core/components";

import fields from "@aeo/core/fields";
import { WithIndex } from "@aeo/core/types";

interface Props extends WithIndex {
  name?: string;
}

export const ThirdPartyPhysicFields = ({ index }: Props) => {
  const subdivision = fields.commercialSubdivision;
  const person = fields.person;

  return (
    <>
      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField
          {...person.first_name}
          name={[index, person.first_name.name]}
        />

        <InputFormField
          {...person.last_name}
          name={[index, person.last_name.name]}
        />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <DatePickerFormField
          {...person.birthday}
          name={[index, person.birthday.name]}
        />

        <InputIDNPFormField {...person.idnp} name={[index, person.idnp.name]} />
      </Grid>

      <Grid cols="1fr 1fr" gap="20px">
        <InputPhoneFormField
          {...person.phone}
          name={[index, person.phone.name]}
        />

        <InputFormField {...person.email} name={[index, person.email.name]} />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField
          {...person.address}
          name={[index, person.address.name]}
        />

        <InputFormField
          {...subdivision.third_party}
          name={[index, subdivision.third_party.name]}
        />
      </Grid>
    </>
  );
};
