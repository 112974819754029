import React from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { Form, Input, Select, useNotify } from "ebs-design";
import { SelectMode } from "ebs-design/dist/components/molecules/Select/interfaces";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import api, { querykeys } from "@aeo/core/api";
import {
  combineQueryPagesData,
  controlSelect,
  hasNextPage,
  notifyErrors,
} from "@aeo/core/utils";
import {
  WhiteSpace,
  // DebouncedInput,
  LoadingButton,
} from "@aeo/core/components";
import models from "@aeo/core/models";

import { FieldWithInfo } from "./FieldWithInfo";
import { relativeClassName } from "./utils";

interface NomenclaturesProps extends FormFieldProps {
  entityName: string;
  placeholder?: string;
  mode?: SelectMode;
  size?: string;
  info?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  labelProp?: string;
  keepPreviousData?: boolean;
  getOptionLabel?: (data: models.IdName) => React.ReactNode;
  allowAddNew?: boolean;
}

export const NomenclaturesSelect = ({
  entityName,
  size = "large",
  placeholder,
  mode,
  extra,
  info,
  required,
  rules = [],
  className,
  disabled,
  labelProp = "name",
  keepPreviousData = true,
  getOptionLabel,
  allowAddNew,
  ...props
}: NomenclaturesProps) => {
  const [search, setSearch] = React.useState("");
  const [newNomenclatures, setNewNomenclatures] = React.useState("");
  const queryParams = { search };
  const notify = useNotify();
  const queryClient = useQueryClient();

  const query = useInfiniteQuery(
    querykeys.nomenclatures.many({ entityName, ...queryParams }),
    ({ pageParam = 1 }) =>
      api.nomenclatures.getEntityList(entityName, {
        ...queryParams,
        page_size: 300, // FIXME: Check if it's needed when the select will be fixed
        page: pageParam,
      }),
    {
      keepPreviousData: keepPreviousData,
      getNextPageParam: (lastPage) =>
        hasNextPage(lastPage) ? (lastPage.page || 0) + 1 : undefined,
    },
  );
  const data = combineQueryPagesData(query.data);

  const mutation = useMutation(
    (body: { name: string }) =>
      api.nomenclatures.createEntity(entityName, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.nomenclatures.many({ entityName, ...queryParams }),
        );
        notify.success({
          title: "Item creat cu succes!",
        });
        setNewNomenclatures("");
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Form.Field
      rules={[{ required }, ...rules]}
      {...props}
      extra={<FieldWithInfo extra={extra} info={info} />}
      className={relativeClassName(info, className)}
    >
      {(_control) => {
        const control = controlSelect(_control, data);

        return (
          <Select
            loading={query.isLoading}
            placeholder={placeholder}
            size={size}
            mode={mode}
            disabled={disabled}
            {...control}
          >
            {/* FIXME: Comment temporary till de Select will be fixed */}
            {/* <DebouncedInput
              placeholder="Caută"
              value={search}
              onChange={setSearch}
            />
            <WhiteSpace v="0.5rem" /> */}

            {allowAddNew && (
              <>
                <Input
                  placeholder="Adăugare item nou"
                  loading={mutation.isLoading}
                  value={newNomenclatures}
                  onChange={(value) => setNewNomenclatures(String(value))}
                  onKeyDown={(e) => {
                    if (e.key !== "Enter" || !newNomenclatures) return;

                    mutation.mutate({ name: newNomenclatures });
                  }}
                />

                <WhiteSpace v="0.5rem" />
              </>
            )}

            <Select.Options emptyLabel="Nu a fost găsit">
              {data.map((item) => (
                <Select.Options.Item key={item.id} value={item.id || 0}>
                  {getOptionLabel
                    ? getOptionLabel(item)
                    : item[labelProp as keyof typeof item]}
                </Select.Options.Item>
              ))}
            </Select.Options>

            <WhiteSpace v="0.5rem" />
            {query.hasNextPage && (
              <LoadingButton
                loading={query.isFetching}
                onClick={() => query.fetchNextPage()}
                size="small"
              >
                Încarcă mai multe
              </LoadingButton>
            )}
          </Select>
        );
      }}
    </Form.Field>
  );
};
