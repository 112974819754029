import { FormInstance } from "ebs-design/dist/components/organisms/Form";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import messages from "@aeo/core/messages";

type Rule = Required<FormFieldProps>["rules"][number];

const passwordStrength = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})",
);

export const passwordValidatorRule = (): Rule => ({
  validateTrigger: "onSubmit",
  validator: (_, value) => {
    return value && value.match(passwordStrength)
      ? Promise.resolve()
      : Promise.reject(messages.validations.password);
  },
});

export const confirmPasswordValidatorRule = (form: FormInstance): Rule => ({
  validateTrigger: "onSubmit",
  validator: (_, value) => {
    return value === form.getFieldValue("password")
      ? Promise.resolve()
      : Promise.reject(messages.validations.confirmationPassword);
  },
});
