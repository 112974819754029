import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Button, Checkbox, Modal, useNotify, Loader } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  Upload,
  Icon,
  Flex,
  QueryList,
  WhiteSpace,
  Pagination,
  UploadItem,
} from "@aeo/core/components";
import { AttachmentImage } from "@aeo/core/components";
import { formattedDateTime, makeBEM, notifyErrors } from "@aeo/core/utils";
import { useHashSet, usePagination } from "@aeo/core/hooks";
import api, { querykeys } from "@aeo/core/api";
import models from "@aeo/core/models";
import { MAX_DOCUMENT_UPLOAD_SIZE } from "@aeo/core/app-constants";

import { AttachmentsUploadContext } from "..";

const bem = makeBEM("attachments-modal");

export interface AttachmentsUploadModalProps extends ModalProps {
  onFinish?: (urls: string[], all: models.Attachment[]) => void;
  initialAttachments?: (string | models.Attachment)[];
  multiple?: boolean;
}

export const AttachmentsUploadModal = ({
  initialAttachments,
  onFinish,
  multiple,
  ...props
}: AttachmentsUploadModalProps) => {
  const { path: apiPath } = React.useContext(AttachmentsUploadContext);
  const queryClient = useQueryClient();

  const notify = useNotify();

  const [selected, { has, toggle, add, reset, addMany, remove }] = useHashSet(
    (a?: models.Attachment) => a?.id,
  );

  React.useEffect(() => {
    reset();
    addMany(
      ...(initialAttachments?.map?.((urlOrObj) =>
        typeof urlOrObj === "string" ? { file_upload: urlOrObj } : urlOrObj,
      ) || []),
    );
  }, [initialAttachments]);

  const pagination = usePagination({ defaultPageSize: 5 });

  const queryParams = { ...pagination.queryParams, ordering: "-modified_at" };

  const query = useQuery(
    querykeys.documents.many(queryParams),
    () => api.documents(apiPath).get(queryParams),
    {
      enabled: !!props.open,
    },
  );

  const { mutate: deleteAttachmentHandler } = useMutation(
    (id: number) => api.documents(apiPath).delete(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.documents.many());
      },
      onError: (error) => {
        notifyErrors(notify, error);
      },
    },
  );

  const [isUploading, setIsUploading] = React.useState(false);

  const finishHandler = () => {
    onFinish?.(
      selected.map((a) => a?.file_upload || ""),
      selected.filter((_) => _) as models.Attachment[],
    );
    props.onClose?.();
    reset();
  };

  const handleToggle = (attachment: models.Attachment) => {
    if (multiple) {
      toggle(attachment);
    } else {
      reset();
      add(attachment);
    }
  };

  return (
    <Modal {...props} title="Incarcă fișier sau selectează din cele existente">
      <Modal.Content>
        {/* Change for Upload component: hideItems instead of showItems={false} */}
        <Upload
          name="file_upload"
          multiple
          showItems={false}
          customRequest={api.documents(apiPath).upload}
          onSuccess={(item?: UploadItem<models.Attachment>) => {
            query.refetch();
            add(item?.data);
          }}
          onError={(item) => {
            notifyErrors(notify, item?.error);
          }}
          beforeUpload={(file) => {
            if (file.size > MAX_DOCUMENT_UPLOAD_SIZE) {
              notify.error({
                title: "",
                description: "Fișierul este mai mare de 5MB",
              });
              return false;
            }
            return true;
          }}
          onChange={(items) => {
            const v = !!items?.find((i) => !i.isFinished);
            v != isUploading && setIsUploading(v);
          }}
        >
          <div className={bem("upload")}>
            <div>
              {isUploading ? (
                <Loader.Inline> </Loader.Inline>
              ) : (
                <Icon type="download" />
              )}
              <WhiteSpace h="0.5rem" />
              <h4>Trageți și fixați fișierele aici</h4>
              <p className="color-gray">
                Se acceptă doar fișiere în format: .pdf
              </p>
              <p className="color-gray">
                Mărimea maximă pentru un fișier atașat: 5 MB
              </p>
              <WhiteSpace h="0.5rem" />
              <Button type="primary">Adaugă fișiere</Button>
            </div>
          </div>
        </Upload>
        <WhiteSpace v="4rem" />
        <h3 className="flex-1">Fișiere existente</h3>
        <WhiteSpace v="2rem" />
        <div className="divider"></div>
        <QueryList
          query={query}
          getItems={(data) => data?.results}
          renderItems={(attachments?: models.Attachment[]) => (
            <div className={bem("list")}>
              {attachments?.map?.((attachment) => (
                <React.Fragment key={attachment.id}>
                  <div
                    className={bem("list-item")}
                    onClick={() => handleToggle(attachment)}
                  >
                    <Checkbox checked={has(attachment)} />
                    <AttachmentImage ext="pdf" />
                    <h4>{attachment.title}</h4>
                    <div className="color-gray">{attachment.file_size}</div>
                    <div>{formattedDateTime(attachment?.created_at)}</div>
                    {!attachment.signed && attachment.id && (
                      <Icon
                        type="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          remove(attachment);
                          deleteAttachmentHandler(attachment.id!);
                        }}
                      />
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
          emptyText={
            <div className="text-center color-gray p-30">
              La moment nu aveți nici un fișier, <br />
              le puteți adăuga mai sus
            </div>
          }
        />
        <WhiteSpace v="1.5rem" />
        <Flex justify="end">
          <Pagination
            data={query.data}
            pagination={pagination}
            pageSizeChoice={[5, 10, 15]}
          />
        </Flex>
      </Modal.Content>
      <Modal.Footer>
        <Flex justify="end">
          <Button onClick={props.onClose}>Anulează</Button>
          <WhiteSpace h="1rem" />
          <Button onClick={finishHandler} type="primary">
            Atașează
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
