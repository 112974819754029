import { Button, Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  AlertErrors,
  Flex,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";

export interface DeleteModalProps extends ModalProps {
  deleteMutation: UseMutationResult<void, AxiosError<never>, void, unknown>;
}

export const DeleteModal = ({ deleteMutation, ...props }: DeleteModalProps) => {
  return (
    <Modal title={props.title} size="small" {...props}>
      <Modal.Content>
        {props.children}
        <AlertErrors error={deleteMutation.error} alertsClassName="mt-20" />
      </Modal.Content>
      <Modal.Footer>
        <Flex justify="end">
          <Button onClick={props.onClose}>Anulează</Button>
          <WhiteSpace h="1rem" />
          <LoadingButton
            type="primary"
            loading={deleteMutation.isLoading}
            onClick={deleteMutation.mutate}
          >
            Șterge
          </LoadingButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
