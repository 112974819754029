import { Tax, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";

import { KeyValueCard, NotesCardForm } from "../components";

export const TurnoverValues = () => {
  const turnover = fields.turnover;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <KeyValueCard {...turnover.annual_turnover} type="default" NA />
      <KeyValueCard {...turnover.annual_profit} type="default" NA />
      <KeyValueCard {...turnover.rented_space} />
      <p className="p-10">{turnover.declarations.label}</p>
      <KeyValueCard {...turnover.import_declaration} type="declaration" NA />
      <KeyValueCard {...turnover.export_declaration} type="declaration" NA />
      <KeyValueCard {...turnover.suspended_mode} type="declaration" NA />
      <KeyValueCard {...turnover.customs_destination} type="declaration" NA />
      <KeyValueCard
        {...turnover.tax}
        keyList={
          <div className="p-10">
            <Tax withCustomPathName />
          </div>
        }
        type="boolean"
      />
      <KeyValueCard {...turnover.any_structural_changes} type="boolean" />
      <KeyValueCard {...turnover.structural_changes} />
      <KeyValueCard {...turnover.any_supply_chain_changes} type="boolean" />
      <KeyValueCard {...turnover.supply_chain_changes} />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...turnover.attachments} type="attachments" />
    </>
  );
};

export const Turnover = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.turnover.title}
      content={<TurnoverValues />}
      path="saq/turnover"
    />
  );
};
