export const request = {
  applicant_name: {
    name: "applicant_name",
    label: "Solicitant (denumirea completă)",
    placeholder: "Ex: John",
    info: "Se va menţiona numele complet al operatorului economic care prezintă cererea.",
  },
  applicant_created: {
    name: "applicant_created",
    label: "Data constituirii",
    placeholder: "Selectează data",
    info: "Se va menţiona, în cifre, ziua, luna şi anul constituirii.",
  },
  applicant_idno: {
    name: "applicant_idno",
    label: "Numărul de identificare (IDNO)",
    placeholder: "XXXXXXXXXXXXX",
    info: "Se va indica codul numeric unic, atribuit de organul înregistrării de stat (IDNO).",
  },
  juridic_form: {
    name: "juridic_form",
    label: "Statutul juridic al solicitantului",
    placeholder: "Selectează statutul",
    info: "Se va menţiona forma juridică de organizare cum este indicat în actul de constituire.",
  },
  applicant_address: {
    name: "applicant_address",
    label: "Adresa sediului",
    placeholder: "Ex: or. Chişinău, str. Puşkin 22",
    info: "Se va menţiona adresa completă a locului unde a fost constituită întreprindere.",
  },
  postal_address: {
    name: "postal_address",
    label: "Adresa poștală",
    info: "Se va completa doar dacă această adresă diferă de cea a sediului.",
  },
  activity_address: {
    name: "activity_address",
    label: "Sediul activității principale",
    placeholder: "Ex: or. Chişinău, str. Puşkin 22",
    info: "Se va menţiona adresa completă a locului unde este desfăşurată activitatea principală a întreprinderii.",
  },
  activity_sector: {
    name: "activity_sector",
    label: "Sectorul de activitate economică",
    placeholder: "Selectează sectorul",
    info: "Utilizați clasificatorul activităților din economia Moldovei (CAEM-2).",
  },
  activity_states: {
    name: "activity_states",
    label: "Statul (statele) cu care se desfăşoară activităţi vamale",
    placeholder: "Selectează statul",
    info: "Se va menţiona codul (codurile) ISO alpha-2 al (ale) ţării (ţărilor) respective.",
  },
  request_type: {
    name: "type",
    label: "Tipul autorizației solicitate",
    info: `Se va marca cu „X" căsuţa corespunzătoare.`,
  },
  crossing_customs: {
    name: "crossing_customs",
    label: "Informație cu privire la trecerea frontierei",
    info: "Se va indica posturile vamale folosite pentru trecerea frontierei de stat.",
  },
  facility: {
    name: "facility",
    label: "Simplificări sau facilități deja acordate, certificate",
    info: "Dacă s-au acordat deja simplificări prevăzute de Codul vamal al Republicii Moldova, se va indica tipul simplificării şi numărul autorizaţiei sau certificatului.",
  },
  management_customs: {
    name: "management_customs",
    label: "Biroul vamal de gestionare a documentației vamale",
    info: "Se va menţiona adresa completă a biroului vamal competent în a cărui rază îşi desfăşoară activitatea operatorul economic.",
  },
  accounting_office: {
    name: "accounting_office",
    label: "Biroul de contabilitate principală",
    info: "Se va menţiona adresa completă a biroului unde se desfăşoară activitatea contabilă a operatorului economic.",
  },
  contact_name: {
    name: "contact_name",
    label: "Nume și Prenume",
    placeholder: "Ex: John John",
  },
  contact_phone: {
    name: "contact_phone",
    label: "Telefon",
    placeholder: "XX-XXX-XXX",
  },
  contact_fax: {
    name: "contact_fax",
    label: "Fax",
    placeholder: "XX-XXX-XXX",
  },
  contact_email: {
    name: "contact_email",
    label: "E-mail",
    placeholder: "john@gmail.com",
  },
  contact_person: {
    info: "Se va indica numele, prenumele complet, numărul de telefon sau de fax şi e-mailul persoanei desemnate în cadrul întreprinderii ca punct de contact pentru consultare de către autoritatea vamală cu ocazia examinării cererii.",
  },
  signer_name: {
    name: "signer_name",
    label: "Nume și Prenume",
    placeholder: "Ex: John John",
    info: "Se va indica numele şi prenumele semnatarului.",
  },
  signer_function: {
    name: "signer_function",
    label: "Funcția semnatarului",
    placeholder: "Funcția semnatarului",
    info: "Se va preciza funcţia semnatarului.",
  },
  license_attachments: {
    name: "license_attachments",
    label:
      "Vă rugăm să încărcați împuternicirile care atestă dreptul la semnătură în cazul în care nu sunteți administratorul operator",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
