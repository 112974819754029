import { Actions, Button, Space, Loader } from "ebs-design";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import classnames from "classnames";

import { AttachmentImage, Flex, Icon } from "@aeo/core/components";
import {
  makeBEM,
  getStatusColor,
  getFullName,
  TableColumnBuilder,
  formattedDateTime,
  downloadFileAsLink,
} from "@aeo/core/utils";
import models from "@aeo/core/models";
import messages from "@aeo/core/messages";

import api from "api";
import { CreateRequests, Decisions, Requests } from "./components";

const bem = makeBEM("aeo-table");

interface Args {
  onDeleteClick: React.Dispatch<any>;
}

export const applicationsColumns = ({ onDeleteClick }: Args) =>
  new TableColumnBuilder<models.Application>()
    .add("Numărul cererii", "id")
    .add("Tipul autorizației", "type")
    .add("Starea cererii", "state", (state: models.ApplicationState) => (
      <div>
        <span
          className={classnames(bem("status-circle"), getStatusColor(state))}
        ></span>
        {messages.applicationStates[state]}
      </div>
    ))
    .add("Data depunerii", "created_at", formattedDateTime)
    .add("Data schimbării stării", "modified_state", formattedDateTime)
    .add("Persoana responsabilă", ["organization", "manager"], getFullName)
    .add("", "", (record: models.Application) => {
      const { mutate, isLoading } = useMutation(api.application.getDocument, {
        onSuccess: (data) => {
          downloadFileAsLink(
            data,
            `Cerere_Nr_${record?.id!}_statut_${record?.type}`,
          );
        },
      });

      return (
        <Flex justify="end">
          <div onClick={(e) => e.stopPropagation()}>
            <Space justify="end">
              <Decisions id={record?.id!} />
              <Requests id={record?.id!} />
              <CreateRequests activeApp={record} />
              <div className="border">
                <Actions placement="left" showTitle={false}>
                  <Actions.Item>
                    <Link to={`/applications/${record?.id!}`}>
                      Vizualizează
                    </Link>
                  </Actions.Item>

                  <Actions.Item>
                    <Loader loading={isLoading} size="small" height="auto">
                      <span onClick={() => record?.id && mutate(record.id)}>
                        Descarcă
                      </span>
                    </Loader>
                  </Actions.Item>

                  {messages.applicationStates[record.state!] ===
                    messages.applicationStates.draft && (
                    <Actions.Item onClick={() => onDeleteClick(record.id)}>
                      Șterge
                    </Actions.Item>
                  )}
                </Actions>
              </div>
            </Space>
          </div>
        </Flex>
      );
    })
    .getColumns();

export interface DecisionsColumnsArgs {
  downloadFile: (attachment: models.Attachment) => Promise<void>;
  sign?: React.Dispatch<any>;
  onExpandRow?: React.Dispatch<number[]>;
  documentKey: "request_document" | "document";
}

export const decisionsColumns = ({
  downloadFile,
  sign,
  onExpandRow,
  documentKey,
}: DecisionsColumnsArgs) =>
  new TableColumnBuilder<models.AeoApproval>()
    .add("", "", (record: models.AeoApproval) => (
      <Flex align="center">
        <div style={{ minHeight: "100%" }}>
          <AttachmentImage ext="pdf" />
        </div>
        <p style={{ marginLeft: "10px" }}>{record[documentKey]?.title}</p>
      </Flex>
    ))
    .add("Data", [documentKey, "created_at"], formattedDateTime)
    .add("Starea", "state", (value: keyof typeof models.ApprovalState) => (
      <div
        className={`${classnames(
          getStatusColor(models.ApprovalState[value]),
        )}--text`}
      >
        {models.ApprovalStateText[value]}
      </div>
    ))
    .add(
      "",
      "",
      (record: models.AeoApproval) => (
        <Space justify="end">
          {record?.can_approve && onExpandRow && (
            <Button onClick={() => onExpandRow([record?.id!])}>Raspunde</Button>
          )}
          {record?.can_sign && sign && (
            <Button onClick={() => sign(record.id)}>Semnează</Button>
          )}
          <Button
            onClick={() => downloadFile(record?.[documentKey]!)}
            prefix={<Icon type="download" />}
          >
            Descarcă
          </Button>
        </Space>
      ),
      { key: "btns" },
    )
    .getColumns();
