import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const PracticalStandardValues = () => {
  const standard = fields.practicalStandard;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <h4>{capitalize(messages.titles.practicalStandard)}</h4>
      <WhiteSpace />
      <KeyValueCard {...standard.has_experience} type="boolean" />
      <KeyValueCard {...standard.experience} NA />
      <KeyValueCard {...standard.experience_attachments} type="attachments" />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...standard.has_quality_standard} type="boolean" />
      <KeyValueCard {...standard.quality_standard} NA />
      <KeyValueCard
        {...standard.quality_standard_attachments}
        type="attachments"
      />
      <WhiteSpace v="1rem" />

      <h4>{messages.titles.professionalQualifications.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueCard {...standard.graduated_training_course} type="boolean" />
      <KeyValueCard {...standard.training_course} NA />
      <KeyValueCard
        {...standard.training_course_attachments}
        type="attachments"
      />

      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...standard.attachments} type="attachments" />
    </>
  );
};

export const PracticalStandard = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.standard.title}
      content={<PracticalStandardValues />}
      path="saq/standard"
    />
  );
};
