import React from "react";
import cn from "classnames";
import { useMutation } from "react-query";

import { Button, Textarea } from "ebs-design";

import { AttachmentItem, Icon, Upload, UploadItem } from "@aeo/core/components";
import { genId, makeBEM } from "@aeo/core/utils";
import coreApi from "@aeo/core/api";
import models from "@aeo/core/models";
import { useAuth } from "@aeo/core/hooks";

export interface SendMessageFormProps {
  apiCall: (reply: models.TemporaryChatReply) => Promise<models.ChatReply>;
  setJustSent: React.Dispatch<
    React.SetStateAction<models.TemporaryChatReply[]>
  >;
  uploadUrl: string;
}

const bem = makeBEM("chat-message-form");

export const SendMessageForm = ({
  apiCall,
  setJustSent,
  uploadUrl,
}: SendMessageFormProps) => {
  const { user } = useAuth();

  const [text, setText] = React.useState("");
  const [attachments, setAttachments] =
    React.useState<UploadItem<models.Attachment>[]>();

  const getMutationData = (): models.TemporaryChatReply => ({
    temporary_id: genId(),
    user: { id: user?.id },
    body: text.trim() || undefined,
    attachments:
      (attachments
        ?.map((a) => a.data)
        .filter((_) => _) as models.Attachment[]) || undefined,
    loading: true,
  });

  const onSend = (reply: models.TemporaryChatReply) =>
    setJustSent((prev) => [reply, ...(prev || [])]);

  const onSuccess = (reply: models.TemporaryChatReply) =>
    setJustSent((prev) =>
      prev?.map((r) => (r.temporary_id === reply.temporary_id ? reply : r)),
    );

  const onError = (reply: models.TemporaryChatReply) =>
    setJustSent((prev) =>
      prev?.map((r) => (r.temporary_id === reply.temporary_id ? reply : r)),
    );

  const mutation = useMutation(() => apiCall(getMutationData()), {
    onMutate: () => {
      const data = getMutationData();
      onSend(data);
      setText("");
      setAttachments([]);
      return data;
    },
    onSuccess: (data, _, context) => {
      onSuccess({
        ...data,
        temporary_id: context?.temporary_id,
        loading: false,
      });
    },
    onError: (_, __, context) => {
      onError({ ...context, error: true, loading: false });
    },
  });

  const sendDisabled = !(
    (text.trim() ||
      (attachments?.length && attachments?.some((i) => i.isSuccess))) &&
    (attachments || []).every?.((i) => i.isFinished)
  );

  return (
    <>
      <div className={cn("normal-textarea-container", bem("input-container"))}>
        <div className={bem("attachments", { empty: !attachments?.length })}>
          <Upload
            name="file_upload"
            multiple
            customRequest={coreApi.documents(uploadUrl).upload}
            value={attachments}
            onChange={setAttachments}
            renderItem={({ item, progress, onRemoveClick, idx }) => {
              return (
                <AttachmentItem
                  {...item.data}
                  key={idx}
                  progress={progress}
                  onRemove={onRemoveClick}
                  loading={!item.isFinished}
                  error={item.error}
                />
              );
            }}
          >
            <Button
              round
              type="text"
              className={cn(bem("input-btn"), bem("input-attachment"))}
              icon={() => <Icon type="attachment" />}
            />
          </Upload>
        </div>
        <Textarea
          value={text}
          onChange={setText}
          maxLength={512}
          onKeyUp={(event) =>
            !event.shiftKey &&
            event.key === "Enter" &&
            !sendDisabled &&
            mutation.mutate()
          }
        />
        <Button
          round
          type="text"
          className={cn(bem("input-btn"), bem("input-send"))}
          icon={() => <Icon type="send" />}
          disabled={sendDisabled}
          onClick={() => !sendDisabled && mutation.mutate()}
        />
      </div>
    </>
  );
};
