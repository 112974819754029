import React from "react";

export const Attachment: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M15 7L8.49999 13.5C8.10217 13.8978 7.87867 14.4374 7.87867 15C7.87867 15.5626 8.10217 16.1022 8.49999 16.5C8.89782 16.8978 9.43738 17.1213 9.99999 17.1213C10.5626 17.1213 11.1022 16.8978 11.5 16.5L18 10C18.7956 9.20435 19.2426 8.12522 19.2426 7C19.2426 5.87478 18.7956 4.79565 18 4C17.2043 3.20435 16.1252 2.75736 15 2.75736C13.8748 2.75736 12.7956 3.20435 12 4L5.49999 10.5C4.30652 11.6935 3.63603 13.3122 3.63603 15C3.63603 16.6878 4.30652 18.3065 5.49999 19.5C6.69347 20.6935 8.31217 21.364 9.99999 21.364C11.6878 21.364 13.3065 20.6935 14.5 19.5L21 13"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
