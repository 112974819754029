import models from "@aeo/core/models";
import { GenericObject } from "@aeo/core/types";
import { stringifyUrl } from "@aeo/core/api/utils";
import { apiAxios } from "@aeo/core/api/axios";

export const news = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/aeo/news`, query);
    const { data } = await apiAxios.get<models.WithResults<models.NewsItem>>(
      url,
    );
    return data;
  },
};
