import {
  Authorization,
  InfoCards,
  News,
  ApplicationStateRoadmap,
  Applications,
  Notifications,
} from "../components";

import { makeBEM } from "@aeo/core/utils";

const bem = makeBEM("dashboard");

export const Dashboard = () => {
  return (
    <div className="dashboard">
      <ApplicationStateRoadmap />
      <div className="m-20" />
      <InfoCards />
      <div className="m-20" />
      <div className={bem("row-4")}>
        <Authorization />
        <div className={bem("row-group")}>
          <Notifications className="dashboard__notification" />
          <News />
        </div>
      </div>
      <div className="m-20" />
      <Applications />
    </div>
  );
};
