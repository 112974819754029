import React from "react";

interface Args extends Omit<IntersectionObserverInit, "root"> {
  targetRef: React.RefObject<HTMLElement | null>;
  rootRef?: React.RefObject<HTMLElement | null>;
  onIntersect: () => void;
  enabled?: boolean;
}

export function useIntersectionObserver({
  targetRef,
  rootRef,
  onIntersect,
  enabled = true,
  ...options
}: Args) {
  React.useEffect(() => {
    if (!enabled || !targetRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      { ...options, root: rootRef?.current },
    );

    observer.observe(targetRef.current);

    return () => {
      targetRef.current && observer.unobserve(targetRef.current);
    };
  }, [enabled]);
}
