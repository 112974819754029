import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useNotify, FormInstance } from "ebs-design";
import { NamePath } from "rc-field-form/es/interface";

import models from "@aeo/core/models";
import { setFieldsErrors, processFormValues } from "@aeo/core/utils";
import { notifications } from "@aeo/core/messages/notifications";

import api from "api";

type FormObj = { [key: string]: any; attachments?: models.Attachment[] };
type MutationFnArgs = FormObj | FormObj[];

export function useValidateMutation(
  path: api.ApplicationValidatePath,
  form: FormInstance,
  nextStep = "",
  pathPrefix: NamePath = [],
) {
  const notify = useNotify();
  const history = useHistory();

  return useMutation<unknown, AxiosError<models.ErrorResponse>, MutationFnArgs>(
    async (values) => {
      return await api.application.validate(path, processFormValues(values));
    },
    {
      onError: (error) => {
        if (error.response?.status === 400) {
          setFieldsErrors(error.response?.data, form, notify, pathPrefix);
        }
      },
      onSuccess: () => {
        notify.success({ title: notifications.validatedData });
        history.push(nextStep);
      },
    },
  );
}
