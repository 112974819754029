import { FormInstance } from "rc-field-form";
import {
  FieldData,
  NamePath,
  InternalNamePath,
} from "rc-field-form/es/interface";

import { tryToNumber } from "@aeo/core/utils";
import models from "@aeo/core/models";
import { UseNotifyResult } from "@aeo/core/types/ebs-design";

export function extractErrorMessages(error: any) {
  let data = error?.response?.data;
  if (data?.detail instanceof Array) return [...data?.detail] as string[];
  if (data?.detail) return [data.detail as string];

  const messages: string[] = [];

  function extract(e?: models.ErrorResponse) {
    if (e instanceof Array) {
      if (typeof e[0] === "string") return messages.push(...(e as string[]));
      e.forEach((item) => extract(item as models.ErrorResponse));
    } else if (e instanceof Object) {
      Object.entries(e).forEach((e) => extract(e[1]));
    }
  }
  extract(data);

  return messages;
}

export const setFieldsErrors = (
  data: any,
  form: FormInstance,
  notify: UseNotifyResult,
  pathPrefix?: NamePath,
) => {
  let fields: FieldData[] = [];

  function extract(e: models.ErrorResponse, name: InternalNamePath = []) {
    if (e instanceof Array) {
      if (typeof e[0] === "string")
        return fields.push({ name, errors: e as string[] });

      e.forEach((item, i) =>
        extract(item as models.ErrorResponse, [...name, i]),
      );
    } else if (e instanceof Object) {
      Object.entries(e).forEach((e) => extract(e[1], [...name, e[0]]));
    }
  }

  extract(
    data,
    pathPrefix
      ? pathPrefix instanceof Array
        ? pathPrefix
        : [pathPrefix]
      : undefined,
  );

  fields = fields.map((f) => ({
    ...f,
    name:
      f.name instanceof Array ? f.name.map(tryToNumber) : tryToNumber(f.name),
  }));

  form.setFields(fields);
  notify.error({
    title: "Datele introduse nu sunt valide",
  });
};

// FIXME: This is a temporary solution to get normalize nomenclature values
const isNomenclatureValue = (value: any) =>
  typeof value === "object" &&
  value?.hasOwnProperty("id") &&
  value?.hasOwnProperty("name") &&
  Object.keys(value).length === 2;

export const normalizeNomenclatures = (values: any) => {
  const normalized = { ...values };

  Object.entries(values).forEach(([key, value]) => {
    if (isNomenclatureValue(value)) {
      normalized[key] = (value as { id: number }).id;
    } else if (Array.isArray(value)) {
      normalized[key] = value.map((v) => (isNomenclatureValue(v) ? v.id : v));
    } else if (typeof value === "undefined") {
      // If undefined is sent, it will return the previous value
      normalized[key] = null;
    }
  });

  return normalized;
};

export const processFormValues = (
  values: models.WithAttachments | models.WithAttachments[],
) =>
  values instanceof Array
    ? values.filter((value) => Object.values(value).some((v) => v))
    : values instanceof Object
    ? {
        ...values,
        // ...normalizeNomenclatures(values),
        // attachments: values.attachments?.map?.((a) => a.id),
      }
    : null;
