import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const SecurityTwoValues = () => {
  const securityTwo = fields.securityTwo;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <h4>{capitalize(messages.titles.buildingSecurity)}</h4>
      <WhiteSpace />
      <KeyValueCard {...securityTwo.location_secured} />
      <KeyValueCard {...securityTwo.procedures_verified} />
      <KeyValueCard {...securityTwo.checks_buildings} />
      <KeyValueCard {...securityTwo.register_checks} />
      <KeyValueCard {...securityTwo.register_checks_solution} />
      <KeyValueCard {...securityTwo.access_possibilities} />
      <KeyValueCard {...securityTwo.access_respected} />
      <KeyValueCard {...securityTwo.access_limits} />
      <KeyValueCard {...securityTwo.premises_light} />
      <KeyValueCard {...securityTwo.manage_keys} />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityTwo.has_described_procedure} type="boolean" />
      <KeyValueCard
        {...securityTwo.described_procedure_attachments}
        type="attachments"
      />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityTwo.has_private_parking} type="boolean" />
      <KeyValueCard {...securityTwo.parking_person} NA />
      <KeyValueCard {...securityTwo.parking_approval} NA />
      <WhiteSpace v="1rem" />

      <KeyValueCard
        {...securityTwo.has_parking_verification}
        NA
        type="boolean"
      />
      <KeyValueCard {...securityTwo.has_parking_procedure} NA type="boolean" />
      <KeyValueCard
        {...securityTwo.parking_procedure_attachments}
        type="attachments"
      />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.premisesAccess)}</h4>
      <WhiteSpace />
      <KeyValueCard {...securityTwo.access_buildings} />
      <KeyValueCard {...securityTwo.verification_person} />
      <KeyValueCard {...securityTwo.unauthorized_procedure} />
      <KeyValueCard {...securityTwo.procedure_communicated} />
      <KeyValueCard {...securityTwo.plan_subdivisions} />
      <WhiteSpace v="1rem" />

      <KeyValueCard
        {...securityTwo.plan_subdivisions_documents}
        type="attachments"
      />
      <WhiteSpace v="1rem" />
      <KeyValueCard {...securityTwo.specify_location} />

      <WhiteSpace />
      <h4>{capitalize(messages.titles.loadingUnits)}</h4>
      <WhiteSpace />
      <KeyValueCard {...securityTwo.has_restrictions_loading} type="boolean" />
      <KeyValueCard {...securityTwo.restrictions_loading} NA />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityTwo.unauthorized_access_measures} />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityTwo.has_own_seals} type="boolean" />

      <KeyValueCard {...securityTwo.seals_details} NA />
      <KeyValueCard {...securityTwo.seals_not_used} NA reverseNA />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityTwo.load_unit_measures} />
      <KeyValueCard {...securityTwo.load_unit_operators} />
      <KeyValueCard {...securityTwo.load_unit_maintains} />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...securityTwo.has_regular_maintenance} type="boolean" />
      <KeyValueCard {...securityTwo.has_external_maintenance} type="boolean" />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.logisticsProcesses)}</h4>
      <WhiteSpace />
      <KeyValueCard {...securityTwo.transportation_methods} />
      <KeyValueCard {...securityTwo.external_service} />
      <KeyValueCard {...securityTwo.external_security_standards} />
      <KeyValueCard {...securityTwo.external_measures} />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...securityTwo.attachments} type="attachments" />
    </>
  );
};

export const SecurityTwo = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.security.title}
      content={<SecurityTwoValues />}
      path="saq/security_two"
    />
  );
};
