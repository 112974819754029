import models from "@aeo/core/models";
import { apiAxios } from "@aeo/core/api/axios";

export const profile = {
  get: async () => {
    const { data } = await apiAxios.get<models.Profile>("/aeo/profile");
    return data;
  },
  patch: async (body: models.User) => {
    const { data } = await apiAxios.patch<models.User>(`/aeo/profile`, body);
    return data;
  },
};
