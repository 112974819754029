import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Form, Space, useForm, useNotify, Alert } from "ebs-design";

import { notifyErrors } from "@aeo/core/utils";
import models from "@aeo/core/models";
import {
  AttachmentsFormField,
  InputFormField,
  LoadingButton,
} from "@aeo/core/components";
import api, { querykeys } from "api";
import { useAuth } from "@aeo/core/hooks";

export const NoteForm = ({ note }: { note?: models.NoteResponse }) => {
  const [form] = useForm();
  const { id } = useParams<{ id?: string }>();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { user } = useAuth();

  const appState = user?.organization?.active_application?.state;

  const isNoteDisabled = !!appState
    ? ![
        models.ApplicationState.pre_audit,
        models.ApplicationState.audit,
      ].includes(appState)
    : false;

  const { mutate, isLoading } = useMutation(
    (v: models.NoteResponse) => api.application.patchNote(Number(id), v),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.applications.details(Number(id)),
        );
        notify.success({
          title: "Răspuns trimis cu success",
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  // Set initial values
  useEffect(() => {
    if (note) {
      form.setFieldsValue(note);
    }
  }, [form, note]);

  if (!note) return null;

  return (
    <Form
      form={form}
      onFinish={(values) => {
        mutate({
          ...values,
          id: note?.id,
        });
      }}
    >
      <h4 className="mb-5 mt-10">Mențiuni</h4>
      <Alert type="error" icon={false}>
        {note?.description}
      </Alert>
      <h4 className="mb-5 mt-10">Răspuns</h4>
      <InputFormField name="response" textarea disabled={isNoteDisabled} />
      <Space justify="space-between" align="end">
        <AttachmentsFormField name="attachment" multiple={false} />
        <LoadingButton
          className="mb-5"
          type="primary"
          loading={isLoading}
          submit
          disabled={isNoteDisabled}
        >
          Răspunde
        </LoadingButton>
      </Space>
    </Form>
  );
};
