import { KeyValueList, KeyValueRow, WhiteSpace } from "@aeo/core/components";
import { prefFieldName } from "@aeo/core/utils";
import fields from "@aeo/core/fields";
import { useFieldValue } from "@aeo/core/hooks";
import { NoteForm } from "./NoteForm";

export interface PersonListProps {
  title?: string;
  path?: string;
}

export const PersonList = ({ path }: PersonListProps) => {
  const person = fields.person;
  const value = useFieldValue(path);

  return (
    <KeyValueList
      name={["value"]}
      ItemContent={({ prefix }) => (
        <>
          <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
          <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
          <KeyValueRow
            {...prefFieldName(person.birthday, prefix)}
            type="date"
          />
          <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
          <KeyValueRow {...prefFieldName(person.phone, prefix)} />
          <KeyValueRow {...prefFieldName(person.email, prefix)} />
          <KeyValueRow {...prefFieldName(person.address, prefix)} />
          <KeyValueRow
            {...prefFieldName(person.attachments, prefix)}
            type="attachments"
          />
          <WhiteSpace v="1rem" />
          <NoteForm note={value?.note} />
        </>
      )}
    />
  );
};
