import React from "react";
import { RouteBuilder } from "@aeo/core/utils";

import {
  ConfirmRegistration,
  Login,
  Register,
  ResetPassword,
  RecoveryPassword,
  MpassLogin,
} from "./features/auth/pages";
import { Dashboard } from "./features/dashboard/pages";
import { Complaints } from "./features/complaints/pages";
import { Users } from "./features/users/pages";
import { Faq } from "./features/faq/pages";

import { Application } from "features/application/pages";
import { Settings } from "features/settings/pages/Settings";
import { ApplicationDetails } from "features/application/pages/ApplicationDetails";

export const authRoutes = new RouteBuilder()
  .add("/login", Login)
  .add("/register", Register)
  .add("/reset-password", ResetPassword)
  .add("/recovery-password", RecoveryPassword)
  .add("/confirmation", ConfirmRegistration)
  .add("/mpass", MpassLogin)
  .getRoutes();

export const appRoutes = new RouteBuilder()
  .add("/", Dashboard, true)
  .add("/complaints", Complaints)
  .add("/users", Users, false, ["user"])
  .add("/faq", Faq)
  .add("/application", Application)
  .add("/applications/:id", ApplicationDetails)
  .add("/settings", Settings)
  .add("*", () => React.createElement("div", { children: "Not found" }))
  .getRoutes();
