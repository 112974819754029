import React from "react";

export const Table: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <rect
        x={2}
        y={3}
        width={20}
        height={18}
        rx={2}
        stroke={stroke}
        strokeWidth={1.5}
      />
      <path
        d="M2 17h20M2 13h20M2 9h20M18 9v12M14 9v12M10 9v12"
        stroke={stroke}
        strokeWidth={1.5}
      />
    </svg>
  );
};
