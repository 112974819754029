import React from "react";

export const ArrowsMinimize: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...svgProps}
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10 10H6m4 0V6m0 4L4 4M10 14H6m4 0v4m0-4l-6 6M14 10V6m0 4h4m-4 0l6-6M14 14v4m0-4h4m-4 0l6 6"
      ></path>
    </svg>
  );
};
