import { ActiveApplication } from "./application";
import { Attachment } from "./attachments";
import { Organization, SimpleUser, User } from "./users";
import { MCSUserRole } from "../utils";
import { AEOType } from "./authorizations";
import { ApplicationExaminationStateType } from "../types";

export interface ApprovalDocument extends Attachment {
  approvals?: {
    created_at?: string;
    owner?: User;
    file_size?: string;
    file_upload?: string;
    id?: number;
  }[];
}

export interface Approval {
  id?: number;
  title?: string;
  can_reject?: boolean;
  can_approve?: boolean;
  can_sign?: boolean;
  created_at?: string;
  owner?: SimpleUser;
  manager?: SimpleUser;
  document?: ApprovalDocument;
  request_document?: Attachment;
  response_document?: Attachment;
  required_signatures?: MCSUserRole[];
  required_users?: SimpleUser[];
  required_approvals?: MCSUserRole[];
  required_users_approvals?: SimpleUser[];
  application?: ActiveApplication;
  organization?: Organization;
  state?: ApprovalState;
  users?: [];
  type?: ApprovalType;
  reason?: string;
  period?: string;
}

export interface AeoApproval {
  id?: number;
  title?: string;
  created_at?: string;
  state?: ApprovalState;
  document?: ApprovalDocument;
  target?: ApplicationExaminationStateType;
  can_sign?: boolean;
  request_document?: Attachment;
  can_approve?: boolean;
  type?: ApprovalDocumentType;
}

export enum ApprovalDocumentType {
  ReportProjectReAuditApproval = "ReportProjectReAuditApproval",
  ReportProjectAuditApproval = "ReportProjectAuditApproval",
}

export enum ApprovalType {
  suspend_approval = "suspend_approval",
  resume_approval = "resume_approval",
  withdraw_approval = "withdraw_approval",
  reject_approval = "reject_approval",
  audit_approval = "audit_approval",
  report_audit_approval = "report_audit_approval",
  list_pre_audit_approval = "list_pre_audit_approval",
  plan_pre_audit_approval = "plan_pre_audit_approval",
  changed_order_audit_approval = "changed_order_audit_approval",
  approved_application_approval = "approved_application_approval",
  re_audit_approval = "re_audit_approval",
  active_application_approval = "active_application_approval",
  report_re_audit_approval = "report_re_audit_approval",
  changed_order_re_audit_approval = "changed_order_re_audit_approval",
  revoked_approval = "revoked_approval",
  annulled_approval = "annulled_approval",
}

export enum ApprovalStateText {
  waiting = "În așteptare",
  approved = "Aprobat",
  rejected = "Respins",
  skiped = "Omis",
}
export enum ApprovalState {
  waiting = "waiting",
  approved = "approved",
  rejected = "rejected",
  skiped = "skiped",
}

export interface Authorization {
  id?: number;
  title?: string;
  file_upload?: string;
  file_size?: string;
  created_at?: string;
  number?: DocumentNumber;
  application?: AuthorizationApplication;
  economic_agent?: AuthorizedEconomicOperator;
}

export interface DocumentNumber {
  number?: string;
}
export interface AuthorizationApplication {
  id?: number;
  state?: string;
  type?: AEOType;
}

export interface AuthorizedEconomicOperator {
  applicant_name?: string;
  applicant_idno?: string;
}
