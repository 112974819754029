import React from "react";
import { Form, Input } from "ebs-design";
import { InputSize } from "ebs-design/dist/components/atoms/Input/Input";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { PHONE_PREFIX } from "@aeo/core/app-constants";
import { FormControl } from "@aeo/core/types";

import { FieldWithInfo } from "./FieldWithInfo";
import { relativeClassName } from "./utils";

export interface InputPhoneFormFieldProps extends FormFieldProps {
  required?: boolean;
  message?: string;
  size?: InputSize;
  info?: React.ReactNode;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  isNational?: boolean;
}

const normalizeValue = (value?: string | number) => {
  if (!value) return "";
  return String(value).replace(PHONE_PREFIX, "");
};

const normalizePhone = (value: string) => {
  const isNumber = /^\d+$/.test(value.charAt(value.length - 1));

  if (!isNumber) {
    return value.slice(0, value.length - 1);
  }

  return `${PHONE_PREFIX}${value.replace(PHONE_PREFIX, "")}`;
};

export const InputPhoneFormField = ({
  rules = [],
  required,
  message,
  size = "large",
  initialValue,
  info,
  hideLabel = false,
  extra,
  className,
  placeholder,
  maxLength = 8,
  isNational,
  ...restProps
}: InputPhoneFormFieldProps) => {
  return (
    <Form.Field
      extra={<FieldWithInfo extra={extra} info={info} />}
      className={relativeClassName(
        info,
        "input-phone input-phone--large",
        className,
      )}
      {...restProps}
      rules={[{ required, message }, ...rules]}
      {...{
        initialValue,
        hideLabel,
      }}
    >
      {(_control, _) => {
        const { value, onChange } = _control as FormControl<string | number>;

        return (
          <Input
            placeholder={placeholder}
            size={size}
            {...(isNational
              ? {
                  maxLength,
                  prefix: <>{PHONE_PREFIX}</>,
                  value: normalizeValue(value),
                  onChange: (value) => onChange(normalizePhone(String(value))),
                }
              : { value, onChange })}
          />
        );
      }}
    </Form.Field>
  );
};
