import React from "react";

export function usePopupClose(
  overlayRef: React.RefObject<Element>,
  callback: () => void,
) {
  React.useEffect(() => {
    let mousedownTarget: EventTarget | null = null;

    const mouseDownListener = (e: Event) => {
      mousedownTarget = e.target;
    };
    const mouseUpListener = (e: Event) => {
      if (e.target === overlayRef.current && mousedownTarget === e.target)
        callback();
      mousedownTarget = null;
    };
    document.addEventListener("mousedown", mouseDownListener);
    document.addEventListener("mouseup", mouseUpListener);

    return () => {
      document.removeEventListener("mousedown", mouseDownListener);
      document.removeEventListener("mouseup", mouseUpListener);
    };
  }, []);
}
