import { mixins } from "./mixins";

export const querykeys = {
  loggedUser: "logged-user",
  documents: {
    many: mixins.many("documents"),
  },
  nomenclatures: {
    many: mixins.many("nomenclatures"),
    phases: mixins.many(["nomenclatures", "phases"]),
    filterPhases: mixins.many(["nomenclatures", "filter-phases"]),
    criterions: {
      many: mixins.many(["nomenclatures", "criterions"]),
      one: mixins.one(["nomenclatures", "criterions"]),
    },
  },
  notifications: mixins.many("notifications"),
  templates: {
    many: mixins.many("templates"),
    one: mixins.one("template"),
  },
};
