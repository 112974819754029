import { Button, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";

import { useModalState, useAuth } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";

import api, { querykeys } from "api";
import { WarningModal } from ".";

export interface AEOInfoModalTriggerProps {
  type: models.AEOType;
}

export const AEOInfoModalTrigger = ({ type }: AEOInfoModalTriggerProps) => {
  const infoModal = useModalState();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { user } = useAuth();

  const activeApp = user?.organization?.active_application;

  const appIsNotAEOF =
    activeApp?.type && activeApp?.type !== models.AEOType.AEOF;

  const typeIsNotAEOF = type && type !== models.AEOType.AEOF;

  const upgradeApp = useMutation(
    (values: { type: models.AEOType }) =>
      api.application.upgrade(activeApp?.id!, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          querykeys.applications.one(activeApp?.id!),
        );
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <>
      {appIsNotAEOF && (
        <Button
          type="ghost"
          onClick={() =>
            typeIsNotAEOF
              ? infoModal.open()
              : upgradeApp.mutate({ type: models.AEOType.AEOF })
          }
          className="view-authorization__change-status-btn ml-8 mr-8 mb-10"
        >
          Completeaza cererea pentru {type}
        </Button>
      )}
      <WarningModal
        open={infoModal.isOpen}
        onClose={infoModal.close}
        size="small"
        type={type}
      />
    </>
  );
};
