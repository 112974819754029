import React from "react";
import { useNotify } from "ebs-design";
import { AxiosError } from "axios";

import models from "@aeo/core/models";
import { downloadFile } from "@aeo/core/utils";
import { getDownloadFile } from "@aeo/core/api/documents";

export const useDownloadFile = () => {
  const notify = useNotify();

  return React.useCallback(
    async (attachment: models.Attachment, type?: string) => {
      if (attachment && attachment.file_upload) {
        getDownloadFile(attachment.file_upload)
          .then((fileBlob) => {
            downloadFile(fileBlob, attachment.title, type);
          })
          .catch((error: AxiosError) => {
            notify.error({ title: error?.message });
          });
      }
    },
    [notify],
  );
};
