import React, { ReactNode } from "react";
import { Button, Card, Space } from "ebs-design";
import { Link } from "react-router-dom";

import models from "@aeo/core/models";
import { formattedDate, makeBEM } from "@aeo/core/utils";
import { Flex, WhiteSpace } from "@aeo/core/components";
import { useModalState, useAuth } from "@aeo/core/hooks";

import {
  AEOApplicationTrigger,
  AEOAuthorizationApproved,
  AEODraftTrigger,
  WarningModal,
} from "./authorization-view";
import { ChangeTypeButtons } from "./authorization-view/ChangeTypeButtons";

const bem = makeBEM("dashboard__row-4");

export const Authorization = () => {
  const { user } = useAuth();

  const infoModal = useModalState();

  const activeApp = React.useMemo(
    () => user?.organization?.active_application,
    [user?.organization?.active_application],
  );

  const appActiveState = React.useMemo(
    () =>
      [models.ApplicationState.active, models.ApplicationState.re_audit].find(
        (v) => v === activeApp?.state,
      ),
    [activeApp?.state],
  );

  const appNegativeState = React.useMemo(
    () =>
      [
        models.ApplicationState.suspend_pre_audit,
        models.ApplicationState.suspend_re_audit,
        models.ApplicationState.suspend_active,
        models.ApplicationState.suspend_audit,
        models.ApplicationState.suspend_registered,
        models.ApplicationState.rejected_registered,
        models.ApplicationState.rejected_audit,
        models.ApplicationState.withdraw_active,
        models.ApplicationState.withdraw_audit,
        models.ApplicationState.withdraw_pre_audit,
        models.ApplicationState.withdraw_registered,
        models.ApplicationState.withdraw_re_audit,
      ].find((v) => v === activeApp?.state),
    [activeApp?.state],
  );

  const applicationStateMap: Record<string, ReactNode> = {
    none: (
      <>
        <h3 className="text-center">Completează cererea pentru:</h3>
        <WhiteSpace v="1rem" />
        <Space>
          {Object.values(models.AEOType).map((type, i) => (
            <AEODraftTrigger type={type} key={i} />
          ))}
        </Space>
      </>
    ),
    [models.ApplicationState.draft]: (
      <>
        <h3 className="text-center">
          Cererea cu statutul {activeApp?.type} este în proces de completare
        </h3>
        <WhiteSpace v="1rem" />
        <ChangeTypeButtons appType={activeApp?.type} />
        <Flex>
          <Link to={`/application/${activeApp?.type?.toLowerCase()}`}>
            <Button type="primary">Continuă</Button>
          </Link>
        </Flex>
        <WarningModal
          open={infoModal.isOpen}
          onClose={infoModal.close}
          size="small"
          type={activeApp?.type}
        />
      </>
    ),
    [models.ApplicationState.registered]: (
      <>
        <h3 className="text-center">
          Cererea pentru acordarea statutului {activeApp?.type} a fost depusă la{" "}
          {formattedDate(activeApp?.register_date)} cu numarul de inregistrare{" "}
          {activeApp?.id}
        </h3>
        <WhiteSpace v="1rem" />
        <ChangeTypeButtons appType={activeApp?.type} />
      </>
    ),
    [models.ApplicationState.pre_audit]: (
      <>
        <h3 className="text-center">
          Cererea pentru acordarea statutului {activeApp?.type} este primită
          spre examinare.
        </h3>
        <WhiteSpace v="1rem" />

        <ChangeTypeButtons appType={activeApp?.type} />
      </>
    ),
    [models.ApplicationState.audit]: (
      <>
        <h3 className="text-center">
          Cererea pentru acordarea statutului {activeApp?.type} este în procesul
          de examinare a condițiilor.
        </h3>
        <WhiteSpace v="1rem" />

        <ChangeTypeButtons appType={activeApp?.type} />
      </>
    ),

    [models.ApplicationState.revoked]: (
      <h3 className="text-center">
        Cererea dumneavoastră a fost revocată la data de{" "}
        {formattedDate(activeApp?.revoked_date)}. Puteți reaplica după un termen
        de 3 ani din această dată.
      </h3>
    ),

    [models.ApplicationState.annul]: (
      <h3 className="text-center">
        Autorizația dumnevoastră a fost anulată și nu mai puteți aplica.
      </h3>
    ),
    [models.ApplicationState.withdraw_active]: (
      <>
        <h3 className="text-center mx-auto">Autorizația a fost retrasă.</h3>
        <WhiteSpace v="1rem" />
        <h3 className="text-center mx-auto">Completează cererea pentru:</h3>
        <WhiteSpace v="1rem" />
        <Space>
          {Object.values(models.AEOType).map((type, i) => (
            <AEODraftTrigger type={type} key={i} />
          ))}
        </Space>
      </>
    ),
    [models.ApplicationState.approved]: (
      <h3 className="text-center mx-auto">
        Autorizația dumneavoastră {activeApp?.type} va intra în vigoare din data
        de {formattedDate(activeApp?.will_be_active_date)}
      </h3>
    ),
    [appNegativeState as models.ApplicationState]: (
      <AEOApplicationTrigger activeApplication={activeApp} />
    ),
    [appActiveState as models.ApplicationState]: (
      <AEOAuthorizationApproved activeApplication={activeApp} />
    ),
  };

  return (
    <Card className={bem("authorization")}>
      <Card.Header>
        <h3 className="pt-2 pb-2">Autorizația AEO</h3>
      </Card.Header>
      <Card.Body>
        <Flex direction="column" align="center">
          {applicationStateMap[activeApp?.state || "none"]}
        </Flex>
      </Card.Body>
    </Card>
  );
};
