import {
  Grid,
  WhiteSpace,
  InputFormField,
  InputPhoneFormField,
  InputIDNPFormField,
} from "@aeo/core/components";

import fields from "@aeo/core/fields";
import { WithIndex } from "@aeo/core/types";
import { inputFormatIntegers } from "@aeo/core/utils";

interface Props extends WithIndex {
  name?: string;
}

export const ThirdPartyJuridicFields = ({ index }: Props) => {
  const subdivision = fields.commercialSubdivision;
  const person = fields.person;

  return (
    <>
      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField {...person.name} name={[index, person.name.name]} />

        <InputIDNPFormField {...person.idno} name={[index, person.idno.name]} />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <InputPhoneFormField
          {...person.phone}
          name={[index, person.phone.name]}
        />

        <InputFormField
          {...person.address}
          name={[index, person.address.name]}
        />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px" items="end">
        <InputFormField
          {...subdivision.third_party}
          name={[index, subdivision.third_party.name]}
          className="mb-0"
        />

        <InputFormField
          {...subdivision.employee_count}
          type="number"
          onKeyDown={inputFormatIntegers}
          nullValue
          name={[index, subdivision.employee_count.name]}
        />
      </Grid>
    </>
  );
};
