export enum SAQTabKey {
  EconomicAgent = "economic_agent",
  Founders = "founders",
  Shareholders = "shareholders",
  Directors = "directors",
  Advisory = "advisory",
  ContactPerson = "contact",
  CustomerPerson = "customer",
  CommercialActivity = "commercial",
  OrganizationStructure = "structure",
  Turnover = "turnover",
  CustomsStatistic = "statistic",
  ComplianceLegislation = "legislation",
  FinancialSolvency = "solvency",
  PracticalStandard = "standard",
  LogisticSystemPartOne = "logistic_one",
  LogisticSystemPartTwo = "logistic_two",
  LogisticSystemPartThree = "logistic_three",
  SecurityPartOne = "security_one",
  SecurityPartTwo = "security_two",
  SecurityPartThree = "security_three",
  SecurityPartFour = "security_four",
}
