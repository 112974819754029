import { Form } from "ebs-design";
import {
  Grid,
  WhiteSpace,
  InputFormField,
  InputPhoneFormField,
  FormList,
  BooleanRadioFormField,
} from "@aeo/core/components";

import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import { capitalize, inputFormatIntegers } from "@aeo/core/utils";
import { WithIndex } from "@aeo/core/types";

import { ThirdPartyJuridicFields, ThirdPartyPhysicFields } from "./";

interface Props extends WithIndex {
  name?: string;
}

export const SubdivisionFields = ({ index, name }: Props) => {
  const subdivision = fields.commercialSubdivision;

  return (
    <>
      <Grid rows="1fr" cols="1fr 1fr" gap="20px">
        <InputFormField
          {...subdivision.name}
          name={[index, subdivision.name.name]}
        />

        <InputFormField
          {...subdivision.address}
          name={[index, subdivision.address.name]}
        />
      </Grid>

      <WhiteSpace />

      <Grid rows="1fr" cols="1fr 1fr" gap="20px">
        <InputPhoneFormField
          {...subdivision.phone}
          name={[index, subdivision.phone.name]}
        />

        <InputFormField
          {...subdivision.email}
          name={[index, subdivision.email.name]}
        />
      </Grid>

      <WhiteSpace />

      <Grid rows="1fr" cols="1fr 1fr" gap="20px">
        <InputFormField
          {...subdivision.employee_count}
          type="number"
          onKeyDown={inputFormatIntegers}
          nullValue
          name={[index, subdivision.employee_count.name]}
        />
      </Grid>

      <WhiteSpace />

      <InputFormField
        textarea
        {...subdivision.activity}
        name={[index, subdivision.activity.name]}
      />

      <WhiteSpace />

      <BooleanRadioFormField
        radioField={{
          ...subdivision.exists_third_party,
          name: [index, subdivision.exists_third_party.name],
          defaultDepsValue: [
            { [`${name}.${index}.${subdivision.third_party_physic.name}`]: [] },
            {
              [`${name}.${index}.${subdivision.third_party_juridic.name}`]: [],
            },
          ],
        }}
      />

      <Form.Field
        dependencies={[
          name as string,
          index,
          subdivision.exists_third_party.name,
        ]}
        shouldUpdate={(prevValues, nextValues) => {
          return !prevValues.subdivisions.every(
            (v: any, idx: number) =>
              nextValues.subdivisions[idx]?.exists_third_party ===
              v?.exists_third_party,
          );
        }}
      >
        {(_, __, form) => {
          const value = form.getFieldValue([
            name as string,
            index,
            subdivision.exists_third_party.name,
          ]);

          return value ? (
            <>
              <h4>{capitalize(messages.titles.physicPerson)}</h4>
              <FormList
                subTitle={messages.titles.thirdPartySubdivision}
                name={[index, subdivision.third_party_physic.name]}
                initialValue={[]}
                Component={ThirdPartyPhysicFields}
              />

              <WhiteSpace />

              <h4>{capitalize(messages.titles.juridicPerson)}</h4>
              <FormList
                subTitle={messages.titles.thirdPartySubdivision}
                name={[index, subdivision.third_party_juridic.name]}
                initialValue={[]}
                Component={ThirdPartyJuridicFields}
              />
            </>
          ) : null;
        }}
      </Form.Field>
    </>
  );
};
