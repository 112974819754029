import txtSrc from "./assets/Document_text.svg";
import audioSrc from "./assets/Music_note.svg";
import excelSrc from "./assets/Excel.svg";
import pdfSrc from "./assets/PDF.svg";
import photoSrc from "./assets/Photograph_image.svg";
import videoSrc from "./assets/Video_camera.svg";
import codeSrc from "./assets/Code.svg";

import fallbackSrc from "./assets/Document.svg";

export function getImageSrc(ext: string) {
  const extensionMap: { [key: string]: string } = {
    txt: txtSrc,
    mp3: audioSrc,
    xls: excelSrc,
    pdf: pdfSrc,
    photo: photoSrc,
    mp4: videoSrc,
    js: codeSrc,
  };

  return extensionMap[ext] || fallbackSrc;
}
