import { BadgeTabLabel, TabLabel, TabsWithRoute } from "@aeo/core/components";
import messages from "@aeo/core/messages";
import { TabBuilder } from "@aeo/core/utils";
import { Request } from "features/request/pages";
import { saqFormsTabs } from "features/saq/config";
import { Saq } from "features/saq/pages";
import { Signing } from "features/signing/pages";
import {
  NotesCardForm,
  PersonList,
  SaqDetails,
  Recommendations,
} from "./components";
import {
  ComplianceLegislation,
  Contact,
  Customer,
  CustomsStatistic,
  EconomicAgent,
  FinancialSolvency,
  LogisticOne,
  LogisticThree,
  LogisticTwo,
  OrganizationStructure,
  PracticalStandard,
  RequestDetails,
  SecurityFour,
  SecurityOne,
  SecurityThree,
  SecurityTwo,
  Turnover,
  Shareholders,
  CommercialActivity,
  Advisory,
} from "./Summary";

const saq = messages.SAQTabs;

export const saqDetailsTabs = new TabBuilder()
  .add(saq.economicAgent.key, <EconomicAgent />, true)
  .add(saq.shareholders.key, <Shareholders />)
  .add(
    saq.directors.key,
    <NotesCardForm
      title={saq.directors.title}
      content={<PersonList title={saq.directors.title} />}
      path="saq/directors"
    />,
  )
  .add(saq.advisory.key, <Advisory />)
  .add(saq.contact.key, <Contact />)
  .add(saq.customer.key, <Customer />)
  .add(saq.commercial.key, <CommercialActivity />)
  .add(saq.structure.key, <OrganizationStructure />)
  .add(saq.turnover.key, <Turnover />)
  .add(saq.statistic.key, <CustomsStatistic />)
  .add(saq.legislation.key, <ComplianceLegislation />)
  .add(saq.logisticOne.key, <LogisticOne />)
  .add(saq.logisticTwo.key, <LogisticTwo />)
  .add(saq.logisticThree.key, <LogisticThree />)
  .add(saq.solvency.key, <FinancialSolvency />)
  .add(saq.standard.key, <PracticalStandard />)
  .add(saq.securityOne.key, <SecurityOne />)
  .add(saq.securityTwo.key, <SecurityTwo />)
  .add(saq.securityThree.key, <SecurityThree />)
  .add(saq.securityFour.key, <SecurityFour />)
  .getTabs();

export const applicationsTabs = [
  {
    label: <TabLabel index={1} title="Cerere de autorizație AEO" />,
    route: "request",
    content: <Request />,
  },
  {
    label: <TabLabel index={2} title="Chestionar de autoevaluare" />,
    route: "saq",
    content: <Saq tabs={saqFormsTabs} />,
  },
  {
    label: <TabLabel index={3} title="Spre semnare" />,
    route: "signing",
    content: <Signing />,
  },
];

const recommendationTabs = [
  {
    label: <BadgeTabLabel title="Audit" />,
    route: "audit",
    content: <Recommendations type="audit" />,
  },
  {
    label: <BadgeTabLabel title="Reaudit" />,
    route: "re-audit",
    content: <Recommendations type="re-audit" />,
  },
];

export const applicationDetailsTabs = [
  {
    label: <TabLabel index={1} title="Cerere de autorizație AEO" />,
    route: "request",
    content: <RequestDetails />,
  },
  {
    label: <TabLabel index={2} title="Chestionar de autoevaluare" />,
    route: "saq",
    content: <SaqDetails tabs={saqDetailsTabs} />,
  },
  {
    label: <TabLabel index={3} title="Recomandări" />,
    route: "recommendations",
    content: <TabsWithRoute type="badges" tabItems={recommendationTabs} />,
  },
];
