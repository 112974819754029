import { useEffect } from "react";
import { useMutation } from "react-query";
import { Form, useForm, useNotify } from "ebs-design";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { makeBEM, delay, notifyErrors } from "@aeo/core/utils";
import { useRouter } from "@aeo/core/hooks";
import {
  confirmPasswordValidatorRule,
  passwordValidatorRule,
} from "@aeo/core/utils/validators";
import {
  InputFormField,
  InputPasswordShowField,
} from "@aeo/core/components/form-utils";
import { AlertErrors, LoadingButton } from "@aeo/core/components";

import api from "api";

const bem = makeBEM("auth-layout");

interface Props {
  submitTitle?: string;
}

export const ResetPasswordForm = ({
  submitTitle = "Resetare parolă",
}: Props) => {
  const [form] = useForm();
  const { push } = useRouter();
  const notify = useNotify();
  const [queryParams] = useQueryParams({
    email: withDefault(StringParam, ""),
    token: withDefault(StringParam, ""),
  });

  // Redirect to login page if token and email are missing
  useEffect(() => {
    if (!queryParams.token && !queryParams.email) {
      push("/login");
    }
  }, [queryParams.token, queryParams.email, push]);

  const { mutate, isLoading, error } = useMutation(api.auth.resetPassword, {
    onSuccess: (res) => {
      notify.success({
        title: res.message || "Parola a fost resetată cu succes!",
      });
      form.resetFields();

      // Delay to show success message
      delay(2000).then(() => push("/login"));
    },
    onError: (error) => notifyErrors(notify, error),
  });

  return (
    <Form
      form={form}
      onFinish={({ email, ...values }) =>
        mutate({
          token: queryParams.token,
          ...values,
        })
      }
    >
      <InputFormField
        disabled
        name="email"
        initialValue={queryParams.email}
        placeholder="E-mail"
        type="email"
      />

      <InputPasswordShowField
        size="large"
        placeholder="Parola"
        name="password"
        validateTrigger="onSubmit"
        rules={[
          {
            validateTrigger: "onSubmit",
          },
          passwordValidatorRule(),
        ]}
      />

      <InputPasswordShowField
        size="large"
        placeholder="Confirmă parola"
        name="confirm_password"
        validateTrigger="onSubmit"
        rules={[
          {
            validateTrigger: "onSubmit",
          },
          confirmPasswordValidatorRule(form),
        ]}
      />

      <LoadingButton
        size="large"
        className={bem("form__btn")}
        type="primary"
        submit
        loading={isLoading}
      >
        {submitTitle}
      </LoadingButton>
      <AlertErrors error={error} />
    </Form>
  );
};
