import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { Button, Form, Modal, useForm, useNotify, Select } from "ebs-design";

import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import {
  Flex,
  InputFormField,
  InputIDNPFormField,
  InputPhoneFormField,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";
import {
  getProperty,
  aeoUserRolesOptions,
  setFieldsErrors,
} from "@aeo/core/utils";

import api from "api";
import messages from "@aeo/core/messages";

interface Props extends ModalProps {
  data?: models.User;
}

export const UserFormModal = ({ data, ...props }: Props) => {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const isEditing = useMemo(() => !!data, [data]);

  const { mutate, isLoading } = useMutation<
    unknown,
    AxiosError<models.ErrorResponse>,
    models.User
  >(
    async (values) =>
      isEditing
        ? api.users.editUser({ id: data?.id, ...values })
        : api.users.createUser(values),
    {
      onError: (error) => {
        if (error.response?.status === 400)
          setFieldsErrors(getProperty(error.response?.data), form, notify);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        form.resetFields();
        props?.onClose?.();
      },
    },
  );

  return (
    <Modal
      title={isEditing ? "Editează utilizatorul" : "Adaugă utilizator"}
      size="small"
      {...props}
    >
      <Form
        form={form}
        initialValues={data}
        onFinish={(values: models.User) => mutate(values)}
      >
        <Modal.Content>
          <InputFormField name="last_name" label="Nume" required />
          <InputFormField name="first_name" label="Prenume" required />
          <InputIDNPFormField
            name="idnp"
            label="IDNP"
            rules={[
              {
                min: 13,
                max: 13,
                required: true,
                message: messages.validations.IDNP,
              },
            ]}
          />
          {!isEditing && (
            <InputFormField name="username" label="E-mail" required />
          )}

          <InputPhoneFormField name="phone" label="Telefon" required />
          <InputFormField
            name={["profile", "function"]}
            label="Funcție"
            required
          />
          <Form.Field name={["profile", "role"]} label="Rol utilizator">
            <Select size="large" options={aeoUserRolesOptions} />
          </Form.Field>
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={props.onClose}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton loading={isLoading} submit type="primary">
              {isEditing ? "Salvează modificarile" : "Adaugă utilizator"}
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
