import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Form, Modal, useForm, useNotify } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { AxiosError } from "axios";

import {
  AttachmentsFormField,
  InputPhoneFormField,
  InputFormField,
  LoadingButton,
  WhiteSpace,
  Flex,
} from "@aeo/core/components";
import { OtherRequest } from "@aeo/core/types";
import { setFieldsErrors, wrapInArr } from "@aeo/core/utils";
import models from "@aeo/core/models";
import { useAuth } from "@aeo/core/hooks";

import api, { querykeys } from "api";

interface RequestFormModalProps extends ModalProps {
  data?: OtherRequest;
  onClose: () => void;
}

export const RequestFormModal = ({ data, ...props }: RequestFormModalProps) => {
  const { user } = useAuth();
  const [form] = useForm();

  const notify = useNotify();
  const queryClient = useQueryClient();

  const activeApp = user?.organization?.active_application;

  //check logo request type
  const isEditingLogo = React.useMemo(
    () => !!data?.entity && data?.type === "logo",
    [data],
  );

  //Mutation methods
  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(querykeys.loggedUser);
      props.onClose();
      form.resetFields();
    },
    onError: (error: any) => {
      const err = error as AxiosError<any>;
      const wrapError = wrapInArr(err.response?.data?.detail);

      if (wrapError.length) wrapError.map((v) => notify.error({ title: v }));

      if (error.response?.status === 400)
        setFieldsErrors(error.response?.data, form, notify);
    },
  };

  //Application mutation
  const appMutation = useMutation(
    async (values: models.RequestStatus) =>
      api.application.patchState(activeApp?.id!, values, data?.entity!),
    mutationOptions,
  );

  //Logo mutation
  const logoMutation = useMutation(
    async (values: models.RequestStatus) =>
      isEditingLogo
        ? api.application.patchLogo(activeApp?.logo?.id!, {
            type: data?.entity!,
            body: values,
          })
        : api.application.postLogo(values),
    mutationOptions,
  );

  //Handle form values
  const handleForm = (values: models.RequestStatus) => {
    const mutationValues = {
      ...values,
      application: activeApp?.id,
    };

    data?.type === "logo"
      ? logoMutation.mutate(mutationValues)
      : appMutation.mutate(mutationValues);
  };

  return (
    <Modal header={<h3>{data?.name}</h3>} size="small" {...props}>
      <Form
        form={form}
        onFinish={handleForm}
        initialValues={{
          name: user?.organization?.name,
          idno: user?.organization?.idno,
          address: activeApp?.request?.applicant_address,
          phone: user?.phone,
          email: user?.email,
        }}
      >
        <Modal.Content>
          <InputFormField name="name" label="Numele companiei" disabled />
          <InputFormField name="idno" label="IDNO" disabled />
          <InputFormField name="address" label="Adresa juridică" disabled />
          <InputPhoneFormField name="phone" label="Telefon" />
          <InputFormField name="email" label="E-mail" />

          {data?.reason && (
            <InputFormField name="reason" label="Motiv" textarea />
          )}
          {data?.suspensionPeriod && (
            <InputFormField
              type="number"
              name="suspension_Period"
              label="Perioada Suspendării"
              placeholder="Întroduce numarul de zile"
              required
            />
          )}
          {data?.documents && (
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Flex justify="end">
            <Button onClick={props.onClose}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton submit type="primary">
              Salvează
            </LoadingButton>
          </Flex>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
