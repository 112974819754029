import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const LogisticTwoValues = () => {
  const logisticTwo = fields.logisticTwo;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <h4>{capitalize(messages.titles.flowOfGoods)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logisticTwo.registration_procedure} />
      <KeyValueCard {...logisticTwo.stock_procedure} />

      <WhiteSpace v="1rem" />
      <h4>{capitalize(messages.titles.customsFormalities)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logisticTwo.has_declaration_process} type="boolean" />
      <KeyValueCard {...logisticTwo.declaration_process} NA />
      <KeyValueCard
        {...logisticTwo.declaration_process_attachments}
        type="attachments"
      />
      <WhiteSpace v="1rem" />
      <KeyValueCard {...logisticTwo.has_checking_declaration} type="boolean" />
      <KeyValueCard {...logisticTwo.checking_declaration} NA />

      <WhiteSpace v="1rem" />

      <KeyValueCard
        {...logisticTwo.has_irregularities_instruction}
        type="boolean"
      />
      <KeyValueCard
        {...logisticTwo.has_documented_instruction}
        type="boolean"
      />
      <KeyValueCard
        {...logisticTwo.documented_instruction_attachments}
        type="attachments"
      />
      <KeyValueCard {...logisticTwo.has_identified_violation} type="boolean" />
      <KeyValueCard {...logisticTwo.identified_violation} />
      <KeyValueCard {...logisticTwo.has_licensed_goods} type="boolean" />
      <KeyValueCard
        {...logisticTwo.licensed_goods_attachments}
        type="attachments"
      />
      <WhiteSpace v="1rem" />

      <KeyValueCard {...logisticTwo.has_external_license} type="boolean" />
      <KeyValueCard {...logisticTwo.external_license} NA />
      <KeyValueCard
        {...logisticTwo.external_license_attachments}
        type="attachments"
      />

      <WhiteSpace v="1rem" />

      <KeyValueCard {...logisticTwo.has_goods_under_law} type="boolean" />
      <KeyValueCard {...logisticTwo.goods_under_law} NA />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logisticTwo.attachments} type="attachments" />
    </>
  );
};

export const LogisticTwo = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.logistic.title}
      content={<LogisticTwoValues />}
      path="saq/logistic_two"
    />
  );
};
