import { Form, Radio } from "ebs-design";
import { booleanRadioOptions, relativeClassName } from "./utils";
import { FieldWithInfo } from "./FieldWithInfo";
import { RadioProps } from "..";

export const RadioFormField = ({
  options = booleanRadioOptions,
  rules = [],
  required,
  initialValue,
  info,
  extra,
  className,
  vertical,
  ...restProps
}: RadioProps) => {
  return (
    <Form.Field
      extra={<FieldWithInfo extra={extra} info={info} />}
      className={relativeClassName(info, "radio-field", className, {vertical})}
      {...restProps}
      rules={[{ required }, ...rules]}
      {...{
        initialValue,
      }}
    >
      <Radio options={options} />
    </Form.Field>
  );
};
