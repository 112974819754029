import { useSetState } from "react-use";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

import defaults from "@aeo/core/app-defaults";
import models from "@aeo/core/models";

export interface UsePaginationResult {
  page: number;
  pageSize: number;
  next: () => void;
  prev: () => void;
  setPage: (p: number | undefined) => void;
  setPageSize: (l: number | undefined) => void;
  queryParams: models.PaginationQueryParams;
}

export interface UsePaginationArgs {
  defaultPageSize?: number;
  defaultPage?: number;
}

export function useQueryPagination({
  defaultPageSize = defaults.pagination.pageSize,
  defaultPage = 1,
}: UsePaginationArgs = {}): UsePaginationResult {
  const [{ page, page_size }, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, defaultPage),
    page_size: withDefault(NumberParam, defaultPageSize),
  });

  return {
    queryParams: { page, page_size },
    page: page,
    pageSize: page_size,
    next: () => (page ? setQueryParams({ page: page + 1 }) : undefined),
    prev: () => (page ? setQueryParams({ page: page - 1 }) : undefined),
    setPage: (p) => setQueryParams({ page: p }),
    setPageSize: (ps) => {
      setQueryParams({ page_size: ps, page: 1 });
    },
  };
}

export function usePagination({
  defaultPageSize = defaults.pagination.pageSize,
  defaultPage = 1,
}: UsePaginationArgs = {}): UsePaginationResult {
  const [{ page, page_size }, setState] = useSetState({
    page: defaultPage,
    page_size: defaultPageSize,
  });

  return {
    queryParams: { page, page_size },
    page: page,
    pageSize: page_size,
    next: () => (page ? setState({ page: page + 1 }) : undefined),
    prev: () => (page ? setState({ page: page - 1 }) : undefined),
    setPage: (p) => setState({ page: p }),
    setPageSize: (ps) => {
      setState({ page_size: ps, page: 1 });
    },
  };
}
