import { mixins } from "@aeo/core/api/querykeys/mixins";

export const querykeys = {
  loggedUser: "logged-user",

  users: {
    settings: ["users", "settings"],
    many: mixins.many("users"),
  },

  draftApplication: "draft-application",
  news: mixins.many("news"),
  applications: {
    one: mixins.one("applications"),
    many: mixins.many("applications"),
    details: mixins.one("application-details"),
    logo: mixins.one("logo"),
    documents: mixins.one("application-documents"),
    recommendation: mixins.one("recommendation"),
    recommendationDocument: mixins.one("recommendation-document"),
  },
  complaints: {
    many: mixins.many("complaints"),
  },
  chat: {
    replies: mixins.many("chat-replies"),
  },
  mpassSAML: mixins.one("saml"),
};
