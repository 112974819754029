import React from "react";
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";

import * as dateFNS from "date-fns";

import { Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { parseDate } from "../../utils";
import { DATE_FORMAT } from "../../app-constants";

import { FieldWithInfo } from "./FieldWithInfo";
import { relativeClassName } from "./utils";

/**
 * Register and set default locale to romanian
 */
import ro from "date-fns/locale/ro";
import { FormControl } from "../../types";
registerLocale("ro", ro);
setDefaultLocale("ro");

export interface DatePickerFormFieldProps extends FormFieldProps {
  type?: string;
  required?: boolean;
  placeholder?: string;
  showTimeSelect?: boolean;
  info?: React.ReactNode;
}

export const DATEPICKER_ALLOWED_FORMATS = ["dd/MM/yyyy", "dd.MM.yyyy"];

type Value = string | null | undefined;

export const DatePickerFormField = ({
  rules = [],
  required,
  type,
  initialValue,
  hideLabel = false,
  info,
  extra,
  showTimeSelect = false,
  className,
  name,
  ...restProps
}: DatePickerFormFieldProps) => {
  return (
    <Form.Field
      name={name}
      extra={<FieldWithInfo label={extra} info={info} />}
      className={relativeClassName(info, className)}
      {...restProps}
      rules={[{ required }, ...rules]}
      {...{
        initialValue,
        hideLabel,
      }}
    >
      {(_control, _validate, form) => {
        const control = _control as FormControl<Value>;
        const value: Value = form.getFieldValue(_validate.name || []);

        const selected = value
          ? parseDate(value, DATEPICKER_ALLOWED_FORMATS)
          : undefined;

        /**
         * Forced to copy implementation from ebs-design to allow multiple formats
         */
        return (
          <ReactDatePicker
            selected={selected}
            onChange={(date: Date | undefined) => {
              if (date) control.onChange(dateFNS.format(date, DATE_FORMAT));
              else control.onChange(null);
            }}
            showYearDropdown
            dateFormat={DATEPICKER_ALLOWED_FORMATS}
            className={`ebs-datepicker ebs-datepicker--large`}
            wrapperClassName={"ebs-datepicker__wrapper"}
            popperClassName={"ebs-datepicker__popper"}
            calendarClassName={"ebs-datepicker__calendar"}
          />
        );
      }}
    </Form.Field>
  );
};
