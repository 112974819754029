import { Card, Form, Alert, useForm } from "ebs-design";
import {
  InputFormField,
  WhiteSpace,
  BooleanRadioFormField,
  AttachmentsFormField,
  LoadingButton,
  Flex,
  FormList,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";

import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableFormList } from "..";
import { ClientFields, RepresentativeFields } from ".";

export const CustomsStatisticForm = () => {
  const statistics = {
    ...fields.customsStatistic,
    ...fields.representStatistic,
  };
  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/statistic", form);
  useDraftApplicationQuery("saq/statistic", form);
  const validateMutation = useValidateMutation(
    "saq/statistic",
    form,
    "legislation",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.statistic.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <Card className="bordered">
              <Card.Body>
                <BooleanRadioFormField
                  radioField={statistics.carry_formalities}
                />
                <NotApplicableFormList
                  dependencies={[statistics.carry_formalities.name]}
                  name={statistics.represent_persons.name}
                  reverse
                >
                  <>
                    <Alert type="info">
                      Pentru a adăuga lista completă a reprezentanților, puteți
                      adăuga manual sau să atașați lista.
                    </Alert>

                    <FormList
                      subTitle={statistics.represent_persons.label}
                      name={statistics.represent_persons.name}
                      Component={RepresentativeFields}
                      emptyList
                    />

                    <WhiteSpace v="20px" />
                  </>
                </NotApplicableFormList>
                <NotApplicableFormList
                  dependencies={[statistics.carry_formalities.name]}
                  name={statistics.represent_persons_attachments.name}
                  reverse
                >
                  <AttachmentsFormField
                    name={statistics.represent_persons_attachments.name}
                  >
                    Atașează lista de reprezentanți
                  </AttachmentsFormField>
                </NotApplicableFormList>

                <BooleanRadioFormField radioField={statistics.has_represent} />
                <NotApplicableFormList
                  dependencies={[statistics.has_represent.name]}
                  name="clients"
                >
                  <>
                    <Alert type="info">
                      Pentru a adăuga lista completă a clienților, puteți adăuga
                      manual sau să atașați lista.
                    </Alert>

                    <FormList
                      subTitle="Client"
                      name="clients"
                      Component={ClientFields}
                    />
                    <WhiteSpace v="20px" />
                  </>
                </NotApplicableFormList>
                <NotApplicableFormList
                  dependencies={[statistics.has_represent.name]}
                  name={statistics.attachments_clients.name}
                >
                  <AttachmentsFormField
                    name={statistics.attachments_clients.name}
                  >
                    Atașează lista de clienți
                  </AttachmentsFormField>
                </NotApplicableFormList>
              </Card.Body>
            </Card>

            <WhiteSpace v="20px" />

            <Card className="bordered">
              <Card.Body>
                <InputFormField {...statistics.who_decide_classification} />
                <InputFormField {...statistics.classification_measures} />
                <BooleanRadioFormField
                  radioField={statistics.register_measures}
                />
                <BooleanRadioFormField
                  radioField={statistics.monitoring_measures}
                />
                <InputFormField {...statistics.resource_measures} />
              </Card.Body>
            </Card>

            <WhiteSpace v="20px" />

            <Card className="bordered">
              <Card.Body>
                <InputFormField {...statistics.determine_values} />
                <InputFormField {...statistics.correct_steps} />
                <BooleanRadioFormField
                  radioField={statistics.regularly_monitoring}
                />
                <BooleanRadioFormField
                  radioField={statistics.register_monitoring}
                />
              </Card.Body>
            </Card>
            <WhiteSpace v="20px" />

            <Card className="bordered">
              <Card.Body>
                <InputFormField {...statistics.imported_overview} />
                <InputFormField {...statistics.internal_actions} />
                <InputFormField {...statistics.issuing_procedure} />
              </Card.Body>
            </Card>

            <WhiteSpace v="20px" />

            <ExtraTextareaFormField
              radioField={statistics.has_transaction_duties}
              textareaField={statistics.transaction_duties_description}
            />

            <WhiteSpace v="20px" />

            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />

            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Flex justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
