import { NamePath } from "rc-field-form/es/interface";
import { GenericObject } from "../../types";
import { makeNamePath } from "../../utils";

export const mixins = {
  one: (name: NamePath) => (id: number | string, query?: GenericObject) =>
    [...makeNamePath(name), id, ...(query ? [query] : [])] as const,
  many:
    (name: NamePath) =>
    (query: GenericObject = {}) =>
      [...makeNamePath(name), query] as const,
};
