import React from "react";
import { useAuthProvider, UseAuthProviderArgs } from "../hooks";

export type AuthContextValue = ReturnType<typeof useAuthProvider>;

export const AuthContext = React.createContext<AuthContextValue>({
  user: null,
  role: undefined,
  isLoading: true,
  login: async () => {},
  register: async () => {},
  logout: async () => {},
  refetchProfile: (async () => null) as any,
  error: null,
});

export const AuthProvider = ({
  children,
  ...apiCalls
}: React.PropsWithChildren<UseAuthProviderArgs>) => {
  const value = useAuthProvider(apiCalls);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
