import React from "react";
import { useLocation } from "react-router-dom";

export const useScrollTo = () => {
  //Get hash from url
  const { hash } = useLocation();

  React.useEffect(() => {
    if (hash) {
      let targetElement = document.getElementById(hash.slice(1));

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [hash]);
};
