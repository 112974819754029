import { makeNamePath } from "@aeo/core/utils";
import { KeyValueListProps, WhiteSpace } from "@aeo/core/components";
import { useFieldValue } from "@aeo/core/hooks";
import { KeyValueCard } from ".";

export interface ExaminationCardListProps extends KeyValueListProps {
  hideRisks?: boolean;
  ident?: string | number;
}

export const KeyValuesListCard = ({
  title,
  name,
  ident,
  ItemContent,
}: ExaminationCardListProps) => {
  const values: any[] | undefined = useFieldValue(name);

  return (
    <div
      style={{
        marginLeft: typeof ident === "number" ? `${ident * 2}rem` : ident,
      }}
    >
      {values?.map?.((_, i) => (
        <>
          <KeyValueCard
            key={i}
            keyList={
              <>
                <h3 className="px-10 pt-10">
                  {title} {i + 1}
                </h3>
                <ItemContent prefix={makeNamePath(name, i, "value")} />
              </>
            }
            name={makeNamePath(name, i)}
          />
          <WhiteSpace />
        </>
      ))}
    </div>
  );
};
