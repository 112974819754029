import React from "react";
import { NamePath } from "rc-field-form/es/interface";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { getProperty, makeNamePath } from "@aeo/core/utils";
import { ApplicationContext, ApplicationPathContext } from "@aeo/core/contexts";

export function useGetFieldValue(name?: NamePath | undefined) {
  const values = React.useContext(ApplicationContext);
  const path = React.useContext(ApplicationPathContext);

  return (field: FormFieldProps) => {
    return getProperty(
      values,
      ...makeNamePath(path?.split("/"), name, field.name),
    );
  };
}
