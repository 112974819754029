import models from "@aeo/core/models";

export const statusColor = [
  [
    [
      models.ApplicationState.draft,
      models.ApplicationState.rejected_registered,
      models.ApplicationState.withdraw_registered,
      models.ApplicationState.withdraw_pre_audit,
      models.ApplicationState.withdraw_audit,
      models.ApplicationState.revoked,
      models.ApplicationState.rejected_audit,
      models.ApplicationState.rejected_re_audit,
      models.ApplicationState.rejected_pre_audit,
      models.ApplicationState.annul,
      models.ApplicationState.withdraw_active,
      models.ApprovalState.rejected,
    ],
    "status-color-danger",
  ],
  [
    [
      models.ApplicationState.suspend_active,
      models.ApplicationState.suspend_audit,
      models.ApplicationState.suspend_registered,
      models.ApplicationState.suspend_pre_audit,
      models.ApplicationState.suspend_re_audit,
      models.AuthorizationState.suspended,
      models.ApprovalState.waiting,
    ],
    "status-color-warning",
  ],
  [
    [
      models.ApplicationState.active,
      models.ApplicationState.registered,
      models.ApplicationState.pre_audit,
      models.ApplicationState.audit,
      models.ApplicationState.approved,
      models.ApplicationState.re_audit,
      models.ApprovalState.approved,
      models.ApplicationState.upgraded,
    ],
    "status-color-success",
  ],
];

export const getStatusColor = (
  state:
    | models.ApplicationState
    | models.ApprovalState
    | models.AuthorizationState,
) => {
  const color = statusColor.find(([states]) => states.includes(state));

  return color ? color[1] : "";
};
