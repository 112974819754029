import { SAQTabKey } from "../models/saq";

export const SAQTabs = {
  economicAgent: {
    key: SAQTabKey.EconomicAgent,
    title: "Secţiunea 1. INFORMAŢII DESPRE OPERATORUL ECONOMIC",
    economicAgent:
      "Obiectivul principal al acestei secţiuni este de a oferi Serviciului Vamal o imagine de ansamblu asupra companiei. Informaţiile solicitate pot fi furnizate într-un mod general şi reprezintă o descriere succintă a activităţilor solicitantului la data depunerii cererii. Dacă informaţiile solicitate sunt deja disponibile, precizaţi acest lucru în formular sau indicaţi data la care au fost transmise informaţiile.",
  },
  shareholders: {
    key: SAQTabKey.Shareholders,
    title:
      "1.1.2. Furnizaţi următoarele date (în cazul în care acestea se aplică formei de organizare juridică a companiei dvs.):",
    indent: 1,
    subTitle: "Acționar majoritar",
  },
  directors: {
    key: SAQTabKey.Directors,
    title:
      "b) Date complete privind membrii consiliului de administraţie şi/sau administratori.",
    indent: 1,
    subTitle: "Membru al consiliului de administrație",
    info: "Detalii complete privind membrii consiliului de administraţie şi/sau administratori.",
  },
  advisory: {
    key: SAQTabKey.Advisory,
    title:
      "c) Date complete privind consiliul consultativ, dacă există, şi consiliul de administraţie.",
    indent: 1,
    subTitle: "Membru al consiliului consultativ",
    info: `<p>Detalii complete privind consiliul consultativ, dacă există, şi consiliul de administraţie. </p>`,
  },
  contact: {
    key: SAQTabKey.ContactPerson,
    title:
      "d) Date complete privind persoana responsabilă din cadrul companiei dvs.  sau care exercită controlul asupra gestionării companiei.",
    info: `<p>Detalii complete privind persoana responsabilă din compania dvs.  sau care exercită controlul asupra gestionării companiei.</p>`,
    indent: 1,
  },
  customer: {
    key: SAQTabKey.CustomerPerson,
    title:
      "1.1.3. Indicaţi date complete privind persoana responsabilă de domeniul vamal din cadrul companiei dvs. Detaliile ar trebui să includă numele şi adresa completă, data naşterii şi numărul naţional de identificare (de exemplu, codul numeric personal).",
    indent: 1,
    info: `<p>
    Persoana responsabilă de domeniul vamal al solicitantului este persoana din cadrul companiei sau o persoană care acționează în temeiul unui contract și gestionează domeniul vamal al solicitantului.</p>
    `,
  },
  commercial: {
    key: SAQTabKey.CommercialActivity,
    title: "1.1.4. - 1.1.6.",
    indent: 1,
  },
  structure: {
    key: SAQTabKey.OrganizationStructure,
    title: "1.1.7. - 1.1.10.",
    indent: 1,
  },
  turnover: {
    key: SAQTabKey.Turnover,
    title: "Subsecţiunea 1.2. VOLUMUL DE AFACERI",
  },
  statistic: {
    key: SAQTabKey.CustomsStatistic,
    title: "Subsecţiunea 1.3. INFORMAȚII ȘI STATISTICI PRIVIND DOMENIUL VAMAL",
  },
  legislation: {
    key: SAQTabKey.ComplianceLegislation,
    title:
      "Secţiunea 2. ANTECEDENTE ÎN CEEA CE PRIVEȘTE RESPECTAREA LEGISLAȚIEI VAMALE",
  },
  logistic: {
    title: "Secţiunea 3. SISTEME CONTABILE ȘI LOGISTICE",
  },

  logisticOne: {
    key: SAQTabKey.LogisticSystemPartOne,
    title: "3.1 Sisteme contabile şi logistice",
  },
  logisticTwo: {
    key: SAQTabKey.LogisticSystemPartTwo,
    title: "3.2 Sisteme contabile şi logistice",
  },
  logisticThree: {
    key: SAQTabKey.LogisticSystemPartThree,
    title: "3.3 Sisteme contabile şi logistice",
  },
  solvency: {
    key: SAQTabKey.FinancialSolvency,
    title: "Secţiunea 4. SOLVABILITATEA FINANCIARĂ",
    info: `
    <p>Prin solvabilitate financiară se înţelege o bună situaţie financiară, suficientă pentru a vă permite să vă îndepliniţi obligaţiile, având în vedere caracteristicile tipului de activitate comercială, iar această concluzie se bazează pe situaţia ultimilor trei ani.</p>
    <p>În cazul în carecompania desfăşoară activităţi de mai puţin de trei ani, solvabilitatea financiară va fi analizată pe baza evidenţelor contabile şi a informaţiilor disponibile.</p>
    <p>Aceste evidenţe contabile trebuie să se refere numai la solicitantul care depune cererea AEO. Orice informaţie cunoscută care va afecta solvabilitatea societăţii  în viitorul apropiat trebuie să fie precizată.</p>
    `,
  },
  standard: {
    key: SAQTabKey.PracticalStandard,
    title:
      "Secţiunea 5. STANDARDE PRACTICE DE COMPETENȚĂ SAU CALIFICĂRI PROFESIONALE",
  },
  security: {
    title: "Secţiunea 6. CERINȚE PRIVIND SECURITATEA ȘI SIGURANȚA",
  },
  securityOne: {
    key: SAQTabKey.SecurityPartOne,
    title: "6.1 Cerinţe privind securitatea şi siguranţa",
  },
  securityTwo: {
    key: SAQTabKey.SecurityPartTwo,
    title: "6.2 Cerinţe privind securitatea şi siguranţa",
  },
  securityThree: {
    key: SAQTabKey.SecurityPartThree,
    title: "6.3 Cerinţe privind securitatea şi siguranţa",
  },
  securityFour: {
    key: SAQTabKey.SecurityPartFour,
    title: "6.4 Cerinţe privind securitatea şi siguranţa",
  },

  // TODO: Remove if not used
  // founders: {
  //   key: "founders",
  //   title: "Fondatori",
  //   subTitle: "Fondator",
  //   info: `

  //   <p>Detaliile ar trebui să includă numele şi adresa completă, data naşterii şi numărul naţional de identificare (de exemplu, codul numeric personal).</p>
  //   `,
  // },
} as const;

export const SAQTabsContent = {
  economicAgentInfo: {
    title: "Secţiunea 1. INFORMAŢII DESPRE OPERATORUL ECONOMIC",
    info: "Obiectivul principal al acestei secţiuni este de a oferi Serviciului Vamal o imagine de ansamblu asupra companiei. Informaţiile solicitate pot fi furnizate într-un mod general şi reprezintă o descriere succintă a activităţilor solicitantului la data depunerii cererii. Dacă informaţiile solicitate sunt deja disponibile, precizaţi acest lucru în formular sau indicaţi data la care au fost transmise informaţiile.",
  },
  companyGeneralInfo: {
    title: "Subsecţiunea 1.1. INFORMAȚII GENERALE PRIVIND COMPANIA",
    indent: 1,
  },
  economicAgent: {
    key: SAQTabKey.EconomicAgent,
    title: "1.1.1.",
    indent: 2,
  },
  shareholders: {
    title: "1.1.2.",
    indent: 2,
  },
  shareholdersOne: {
    title: "a)",
    key: SAQTabKey.Shareholders,
    indent: 3,
  },
  directors: {
    title: "b)",
    key: SAQTabKey.Directors,
    indent: 3,
  },
  advisory: {
    title: "c)",
    key: SAQTabKey.Advisory,
    indent: 3,
  },
  contactPerson: {
    title: "d), e)",
    key: SAQTabKey.ContactPerson,
    indent: 3,
  },
  customer: {
    key: SAQTabKey.CustomerPerson,
    title: "1.1.3.",
    indent: 2,
  },
  commercial: {
    key: SAQTabKey.CommercialActivity,
    title: "1.1.4. - 1.1.6.",
    indent: 2,
  },
  structure: {
    key: SAQTabKey.OrganizationStructure,
    title: "1.1.7. - 1.1.10.",
    indent: 2,
  },
  turnover: {
    key: SAQTabKey.Turnover,
    title: "Subsecţiunea 1.2. VOLUMUL DE AFACERI",
    indent: 1,
  },
  statistic: {
    key: SAQTabKey.CustomsStatistic,
    title: "Subsecţiunea 1.3. INFORMAŢII ŞI STATISTICI PRIVIND DOMENIUL VAMAL",
    indent: 1,
  },
  legislation: {
    title:
      "Secţiunea 2. ANTECEDENTE ÎN CEEA CE PRIVEȘTE RESPECTAREA LEGISLAȚIEI VAMALE",
  },
  legislationOne: {
    key: SAQTabKey.ComplianceLegislation,
    title:
      "Subsecţiunea 2.1. ANTECEDENTE ÎN CEEA CE PRIVEŞTE RESPECTAREA LEGISLAȚIEI VAMALE",
    indent: 1,
  },
  logistic: {
    title: "Secţiunea 3. SISTEMELE CONTABILE ȘI LOGISTICE",
  },
  logisticOne: {
    key: SAQTabKey.LogisticSystemPartOne,
    title: "Subsecțiunea 3.1. – Subsecțiunea 3.3.",
    indent: 1,
  },
  logisticTwo: {
    key: SAQTabKey.LogisticSystemPartTwo,
    title: "Subsecțiunea 3.4. – Subsecțiunea 3.5.",
    indent: 1,
  },
  logisticThree: {
    key: SAQTabKey.LogisticSystemPartThree,
    title: "Subsecțiunea 3.6. – Subsecțiunea 3.8.",
    indent: 1,
  },
  solvency: {
    key: SAQTabKey.FinancialSolvency,
    title: "Secţiunea 4. SOLVABILITATEA FINANCIARĂ",
  },
  standard: {
    title:
      "Secţiunea 5. STANDARDE PRACTICE DE COMPETENȚĂ SAU CALIFICĂRI PROFESIONALE",
  },
  standardOne: {
    key: SAQTabKey.PracticalStandard,
    title: "Subsecţiunea 5.1. – Subsecţiunea 5.2.",
    indent: 1,
  },
  security: {
    title: "Secţiunea 6. CERINȚE PRIVIND SECURITATEA ȘI SIGURANȚA",
  },
  securityOne: {
    key: SAQTabKey.SecurityPartOne,
    title: "Subsectiunea 6.1.",
    indent: 1,
  },
  securityTwo: {
    key: SAQTabKey.SecurityPartTwo,
    title: "Subsectiunea 6.2. – Subsecţiunea 6.5.",
    indent: 1,
  },
  securityThree: {
    key: SAQTabKey.SecurityPartThree,
    title: "Subsecţiunea 6.6. – Subsecţiunea 6.9.",
    indent: 1,
  },
  securityFour: {
    key: SAQTabKey.SecurityPartFour,
    title: "Subsectiunea 6.10. – Subsecţiunea 6.12.",
    indent: 1,
  },
} as const;

export const examinationPageTitle = {
  request: "Cererea",
  economicAgent: "SAQ: 1.1.1.",
  shareholders: "SAQ: 1.1.2. a)",
  directors: "SAQ: 1.1.2. b)",
  advisory: "SAQ: 1.1.2. c)",
  contactPerson: "SAQ: 1.1.2. d), e)",
  customer: "SAQ: 1.1.3.",
  commercial: "SAQ: 1.1.4. - 1.1.6.",
  structure: "SAQ: 1.1.7. - 1.1.10.",
  turnover: "SAQ: Subsecţiunea 1.2. VOLUMUL DE AFACERI",
  statistic:
    "SAQ: Subsecţiunea 1.3. INFORMAŢII ŞI STATISTICI PRIVIND DOMENIUL VAMAL",
  legislation:
    "SAQ: Subsecţiunea 2.1. ANTECEDENTE ÎN CEEA CE PRIVEŞTE RESPECTAREA LEGISLAȚIEI VAMALE",
  logisticOne: "SAQ: Subsecțiunea 3.1. – Subsecțiunea 3.3.",
  logisticTwo: "SAQ: Subsecțiunea 3.4. – Subsecțiunea 3.5.",
  logisticThree: "SAQ: Subsecțiunea 3.6. – Subsecțiunea 3.8.",
  solvency: "SAQ: Secţiunea 4. SOLVABILITATEA FINANCIARĂ",
  standard: "SAQ: Subsecţiunea 5.1. – Subsecţiunea 5.2.",
  securityOne: "SAQ: Subsectiunea 6.1.",
  securityTwo: "SAQ: Subsectiunea 6.2. – Subsecţiunea 6.5.",
  securityThree: "SAQ: Subsecţiunea 6.6. – Subsecţiunea 6.9.",
  securityFour: "SAQ: Subsectiunea 6.10. – Subsecţiunea 6.12.",
};
