import { Card } from "ebs-design";
import { Tab } from "@aeo/core/utils/TabBuilder";
import { AutoScrollToTop } from "@aeo/core/components";
import { useAuth } from "@aeo/core/hooks";

import { TabSwitchesByType } from "features/saq/components";
import { SAQTabs } from "../components/SAQTabs";

interface SaqProps {
  tabs?: Tab[];
}

export const Saq = ({ tabs }: SaqProps) => {
  const { user } = useAuth();

  return (
    <div className="saq-grid">
      {tabs?.map((tab, i) => (
        <SAQTabs.Content
          tabKey={tab.tabKey}
          key={i}
          defaultActive={tab.defaultActive}
        >
          <AutoScrollToTop />
          {tab.content}
        </SAQTabs.Content>
      ))}

      <Card className="saq-tabs">
        <Card.Body>
          <TabSwitchesByType
            type={user?.organization?.active_application?.type}
          />
        </Card.Body>
      </Card>
    </div>
  );
};
