import { extraTextareaProps } from "../common";

export const commercialActivity = {
  description: {
    name: "description",
    label: "1.1.4. Descrieți succint activitatea dvs. comercială.",
    info: `<p>1.1.4.	Descrieți succint activitățile comerciale și indicați rolul companiei în lanțul de aprovizionare, spre exemplu:</p>
    <p><strong>a)	producător de mărfuri (MF)</strong>-persoana care produce mărfuri. Acest cod trebuie utilizat doar în cazul în care operatorul economic produce mărfurile. Acesta nu acoperă cazurile în care operatorul economic este implicat numai în comerţul cu mărfuri (de exemplu, export sau import).</p>
             <p><strong>b)	importator (IM)</strong> -partea care depune sau în numele căreia un broker vamal ori o altă persoană autorizată depune o declaraţie de import. Aceasta poate include o persoană care are în posesie mărfurile sau căreia îi sunt expediate mărfurile. Acest cod trebuie utilizat doar în cazul în care operatorul economic este în posesia mărfurilor. În cazul agenţilor/reprezentanţilor vamali, utilizațicodul pentru „broker vamal".</p>
            <p><strong>c)	exportator (EX)</strong> -partea care face declaraţia de export sau în numele căreia este făcută această declaraţie şi care este proprietarul mărfurilor sau are drepturi similare de a dispune de acestea în momentul acceptării declaraţiei. În cazul agenţilor/reprezentanţilor vamali, utilizațicodul pentru „broker vamal".</p>
            <p><strong>d)	broker vamal (CB)</strong> -persoana juridică, înregistrată în conformitate cu legislaţia, care deţine licenţă pentru activitatea de broker vamal, eliberată de organul competent şi care, pe principiile reprezentării directe sau indirecte, declară mărfurile, le prezintă pentru vămuire, efectuează şi alte operaţiuni vamale.</p>
    <p><strong>e) transportator (CA)</strong> - partea care efectuează sau organizează transportul de mărfuri.</p>
              <p><strong>f) expeditor de mărfuri (FW)partea care organizează expedierea mărfurilor.<strong></p>
             <p><strong>g) deţinător de antreprozit vamal (WH)- partea care gestionează un antrepozit vamal.<strong></p>
     <p><strong>În cazul în care aveți mai multe roluri în cadrul lanțului de aprovizionare internațional,  indicați-le pe toate.<strong></p>
    `,
    ...extraTextareaProps,
  },
  roles: {
    name: "roles",
    label:
      "Precizaţi rolul dvs. în cadrul lanţului logistic internaţional de aprovizionare: producător, importator, exportator, broker vamal, expeditor de mărfuri, deținător de antrepozit vamal,  transportator, operator de terminal etc. Dacă dețineți mai multe poziții, indicați-le pe toate.",
    ...extraTextareaProps,
  },
  subdivisions: {
    name: "subdivisions",
    label: "Subdiviziuni",
  },
  buy_from_associated: {
    name: "buy_from_associated",
    label: "1.1.6. Cumpărați de la companiile cu care sunteți asociat?",
    info: "Oferiți informaţii dacă compania este sau nu implicată în schimburi comerciale (de mărfuri, nu de servicii) cu societăţile asociate. De exemplu, toate achiziţiile  sunt de la societatea-mamă din SUA sau le importaţi în numele societăţilor asociate şi le distribuiţi acestora. Este necesar să oferiţi detalii complete în timpul procesului de autorizare.",
    defaultDepsValue: [
      {
        associated_buy_companies: [],
      },
    ],
  },
  associated_buy_companies: {
    name: "associated_buy_companies",
    label: "Companii asociate",
  },
  sell_to_associated: {
    name: "sell_to_associated",
    label: "Vindeți către companiile cu care sunteți asociat?",
    defaultDepsValue: [
      {
        associated_sell_companies: [],
      },
    ],
  },
  associated_sell_companies: {
    name: "associated_sell_companies",
    label: "Companii asociate",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};

export const commercialSubdivision = {
  name: {
    name: "name",
    label: "Denumirea",
    placeholder: "Ex: Denumirea subdiviziunii",
  },
  address: {
    name: "address",
    label: "Adresa",
    placeholder: "Ex: or. Chişinău, str. Puşkin 22",
  },
  phone: {
    name: "phone",
    label: "Numar de telefon",
    placeholder: "XXXXXXXX",
  },
  email: {
    name: "email",
    label: "E-mail",
    placeholder: "xxxxxx@nomail.md",
  },
  activity: {
    name: "activity",
    label: "Oferiți o scurtă descriere a activității comerciale desfășurate.",
    placeholder: "Descrie activitate",
  },
  third_party: {
    name: "third_party",
    label: "Partea terță",
  },
  exists_third_party: {
    name: "exists_third_party",
    label:
      "b) Există subdiviziuni unde o parte terță desfășoară activități pentru compania dvs?",
  },
  third_party_physic: {
    name: "third_party_physic",
    label: "Subdiviziuni terțe părți fizice",
  },
  third_party_juridic: {
    name: "third_party_juridic",
    label: "Subdiviziuni terțe părți juridice",
  },
  employee_count: {
    name: "employee_count",
    label:
      "Indicați numărul aproximativ de angajați pentru fiecare departament.",
  },
};
