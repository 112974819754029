import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  InputFormField,
  AttachmentsFormField,
  LoadingButton,
  ExtraTextareaFormField,
  BooleanRadioFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableFormList } from "../NotApplicableFormList";
import { NotApplicableBooleanField } from "../NotApplicableBooleanField";

export const LogisticTwoForm = () => {
  const logisticTwo = fields.logisticTwo;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/logistic_two", form);
  useDraftApplicationQuery("saq/logistic_two", form);
  const validateMutation = useValidateMutation(
    "saq/logistic_two",
    form,
    "logistic_three",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.logistic.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <h4>{capitalize(messages.titles.flowOfGoods)}</h4>
            <WhiteSpace />

            <InputFormField {...logisticTwo.registration_procedure} />
            <InputFormField {...logisticTwo.stock_procedure} />

            <h4>{capitalize(messages.titles.customsFormalities)}</h4>
            <WhiteSpace />

            <ExtraTextareaFormField
              radioField={logisticTwo.has_declaration_process}
              textareaField={logisticTwo.declaration_process}
            />
            <NotApplicableFormList
              dependencies={[logisticTwo.has_declaration_process.name]}
              name={logisticTwo.declaration_process_attachments.name}
            >
              <AttachmentsFormField
                {...logisticTwo.declaration_process_attachments}
              >
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <NotApplicableFormList
              dependencies={[logisticTwo.has_declaration_process.name]}
              name={logisticTwo.checking_declaration.name}
              reverse
            >
              <ExtraTextareaFormField
                radioField={logisticTwo.has_checking_declaration}
                textareaField={logisticTwo.checking_declaration}
              />
            </NotApplicableFormList>

            <BooleanRadioFormField
              radioField={logisticTwo.has_irregularities_instruction}
            />

            <NotApplicableBooleanField
              {...logisticTwo.has_documented_instruction}
            />
            <NotApplicableFormList
              dependencies={[logisticTwo.has_irregularities_instruction.name]}
              name={logisticTwo.documented_instruction_attachments.name}
            >
              <AttachmentsFormField
                {...logisticTwo.documented_instruction_attachments}
              >
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <ExtraTextareaFormField
              radioField={logisticTwo.has_identified_violation}
              textareaField={logisticTwo.identified_violation}
            />

            <BooleanRadioFormField
              radioField={logisticTwo.has_licensed_goods}
            />
            <NotApplicableFormList
              dependencies={[logisticTwo.has_licensed_goods.name]}
              name={logisticTwo.licensed_goods_attachments.name}
            >
              <AttachmentsFormField {...logisticTwo.licensed_goods_attachments}>
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <ExtraTextareaFormField
              radioField={logisticTwo.has_external_license}
              textareaField={logisticTwo.external_license}
            />
            <NotApplicableFormList
              dependencies={[logisticTwo.has_external_license.name]}
              name={logisticTwo.external_license_attachments.name}
            >
              <AttachmentsFormField
                {...logisticTwo.external_license_attachments}
              >
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <ExtraTextareaFormField
              radioField={logisticTwo.has_goods_under_law}
              textareaField={logisticTwo.goods_under_law}
            />

            <WhiteSpace />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
