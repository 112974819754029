import React from "react";

export const Category: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <rect
        height="6"
        width="6"
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth="1.5"
        x="4"
        y="4"
      />
      <rect
        height="6.42646"
        width="6.42646"
        rx="3.21323"
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth="1.5"
        x="13.7866"
        y="13.7868"
      />
      <path
        d="M13.7924 7.53462C13.4972 7.23941 13.4972 6.76078 13.7924 6.46557L16.4651 3.79293C16.7603 3.49772 17.2389 3.49772 17.5341 3.79293L20.2067 6.46557C20.502 6.76078 20.502 7.23941 20.2067 7.53462L17.5341 10.2073C17.2389 10.5025 16.7603 10.5025 16.4651 10.2073L13.7924 7.53462Z"
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M5.79381 14.6962C6.29087 13.7679 7.70951 13.7679 8.20657 14.6962L10.0705 18.1773C10.5189 19.0147 9.8668 20 8.86415 20H5.13623C4.13358 20 3.48145 19.0147 3.92985 18.1773L5.79381 14.6962Z"
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
