import React from "react";
import { Button } from "ebs-design";

import { Icon } from ".";

import { useStateRef } from "../hooks";
import { makeBEM } from "../utils";
import { SCROLL_TO_TOP_SHOW_OFFSET } from "../app-constants";

const bem = makeBEM("sroll-to-top-button");

export interface ScrollToTopButtonProps {}

export const ScrollToTopButton = ({ ...props }: ScrollToTopButtonProps) => {
  const [visible, setVisible] = React.useState(false);

  const visibleRef = useStateRef(visible);
  const layoutRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    const $layout = document.querySelector(".ebs-layout__container");
    layoutRef.current = $layout as HTMLDivElement;
    if (!$layout) return;

    const listener = () => {
      if ($layout.scrollTop > SCROLL_TO_TOP_SHOW_OFFSET) {
        !visibleRef.current && setVisible(true);
      } else {
        visibleRef.current && setVisible(false);
      }
    };

    $layout?.addEventListener("scroll", listener);

    return () => {
      $layout?.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <>
      {visible && (
        <Button
          className={bem()}
          type="primary"
          icon={() => <Icon type="down-chevrone" className={bem("icon")} />}
          onClick={() => {
            layoutRef.current?.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          type="primary"
        />
      )}
    </>
  );
};
