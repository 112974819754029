import { useQuery } from "react-query";
import { useQueryParams, StringParam } from "use-query-params";
import { Auth } from "@aeo/core/components/auth";
import { getFullName } from "@aeo/core/utils";
import { useNotify } from "ebs-design";

import { notifyErrors } from "@aeo/core/utils";

import api, { querykeys } from "api";
import { MpassLoginForm } from "../components";

export const MpassLogin = () => {
  const notify = useNotify();
  const [query] = useQueryParams({
    saml_instance_id: StringParam,
  });

  const saml = query.saml_instance_id || "";

  const { data, isLoading } = useQuery(
    querykeys.mpassSAML(saml),
    () => api.auth.getMpassSAML(saml),
    {
      enabled: !!saml,
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Auth title={getFullName(data?.details)}>
      <MpassLoginForm data={data} isLoading={isLoading} />
    </Auth>
  );
};
