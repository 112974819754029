import { FormControl } from "@aeo/core/types";
import { Checkbox, Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import models from "../../models";
import { FieldWithInfo } from "./FieldWithInfo";
import { relativeClassName } from "./utils";

export interface CheckboxProps extends FormFieldProps {
  info?: React.ReactNode;
  disabled?: boolean;
  defaultDepsValue?: models.DefaultDepsValue[];
}

export const CheckboxFormField = ({
  label,
  extra,
  info,
  className,
  disabled,
  defaultDepsValue,
  ...props
}: CheckboxProps) => {
  return (
    <Form.Field
      {...props}
      extra={<FieldWithInfo extra={extra} info={info} />}
      className={relativeClassName(info, className)}
    >
      {(_control, __, form) => {
        const { value, onChange } = _control as FormControl<boolean>;

        if (props.dependencies && !value) {
          props.dependencies.map((dep) => {
            form.resetFields([dep]);
          });
        }

        return (
          <Checkbox
            text={label}
            checked={value}
            onChange={(v) => {
              if (v && Array.isArray(defaultDepsValue)) {
                form.setFields(
                  defaultDepsValue
                    .map((dep) =>
                      Object.entries(dep).map(([key, value]) => ({
                        name: key,
                        value: value,
                      })),
                    )
                    .flat() || [],
                );
              }
              onChange?.(v);
            }}
            disabled={disabled}
          />
        );
      }}
    </Form.Field>
  );
};
