export const editorToolbarGroups = [
  { name: "forms", groups: ["forms"] },
  { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
  {
    name: "paragraph",
    groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
  },
  { name: "links", groups: ["links"] },
  { name: "insert", groups: ["insert"] },
  { name: "styles", groups: ["styles"] },
  { name: "colors", groups: ["colors"] },
  { name: "tools", groups: ["tools"] },
  { name: "others", groups: ["others"] },
  { name: "about", groups: ["about"] },
  {
    name: "editing",
    groups: ["find", "selection", "spellchecker", "editing"],
  },
  { name: "document", groups: ["mode", "document", "doctools"] },
  { name: "clipboard", groups: ["clipboard", "undo"] },
];

export const editorRemoveButtons =
  "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,Scayt,Form,Radio,Checkbox,TextField,Textarea,Select,Button,HiddenField,Blockquote,CreateDiv,BidiRtl,BidiLtr,Language,Anchor,Image,Flash,Smiley,Iframe,Font,Maximize,ShowBlocks,About,ImageButton,Outdent,Indent";
