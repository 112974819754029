export function insertBetween<T, E>(arr: T[], v: E) {
  if (!arr.length) return arr;
  return [
    arr[0],
    ...arr
      .slice(1, arr.length)
      .map((i) => [v, i])
      .flat(1),
  ];
}

export function arrIncludes(arr: unknown[], what: any) {
  return arr.includes(what);
}
/**
 *
 * @example
 * wrapInArr(['asdf', 'asdf']) === ['asdf', 'asdf'];
 * wrapInArr('asdf') === ['asdf'];
 * wrapInArr(undefined) === [];

 */
export function wrapInArr<T>(value: T | readonly T[] | undefined) {
  return value instanceof Array
    ? [...value]
    : value !== undefined
    ? [value]
    : [];
}

export function groupBy<T>(
  arr: T[] | undefined,
  options: { [key: string]: (value: T, index: number, array: T[]) => unknown },
) {
  const groupedOptions: { [key: string]: T[] } = {};

  if (!arr) return groupedOptions;

  for (const key in options) {
    groupedOptions[key] = arr.filter(options[key]);
  }

  return groupedOptions;
}
