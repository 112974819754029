import React from "react";

export const ArrowsMaximize: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...svgProps}
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4 4h4M4 4v4m0-4l6 6M4 20h4m-4 0v-4m0 4l6-6M20 4v4m0-4h-4m4 0l-6 6M20 20v-4m0 4h-4m4 0l-6-6"
      ></path>
    </svg>
  );
};
