import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { Form, useForm, useNotify } from "ebs-design";

import { makeBEM, notifyErrors } from "@aeo/core/utils";
import {
  InputFormField,
  InputPasswordShowField,
} from "@aeo/core/components/form-utils";
import { AlertErrors, LoadingButton } from "@aeo/core/components";
import models from "@aeo/core/models";
import messages from "@aeo/core/messages";
import { useAuth } from "@aeo/core/hooks";

import { LoginWithMPassLinkButton } from "components";

const bem = makeBEM("auth-layout");

export const LoginForm = () => {
  const auth = useAuth();
  const [form] = useForm();
  const notify = useNotify();

  const loginMutation = useMutation(auth.login, {
    onError: (error) => notifyErrors(notify, error),
  });

  const submitHandler = (values: models.LoginCredentials) => {
    loginMutation.mutate({
      username: values.username,
      password: values.password,
    });
  };

  return (
    <>
      <Form form={form} onFinish={submitHandler}>
        <InputFormField
          name="username"
          placeholder="E-mail"
          type="text"
          hideLabel
          rules={[
            {
              required: true,
              message: messages.validations.requiredEmail,
            },
          ]}
        />
        <InputPasswordShowField
          name="password"
          placeholder="Parola"
          rules={[
            {
              required: true,
              message: messages.validations.requiredPassword,
            },
          ]}
        />
        {/* <Checkbox text="Păstează-mă logat" /> */}
        <LoadingButton
          size="large"
          className={bem("form__btn")}
          type="primary"
          submit
          loading={loginMutation.isLoading}
        >
          Conectează-te
        </LoadingButton>
        <AlertErrors error={loginMutation.error} />
      </Form>
      <h5 className={bem("form__footer fw-500")}>
        Ai uitat parola?
        <span>
          <Link to="/recovery-password" className={bem("link")}>
            Recuperează parola
          </Link>
        </span>
      </h5>

      <LoginWithMPassLinkButton className="mt-24" />
    </>
  );
};
