import { Card, Form, useForm } from "ebs-design";
import {
  Flex,
  InputFormField,
  Grid,
  WhiteSpace,
  DatePickerFormField,
  AttachmentsFormField,
  LoadingButton,
  InputIDNPFormField,
  InputPhoneFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";

export const PersonCustomerForm = () => {
  const [form] = useForm();
  const person = { ...fields.person, ...fields.personCustomer };

  const [save, saveMutation] = useAutoSave("saq/customer", form);
  useDraftApplicationQuery("saq/customer", form);
  const validateMutation = useValidateMutation(
    "saq/customer",
    form,
    "commercial",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              info={messages.SAQTabs.customer.info}
              title={messages.SAQTabs.customer.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <h4>{capitalize(messages.titles.responsible)}</h4>

            <WhiteSpace />
            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...person.first_name} />

              <InputFormField {...person.last_name} />
            </Grid>

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <DatePickerFormField {...person.birthday} />

              <InputIDNPFormField {...person.idnp} />
            </Grid>

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputPhoneFormField {...person.phone} />
              <InputFormField {...person.email} />
            </Grid>
            <Grid rows="1fr" gap="20px" cols="1fr 1fr">
              <InputFormField {...person.address} />
              <AttachmentsFormField {...person.attachments_persons}>
                Atașează cazierul judiciar
              </AttachmentsFormField>
            </Grid>
            <WhiteSpace />

            <AttachmentsFormField {...person.attachments}>
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Flex justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
