import { RenderUploadItemProps } from "./types";

export const DefaultUploadItem = <TData, TError extends Error>({
  item,
  progress,
  onRemoveClick,
}: RenderUploadItemProps<TData, TError>) => {
  const file = item?.file;

  return (
    <div className="mb-4">
      <div className="upload__container">
        <span
          className="upload__file__remove cursor-pointer"
          onClick={onRemoveClick}
        >
          &#10005;
        </span>

        <div className="upload__file">
          <a
            href={(file as any)?.url || (item?.data as any)?.url}
            target="_blank"
            rel="noopener noreferrer"
            className="upload__file__name"
          >
            {file?.name}
          </a>

          {progress !== undefined && !item.isError && (
            <div className="upload__status">
              <div className="upload__progress">
                <span className="upload__progress__text">{progress}%</span>
                <span
                  className="upload__progress__bar"
                  style={{
                    width: `${progress}%`,
                    flexBasis: `${progress}%`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {item.isError && (
        <div className="color-danger">{item.error?.message}</div>
      )}
    </div>
  );
};
