import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useNotify } from "ebs-design";

import { LoadingButton } from "@aeo/core/components";
import { notifyErrors } from "@aeo/core/utils";
import models from "@aeo/core/models";
import { useAuth } from "@aeo/core/hooks";

import api, { querykeys } from "api";

export interface AEODraftTriggerProps {
  type: models.AEOType;
}

export const AEODraftTrigger = ({ type }: AEODraftTriggerProps) => {
  const queryClient = useQueryClient();

  const history = useHistory();
  const notify = useNotify();
  const auth = useAuth();

  const draftMutation = useMutation(() => api.application.createDraft(type), {
    onSuccess: async () => {
      await auth.refetchProfile();
      queryClient.invalidateQueries(querykeys.applications.many());
      history.push(`/application/`);
    },
    onError: (error) => notifyErrors(notify, error),
  });

  return (
    <LoadingButton
      loading={draftMutation.isLoading}
      type="primary"
      size="large"
      onClick={draftMutation.mutate}
    >
      {type}
    </LoadingButton>
  );
};
