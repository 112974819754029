import { Badge, Grid } from "@aeo/core/components";
import models from "@aeo/core/models";
import { formattedDate, xor } from "@aeo/core/utils";
import { notApplicable, noNotApplicable } from "./form-utils/utils";
import { useFieldValue, useNAField } from "../hooks";
import { KeyValueProps, ValueType } from "../types";
import { NABadge, AttachmentsList } from ".";

export const KeyValueRow = ({
  name,
  label,
  type = "default",
  NA,
  reverseNA,
  dependencies,
}: KeyValueProps) => {
  const value = useFieldValue(name);
  const na = useNAField({ name, NA, reverseNA, dependencies });

  return (
    <Grid
      className="py-8"
      cols="1fr 1fr"
      gap="1rem"
      style={{ alignItems: "start" }}
    >
      <div className="color-gray">{label}</div>
      <div className="fw-600">
        {na || value === notApplicable ? (
          <NABadge />
        ) : !(value === null || value === "undefined") ? (
          valueTypeMap[type](value)
        ) : (
          "---"
        )}
      </div>
    </Grid>
  );
};

const valueTypeMap: Record<ValueType, (v: any) => React.ReactNode> = {
  default: (v) => <>{v}</>,
  nomenclature: (v: models.IdName) => <>{v?.name}</>,
  "nomenclature-list": (v: models.IdName[]) => (
    <>{v?.map?.((i) => i.name).join(", ")}</>
  ),
  boolean: (v: boolean) => <Badge variant="fill">{v ? "Da" : "Nu"}</Badge>,
  "boolean-applicable": (v: boolean) => (
    <Badge variant="fill">{v ? "Da" : notApplicable}</Badge>
  ),
  "boolean-no-na": (v: boolean) => (
    <Badge variant="fill">{v ? "Da" : noNotApplicable}</Badge>
  ),
  "boolean-place": (v: boolean) => (
    <Badge variant="fill">{v ? "Intern" : "Extern"}</Badge>
  ),
  declaration: (v: models.Declaration) => (
    <Grid cols="1fr 1fr" gap="1rem">
      <div>
        <span className="color-gray">Număr: </span>
        {v.number || "---"}
      </div>
      <div>
        <span className="color-gray">Valoare: </span>
        {v.value || "---"}
      </div>
    </Grid>
  ),
  date: (v: string) => <>{formattedDate(v)}</>,
  "direct-mode": (v: boolean) => (
    <Badge variant="subtle">{v ? "Direct" : "Indirect"}</Badge>
  ),
  attachments: (v?: models.Attachment[]) => (
    <AttachmentsList hideLabel value={v} name={[]} />
  ),
};
