import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const OrganizationStructureValues = () => {
  const organizationStructure = fields.organizationStructure;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <KeyValueCard {...organizationStructure.departament_description} />
      <KeyValueCard {...organizationStructure.employee_description} />
      <KeyValueCard {...organizationStructure.category} type="nomenclature" />
      <KeyValueCard {...organizationStructure.employee_count} />
      <KeyValueCard {...organizationStructure.employee_name} />
      <KeyValueCard {...organizationStructure.employee_function} />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...organizationStructure.attachments} type="attachments" />
    </>
  );
};

export const OrganizationStructure = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.structure.title}
      content={<OrganizationStructureValues />}
      path="saq/structure"
    />
  );
};
