export const makeBEM =
  (prefix: string) =>
    (className?: string | null | undefined, ...modifiersArgs: (Record<string, any> | string[])[]) =>
      className
        ? createClassWithModifiers(`${prefix}__${className}`, ...modifiersArgs)
        : createClassWithModifiers(prefix, ...modifiersArgs);

/**
 * clsMods("class", { "foo": true, "bar": false } ) => "class class--foo"
 */
export function createClassWithModifiers(
  baseClass: string,
  ...modifiersArgs: (Record<string, any> | string[])[]
): string {
  let result: string[] = [baseClass];

  modifiersArgs.forEach((modifiers) => {
    if (modifiers instanceof Array) {
      modifiers.forEach((m) => m && result.push(`${baseClass}--${m}`));
    } else {
      Object.entries(modifiers).forEach((entry) =>
        result.push(entry[1] ? baseClass + "--" + entry[0] : "")
      );
    }
  });

  return result.filter((_) => _).join(" ");
}
