import { Link, useLocation, matchPath } from "react-router-dom";

import { Sidebar } from "ebs-design";

import { Icon } from "@aeo/core/components/icons";
import { UserRoles } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";
import models from "@aeo/core/models";

const allowedStatesForComplaints = [
  models.ApplicationState.active,
  models.ApplicationState.revoked,
  models.ApplicationState.annul,
  models.ApplicationState.withdraw_active,
  models.ApplicationState.suspend_active,
  models.ApplicationState.withdraw_re_audit,
  models.ApplicationState.re_audit,
  models.ApplicationState.suspend_re_audit,
];

export const AppLayoutMenu = () => {
  const { role, user } = useAuth();
  const location = useLocation();
  const applicationStatus = user?.organization?.active_application?.state;

  const isActive = (url: string | string[]): boolean =>
    !!matchPath(location.pathname, { path: url, exact: true });

  return (
    <Sidebar.TopMenu toggleText="Ascunde">
      <Link to="/">
        <Sidebar.Item
          text={"Panou control"}
          prefix={<Icon type="dashboard" />}
          active={isActive(["/", "/application/*", "/applications/*"])}
        />
      </Link>
      {applicationStatus &&
        allowedStatesForComplaints.includes(applicationStatus) && (
          <Link to="/complaints">
            <Sidebar.Item
              text={"Sesizări"}
              prefix={<Icon type="complaints" />}
              active={isActive("/complaints")}
            />
          </Link>
        )}
      {role === UserRoles.admin && (
        <Link to="/users">
          <Sidebar.Item
            text={"Utilizatori"}
            prefix={<Icon type="users" />}
            active={isActive("/users")}
          />
        </Link>
      )}
      <Link to="/faq">
        <Sidebar.Item
          text={"Întrebări frecvente"}
          prefix={<Icon type="help" />}
          active={isActive("/faq")}
        />
      </Link>
    </Sidebar.TopMenu>
  );
};
