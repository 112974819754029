import {
  Grid,
  WhiteSpace,
  InputFormField,
  InputPhoneFormField,
  AttachmentsFormField,
  InputIDNPFormField,
} from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";

interface Props {
  index: number;
  showPercentageShares?: boolean;
}

export const JuridicWithAddressFormFields = ({
  index,
  showPercentageShares,
}: Props) => {
  const person = fields.person;

  return (
    <>
      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField {...person.name} name={[index, person.name.name]} />

        <InputIDNPFormField {...person.idno} name={[index, person.idno.name]} />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <InputPhoneFormField
          {...person.phone}
          name={[index, person.phone.name]}
        />

        <InputFormField {...person.email} name={[index, person.email.name]} />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField
          {...person.address}
          name={[index, person.address.name]}
        />

        {showPercentageShares ? (
          <InputFormField
            {...person.percentage_shares}
            name={[index, person.percentage_shares.name]}
            suffix={<>%</>}
          />
        ) : null}
      </Grid>

      <WhiteSpace v="20px" />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <AttachmentsFormField name={[index, "attachments"]}>
        Atașează documente
      </AttachmentsFormField>
    </>
  );
};
