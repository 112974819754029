export const validations = {
  IDNO: "IDNO-ul introdus trebuie să conțină 13 cifre.",
  IDNP: "IDNP-ul introdus trebuie să conțină 13 cifre.",
  requiredEmail: "E-mailul este obligatoriu.",
  requiredCompanyName: "Numele companiei este obligatoriu.",
  requiredPhone: "Telefonul este obligatoriu.",
  password:
    "Parola trebuie să conțină cel puțin 8 caractere, o majusculă, o literă latină, un simbol și o cifră.",
  requiredPassword: "Parola este obligatorie.",
  confirmationPassword: "Parolele trebuie să fie identice.",
};
