export enum AEOType {
  AEOC = "AEOC",
  AEOS = "AEOS",
  AEOF = "AEOF",
}

export enum AuthorizationState {
  active = "active",
  approved = "approved",
  suspended = "suspended",
  revoked = "revoked",
  anull = "anull",
}
