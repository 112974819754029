import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Modal, useNotify } from "ebs-design";

import { Flex, Pagination, QueryTable } from "@aeo/core/components";
import { useDownloadFile, useModalState, usePagination } from "@aeo/core/hooks";
import { makeBEM, notifyErrors } from "@aeo/core/utils";
import models from "@aeo/core/models";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";

import { decisionsColumns } from "../config";

import { RowEditor } from "./RowEditor";

const bem = makeBEM("dashboard");

interface ApproveMutationValues {
  id?: number;
  body: models.ContentEntityWithAttachment;
  documentState: models.ApprovalDocumentType;
}

export const Decisions = ({ id }: { id: number }) => {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<number[]>();
  const decisionsModal = useModalState();
  const pagination = usePagination();
  const downloadFile = useDownloadFile();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const queryParams = {
    page: pagination.page,
    page_size: pagination.pageSize,
  };
  const query = useQuery(
    querykeys.applications.many(queryParams),
    () => api.application.getApprovals(id, queryParams),
    { enabled: decisionsModal.isOpen },
  );

  const { mutate: sign } = useMutation(api.approvals.sign, {
    onSuccess: () => {
      queryClient.invalidateQueries(querykeys.applications.many(queryParams));
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const approveMutation = useMutation(
    ({ id, documentState, body }: ApproveMutationValues) =>
      api.application.approvalResponse(id, documentState, body),
    {
      onSuccess: () => {
        notify.success({ title: notifications.response });
        queryClient.invalidateQueries(querykeys.applications.many(queryParams));
        setExpandedRowKeys([]);
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  const onExpandRow = React.useMemo(
    () => (rowId: number[]) =>
      expandedRowKeys?.includes(rowId[0])
        ? setExpandedRowKeys([])
        : setExpandedRowKeys(rowId ?? 0),
    [expandedRowKeys],
  );

  const tableColumns = React.useMemo(
    () =>
      decisionsColumns({
        downloadFile,
        sign,
        onExpandRow,
        documentKey: "document",
      }),
    [downloadFile, sign, onExpandRow],
  );

  return (
    <>
      <Button onClick={() => decisionsModal.open()}>Decizii</Button>

      {decisionsModal.isOpen && (
        <Modal
          title="Decizii"
          open={decisionsModal.isOpen}
          onClose={() => {
            decisionsModal.close();
            onExpandRow([]);
          }}
          size="large"
          closeOnClickOutside={false}
        >
          <Modal.Content>
            <QueryTable
              className={bem("decisions-table")}
              query={query}
              columns={tableColumns}
              emptyText={
                <div className="text-center color-gray p-30">
                  La moment nu exista nici un fișier
                </div>
              }
              rowKey="id"
              expandable={{
                expandedRowRender: (record, index, indent, expanded) =>
                  expanded ? (
                    <RowEditor
                      mutation={approveMutation}
                      onCloseRow={() => setExpandedRowKeys([])}
                      state={record?.type}
                      documentId={record?.id}
                    />
                  ) : null,
                onExpand: () => onExpandRow,
                expandedRowKeys,
              }}
            />
          </Modal.Content>
          <Modal.Footer>
            <Flex justify="end">
              <Pagination
                data={query.data}
                pagination={pagination}
                pageSizeChoice={[5, 10, 15]}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
