import { WhiteSpace, Grid } from "@aeo/core/components";
import fields from "@aeo/core/fields";

import { NABadge } from ".";
import { useGetFieldValue, useFieldValue } from "../hooks";

interface TaxProps {
  withCustomPathName?: boolean;
}
export const Tax = ({ withCustomPathName }: TaxProps) => {
  const tax = fields.turnover.tax;

  const getValue = useGetFieldValue();
  const triggerValue = useFieldValue(
    withCustomPathName ? tax.has_tax.customPathName : tax.has_tax.name,
  );

  return (
    <>
      <div>
        {tax.label} {!triggerValue && <NABadge />}
      </div>
      <WhiteSpace />
      <div>
        {triggerValue && (
          <>
            <Grid cols="repeat(4, 1fr)" gap="0.25rem 1rem">
              <span className="color-gray">{tax.customs_taxes.label}:</span>
              <span>
                {withCustomPathName
                  ? getValue({ name: tax.customs_taxes.customPathName })
                  : getValue(tax.customs_taxes)}
              </span>
              <span className="color-gray">
                {tax.customs_procedures.label}:
              </span>
              <span>
                {withCustomPathName
                  ? getValue({ name: tax.customs_procedures.customPathName })
                  : getValue(tax.customs_procedures)}
              </span>
              <span className="color-gray">{tax.excise.label}:</span>
              <span>
                {withCustomPathName
                  ? getValue({ name: tax.excise.customPathName })
                  : getValue(tax.excise)}
              </span>
              <span className="color-gray">{tax.vat.label}:</span>
              <span>
                {withCustomPathName
                  ? getValue({ name: tax.vat.customPathName })
                  : getValue(tax.vat)}
              </span>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};
