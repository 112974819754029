export enum FilterType {
  enum,
  date,
}


export interface FilterBase {
  key: string;
  name: string;
  type: FilterType;
}

export interface EnumFilter extends FilterBase {
  type: FilterType.enum;
  values: { text: React.ReactNode; value: string | number }[];
  isLoading?: boolean;
}

export interface DateFilter extends FilterBase {
  type: FilterType.date;
}

export type FilterSource = EnumFilter | DateFilter;
