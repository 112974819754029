export const asycudaFields = {
  organization: {
    identifier: "IDNO", // "cmp_cod",
    valid_from: "valid_from",
    valid_to: "valid_to",
    name: "cmp_nam",
    address: "cmp_adr",
    region: "cmp_ad2",
    street: "cmp_ad3",
    code: "cmp_ad4",
    phone: "cmp_tel",
    fax: "cmp_fax",
    tlx: "cmp_tlx",
    status: "cmp_sta",
    modify_time: "modify_time",
    flg_rem: "flg_rem",
  },
  declaration: {
    id: "ID", // "instanceid",
    code: "Cod", // "ide_cuo_cod",

    //Responsabil financiar (Denumire, IDNO)
    identifier: "IDNO", //"cmp_fis_cod",
    name: "Denumire", //"cmp_fis_nam",
    status: "Statut", //"status",
    type: "Declarație", // "ide_typ_sad",

    //Formulare
    principal: "Număr", // "pty_frm_nbr",
    complimentary: "Număr total", //"pty_frm_tot",

    //Articole
    article: " Articole", //"pty_nbr_itm",

    //Țara trazact./ prod.
    transaction_country: "Țara trazact./ prod.", //"gen_cty_trd",

    //Țara de expediție/ de export
    export_code: "Cod ț. export", //"gen_cty_ept_cod",
    export_country: "Țara de expediție/ de export", //"gen_cty_ept_nam",
    expedition_code: "Cod ț. exped.", // gen_cty_ept_crg",
    origin_country: "Țara de origine", //"gen_cty_org",
    transport_identity: "Identitatea mijl. de transport", // "gen_cty_des_crg",

    //Moneda și valoarea totală facturată
    internal_value: "Valuarea în lei", //"vgs_inv_amt_nmu",
    currency_value: "Valuarea în valută", //vgs_inv_amt_fcx",
    currency_code: "Cod valută", //"vgs_inv_cur_cod",

    //Mod de transport interior
    transport_internal: "Mod de transport interior", //"tpt_mot_inl",

    //Condiții de livrare
    delivery_terms: "Condiții de livrare", //" "tpt_tod_cod",

    //LOCUL ŞI DATA /SEMNĂTURA ŞI NUMELE DECLARANTULUI SAU REPREZENTANTULUI
    place_and_date: "Locul și data", //"tpt_tod_plc",

    //Birou de ieșire/ intrare
    customs_code: "Cod vama la frontieră", // "tpt_cuo_cod",
    customs_name: "Nume vama la frontieră", //"tpt_cuo_nam",

    //Localizarea mărfurilor
    goods_location: "Localizarea mărfurilor", //"tpt_loc",

    //Curs de schimb
    exchange_rate: "Curs de schimb", //"vgs_inv_cur_rat",

    //Natura tranzacției
    transaction_code: "Codul general al operaţiunii", //"fin_tra_na1",
    transaction_particular_code: "Codul particular al operaţiunii", //"fin_tra_na2",

    //Informații financiare și bancare
    bank_code: "Cod bancă", //"fin_bnk_cod",
    bank_name: "Descriere filiala băncii", //"fin_bnk_nam",
    subdivision_code: "Cod filială", //"fin_bnk_bra",
    settlement_account: "Cont decontare", //"fin_bnk_fre",
    payment_term_code: "Cod termen achitare", //"fin_top_cod",
    payment_term_description: "Descriere termen achitare", //"fin_top_nam",

    //Calculul impozitărilor
    tax_calculation: "Calculul impozitărilor", //"prv_dty",

    //Report de plată
    payment_report: "Report de plată", //"fin_acc",

    //Identificarea antrepozitului
    warehouse_code: "Identificarea antrepozitului", //"whs_cod",
    warehouse_term: "Termen în luni", //"whs_tim",
  },
  item: {
    id: "ID", // "instanceid",

    //Responsabil financiar (Denumire, IDNO)
    identifier: "IDNO", //"cmp_fis_cod",

    //Colete și descrierea mărfurilor
    package_first: "Mărci și colete 1", //"pck_mrk1",
    package_second: "Mărci și colete 2", //"pck_mrk2",
    package_code: "Cod ambalaj", //"pck_typ_cod",
    package_name: "Denumire ambalaj", //"pck_typ_nam",
    container_first: "Nr. Container 1", //"gds_ctn_ct1",
    container_second: "Nr. Container 2", //"gds_ctn_ct2",
    container_third: "Nr. Container 3", //"gds_ctn_ct3",
    container_fourth: "Nr. Container 4", //"gds_ctn_ct4",
    goods_description: "Descrierea mărfii", //"gds_dsc",

    //Articol număr
    package_number: "Nr. ambalaje", //"pck_nbr",

    //Codul mărfurilor
    cargo_code_first: "Codul mărfii primele 8 cifre", //"tar_hsc_nb1",
    cargo_code_second: "Codul mărfii urmatoarele 2 cifre", //"tar_hsc_nb2",
    cargo_code_third: "Codul mărfii urmatoarele 3 cifre", //"tar_hsc_nb3",
    cargo_code_fourth: "Codul mărfii urmatoarele 4 cifre", //"tar_hsc_nb4",
    cargo_code_fifth: "Codul mărfii urmatoarele 4 cifre", //"tar_hsc_nb5",

    // /Cod țara origine
    origin_country_code: "Cod teritorial tara de origine", //"gds_org_crg",

    //Greutate brutto (kg)
    gross_weight: "Greutate brutto (kg)", //"vit_wgt_grs",

    //Preferințe
    preference: "Preferințe", //"tar_prf",

    //Regim
    customs_regime: "Regim Vamal", //"tar_prc_ext",
    customs_specification: "Specificul regimului", //"tar_prc_nat",

    //Greutate netto (kg)
    net_weight: "Greutate netto (kg)", //"vit_wgt_net",

    //Contingent
    contingent: "Contingent", //"tar_quo",

    //Declarație sumară/ Document precedent
    summary_statement: "Declarație sumară/ Document precedent", //"lnk_tpt",

    //UM suplimentare
    measurement_code: "Cod unitate de măsură 1", // "tar_sup_cod",
    measurement_quantity: "Cantitatea 1", // "tar_sup_qty",
    measurement_name: "Descriere unitate măsură 1", // "tar_sup_nam",
    measurement_code_first: "Cod unitate de măsură 2", // "tar_sup_cod1",
    measurement_quantity_first: "Cantitatea 2", // "tar_sup_qty1",
    measurement_name_first: "Descriere unitate măsură 2", // "tar_sup_nam1",
    measurement_code_second: "Cod unitate de măsură 3", // "tar_sup_cod2",
    measurement_quantity_second: "Cantitatea 3", // "tar_sup_qty2",
    measurement_name_second: "Descriere unitate măsură 3", // "tar_sup_nam2",

    //Preț articol
    price_invoice_currency: "Preț invoice în valută", // "vit_inv_amt_fcx",
    price_invoice_currency_code: "Cod valută", // "vit_inv_cur_cod",
    price_invoice_currency_curs: "Cursul", // "vit_inv_cur_rat",
    price_invoice_currency_curs_reference: "Referință curs", // "vit_inv_cur_ref",

    price_external: "Preț transportare în lei", //" "vit_efr_amt_nmu",
    price_external_currency: "Preț transoprtare în valută", //" "vit_efr_amt_fcx",
    price_external_currency_code: "Cod valută", //" "vit_efr_cur_cod",
    price_external_currency_curs: "Cursul", //" "vit_efr_cur_rat",
    price_external_currency_curs_reference: "Referință curs", //" "vit_efr_cur_ref",

    price_internal: "Preț transportare internă în lei", // "vit_ifr_amt_nmu",
    price_internal_currency: "Preț transportare internă în valută", // "vit_ifr_amt_fcx",
    price_internal_currency_code: " Cod valută", // "vit_ifr_cur_cod",
    price_internal_currency_curs: "Cursul", // "vit_ifr_cur_rat",
    price_internal_currency_curs_reference: "Referință curs", // "vit_ifr_cur_ref",

    price_ensuring: "Preț asigurare în valută", // "vit_ins_amt_fcx",
    price_ensuring_currency_code: "Cod valută", // "vit_ins_cur_cod",
    price_ensuring_currency_curs: "Curs", // "vit_ins_cur_rat",
    price_ensuring_currency_curs_reference: "Referință curs", // "vit_ins_cur_ref",

    price_other: "Preț alte costuri în valută", // "vit_otc_amt_fcx",
    price_other_currency_code: "Cod valută", // "vit_otc_cur_cod",
    price_other_currency_curs: "Cursul", // "vit_otc_cur_rat",
    price_other_currency_curs_reference: "Referință curs", // "vit_otc_cur_ref",

    price_deduction: "Preț deduceri în valută", // "vit_ded_amt_fcx",
    price_deduction_currency_code: "Cod valută", // "vit_ded_cur_cod",
    price_deduction_currency_curs: " Cursul", // "vit_ded_cur_rat",
    price_deduction_currency_curs_reference: "Referință curs", // "vit_ded_cur_ref",

    //Mențiuni Speciale/ Documente anexate/ Certificate și autorizații
    closing_documents: "Documentele care se închid", // "lnk_prv_doc",
    additional_deposit_code: "Cod depozit adițional", // "lnk_prv_whs",
    licence_code: " Licență", // "lic_cod",
    licence_value: "Valoarea licenței", // "lic_amt_val",
    licence_quantity: "Cantitatea licenței", // "lic_amt_qty",
    text_free: "Text liber", // "txt_fre",
    text_reserved: "Text rezervat", // "txt_rsv",

    //Valoare statistică
    statistical_value: "Valoare statistică", //"vit_stv",

    //Cod M.E.
    evaluation_method_code: "Cod M.E.", //"tar_vmt",
  },
  segment: {
    id: "ID", // "instanceid",
    identifier: "IDNO", // "cmp_con_cod",

    //Formulare
    principal: "Număr", // "pty_frm_nbr",
    complimentary: "Număr total", //"pty_frm_tot",

    //Articole
    article: " Articole", //"pty_nbr_itm",

    //Nr. de referință
    reference_year: "Anul autorizării", //"dec_ref_yer",
    reference_number: "Nr. de referință", //"ide_reg_ser",

    //Total colete
    package_count: "Total colete", //"pty_nbr_pck",

    //Declarație
    declaration: "Declarație", // "ide_typ_trs",

    //Expeditor/ Exportator
    exporter_cod: "Cod expeditor", //"cmp_exp_cod",
    exporter_name: "Denumire, Adresa exportator", //"cmp_exp_nam",

    //Destinatar
    recipient_name: "Destinatar", // "cmp_con_nam",

    //Țara de expediție/ de export
    export_code: "Cod", //"gen_cty_ept_cod",
    export_country: "Țara de expediție/ de export", //"gen_cty_ept_nam",
    recipient_country_code: "Cod ț. exped", //"gen_cty_des_cod",
    recipient_country_name: "Cod ț. export", //"gen_cty_des_nam",

    //Identitatea și naționalitatea mijl. de transport activ care trece frontiera
    transport_identification:
      "Identitatea și naționalitatea mijl. de transport activ care trece frontiera", //"tpt_mot_brd_nam",
    transport_country_code: "Codul ţară transport", // "tpt_mot_brd_cty",

    //Mod de transport la frontieră
    transport_mode: "Mod de transport la frontieră", // "tpt_mot_brd_cod",

    //Locul şi data /semnătura şi numele declarantului sau reprezentantului
    // broker_code: "Cod broker", //"dec_cod",
    broker_code: "IDNO agent destinatar", //"dec_cod",
    broker_name: "Nume broker", //"dec_nam",
    transport_signature: "Semnătura", //"trs_ctl",
    transport_stamp: "Ștampilă", //"trs_lim",

    //Identitatea și naționalitatea mijlocului de transport la plecare / la sosire
    transport_arrival_identification:
      "Identitatea și naționalitatea mijlocului de transport la plecare / la sosire", // "tpt_mot_dpa_nam",
    transport_arrival_code: "Codul naţionalităţii", //"tpt_mot_dpa_cty",

    //Container
    transport_container: "Container", //"tpt_ctf",
  },
};
