import { RouteProps } from "react-router-dom";
import { UserRole } from "../utils";

export interface RouteWithRoles extends RouteProps {
  restrictedTo?: UserRole[];
}

export class RouteBuilder {
  private _routes: RouteWithRoles[];

  constructor() {
    this._routes = [];
  }

  add(
    path: string | string[],
    component: RouteProps["component"],
    exact = false,
    restrictedTo: UserRole[] = [],
  ) {
    this._routes.push({ path, exact, component, restrictedTo });
    return this;
  }

  getRoutes() {
    return [...this._routes];
  }
}
