import React from "react";

export const Upload: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M4.00012 16.9999V18.9999C4.00012 19.5303 4.21084 20.039 4.58591 20.4141C4.96098 20.7892 5.46969 20.9999 6.00012 20.9999H18.0001C18.5305 20.9999 19.0392 20.7892 19.4143 20.4141C19.7894 20.039 20.0001 19.5303 20.0001 18.9999V16.9999"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.00012 9.00013L12.0001 4.00012L17.0001 9.00013"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 4.00012V16.0001"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
