import React from "react";

export const IconContainer: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12 18.25C8.46131 18.25 5.41108 16.2677 2.86829 12C5.41108 7.73232 8.46131 5.75 12 5.75C15.5387 5.75 18.5889 7.73232 21.1317 12C18.5889 16.2677 15.5387 18.25 12 18.25ZM12 4.25C7.63999 4.25 4.09715 6.81857 1.34883 11.6279C1.21706 11.8585 1.21706 12.1415 1.34883 12.3721C4.09715 17.1814 7.63999 19.75 12 19.75C16.36 19.75 19.9028 17.1814 22.6512 12.3721C22.7829 12.1415 22.7829 11.8585 22.6512 11.6279C19.9028 6.81857 16.36 4.25 12 4.25ZM10.75 12C10.75 11.3096 11.3096 10.75 12 10.75C12.6903 10.75 13.25 11.3096 13.25 12C13.25 12.6904 12.6903 13.25 12 13.25C11.3096 13.25 10.75 12.6904 10.75 12ZM12 9.25C10.4812 9.25 9.24999 10.4812 9.24999 12C9.24999 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};
