import React from "react";

export interface AttachmentsUploadContextValue {
  path: string;
}

export const AttachmentsUploadContext =
  React.createContext<AttachmentsUploadContextValue>({
    path: "invalid-path",
  });
