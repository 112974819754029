import React from "react";
import { Button, Form, useForm, Modal, Loader } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";
import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";
import "react-quill-2/dist/quill.snow.css";

import {
  AttachmentsFormField,
  Editor,
  Flex,
  LoadingButton,
  PDFViewer,
  PreviewTabs,
  PreviewTabsEntity,
  PreviewTabsState,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";

export interface EditorModalProps extends ModalProps {
  request?: models.ApplicationRequest;
  data?: {
    name?: string;
    action?: string;
    initialValue?: {
      name?: string;
      content?: string;
    };
    period?: boolean;
    templateId?: string;
    headerTemplateId?: string;
  };
  mutation?: UseMutationResult<unknown, AxiosError, any, unknown>;
  withAttachments?: boolean;
  previewMutation?: UseMutationResult<unknown, AxiosError, any, unknown>;
  pdfFile?: ArrayBuffer;
  setPdfFile?: (pdf?: ArrayBuffer) => void;
}

export const EditorModal = ({
  data,
  request,
  mutation,
  previewMutation,
  withAttachments,
  pdfFile,
  setPdfFile,
  ...props
}: EditorModalProps) => {
  const [form] = useForm();
  const [editorState, setEditorState] = React.useState<string>();
  const [currentTab, setCurrentTab] =
    React.useState<PreviewTabsState>("editor");

  React.useEffect(
    () => setEditorState(data?.initialValue?.content || ""),
    [data?.initialValue?.content],
  );

  const handleClick = () => {
    mutation?.mutate({
      content: editorState,
      ...(withAttachments && {
        attachments: form.getFieldValue("attachments"),
      }),
    });
  };

  const previewTabs: PreviewTabsEntity = {
    editor: {
      tabKey: "editor",
      title: "Editare",
      onClick: () => setCurrentTab("editor"),
      content: (
        <Form form={form}>
          <Editor value={editorState} onChange={setEditorState} />
          {withAttachments && (
            <div className="py-15">
              <AttachmentsFormField />
            </div>
          )}
        </Form>
      ),
    },
    preview: {
      tabKey: "preview",
      title: "Vizualizare",
      onClick: () => {
        previewMutation?.mutate({
          content: editorState,
          attachments: form.getFieldValue("attachments"),
        });
        setCurrentTab("preview");
        setEditorState(editorState);
      },
      content: (
        <div className="py-20">
          <Loader loading={previewMutation?.isLoading!}>
            <PDFViewer file={pdfFile} />
          </Loader>
        </div>
      ),
    },
  };

  return (
    <Modal
      title={data?.initialValue?.name || data?.name || ""}
      {...props}
      className="editor-modal"
      size="large"
      closeOnClickOutside={false}
    >
      <Modal.Content className="p-0">
        <PreviewTabs tabs={previewTabs} currentTab={currentTab} />
      </Modal.Content>
      <Modal.Footer>
        <Flex justify="flex-end">
          <Button
            onClick={() => {
              props?.onClose?.();
              setPdfFile?.(undefined);
            }}
          >
            Anulează
          </Button>
          <WhiteSpace h="1rem" />
          <LoadingButton
            type="primary"
            onClick={handleClick}
            loading={mutation?.isLoading}
          >
            Transmite
          </LoadingButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
