import React from "react";

export const Task: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M10.9996 21.0037H7.99833C5.23576 21.0037 2.99625 18.7642 2.99625 16.0017V7.99833C2.99625 5.23576 5.23576 2.99625 7.99833 2.99625H16.0017C18.7642 2.99625 21.0037 5.23576 21.0037 7.99833V10.9996"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M17.5023 16.0017V19.0029"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M16.0017 17.5023H19.0029"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M6.9499 9.72505L7.86928 10.5534L9.72005 8.8867"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12.9524 9.99917H16.9541"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M6.9499 14.7271L7.86928 15.5555L9.72005 13.8888"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <circle
        cx="17.5023"
        cy="17.5023"
        r="4.50187"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};
