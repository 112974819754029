import React from "react";
import ReactDOM from "react-dom";
import { initCore } from "@aeo/core/init";
import App from "./App";

import "./styles/index.scss";

// Init core
initCore();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
