import React from "react";

export const Reply: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M11 12.9539C10.6621 12.9539 10.3281 12.9687 10 13L10 15L4 10.5L10 6L10 8C15.5234 8 20 12.2233 20 17.4343L19.9941 17.7236L19.9824 18C18.3555 14.8712 14.9434 12.9539 11 12.9539Z"
        stroke={stroke}
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
