import { extraTextareaProps } from "../common";

export const securityThree = {
  security_goods_procedure: {
    name: "security_goods_procedure",
    label:
      "6.6.1. a) Descrieţi succint procedura pentru asigurarea securităţii şi siguranţei mărfurilor intrate.",
    ...extraTextareaProps,
    info: "6.6.1. lit. a) şi b) Aceste proceduri trebuie să înceapă din momentul trimiterii unei comenzi şi să se termine cu livrarea pentru lanţul de aprovizionare internaţional. Procedurile documentate trebuie să indice fluxul mărfurilor şi documentele conexe şi să includă alte părţi implicate precum furnizori, ambalatori, transportatori etc.",
  },
  checking_security_procedure: {
    name: "checking_security_procedure",
    label:
      "b) Descrieţi succint modul în care este verificată respectarea acestor proceduri.",
    ...extraTextareaProps,
  },
  employee_security_measures: {
    name: "employee_security_measures",
    label:
      "6.6.2. Sunt angajaţii informaţi cu privire la măsurile de securitate luate împreună cu furnizorii și cum este asigurată această respectare?",
    ...extraTextareaProps,
    info: `6.6.2. În cazul în care există acorduri privind măsurile de securitate încheiate cu furnizorii interni şi/sau din Republic Moldova și din afara acesteia, personalul trebuie să cunoască aceste acorduri şi trebuie instituite proceduri de verificare a angajamentului faţă de aceste acorduri. Trebuie să descrieţi procedura prin care angajaţii sunt informaţi cu privire la securitate, la frecvenţa repetării instruirilor şi trebuie să puneţi la dispoziţie dovezile solicitate pentru a demonstra toate acestea echipei de audit şi pentru revizuirea statutului de AEO.
Procedurile trebuie, de asemenea, să includă:
<ul>
<li>a) numirea personalului responsabil de primirea şoferului şi a mărfurilor la sosire;</li>
<li>b) menţinerea unui program de sosiri prevăzute;</li>
<li>c) gestionarea sosirilor neprevăzute;</li>
<li>d) înregistrarea documentelor de transport şi a documentelor vamale care însoţesc mărfurile;</li>
<li>e) verificarea mărfurilor în raport cu documentele de transport şi cu documentele vamale însoţitoare;</li>
<li>f) verificarea integrităţii sigiliilor;</li>
<li>g) înregistrarea finalizării şi a rezultatelor verificărilor;</li>
<li>h) informarea autorităţilor vamale cu privire la sosirea mărfurilor, pentru a le permite să efectueze controalele necesare;</li>
<li>i) cântărirea/numărarea şi bifarea mărfurilor pe lista de ridicare/comanda de achiziţii;</li>
<li>j) testarea calităţii;</li>
<li>k) marcarea corespunzătoare a mărfurilor înainte de intrarea în stoc pentru a permite identificarea;</li>
<li>l) identificarea şi raportarea discrepanţelor sau a rezultatelor negative ale controlului calităţii;</li>
<li>m) informarea departamentului de achiziţii şi a administraţiei cu privire la primirea mărfurilor.</li>
</ul>
De exemplu, dacă efectuaţi tranzacţii cu mărfuri cu valoare/risc mare pot exista acorduri în care să se prevadă că mărfurile:
<ul>
<li>a) trebuie să sosească în aceeaşi stare în care au plecat de la furnizor;</li>
<li>b) trebuie să poarte în permanenţă sigiliu;</li>
<li>c) trebuie să nu fi încălcat nicio cerinţă de securitate sau siguranţă.</li>
</ul>
Procedurile trebuie să includă:
<ul>
<li>a) comunicarea acestor acorduri personalului responsabil de primirea mărfurilor intrate astfel încât personalul să ştie ce trebuie să facă, în special dacă se descoperă o neregulă;</li>
<li>b) revizuirea şi actualizarea acestor proceduri în mod regulat;</li>
<li>c) controale de supraveghere sau de gestiune pentru a asigura că personalul respectă aceste cerinţe.</li>
</ul>
`,
  },
  checking_goods_seals: {
    name: "checking_goods_seals",
    label:
      "6.6.3. a) Descrieţi succint modul în care se realizează verificările privind integritatea sigiliilor mărfurilor intrate.",
    ...extraTextareaProps,
    info: `6.6.3. lit. a) şi b) La sosirea unităţilor de încărcare sigilate, trebuie să existe măsuri prin care să se asigure aplicarea corectă a sigiliilor. Acestea ar putea include o inspecţie vizuală pentru a asigura că:
<ul>
<li>a) sigiliul este într-adevăr intact şi </li>
<li>b) nu există dovezi de alterare a acestuia. Dacă inspecţia vizuală este satisfăcătoare, persoana autorizată poate proceda la testarea fizică a sigiliului aplicând presiunea corespunzătoare pentru a se asigura că acesta este încă intact.</li>
<li>c) în cazul în care societatea manevrează anumite tipuri de mărfuri care necesită măsuri de securitate specifice (de exemplu, transportul aerian de mărfuri/poşta aeriană), procedurile trebuie să includă modul în care le aplicaţi/verificaţi aplicarea acestora. De exemplu, în cazul în care acţionaţi în calitate de agent abilitat, dacă şi modul în care verificaţi declaraţia de transport şi identitatea transportatorului pentru transportul aerian de mărfuri/poşta aeriană securizată din partea unui expeditor cunoscut.</li>
</ul>
`,
  },
  has_import_goods_sealed: {
    name: "has_import_goods_sealed",
    label: "b) Sunt bunurile intrate sigilate, dacă acest lucru este necesar?",
  },
  has_types_of_goods: {
    name: "has_types_of_goods",
    label:
      "c) Compania dvs. lucrează cu anumite tipuri de mărfuri care necesită măsuri de securitate specifice (de exemplu, transport aerian de mărfuri/poştă)?",
    dependencies: ["import_goods_sealed"],
  },
  import_goods_sealed: {
    name: "import_goods_sealed",
    label: "Dacă răspunsul este da, ce practici/măsuri sunt instituite?",
    dependencies: ["has_types_of_goods"],
  },
  has_marking_goods: {
    name: "has_marking_goods",
    label: "6.6.4. Sunt mărfurile intrate marcate?",
  },
  marking_goods: {
    name: "marking_goods",
    label: "Dacă răspunsul este da, cum sunt marcate?",
  },
  counting_process: {
    name: "counting_process",
    label:
      "6.6.5. Descrieţi succint procesul de numărare şi cântărire a mărfurilor intrate.",
    ...extraTextareaProps,
    info: "6.6.5. În funcţie de natura mărfurilor pe care le tranzacţionaţi, este posibil ca numărarea, cântărirea sau cuantificarea acestora să nu fie întotdeauna adecvate. Trebuie descrisă o metodă alternativă pentru contabilizarea mărfurilor intrate şi pentru dovedirea respectării acesteia.",
  },
  counting_process_register: {
    name: "counting_process_register",
    label:
      "6.6.6. Descrieţi succint modul în care, când şi de către cine mărfurile intrate sunt verificate în raport cu documentele însoţitoare şi sunt înscrise în evidențele dvs.",
    ...extraTextareaProps,
    info: `6.6.6. Procedurile trebuie să includă:
<ul>
<li>a) modul în care mărfurile primite sunt în înregistrate în registrul de stocuri, documentele în baza cărora se efectuează acest lucru, momentul în care are loc înregistrarea şi persoana care o realizează;</li>
<li>b) verificarea mărfurilor în raport cu listele de încărcare şi cu comenzile de achiziţii;</li>
<li>c) înregistrarea mărfurilor în registrul de stocuri cât mai curând posibil după sosire.</li>
</ul>
`,
  },
  has_separated_responsible: {
    name: "has_separated_responsible",
    label:
      "6.6.7. a) Sunt unităţile responsabile pentru achiziţionarea mărfurilor, primirea mărfurilor şi administraţia generală separate în mod clar?",
    info: "6.6.7. lit. a) şi b) Trebuie să existe o separare a funcţiilor între comandarea mărfurilor (achiziţii), primirea acestora (depozitare), intrarea mărfurilor în sistem (gestionare) şi plata facturilor corespunzătoare. Această separare va depinde de dimensiunea şi complexitatea întreprinderii.",
  },
  has_internal_control: {
    name: "has_internal_control",
    label:
      "b) Există între aceste unităţi mecanisme de control intern integrate?",
    dependencies: ["internal_control"],
  },
  internal_control: {
    name: "internal_control",
    label: "Dacă răspunsul este da, cum sunt aplicate acestea?",
    dependencies: ["has_internal_control"],
  },
  allocated_storage_subdivision: {
    name: "allocated_storage_subdivision",
    label:
      "6.7.1. Descrieţi în ce subdiviune aţi alocat zone pentru depozitarea mărfurilor.",
    ...extraTextareaProps,
    info: `6.7.1. - 6.7.5.Procedurile trebuie să includă:
<ul>
<li>a) zonă special amenajată pentru depozitarea mărfurilor care să asigure atât condiţii de siguranţă, cât şi de securitate şi care să fie cunoscută în mod clar de personalul de control;</li>
<li>b) zona de depozitare accesibilă exclusiv personalului autorizat;</li>
<li>c) inventarierea regulată a stocurilor;</li>
<li>d) controlarea mărfurilor intrate, a transferurilor către alte spaţii, a eliminării definitive sau temporare;</li>
<li>e) măsurile care trebuie luate dacă sunt identificate nereguli, discrepanţe, pierderi sau furturi;</li>
<li>f) manipularea şi prelucrarea mărfurilor şi reincluderea acestora în stocuri;</li>
<li>g) separarea diferitelor tipuri de mărfuri, acolo unde este cazul, de exemplu, străine, autohtone, cu valoare mare, periculoase, transport aerian de mărfuri/poştă aeriană;</li>
<li>h) ţinerea şi actualizarea registrelor de stocuri prin indicarea corectă a locului unde se află mărfurile;</li>
<li>i) abordarea tuturor aspectelor care ţin de securitatea fizică a spaţiului de depozitare.</li>
</ul>
Standardele de securitate vor depinde de tipul de mărfuri, de dimensiunea şi complexitatea întreprinderii, care poate varia de la o cameră într-o clădire de birouri până la o societate complexă cu subdiviziuni multiple.`,
  },
  assigning_storage_procedure: {
    name: "assigning_storage_procedure",
    label:
      "6.7.2. a) Descrieţi succint procedura de atribuire a poziţiei de depozitare a mărfurilor intrate.",
    ...extraTextareaProps,
  },
  has_outdoor_storage: {
    name: "has_outdoor_storage",
    label: "b) Dispuneţi de zone de depozitare în aer liber?",
    dependencies: ["outdoor_storage"],
  },
  outdoor_storage: {
    name: "outdoor_storage",
    label: "Dacă răspunsul este da, descrieţi-le pe scurt.",
    dependencies: ["has_outdoor_storage"],
  },
  has_inventorying_procedures: {
    name: "has_inventorying_procedures",
    label:
      "6.7.3. Dispuneţi de proceduri documentate pentru inventarierea stocurilor de mărfuri şi pentru corectarea discrepanţelor identificate în cadrul inventarierii?",
    dependencies: ["inventorying_procedures"],
  },
  inventorying_procedures: {
    name: "inventorying_procedures",
    label: "Dacă răspunsul da, descrieţi-le pe scurt.",
    dependencies: ["has_inventorying_procedures"],
  },
  has_stored_separate: {
    name: "has_stored_separate",
    label:
      "6.7.4. Sunt mărfurile care prezintă niveluri diferite de risc depozitate separat?",
  },
  criteria_separate_storage: {
    name: "criteria_separate_storage",
    label:
      "a) Descrieți criteriile pentru orice depozitare separată (de exemplu, mărfuri periculoase, mărfuri de valoare mare, substanţe chimice, arme, transport aerian de mărfuri/poştă aeriană).",
    ...extraTextareaProps,
  },
  register_accounting_goods: {
    name: "register_accounting_goods",
    label:
      "b) Descrieţi modul în care vă asiguraţi că mărfurile sunt înregistrate fără întârziere în registrele contabile/de inventar.",
    ...extraTextareaProps,
  },
  protected_goods_access: {
    name: "protected_goods_access",
    label:
      "6.7.5. a) Descrieţi succint modul în care mărfurile sunt protejate împotriva accesului neautorizat în spaţiul de depozitare.",
    ...extraTextareaProps,
  },
  procedures_verified: {
    name: "procedures_verified",
    label:
      "b) Descrieţi succint modul în care este verificată respectarea acestor proceduri.",
    ...extraTextareaProps,
  },
  has_third_party_storage: {
    name: "has_third_party_storage",
    label:
      "6.7.6. Este depozitarea mărfurilor contractată unui terţ/furnizor de servicii?",
    dependencies: ["third_party_storage"],
    info: "6.7.6. În cazul subcontractării, indicaţi societatea (societăţile) care prestează servicii de depozitare.",
  },
  third_party_storage: {
    name: "third_party_storage",
    label:
      "Dacă răspunsul da, descrieţi succint cum şi unde sunt depozitate mărfurile şi măsurile de control pe care le utilizaţi pentru supravegherea manipulării mărfurilor.",
    dependencies: ["has_third_party_storage"],
  },
  production_subdivisions: {
    name: "production_subdivisions",
    label:
      "6.8.1. a) Descrieţi succint ce sudiviziuni/zone sunt destinate producţiei de mărfuri.",
    ...extraTextareaProps,
    info: `6.8.1 lit. a) şi b) Precizaţi în descriere dacă personalul care lucrează în cadrul zonei de producţie este angajat permanent al societăţii sau este personal temporar. Descrieţi amplasamentul zonei de producţie în cadrul sediului şi, dacă este posibil, indicaţi amplasarea acesteia pe o copie a planului de amplasament. De asemenea, consultaţi şi notele aferente subpunctului 6.2.3.`,
  },
  has_external_production: {
    name: "has_external_production",
    label:
      "b) Este producţia realizată de un partener extern (de exemplu, prelucrare în lohn, livrări directe)?",
    dependencies: ["external_production"],
  },
  external_production: {
    name: "external_production",
    label:
      "Dacă răspunsul da, descrieţi pe scurt modul în care este asigurată integritatea mărfurilor (de exemplu, acorduri contractuale)?",
    dependencies: ["has_external_production"],
  },
  has_protect_access_measures: {
    name: "has_protect_access_measures",
    label:
      "6.8.2. Există măsuri de securitate care protejează mărfurile împotriva accesului neautorizat în zona de producţie?",
    dependencies: ["protect_access_measures"],
    info: "6.8.2. Justificaţi răspunsul făcând referire, dacă este cazul, la evaluarea riscurilor şi ameninţărilor descrisă la întrebările de la subpunctul 6.1.2 lit. a) şi b). Verificarea conformităţii trebuie să fie susţinută prin dovezi corespunzătoare semnate şi datate.",
  },
  protect_access_measures: {
    name: "protect_access_measures",
    label:
      "Dacă răspunsul este da, descrieţi pe scurt care sunt aceste măsuri?",
    dependencies: ["has_protect_access_measures"],
  },
  product_packaging_procedure: {
    name: "product_packaging_procedure",
    label:
      "6.8.3. Descrieţi succint procedurile de ambalare a produselor şi menţionaţi dacă există instrucțiuni scrise în acest sens.",
    ...extraTextareaProps,
    info: "6.8.3. Includeţi referirile la suporturile tehnologice pentru integritatea ambalajelor (de exemplu, greutatea verificată sau supraveghere prin TV cu circuit închis etc.). De asemenea, descrieţi procesul de securizare atât a ambalajelor individuale, cât şi a modului în care ambalajele sunt consolidate, de exemplu, prin paletizare. Furnizaţi detalii privind măsura în care destinatarul (adresa/ţara) este cunoscut şi modul în care este controlată cunoaşterea acestor date.",
  },
  has_third_party_packaging: {
    name: "has_third_party_packaging",
    label:
      "6.8.4. Ambalarea produsului final este externalizată către un terț?",
    dependencies: ["third_party_packaging"],
    info: `6.8.4. Descrierea trebuie să menţioneze contractele şi/sau acordurile privind nivelul serviciilor încheiate cu părţile terţe. Autoritatea vamală va dori să vadă aceste contracte.
Întrebarea vizează şi situaţiile în care ambalajele pot fi consolidate.`,
  },
  third_party_packaging: {
    name: "third_party_packaging",
    label:
      "Dacă răspunsul este da, descrieți pe scurt cum este garantată integritatea mărfurilor.",
    dependencies: ["has_third_party_packaging"],
  },
  goods_loading: {
    name: "goods_loading",
    label:
      "6.9.1. a) Descrieţi pe scurt modul în care este gestionată încărcarea mărfurilor în compania dvs. (de exemplu, stabilirea responsabilităţilor, verificarea mărfurilor şi a mijloacelor de transport, înregistrarea rezultatelor, furnizarea de informaţii etc.)?",
    ...extraTextareaProps,
    info: `6.9.1. lit. a) şi b) şi 6.9.2 lit. a), b) şi c) Trebuie să fie numiţi membri ai personalului care să supravegheze încărcarea mărfurilor pentru a preveni încărcarea nesupravegheată a acestora sau omiterea unora dintre acestea. Procedurile trebuie să includă:
<ul>
<li>a) numirea personalului responsabil de primirea şoferului şi de încărcarea mărfurilor;</li>
<li>b) personalul desemnat este prezent permanent;</li>
<li>c) procedura în cazul în care personalul desemnat nu este disponibil, de exemplu, numirea unor supleanți;</li>
<li>d) încărcarea are loc numai în prezenţa personalului autorizat;</li>
<li>e) cântărirea, numărarea, bifarea şi marcarea mărfurilor;</li>
<li>f) gestionarea discrepanţelor/neregulilor;</li>
<li>g) aplicarea sigiliilor şi înregistrarea în documente/evidenţe, asigurându-se că sigiliile au fost utilizate pentru mărfurile corespunzătoare, că acestea îndeplinesc standardele stabilite şi au fost aplicate în conformitate cu cerinţele legale;</li>
<li>h) înregistrarea documentelor de transport şi vamale care însoţesc marfa în evidenţele ;</li>
<li>i) compararea mărfurilor cu documentele de transport şi cu documentele vamale însoţitoare</li>
<li>j) înregistrarea finalizării şi a rezultatelor verificărilor;</li>
<li>k) informarea organelor vamale cu privire la plecarea mărfurilor, pentru a le permite să efectueze controalele necesare;</li>
<li>l) informarea departamentului de vânzări/administraţiei cu privire la plecarea mărfurilor;</li>
<li>m) în ce mod (pe baza căror documente), când şi de către cine sunt înregistrate mărfurile încărcate în registrele de stocuri;</li>
<li>n) verificarea mărfurilor în raport cu listele de încărcare şi cu comenzile de vânzări;</li>
<li>o) înregistrarea ieşirii mărfurilor din stoc, cât mai curând posibil după plecarea acestora;</li>
<li>p) confirmarea de primire din partea clienţilor a primirii mărfurilor şi a eventualelor nereguli;</li>
<li>q) dovada de export, dacă este cazul.</li>
</ul>
`,
  },
  has_loading_procedure: {
    name: "has_loading_procedure",
    label:
      "b) Există instrucţiuni scrise privind modul în care procesul ar trebui să fie organizat?",
  },
  has_goods_sealed: {
    name: "has_goods_sealed",
    label:
      "6.9.2. a) Sunt mărfurile sau mijloacele de transport ieşite sigilate?",
    dependencies: ["goods_sealed"],
  },
  goods_sealed: {
    name: "goods_sealed",
    label:
      "Dacă răspunsul este da, cum, de către cine şi ce fel de sigilii utilizaţi?",
    dependencies: ["has_goods_sealed"],
  },
  has_seals_register: {
    name: "has_seals_register",
    label:
      "b) Sunt menţionate numere de sigilii în documentele care însoţesc mărfurile?",
  },
  seals_register: {
    name: "seals_register",
    label: "c) Cum se realizează evidența sigiliilor?",
    ...extraTextareaProps,
  },
  client_requirements: {
    name: "client_requirements",
    label:
      "6.9.3. Descrieţi succint modul în care este garantată respectarea cerinţelor de securitate ale clienţilor în ceea ce priveşte încărcarea.",
    ...extraTextareaProps,
    info: "6.9.3. Acest punct se va aplica numai dacă aţi convenit împreună cu clienţii asupra unor cerinţe specifice, de exemplu, toate mărfurile trebuie să fie sigilate, ambalate şi etichetate într-un anumit mod pentru cerinţele privind razele X. În caz afirmativ, personalul trebuie informat cu privire la aceste acorduri, iar procedurile trebuie să includă controale de gestiune/de supraveghere pentru a vă asigura că personalul respectă cerinţele respective. Aceste proceduri trebuie să fie revizuite şi actualizate în mod regulat. A se vedea şi răspunsul de la subpunctul 6.1.11.",
  },
  supervision_measures: {
    name: "supervision_measures",
    label:
      "6.9.4. Descrieţi succint măsurile instituite care asigură supravegherea permanentă a mărfurilor care trebuie încărcate şi a procesului de încărcare.",
    ...extraTextareaProps,
  },
  has_checking_completion: {
    name: "has_checking_completion",
    label:
      "6.9.5. Se verifică dacă mărfurile ieşite sunt complete (de exemplu, sunt numărate, cântărite)?",
    dependencies: ["checking_completion"],
  },
  checking_completion: {
    name: "checking_completion",
    label: "Dacă răspunsul este da, cum şi de către cine?",
    dependencies: ["has_checking_completion"],
  },
  checking_coming_orders: {
    name: "checking_coming_orders",
    label:
      "6.9.6. Descrieţi succint cum, când şi de către cine sunt verificate mărfurile care ies în raport cu comenzile şi listele de încărcare, precum şi procedura prin care este înregistrată ieşirea lor din registrul de stocuri.",
    ...extraTextareaProps,
  },
  loading_procedure: {
    name: "loading_procedure",
    label:
      "6.9.7. Descrieţi succint ce mecanisme de control aţi instituit pentru detectarea neregulilor privind încărcarea mărfurilor.",
    ...extraTextareaProps,
    info: "<p>6.9.7. Dovezile care vin în sprijinul răspunsului de la această secţiune trebuie să fie prezentate împreună cu referirile la partea corespunzătoare din cadrul evaluării riscurilor şi a ameninţărilor de la întrebarea de la subpunctul 6.1.2. lit.a) şi b). Neregulile pot include returnările din partea clienţilor, şoferii neautorizaţi, dispozitivele de protecţie împotriva modificărilor ilicite deteriorate etc.</p>",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
