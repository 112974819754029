import { makeBEM } from "@aeo/core/utils";

export interface SubItemListProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const bem = makeBEM("saq-tabs");

export const SubItemList = (props: SubItemListProps) => {
  return <div className={bem("subitem-list")} {...props}></div>;
};
