import cn from "classnames";
import models from "../../models";
import { DefaultDepsValueObj } from "../../models/common";
import { FakeInputProps } from "../FakeInput";

export const booleanRadioOptions = [
  { text: "Da", value: "true" },
  { text: "Nu", value: "false" },
];

export const modeOptions = [
  { text: "Direct", value: "true" },
  { text: "Indirect", value: "false" },
];

export const booleansMap: { [key: string]: boolean } = {
  true: true,
  false: false,
};

export const notApplicable = "Nu se aplică";
export const noNotApplicable = "Nu / Nu se aplică";

export const notApplicableRadioOptions = [
  { text: "Da", value: "true" },
  { text: notApplicable, value: "false" },
];

export const notNARadioOptions = [
  { text: "Da", value: "true" },
  { text: noNotApplicable, value: "false" },
];

export const placeOptions = [
  { text: "Intern", value: "true" },
  { text: "Extern", value: "false" },
];

export const relativeClassName = (
  info?: React.ReactNode,
  ...classNames: any[]
) =>
  cn(classNames, {
    "ebs-form__item--relative": info,
  });

export type FieldValueType = string | boolean | string[] | null | undefined;

export const normalizeRadioDepsValues = (
  value?: string | boolean | string[] | DefaultDepsValueObj | null | undefined,
  radioValue?: string | number,
) =>
  typeof value === "object" && value !== null && !Array.isArray(value)
    ? value[radioValue as keyof typeof value]
    : value;
