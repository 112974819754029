import React from "react";

export const News: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M18 8.00001C18.7956 8.00001 19.5587 8.31608 20.1213 8.87869C20.6839 9.44129 21 10.2044 21 11C21 11.7957 20.6839 12.5587 20.1213 13.1213C19.5587 13.6839 18.7956 14 18 14M10 8.00001V19C10 19.2652 9.89464 19.5196 9.70711 19.7071C9.51957 19.8946 9.26522 20 9 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V14M12 8.00001L16.524 4.23001C16.6555 4.12052 16.8154 4.05076 16.9851 4.02891C17.1548 4.00707 17.3271 4.03404 17.482 4.10666C17.6369 4.17928 17.7679 4.29455 17.8597 4.43896C17.9514 4.58337 18.0001 4.75093 18 4.92201V17.078C18.0001 17.2491 17.9514 17.4166 17.8597 17.5611C17.7679 17.7055 17.6369 17.8207 17.482 17.8934C17.3271 17.966 17.1548 17.9929 16.9851 17.9711C16.8154 17.9493 16.6555 17.8795 16.524 17.77L12 14H4C3.73478 14 3.48043 13.8946 3.29289 13.7071C3.10536 13.5196 3 13.2652 3 13V9.00001C3 8.73479 3.10536 8.48044 3.29289 8.2929C3.48043 8.10536 3.73478 8.00001 4 8.00001H12Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
