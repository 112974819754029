import { createClassWithModifiers } from "../../utils";

export type ButtonVariants = "rounded";

interface Props {
  icon: React.ReactNode;
  className?: string;
  rounded?: boolean;
  variants?: ButtonVariants[];
}
//DEPRECATED DON'T USE
export const IconButton = ({
  className = "",
  variants = [],
  icon,
  rounded,
}: Props) => {
  return (
    <button
      className={`${createClassWithModifiers("icon-button", variants, {
        rounded: rounded,
      })} ${className}`}
    >
      {icon}
    </button>
  );
};
