import { useMutation, useQuery } from "react-query";
import { Button, Card, Space, Loader, useNotify } from "ebs-design";
import { NumberParam } from "use-query-params";

import { useModalState, useParam } from "@aeo/core/hooks";
import { formattedDateTime, notifyErrors } from "@aeo/core/utils";
import { SignModal } from "@aeo/core/components";
import models from "@aeo/core/models";
import { ApplicationExaminationStateType } from "@aeo/core/types";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";
import { RecommendationForm } from "./RecommendationForm";

const sortData = (
  array: models.Recommendation[] | models.ReAuditRecommendation[],
) => array?.sort((a, b) => (a.id! < b.id! ? -1 : a.id! > b.id! ? 1 : 0));

export const Recommendations = ({
  type,
}: {
  type: ApplicationExaminationStateType;
}) => {
  const notify = useNotify();
  const signModalState = useModalState();

  const applicationId = useParam("id", NumberParam.decode) || 0;

  const { data, isLoading } = useQuery(
    querykeys.applications.recommendation(applicationId, { type }),
    () => api.application.getRecommendations(applicationId, type),
    {
      enabled: !!applicationId,
    },
  );

  const { data: file_upload } = useQuery(
    querykeys.applications.recommendationDocument(applicationId, { type }),
    () => api.application.getRecommendationDocument(applicationId),
    {
      enabled: signModalState.isOpen,
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const signMutation = useMutation(
    async () => api.application.signRecommendation(applicationId),
    {
      onSuccess: () => notify.success({ title: notifications.signedDocument }),
      onError: (error) => notifyErrors(notify, error),
    },
  );

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <>
      {type === "audit" && data?.length ? (
        <Card style={{ maxWidth: 900 }}>
          <Card.Body>
            <Space direction="vertical" className="stretch-vertical-elements">
              {sortData(data).map((item, index) => {
                return (
                  <RecommendationForm
                    key={index}
                    recommendation={item}
                    title={`Recomandare nr. ${index + 1}`}
                    application={{
                      id: applicationId,
                      type,
                    }}
                  />
                );
              })}
            </Space>
          </Card.Body>

          <Card.Footer>
            <Space justify="end">
              {data && type === "audit" && (
                <Button
                  onClick={signModalState.open}
                  type="primary"
                  disabled={
                    !(data as models.Recommendation[]).some(
                      (v) => !!v.can_response,
                    )
                  }
                >
                  Spre semnare
                </Button>
              )}
            </Space>
          </Card.Footer>
        </Card>
      ) : data?.length ? (
        sortData(data).map((item: models.ReAuditRecommendation, index) => {
          return (
            <Card key={index} className="mb-30">
              <Card.Header>
                <h3>Recomandari {formattedDateTime(item?.re_audit_date)}</h3>
              </Card.Header>
              {item?.recommendations?.length ? (
                <>
                  <Card.Body>
                    <Space
                      direction="vertical"
                      className="stretch-vertical-elements"
                    >
                      {sortData(item?.recommendations).map((v, idx) => {
                        return (
                          <RecommendationForm
                            key={idx}
                            title={`Recomandare nr. ${idx + 1}`}
                            recommendation={v}
                            application={{
                              id: applicationId,
                              type,
                            }}
                          />
                        );
                      })}
                    </Space>
                  </Card.Body>

                  <Card.Footer>
                    <Space justify="end">
                      <Button
                        onClick={signModalState.open}
                        type="primary"
                        disabled={
                          !item.recommendations.some((v) => !!v.can_response)
                        }
                      >
                        Spre semnare
                      </Button>
                    </Space>
                  </Card.Footer>
                </>
              ) : (
                <Card.Body>
                  <p>Nu aveți nici o recomandare.</p>
                </Card.Body>
              )}
            </Card>
          );
        })
      ) : (
        <p>Nu aveți nici o recomandare.</p>
      )}

      {signModalState.isOpen && (
        <SignModal
          open={signModalState.isOpen}
          onClose={signModalState.close}
          file={file_upload}
          mutation={signMutation}
        />
      )}
    </>
  );
};
