import { Button, Card, Form, Loader, Space, useForm } from "ebs-design";

import {
  Grid,
  WhiteSpace,
  InputFormField,
  DatePickerFormField,
  AttachmentsFormField,
  LoadingButton,
  NomenclaturesSelect,
  InputPhoneFormField,
  InputIDNPFormField,
} from "@aeo/core/components";
import { useRouter, useAuth } from "@aeo/core/hooks";
import { capitalize, UserRoles } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import models from "@aeo/core/models";

import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { TitleWithLoading, TitleTooltip } from "components";

export const RequestForm = () => {
  const { push } = useRouter();
  const { user, role } = useAuth();

  const [form] = useForm();
  const request = fields.request;

  const [save, saveMutation] = useAutoSave("request", form);
  const { isLoading } = useDraftApplicationQuery(
    "request",
    form,
    undefined,
    (v) => ({
      ...v,
      applicant_name: v?.applicant_name || user?.organization?.name,
      applicant_idno: v?.applicant_idno || user?.organization?.idno,
      juridic_form: v?.juridic_form || user?.organization?.juridic_form,
    }),
  );

  const validateMutation = useValidateMutation(
    "request",
    form,
    "/application/saq",
  );

  if (isLoading) return <Loader loading />;

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={validateMutation.mutate}
        onValuesChange={(_, values) => save(values)}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              {...messages.requestConfig}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <h4>{capitalize(messages.titles.requiredRequestData)}</h4>
            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...request.applicant_name} />

              <InputIDNPFormField {...request.applicant_idno} />
            </Grid>

            <div className="divider mt-20 mb-20" />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <NomenclaturesSelect
                entityName={models.Nomenclatures.Juridics}
                {...request.juridic_form}
              />
              <DatePickerFormField {...request.applicant_created} />
            </Grid>

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...request.applicant_address} />
              <InputFormField {...request.activity_address} />
            </Grid>

            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...request.postal_address} />
              <NomenclaturesSelect
                {...request.activity_sector}
                mode="multiple"
                entityName={models.Nomenclatures.Sectors}
                allowAddNew
              />
            </Grid>

            <WhiteSpace />
            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <NomenclaturesSelect
                {...request.activity_states}
                entityName={models.Nomenclatures.States}
                mode="multiple"
                labelProp="full_name"
                getOptionLabel={(item: models.Country) =>
                  `${item.full_name} (${item.name})`
                }
              />
            </Grid>

            <div className="divider mt-20 mb-20" />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <NomenclaturesSelect
                {...request.crossing_customs}
                entityName={models.Nomenclatures.Posts}
                mode="multiple"
              />
              <InputFormField {...request.facility} />
            </Grid>

            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...request.management_customs} />
              <InputFormField {...request.accounting_office} />
            </Grid>

            <div className="divider mt-10" />
            <WhiteSpace />

            <TitleTooltip
              title={<h4>{capitalize(messages.titles.contactPerson)}</h4>}
              info={request.contact_person.info}
            />
            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...request.contact_name} />

              <InputPhoneFormField {...request.contact_phone} />
            </Grid>

            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...request.contact_email} />

              <InputPhoneFormField {...request.contact_fax} />
            </Grid>

            <div className="divider mt-20 mb-20" />

            <h4>{capitalize(messages.titles.signatory)}</h4>
            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...request.signer_name} />

              <InputFormField {...request.signer_function} />
            </Grid>

            <AttachmentsFormField
              {...request.license_attachments}
              rules={[{ required: role !== UserRoles.admin }]}
            >
              Atașează împuternicirea
            </AttachmentsFormField>

            <div className="divider mt-20 mb-20" />

            {/* Required documents */}
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />

            {/* Deschide Modalul pentru a selecta documente sau sa incarce */}
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <Button type="ghost" onClick={() => push("/")}>
                Anulează
              </Button>
              <LoadingButton
                loading={validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
