import { Alert, Card, Form, useForm } from "ebs-design";

import {
  BooleanRadioFormField,
  Flex,
  FormList,
  LoadingButton,
} from "@aeo/core/components";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";

import { NotApplicableFormList } from "../NotApplicableFormList";
import { PersonFormFields } from "./fields";

const saq = messages.SAQTabs;

// TODO: Use this function for form list fields
const normalizeValues = (values: any) => {
  const updatedValues: any = {};
  Object.entries(values).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      updatedValues[key] = value.some((v) =>
        Object.values(v).some((val) => val),
      )
        ? value
        : [];
    } else {
      updatedValues[key] = value;
    }
  });

  return updatedValues;
};

// FIXME: Handle validation errors for form list
export const AdvisoryForm = () => {
  const [form] = useForm();
  const person = { ...fields.person, ...fields.personAdvisory };

  const [save, saveMutation] = useAutoSave("saq/advisory", form);
  useDraftApplicationQuery("saq/advisory", form);
  const validateMutation = useValidateMutation("saq/advisory", form, "contact");

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(normalizeValues(values), {
            onSuccess: () => validateMutation.mutate(normalizeValues(values)),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(normalizeValues(form.getFieldsValue())), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              info={saq.advisory.info}
              title={saq.advisory.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body className="pt-5">
            <BooleanRadioFormField
              className="mb-0"
              radioField={person.has_advisory}
            />

            <NotApplicableFormList {...person.advisers}>
              <FormList
                subTitle={saq.advisory.subTitle}
                name={person.advisers.name}
                Component={PersonFormFields}
                initialValue={[]}
                componentProps={{
                  informationBox: (
                    <Alert type="info" closable>
                      Pentru a adăuga lista completă a datelor consiliului
                      consultativ, puteți adăuga manual sau să atașați lista.
                    </Alert>
                  ),
                  attachmentsLabel:
                    "Documente confirmative privind numele, prenumele membrilor consiliului consultativ.",
                }}
              />
            </NotApplicableFormList>
          </Card.Body>
          <Card.Footer>
            <Flex justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
