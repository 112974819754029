import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const LogisticThreeValues = () => {
  const logisticThree = fields.logisticThree;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <h4>{capitalize(messages.titles.procedures)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logisticThree.backup_process} />
      <KeyValueCard {...logisticThree.backup_ttl} />
      <KeyValueCard {...logisticThree.has_emergency_plan} type="boolean" />
      <KeyValueCard {...logisticThree.emergency_plan} />
      <KeyValueCard
        {...logisticThree.emergency_plan_attachments}
        type="attachments"
      />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.protectionOfSystems)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logisticThree.protect_system} />
      <KeyValueCard {...logisticThree.has_test_auth} type="boolean" />
      <KeyValueCard {...logisticThree.test_authorization} NA />
      <KeyValueCard {...logisticThree.incident_security} />
      <KeyValueCard {...logisticThree.access_rights} />
      <KeyValueCard {...logisticThree.protection_responsible} />
      <KeyValueCard {...logisticThree.internal_security} />
      <KeyValueCard
        {...logisticThree.internal_security_attachments}
        type="attachments"
      />
      <KeyValueCard {...logisticThree.monitor_security} />
      <KeyValueCard {...logisticThree.server_located} />
      <KeyValueCard {...logisticThree.server_secured} />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.documentationSecurity)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logisticThree.protect_information_procedure} />
      <KeyValueCard {...logisticThree.has_unauthorized_access} type="boolean" />
      <KeyValueCard {...logisticThree.unauthorized_access} />
      <KeyValueCard {...logisticThree.employee_category} />
      <KeyValueCard {...logisticThree.allowed_change} />
      <KeyValueCard {...logisticThree.change_document_procedure} />
      <KeyValueCard {...logisticThree.security_requirements} />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...logisticThree.attachments} type="attachments" />
    </>
  );
};

export const LogisticThree = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.logistic.title}
      content={<LogisticThreeValues />}
      path="saq/logistic_three"
    />
  );
};
