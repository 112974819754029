import models from "@aeo/core/models";
import { SelectProps } from "ebs-design/dist/components/molecules/Select/components";

export const riskLevelFeminine = {
  [models.RiskLevel.low]: "scăzută",
  [models.RiskLevel.medium]: "medie",
  [models.RiskLevel.high]: "înaltă",
};

export const riskLevelMasculine = {
  [models.RiskLevel.low]: "scăzut",
  [models.RiskLevel.medium]: "mediu",
  [models.RiskLevel.high]: "înalt",
};

export const probabilityOptions: SelectProps["options"] = Object.entries(
  riskLevelFeminine,
).map(([value, text]) => ({ value, text }));

export const impactOptions: SelectProps["options"] = Object.entries(
  riskLevelMasculine,
).map(([value, text]) => ({ value, text }));
