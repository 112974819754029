/** API */

export const REFRESH_TOKEN_KEY = "refresh_token";
export const ACCESS_TOKEN_KEY = "access_token";

export const API_POST_CONTENT_TYPE = "application/json";

//
export const NO_AVAILABLE_ERRORS_MESSAGE =
  "A apărut o eroare neprevăzută, contactați asistența tehnică.";

/** Text */
export const PHONE_PREFIX = "+373";
export const LOCALE = "RO-ro";

export const MAX_DOCUMENT_UPLOAD_SIZE = 5e6;

/**  */
export const AUTO_SAVE_TIMEOUT = 2000; // 1500

export const COLLAPSE_DURATION = 400;

export const INFINITE_SCROLL_MARGIN = "500px";

export const NO_TITLE_PLACEHOLDER = "Fără titlu";

export const SCROLL_TO_TOP_SHOW_OFFSET = 50;

export const DATE_FORMAT = "dd/MM/yyyy";

export const DATE_FORMAT_API = "yyyy-MM-dd";

export const REFETCH_NOTIFICATION_INTERVAL = 30000; /* Chat notifications 30 sec */

export const REFETCH_MENU_NOTIFICATIONS_INTERVAL = 10000;
