import React from "react";

export const Notification: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M9.99971 5C9.99971 4.46957 10.2104 3.96086 10.5855 3.58579C10.9606 3.21071 11.4693 3 11.9997 3C12.5301 3 13.0388 3.21071 13.4139 3.58579C13.789 3.96086 13.9997 4.46957 13.9997 5C15.1481 5.54303 16.1271 6.38833 16.8318 7.4453C17.5364 8.50227 17.9401 9.73107 17.9997 11V14C18.075 14.6217 18.2951 15.2171 18.6425 15.7381C18.9899 16.2592 19.4548 16.6914 19.9997 17H3.99971C4.54465 16.6914 5.00952 16.2592 5.3569 15.7381C5.70428 15.2171 5.92445 14.6217 5.99971 14V11C6.05927 9.73107 6.46301 8.50227 7.16766 7.4453C7.87231 6.38833 8.8513 5.54303 9.99971 5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9 17V18C9 18.7956 9.31607 19.5587 9.87868 20.1213C10.4413 20.6839 11.2044 21 12 21C12.7956 21 13.5587 20.6839 14.1213 20.1213C14.6839 19.5587 15 18.7956 15 18V17"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
