import { Card, Form, Space, useForm } from "ebs-design";
import {
  Grid,
  WhiteSpace,
  InputFormField,
  AttachmentsFormField,
  LoadingButton,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import { GenericObject } from "@aeo/core/types";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableCheckboxTrigger, NotApplicableFormField } from "..";

/**
 * Reverse boolean values for has_* props that have double negation
 * It's only used for turnovers
 * @param values form values
 * @returns form values with reversed boolean values
 */
const reverseBooleanValues = (values: GenericObject) =>
  values
    ? {
        ...values,
        has_annual_profit: !values?.has_annual_profit,
        has_annual_turnover: !values?.has_annual_turnover,
        has_declarations: !values?.has_declarations,
        tax: {
          ...values?.tax,
          has_tax: !values?.tax?.has_tax,
        },
      }
    : undefined;

export const TurnoverForm = () => {
  const turnover = fields.turnover;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/turnover", form);
  useDraftApplicationQuery(
    "saq/turnover",
    form,
    undefined,
    reverseBooleanValues,
  );
  const validateMutation = useValidateMutation(
    "saq/turnover",
    form,
    "statistic",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) =>
          saveMutation.mutate(reverseBooleanValues(values), {
            onSuccess: () => {
              validateMutation.mutate(
                values ? reverseBooleanValues(values) : values,
              );
            },
          })
        }
        onValuesChange={() => {
          setTimeout(
            () => save(reverseBooleanValues(form.getFieldsValue())),
            0,
          );
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.turnover.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <TitleTooltip
              title={turnover.annual_turnover.label}
              info={turnover.annual_turnover.infoTitle}
            />
            <Grid cols="1fr 1fr" gap="1rem">
              <NotApplicableFormField
                {...turnover.annual_turnover}
                hideLabel
                dependencies={["has_annual_turnover"]}
              />
              <NotApplicableCheckboxTrigger {...turnover.has_annual_turnover} />
            </Grid>

            <p>{turnover.annual_profit.label}</p>
            <Grid cols="1fr 1fr" gap="1rem">
              <NotApplicableFormField
                {...turnover.annual_profit}
                hideLabel
                dependencies={["has_annual_profit"]}
              />
              <NotApplicableCheckboxTrigger {...turnover.has_annual_profit} />
            </Grid>

            <InputFormField {...turnover.rented_space} />

            <Grid cols="1fr 250px" gap="1rem">
              <TitleTooltip
                title={turnover.declarations.label}
                info={turnover.declarations.infoTitle}
              />

              <NotApplicableCheckboxTrigger {...turnover.has_declarations} />
            </Grid>
            <WhiteSpace />
            <Grid cols="repeat(6, 1fr)" gap="0.25rem 1rem">
              <div></div>
              <p>Număr</p>
              <p>Valoare</p>
              <div></div>
              <p>Număr</p>
              <p>Valoare</p>
              <p>{turnover.import_declaration.label}</p>
              <div>
                <NotApplicableFormField
                  {...turnover.import_declaration.number}
                />
              </div>
              <div>
                <NotApplicableFormField
                  {...turnover.import_declaration.value}
                />
              </div>
              <p>{turnover.export_declaration.label}</p>
              <div>
                <NotApplicableFormField
                  {...turnover.export_declaration.number}
                />
              </div>
              <div>
                <NotApplicableFormField
                  {...turnover.export_declaration.value}
                />
              </div>

              <p>{turnover.suspended_mode.label}</p>
              <div>
                <NotApplicableFormField {...turnover.suspended_mode.number} />
              </div>
              <div>
                <NotApplicableFormField {...turnover.suspended_mode.value} />
              </div>
              <p>{turnover.customs_destination.label}</p>
              <div>
                <NotApplicableFormField
                  {...turnover.customs_destination.number}
                />
              </div>
              <div>
                <NotApplicableFormField
                  {...turnover.customs_destination.value}
                />
              </div>
            </Grid>
            <WhiteSpace v="1rem" />
            <div className="divider"></div>
            <WhiteSpace v="2rem" />

            <Grid cols="1fr 250px" gap="1rem">
              <TitleTooltip
                title={turnover.tax.label}
                info={turnover.tax.info}
              />
              <NotApplicableCheckboxTrigger {...turnover.tax.has_tax} />
            </Grid>

            <WhiteSpace />

            <Grid cols="repeat(4, 1fr)" gap="0.25rem 1rem">
              <p>{turnover.tax.customs_taxes.label}</p>
              <NotApplicableFormField
                {...turnover.tax.customs_taxes}
                hideLabel
              />
              <p>{turnover.tax.customs_procedures.label}</p>
              <NotApplicableFormField
                {...turnover.tax.customs_procedures}
                hideLabel
              />
              <p>{turnover.tax.vat.label}</p>
              <NotApplicableFormField {...turnover.tax.vat} hideLabel />
              <p>{turnover.tax.excise.label}</p>
              <NotApplicableFormField {...turnover.tax.excise} hideLabel />
            </Grid>

            <WhiteSpace v="2rem" />
            <div className="divider"></div>
            <WhiteSpace v="2rem" />

            <ExtraTextareaFormField
              radioField={turnover.any_structural_changes}
              textareaField={turnover.structural_changes}
            />

            <WhiteSpace v="1rem" />
            <div className="divider"></div>
            <WhiteSpace v="1rem" />

            <ExtraTextareaFormField
              radioField={turnover.any_supply_chain_changes}
              textareaField={turnover.supply_chain_changes}
            />
            <WhiteSpace v="2rem" />

            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField />
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
