import { extraTextareaProps } from "../common";

export const logisticThree = {
  backup_process: {
    name: "backup_process",
    label:
      "3.6.1. Descrieţi pe scurt procedurile dvs. privind realizarea de copii de siguranţă, recuperarea datelor, soluţiile de rezervă şi opţiunile de arhivare, precum şi recuperarea evidențelor dvs. comerciale.",
    ...extraTextareaProps,
    info: `<p>3.6.1 Procedurile trebuie să includă tipul de suport pe care sunt stocate datele, formatul software în care sunt stocate datele şi dacă datele sunt comprimate şi în ce etapă. Dacă se face apel la un terţ, indicațice acorduri există, care sunt frecvenţa şi locul de stocare a copiilor de siguranţă şi a informaţiilor arhivate.</p>`,
  },
  backup_ttl: {
    name: "backup_ttl",
    label:
      "3.6.2. Pentru cât timp sunt salvate datele în sistemul evidenței operațiunilor comerciale și pentru cât timp sunt arhivate aceste date?",
    ...extraTextareaProps,
  },
  has_emergency_plan: {
    name: "has_emergency_plan",
    label:
      "3.6.3. Societatea are un plan de urgenţă pentru cazuri de pană/disfuncție a sistemului?",
  },
  emergency_plan: {
    name: "emergency_plan",
    label: "Dacă răspunsul este da, furnizaţi detalii.",
  },
  emergency_plan_attachments: {
    name: "emergency_plan_attachments",
    label: "Ataşaţi planul de urgenţă",
  },
  protect_system: {
    name: "protect_system",
    label:
      "3.7.1. a) Descrieţi succint acţiunile pe care le-aţi întreprins pentru a vă proteja sistemul informatic de accese neautorizate (firewall, antivirus, protejarea parolei etc.).",
    ...extraTextareaProps,
    info: `3.7.1. Pentru a îndeplini condiția menţionată la 195.3, alineatul (4) litera g) din Codul vamal, trebuie să dispuneţi de măsuri adecvate de securitate a tehnologiilor informaționale pentru a proteja sistemul informatic și documentația împotriva oricăror imixtiuni.
3.7.1. Pentru întrebarea de la litera a), măsurile trebuie să ia în considerare următoarele:
<ul>
<li>a) un plan de siguranţă actualizat care conţine descrierea măsurilor existente pentru protejarea sistemului informatic împotriva accesului neautorizat şi împotriva distrugerii intenţionate sau a pierderii de informaţii;</li>
<li>b) detalii privind operarea sistemelor multiple la sedii multiple şi modul în care sunt controlate;</li>
<li>c) persoanele responsabile pentru protejarea şi funcţionarea sistemului informatic al companiei (responsabilitatea nu trebuie limitată la o singură persoană, ci la mai multe persoane care îşi pot astfel monitoriza reciproc acţiunile);</li>
<li>d) detalii privind programe de tip firewall, antivirus şi alte programe de protecţie împotriva malware;</li>
<li>e) un plan de continuitate a activităţii/ de recuperare în caz de dezastru dacă are loc un incident;</li>
<li>f) efectuarea periodică a copiilor de siguranţă, inclusiv restabilirea programelor relevante şi a datelor, ca urmare a unei întreruperi din cauza unei defecţiuni a sistemului;</li>
<li>g) jurnale unde sunt înregistrate informaţii despre fiecare utilizator şi acţiunile acestora;</li>
<li>h) dacă gestionarea vulnerabilităţii sistemului este realizată periodic şi de către cine.</li>
</ul>
      Pentru întrebarea de la litera b), indicaţi frecvenţa cu care este testat sistemul împotriva accesului neautorizat, modul în care se înregistrează rezultatele şi modul de gestionare a incidentelor atunci când securitatea sistemului este compromisă.`,
  },
  has_test_auth: {
    name: "has_test_auth",
    label: "b) S-a desfăşurat o testare în privinţa acceselor neautorizate?",
    dependencies: ["test_authorization"],
  },
  test_authorization: {
    name: "test_authorization",
    label:
      "Dacă răspunsul este da, care au fost rezultatele și ce măsuri corective au fost luate?",
    placeholder: "Detalii",
    dependencies: ["has_test_auth"],
  },
  incident_security: {
    name: "incident_security",
    label:
      "c) Ați avut parte de incidente legate de securitatea informatică în ultimul an?",
    ...extraTextareaProps,
  },
  access_rights: {
    name: "access_rights",
    label:
      "3.7.2. a) Descrieţi succint modul în care sunt acordate drepturile de acces la sistemele informatice.",
    ...extraTextareaProps,
    info: `3.7.2. Procedurile privind drepturile de acces trebuie să includă:
<ul>
<li>a) modul în care eliberaţi autorizaţiile de acces şi nivelul de acces la sistemele informatice (accesul la informaţiile sensibile ar trebui limitat la acei angajați care sunt autorizaţi să opereze modificări şi adăugări la informaţii); </li>
<li>b) formatul pentru stabilirea parolelor, frecvenţa modificărilor şi persoana care emite parolele şi eliminarea/păstrarea/actualizarea detaliilor utilizatorilor.</li>
</ul>`,
  },
  protection_responsible: {
    name: "protection_responsible",
    label:
      "b) Cine este responsabil pentru funcţionarea şi protejarea sistemului informatic?",
    ...extraTextareaProps,
  },
  internal_security: {
    name: "internal_security",
    label:
      "c)Dispuneți de instrucțiuni interne privind securitatea informatică pentru personalul dvs.?",
    ...extraTextareaProps,
  },
  internal_security_attachments: {
    name: "internal_security_attachments",
    label: "Documente",
  },
  monitor_security: {
    name: "monitor_security",
    label:
      "d) Cum realizați monitorizarea astfel încât măsurile de securitate informatică să fie respectate în cadrul companiei dvs.?",
    ...extraTextareaProps,
  },
  server_located: {
    name: "server_located",
    label:
      "3.7.3. a) Oferiți detalii privind locul unde este amplasat serverul dvs.principal.",
    ...extraTextareaProps,
  },
  server_secured: {
    name: "server_secured",
    label:
      "b) Furnizaţi detalii privind modul în care este securizat serverul dvs.principal.",
    ...extraTextareaProps,
  },
  protect_information_procedure: {
    name: "protect_information_procedure",
    label:
      "3.8.1. Descrieţi succint ce acţiuni au fost întreprinse pentru protejarea informaţiilor/documentelor (de exemplu, drepturi de acces restrânse, crearea de copii electronice de rezervă) împotriva accesului neautorizat, utilizării abuzive, intenţiilor distructive şi pierderii de date?",
    ...extraTextareaProps,
    info: `3.8.1. Măsurile luate trebuie să includă:
    <ul>
<li>a) înregistrarea şi realizarea de copii de siguranţă ale documentelor inclusiv scanarea, folosirea microfişei şi limitarea accesului;</li>
<li>b) un plan de siguranţă actualizat care conţine descrierea măsurilor instituite pentru protejarea documentelor împotriva accesului neautorizat şi împotriva distrugerii sau a pierderii;</li>
<li>c) proceduri de îndosariere şi depozitare a documentelor în condiţii de siguranţă şi securitate, inclusiv responsabilităţile pentru gestionarea acestora;</li>
<li>d) gestionarea incidentelor care compromit securitatea documentelor.</li>
</ul>`,
  },
  has_unauthorized_access: {
    name: "has_unauthorized_access",
    label:
      "3.8.2. Au existat cazuri de acces neautorizat la documente în ultimul an?",
    info: ` 3.8.2. Măsurile luate trebuie să ia în considerare:
    <ul>
<li>a) testarea sistemului împotriva accesului neautorizat şi înregistrarea rezultatelor;</li>
<li>b) un plan de continuitate a activităţii/de recuperare în caz de dezastru;</li>
<li>c) măsuri de remediere documentate luate ca urmare a incidentelor efective.</li>
</ul>`,
  },
  unauthorized_access: {
    name: "unauthorized_access",
    label:
      "Dacă da, ce măsuri au fost luate pentru a preveni reapariţia unor astfel de situaţii în viitor?",
    info: `Măsurile luate trebuie să ia în considerare:
    <ul>
<li>a) testarea sistemului împotriva accesului neautorizat şi înregistrarea rezultatelor;</li>
<li>b) un plan de continuitate a activităţii/de recuperare în caz de dezastru;</li>
<li>c) măsuri de remediere documentate luate ca urmare a incidentelor efective.</li>
</ul>`,
  },
  employee_category: {
    name: "employee_category",
    label:
      "3.8.3. a) Ce categorii de angajaţi au acces la date detaliate privind fluxul de materiale şi de mărfuri?",
    ...extraTextareaProps,
  },
  allowed_change: {
    name: "allowed_change",
    label:
      "b) Ce categorii de angajaţi sunt autorizaţi să modifice aceste date?",
    ...extraTextareaProps,
  },
  change_document_procedure: {
    name: "change_document_procedure",
    label: "Sunt modificările documentate în mod complet?",
    ...extraTextareaProps,
  },
  security_requirements: {
    name: "security_requirements",
    label:
      "3.8.4. Descrieţi succint ce cerințe privind securitatea şi siguranţa impuneţi partenerilor dvs. comerciali şi altor persoane de contact pentru a evita utilizarea abuzivă a informaţiilor (de exemplu, periclitarea lanţului de aprovizionare prin transferul neautorizat al detaliilor de expediere)?",
    ...extraTextareaProps,
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
