import React from "react";
import { useQuery } from "react-query";
import classNames from "classnames";

import { Button, Card, Space } from "ebs-design";

import { Grid, Icon, QueryList } from "@aeo/core/components";
import { usePagination } from "@aeo/core/hooks";
import { formattedDate, makeBEM } from "@aeo/core/utils";
import models from "@aeo/core/models";

import api, { querykeys } from "api";

const PAGE_SIZE = 1;

const bem = makeBEM("dashboard");

export const News = () => {
  const pagination = usePagination();
  const queryParams = { ...pagination.queryParams, page_size: PAGE_SIZE };

  const query = useQuery(querykeys.news(queryParams), () =>
    api.news.get(queryParams),
  );

  const disabledPrev = (query.data?.page || 0) <= 1;
  const disabledNext = (query.data?.page || 0) >= (query.data?.num_pages || 0);

  return (
    <Card>
      <Card.Header>
        <Space justify="space-between">
          <h3>Anunțuri</h3>
          <div>
            <Button
              size="small"
              className="icon-btn mr-10"
              onClick={() => !disabledPrev && pagination.prev()}
              disabled={disabledPrev}
            >
              <Icon type="arrow-left" />
            </Button>
            <Button
              size="small"
              className="icon-btn"
              disabled={disabledNext}
              onClick={() => !disabledNext && pagination.next()}
            >
              <Icon type="arrow-right" />
            </Button>
          </div>
        </Space>
      </Card.Header>
      <Card.Body>
        <QueryList
          query={query}
          getItems={(data) => data?.results}
          renderItems={(data?: models.NewsItem[]) => (
            <Grid cols={`repeat(${PAGE_SIZE}, 1fr)`} gap="1rem">
              {data?.map((item, idx) => (
                <Card
                  key={item.created_at || idx}
                  className={classNames(bem("news-card"), "bordered")}
                >
                  <Card.Header>{item.title}</Card.Header>
                  <Card.Body className={bem("news-text")}>
                    {item.text}
                  </Card.Body>
                  <Card.Footer>
                    {formattedDate(item.created_at || "")}
                  </Card.Footer>
                </Card>
              ))}
            </Grid>
          )}
        />
      </Card.Body>
    </Card>
  );
};
