import { UserRole } from "@aeo/core/utils";

export interface User {
  id?: number;
  first_name?: string;
  last_name?: string;
  name?: string;
  username?: string;
  phone?: string;
  profile?: {
    function?: string;
    role?: UserRole;
    domain?: {
      id: number;
      name: string;
    };
  };
  is_staff?: boolean;
  date_joined?: string;
  email?: string;
  groups?: number[];
  is_active?: boolean;
  is_blocked?: boolean;
  is_superuser?: boolean;
  last_login?: string;
  organization?: Organization;
  register_type?: RegisterType;
  user_permissions?: number[];
}

export interface Organization {
  id?: number;
  idno?: string;
  name?: string;
  admin_first_name?: string;
  admin_last_name?: string;
  has_inactive_statuses?: boolean;
  phone?: string;
  manager?: Pick<
    User,
    "id" | "first_name" | "last_name" | "username" | "phone"
  >;
}

export enum RegisterType {
  EMAIL = "email",
}

export interface SimpleUser {
  id?: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  name?: string;
  function?: string;
  idnp?: string;
}
