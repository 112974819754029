import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";

import { TitleTooltip } from "components";
import { NotesCardForm, KeyValueCard } from "features/application/components";

const EconomicAgentValues = () => {
  const economicAgent = fields.economicalAgent;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <TitleTooltip
        title={<h4>{capitalize(messages.titles.companyInfo)}</h4>}
        info={messages.SAQTabsContent.economicAgentInfo.info}
      />

      <WhiteSpace />
      <h4>{capitalize(messages.titles.economicAgentSubsection)}</h4>
      <WhiteSpace />
      <KeyValueCard {...economicAgent.name} />
      <KeyValueCard {...economicAgent.address} />
      <KeyValueCard {...economicAgent.created} type="date" />
      <KeyValueCard {...economicAgent.juridic_form} type="nomenclature" />
      <KeyValueCard {...economicAgent.site} />
      <KeyValueCard {...economicAgent.part_of_group} type="boolean" />
      <KeyValueCard {...economicAgent.detail_group} NA />
      <KeyValueCard {...economicAgent.license_group} type="boolean" />
      <KeyValueCard {...economicAgent.license_name_group} NA />
      <KeyValueCard {...economicAgent.license_audit} type="boolean" />
      <KeyValueCard {...economicAgent.license_audit_group} NA />
      <KeyValueCard {...economicAgent.reorganization} />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...economicAgent.attachments} type="attachments" />
    </>
  );
};

export const EconomicAgent = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.economicAgent.title}
      content={<EconomicAgentValues />}
      path="saq/economic_agent"
    />
  );
};
