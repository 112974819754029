import { Modal } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { useDownloadFile } from "../hooks";
import models from "../models";
import { Flex, Icon, LoadingButton, PDFViewer } from ".";

interface Props extends ModalProps {
  file?: string;
  fileTitle?: string;
}
export const ViewPdfModal = ({ file, fileTitle, ...props }: Props) => {
  const attachment: models.Attachment = { file_upload: file, title: fileTitle };

  const downloadFile = useDownloadFile();

  return (
    <Modal className="view-pdf-modal" {...props}>
      <Modal.Content>
        <PDFViewer file={file} />
      </Modal.Content>
      <Modal.Footer>
        <Flex justify="flex-end">
          <LoadingButton
            type="primary"
            prefix={<Icon type="download" />}
            onClick={() => downloadFile(attachment)}
          >
            Descarcă
          </LoadingButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
