import { KeyValueList, KeyValueRow, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize, prefFieldName } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const ContactValues = () => {
  const person = { ...fields.person, ...fields.personContact };

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <KeyValueCard {...person.first_name} />
      <KeyValueCard {...person.last_name} />
      <KeyValueCard {...person.birthday} type="date" />
      <KeyValueCard {...person.idnp} />
      <KeyValueCard {...person.phone} />
      <KeyValueCard {...person.email} />
      <KeyValueCard {...person.address} />
      <KeyValueCard {...person.attachments_persons} type="attachments" />
      <KeyValueCard {...person.departments_involved} />

      <KeyValueCard {...person.has_customs_consulting} type="boolean" />
      <KeyValueCard {...person.customs_consulting} />

      <KeyValueCard {...person.has_third_party_involved} type="boolean" />
      <KeyValueList
        title={`${messages.titles.thirdParty} - ${messages.titles.physicPerson}`}
        name={[person.third_party_physic.name, "value"]}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.birthday, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.attachments, prefix)}
              type="attachments"
            />
          </>
        )}
      />
      <WhiteSpace v="1rem" />
      <KeyValueList
        title={`${messages.titles.thirdParty} - ${messages.titles.juridicPerson}`}
        name={[person.third_party_juridic.name, "value"]}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.name, prefix)} />
            <KeyValueRow {...prefFieldName(person.idno, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.created, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.attachments, prefix)}
              type="attachments"
            />
          </>
        )}
      />

      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...person.attachments} type="attachments" />
    </>
  );
};

export const Contact = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.contact.title}
      content={<ContactValues />}
      path="saq/contact"
    />
  );
};
