import { Form, Input, Textarea } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { noSpaceRule } from "../../utils";

import { FieldWithInfo } from "./FieldWithInfo";
import { relativeClassName } from "./utils";

export interface InputFormFieldProps extends FormFieldProps {
  type?: string;
  required?: boolean;
  textarea?: boolean;
  placeholder?: string;
  disabled?: boolean;
  info?: React.ReactNode;
  suffix?: React.ReactElement;
  maxLength?: number;
  min?: number;
  max?: number;
  pattern?: string;
  nullValue?: boolean;
  noSpace?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<Element>) => void;
}

export const InputFormField = ({
  rules = [],
  required,
  type,
  hideLabel = false,
  textarea,
  disabled,
  info,
  extra,
  suffix,
  className,
  maxLength,
  min,
  max,
  initialValue,
  pattern,
  onKeyDown,
  nullValue,
  noSpace = true,
  ...restProps
}: InputFormFieldProps) => {
  return (
    <Form.Field
      extra={<FieldWithInfo extra={extra} info={info} />}
      initialValue={initialValue || undefined}
      {...restProps}
      rules={[{ required }, ...(noSpace ? [noSpaceRule] : []), ...rules]}
      {...{
        hideLabel,
      }}
      className={relativeClassName(info, className)}
    >
      {(control) => {
        if (control.value === null) {
          control.value = undefined;
        }

        return textarea ? (
          <Textarea
            {...control}
            placeholder={restProps.placeholder}
            disabled={disabled}
            onChange={(v) =>
              nullValue
                ? control.onChange(v === "" ? null : v)
                : control.onChange(v)
            }
          />
        ) : (
          <Input
            {...control}
            disabled={disabled}
            type={type}
            size="large"
            placeholder={restProps.placeholder}
            suffix={suffix}
            maxLength={maxLength}
            min={min}
            max={max}
            onKeyDown={onKeyDown}
            onChange={(v) =>
              nullValue
                ? control.onChange(v === "" ? null : v)
                : control.onChange(v)
            }
          />
        );
      }}
    </Form.Field>
  );
};
