import { Card, Form, useForm } from "ebs-design";
import {
  Flex,
  InputFormField,
  WhiteSpace,
  FormList,
  BooleanRadioFormField,
  LoadingButton,
  AttachmentsFormField,
  NomenclaturesSelect,
} from "@aeo/core/components";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import { capitalize } from "@aeo/core/utils";
import models from "@aeo/core/models";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableFormList } from "..";
import { SubdivisionFields, JuridicWithAddressFormFields } from ".";

export const CommercialActivityForm = () => {
  const commercialActivity = fields.commercialActivity;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/commercial", form);
  useDraftApplicationQuery("saq/commercial", form);
  const validateMutation = useValidateMutation(
    "saq/commercial",
    form,
    "structure",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.commercial.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <InputFormField {...commercialActivity.description} />
            <NomenclaturesSelect
              entityName={models.Nomenclatures.Roles}
              mode="multiple"
              {...commercialActivity.roles}
            />
            <TitleTooltip
              title={<p>{capitalize(messages.titles.subdivisionsActivity)}</p>}
              info={capitalize(messages.titles.subdivisionsActivityInfo)}
            />

            <WhiteSpace />

            <p>{capitalize(messages.titles.individualSubdivisions)}</p>

            <FormList
              subTitle={messages.titles.subdivisions}
              name="subdivisions"
              Component={SubdivisionFields}
            />

            <WhiteSpace v="20px" />

            <BooleanRadioFormField
              radioField={commercialActivity.buy_from_associated}
            />
            <NotApplicableFormList
              dependencies={[commercialActivity.buy_from_associated.name]}
              name={commercialActivity.associated_buy_companies.name}
            >
              <FormList
                subTitle={messages.titles.associatedCompany}
                name={commercialActivity.associated_buy_companies.name}
                Component={JuridicWithAddressFormFields}
              />
            </NotApplicableFormList>

            <WhiteSpace />

            <BooleanRadioFormField
              radioField={commercialActivity.sell_to_associated}
            />
            <NotApplicableFormList
              dependencies={[commercialActivity.sell_to_associated.name]}
              name={commercialActivity.associated_sell_companies.name}
            >
              <FormList
                subTitle={messages.titles.associatedCompany}
                name={commercialActivity.associated_sell_companies.name}
                Component={JuridicWithAddressFormFields}
              />
            </NotApplicableFormList>

            <WhiteSpace v="20px" />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />

            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Flex justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
