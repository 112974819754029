import { Form, Input, Textarea } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { xor } from "@aeo/core/utils";

export interface NotApplicableFormFieldProps extends FormFieldProps {
  textarea?: boolean;
  type?: string;
  placeholder?: string;
  reverse?: boolean;
  name?: string | (string | number)[];
  disableException?: boolean;
}

interface FormControl {
  value: string;
  onChange: React.Dispatch<string>;
}

export const NotApplicableFormField = ({
  dependencies,
  type,
  initialValue,
  textarea,
  reverse = false,
  disableException,
  ...props
}: NotApplicableFormFieldProps) => {
  return (
    <Form.Field
      dependencies={dependencies}
      initialValue={initialValue}
      {...props}
    >
      {(_control, _, form) => {
        const { value, onChange } = _control as FormControl;

        const disabled =
          !!xor(
            reverse,
            !!dependencies?.every((dep) => form.getFieldValue(dep)),
          ) || disableException;

        const inpProps = {
          value: value || "",
          disabled,
          onChange: onChange as React.Dispatch<number | string | null>,
          placeholder: props.placeholder,
        };

        return textarea ? (
          <Textarea {...inpProps} />
        ) : (
          <Input
            type={type}
            size="large"
            className={disabled ? "input-hide-value" : undefined}
            {...inpProps}
          />
        );
      }}
    </Form.Field>
  );
};
