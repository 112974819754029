import { ApplicationState } from "./application";
import { IdName, IdDescription } from "./common";

export enum Nomenclatures {
  Sectors = "sectors",
  Categories = "categories",
  Facilities = "facilities",
  Juridics = "juridics",
  Posts = "posts",
  States = "states",
  Domains = "domains",
  ReAssessmentMethod = "methods/re-assessment",
  ResearchMethod = "methods/research",
  ValidationMethod = "methods/validation",
  Roles = "roles",
  Templates = "templates",
}

export interface FilterPhases {
  id?: string;
  name?: string;
  order?: number;
  states?: FilterState[];
}

export interface FilterState {
  id?: ApplicationState;
  name?: string;
  label?: ApplicationState;
  legal_period?: number;
  order?: number;
}

export enum StatusGroups {
  COMPLETION = "Completare",
  APPLICATION = "Aplicare",
  EXAMINATION = "Examinare prealabilă",
  AUDIT = "Audit",
  MONITORING = "Monitorizare",
  REEVALUATION = "Reevaluare",
}

export interface PhaseNomenclature extends IdName {
  states?: IdName<string>[];
  // order?: number;
}

export interface CriterionNomenclature extends IdName {
  children?: CriterionNomenclature[];
  domain?: IdName;
  default_risks: IdDescription[];
  activity?: string;
  conclusion_question?: string;
  conclusion_details?: string;
  description?: string;
  id?: number;
  name?: string;
  completed?: boolean;
  can_edit?: boolean;
}

export interface PeriodNomenclature extends IdName {
  label?: string;
  legal_period?: string;
}
