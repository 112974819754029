import React from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useHistory } from "react-router-dom";

import models from "@aeo/core/models";
import apiCore, { querykeys } from "@aeo/core/api";
import { apiAxios } from "@aeo/core/api/axios";
import { createRefreshTokenInterceptors } from "@aeo/core/api/axios/interceptors";
import { clearLocalStorage } from "@aeo/core/api/authentication";

export interface UseAuthProviderArgs {
  getUser: () => Promise<models.Profile>;
  userQueryOptions?: UseQueryOptions<models.Profile>;
  logout: () => Promise<void>;
  register: (credentials: models.RegisterCredentials) => Promise<void>;
}

const logoutPageURL = process.env.REACT_APP_MPASS_LOGOUT_URL;

export function useAuthProvider(apiCalls: UseAuthProviderArgs) {
  const history = useHistory();
  const [user, setUser] = React.useState<models.Profile | null>(null);
  const queryClient = useQueryClient();

  const { refetch, isLoading, error } = useQuery(
    querykeys.loggedUser,
    apiCalls.getUser,
    {
      retry: false,
      onSuccess: (user) => setUser(user),
      onError: () => setUser(null),
      ...apiCalls.userQueryOptions,
    },
  );

  const login = async (credentials: models.LoginCredentials) => {
    await apiCore.authentication.login(credentials);
    await refetch();
    history.push("/");
  };

  const register = apiCalls.register;

  const logout = async () => {
    try {
      await apiCalls.logout();
    } finally {
      setUser(null);
      queryClient.clear();
      clearLocalStorage();
      logoutPageURL &&
        user?.is_authenticated_by_mpass &&
        window.location.replace(logoutPageURL);
    }
  };

  React.useEffect(() => {
    const id = apiAxios.interceptors.response.use(
      ...createRefreshTokenInterceptors(apiAxios, () => setUser(null)),
    );

    return () => {
      apiAxios.interceptors.response.eject(id);
    };
  }, []);

  return {
    user,
    role: user?.profile?.role,
    isLoading,
    login,
    register,
    logout,
    refetchProfile: refetch,
    error,
  };
}
