import React from "react";
import { useQuery } from "react-query";

import { Collapse, Loader } from "ebs-design";

import coreApi, { querykeys } from "@aeo/core/api";
import { Flex, WhiteSpace, Badge, Grid } from "@aeo/core/components";
import { makeBEM, throttle, toRoman } from "@aeo/core/utils";
import { COLLAPSE_DURATION } from "@aeo/core/app-constants";
import { useAuth } from "@aeo/core/hooks";

const bem = makeBEM("application-state-roadmap");

export const ApplicationStateRoadmap = () => {
  const { user } = useAuth();
  const activeApplication = user?.organization?.active_application;

  const query = useQuery(querykeys.nomenclatures.phases(), () =>
    coreApi.nomenclatures.phases.get(),
  );

  const nomenclatures = query.data?.results;

  // to test
  // activeApplication?.state && (activeApplication.state = "approved" as any);

  const activePhaseId = nomenclatures?.find((n) =>
    n?.states?.find((s) => s?.id === activeApplication?.state),
  )?.id;

  const activeBadgeRef = React.useRef<HTMLDivElement>(null);
  const connectionRef = React.useRef<HTMLDivElement>(null);
  const titleDotRef = React.useRef<HTMLDivElement>(null);

  const adjust = React.useMemo(
    () =>
      throttle(() => {
        const $activeBadge = activeBadgeRef.current;
        const $connection = connectionRef.current;
        const $titleDot = titleDotRef.current;

        if (!$activeBadge || !$connection || !$titleDot) return;

        const midY = ($el: HTMLElement) => {
          const bcr = $el.getBoundingClientRect();
          return bcr.top + bcr.height / 2;
        };
        const distance = Math.abs(midY($activeBadge) - midY($titleDot));

        $connection.style.height = Math.round(distance) + "px";
      }, 50),
    [],
  );

  React.useEffect(() => {
    window.addEventListener("resize", adjust);
    return () => {
      window.removeEventListener("resize", adjust);
    };
  }, [adjust]);

  return (
    <Collapse
      // Can't use on click as there is stopPropagation probably
      onMouseUp={(e) => {
        const $target = e.nativeEvent.currentTarget as HTMLElement;
        setTimeout(() => {
          if ($target?.querySelector(".ebs-collapse__header--collapsed")) {
            const $connection = connectionRef.current;
            if (!$connection) return;
            $connection.style.height = "0px";
          } else {
            adjust();
          }
        }, COLLAPSE_DURATION);
      }}
      size="large"
      collapsed={true}
    >
      <Collapse.Header className={bem("collapse-header")}>
        {query.isLoading && <Loader.Inline>Se încarcă</Loader.Inline>}
        <Grid
          className={bem("grid")}
          cols={`repeat(${nomenclatures?.length}, 1fr)`}
          gap="1rem"
        >
          {nomenclatures?.map((phase, i) => (
            <Flex
              key={i}
              wrap
              className={bem("title-wrapper", {
                active: phase?.id === activePhaseId,
              })}
              align="start"
            >
              <h4 className={bem("title-first-part")}>
                Etapa {toRoman(i + 1)}
                <div
                  className={bem("title-dot")}
                  ref={phase?.id === activePhaseId ? titleDotRef : undefined}
                ></div>
                {phase?.id === activePhaseId && (
                  <div ref={connectionRef} className={bem("connection")}></div>
                )}
              </h4>
              <WhiteSpace h="0.25rem" />{" "}
              <h4 style={{ paddingRight: "0.5rem" }}>{phase?.name} </h4>
            </Flex>
          ))}
        </Grid>
      </Collapse.Header>
      <Collapse.Body>
        <Grid
          className={bem("grid")}
          cols={`repeat(${nomenclatures?.length}, 1fr)`}
          gap="1rem"
          // Collapse icon fix
          style={{ paddingRight: "var(--collapse-icon-width)" }}
        >
          {nomenclatures?.map((n, i) => (
            <div key={i}>
              <Grid gap="0.5rem" rows="auto">
                {n?.states?.map((s, i) => {
                  return (
                    <Badge
                      key={i}
                      size="lg"
                      color={
                        s?.id === activeApplication?.state
                          ? "orange"
                          : undefined
                      }
                      className={bem("badge", {
                        active: s?.id === activeApplication?.state,
                      })}
                      ref={
                        s?.id === activeApplication?.state
                          ? activeBadgeRef
                          : undefined
                      }
                    >
                      {s?.name}
                      {s?.id === activeApplication?.state && (
                        <div className={bem("badge-dot")}></div>
                      )}
                    </Badge>
                  );
                })}
              </Grid>
            </div>
          ))}
        </Grid>
      </Collapse.Body>
    </Collapse>
  );
};
