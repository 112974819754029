import { AxiosResponse } from "axios";

import { apiAxios } from "@aeo/core/api/axios";
import { stringifyUrl } from "@aeo/core/api/utils";
import models from "@aeo/core/models";
import { GenericObject } from "@aeo/core/types";

export const chat = {
  getReplies: async (query: GenericObject) => {
    const url = stringifyUrl(`/aeo/chat`, query);
    const { data } = await apiAxios.get<models.WithResults<models.ChatReply>>(
      url,
    );
    return data;
  },
  postReply: async (reply?: models.ChatReply) => {
    const { data } = await apiAxios.post<
      models.ChatReply,
      AxiosResponse<models.ChatReply>
    >(`/aeo/chat`, reply);
    return data;
  },
};
