import { Card } from "ebs-design";
import { WhiteSpace } from "@aeo/core/components";

export const Faq = () => {
  return (
    <div style={{ maxWidth: 900 }}>
      <Card collapsible collapsed>
        <Card.Header>
          <h3>
            Ce documente trebuie să fie anexate la cererea Autorizației AEO?
          </h3>
        </Card.Header>
        <Card.Body className="text-justify">{text1}</Card.Body>
      </Card>
      <WhiteSpace v="2rem" />
      <Card collapsible collapsed>
        <Card.Header>
          <h3>
            Care este perioada de examinare a cererii de acordare a Autorizației
            AEO?
          </h3>
        </Card.Header>
        <Card.Body className="text-justify">{text2}</Card.Body>
      </Card>
      <WhiteSpace v="2rem" />
      <Card collapsible collapsed>
        <Card.Header>
          <h3>Care este metoda de obținere a Logo-ului AEO?</h3>
        </Card.Header>
        <Card.Body className="text-justify">{text3}</Card.Body>
      </Card>
    </div>
  );
};

const text1 = (
  <>
    <p style={{ textIndent: 20 }}>
      Autorizaţia AEO se eliberează la solicitarea operatorului economic (în
      continuare – solicitant), prin depunerea unei cereri la ghişeul unic, care
      reprezintă o platformă comună de acces şi un mecanism care permite
      solicitantului să se adreseze Serviciului Vamal, care are obligaţia să
      asigure coordonarea acţiunilor la verificarea condiţiilor de autorizare,
      conform competenţelor funcţionale atribuite prin lege. Cererea poate fi
      depusă de către reprezentantul împuternicit al solicitantului.
    </p>
    <WhiteSpace v="1rem" />
    <p style={{ textIndent: 20 }}>
      Cererea se completează în conformitate cu modelul și Instrucţiunile de
      completare a cererii de acordare a Autorizației AEO, prevăzute în Anexa
      nr.1 la Regulamentul privind procedura de eliberare, suspendare şi
      retragere a autorizației de agent economic autorizat, aprobat prin
      Hotărîrea Guvernului nr.647 din 07 august din 2014 privind punerea în
      aplicare a prevederilor secţiunilor a 271-a şi a 281–a din Codul Vamal al
      Republicii Moldova şi se prezintă în format electronic, cu utilizarea
      tehnicilor de prelucrare electronică a datelor şi autentificare prin
      aplicarea semnăturii digitale, care corespunde cerinţelor faţă de
      documentul electronic prevăzute de legislația Republicii Moldova în
      vigoare. Anexele care urmează a fi depuse împreună cu cererea sînt cele
      enumerate în pct.16 din Instrucţiunile de completare a cererii de acordare
      a Autorizației AEO. Solicitantul depune, în mod obligatoriu, chestionarul
      de autoevaluare, completat conform modelului stabilit de Serviciul Vamal
      în Anexa nr.2 la Ordinul directorului Serviciului Vamal nr.41-O din 25
      ianuarie 2019 cu privire la aprobarea Normelor metodologice privind modul
      de verificare a solicitanţilor şi titularilor Autorizaţiei de agent
      economic autorizat.
    </p>
  </>
);

const text2 = (
  <>
    <p style={{ textIndent: 20 }}>
      Serviciul Vamal, în termen de cel mult 10 zile calendaristice, verifică
      informațiile prezentate și informează operatorul economic cu privire la
      acceptarea cererii. În cazul în care se constată că cererea nu conține
      toate informațiile necesare, Serviciul Vamal cere solicitantului să
      furnizeze informațiile relevante, într-un termen-limită care nu depășește
      30 de zile calendaristice de la data primirii cererii.
    </p>
    <p style={{ textIndent: 20 }}>
      Cererea şi documentele anexate sînt examinate în termen de pînă la 60 de
      zile calendaristice de la data acceptării acestora. În cazuri întemeiate
      şi justificate documentar, termenul dat poate fi prelungit cu cel mult 30
      de zile calendaristice, cu informarea solicitantului.
    </p>
  </>
);

const text3 = (
  <>
    <p style={{ textIndent: 20 }}>
      Logoul se eliberează agenţilor economici autorizaţi la solicitare prin
      depunerea unei cereri-declarație la Serviciul Vamal în format electronic
      în termen de cel mult 5 zile lucrătoare de la data recepţionării
      cererii-declarație. Logoul AEO este transmis agenților economici
      autorizați de către Serviciul Vamal, numai după sau concomitent cu
      obținerea autorizației AEO. Dreptul unui agent economic autorizat de a
      utiliza logoul AEO sau referirea la acesta se dobîndește la data
      producerii efectelor autorizației AEO şi se menţine pe toată perioada de
      valabilitate a acesteia.
    </p>
  </>
);
