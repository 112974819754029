import { ReactNode } from "react";

export interface Tab {
  tabKey: string;
  content: ReactNode;
  defaultActive?: boolean;
}
export class TabBuilder {
  private _tabs: Tab[] = [];
  add(tabKey: string, content: ReactNode, defaultActive?: boolean) {
    this._tabs.push({ tabKey, content, defaultActive });
    return this;
  }
  getTabs() {
    return [...this._tabs];
  }
}
