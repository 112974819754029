import { KeyValueList, KeyValueRow, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useFieldValue, useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { prefFieldName } from "@aeo/core/utils";
import { NotesCardForm, PersonListProps } from "../components";
import { NoteForm } from "../components/NoteForm";

export const ShareholdersValues = ({ path }: PersonListProps) => {
  const person = fields.person;
  const value = useFieldValue(path);

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <h4>
        a) Date complete privind proprietarii sau principalii acţionari,
        inclusiv procentul de acțiuni deţinute de aceştia.
      </h4>
      <WhiteSpace />

      <KeyValueList
        title={messages.titles.physicPerson}
        name={[person.physic.name, "value"]}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.birthday, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow {...prefFieldName(person.percentage_shares, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.attachments, prefix)}
              type="attachments"
            />
            <WhiteSpace v="1rem" />
            <NoteForm note={value?.[person.juridic.name]?.note} />
          </>
        )}
      />
      <WhiteSpace />
      <KeyValueList
        title={messages.titles.juridicPerson}
        name={[person.juridic.name, "value"]}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.name, prefix)} />
            <KeyValueRow {...prefFieldName(person.idno, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.created, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.percentage_shares, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.attachments, prefix)}
              type="attachments"
            />
            <WhiteSpace v="1rem" />
            <NoteForm note={value?.[person.physic.name]?.note} />
          </>
        )}
      />
    </>
  );
};

export const Shareholders = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.shareholders.title}
      content={<ShareholdersValues />}
      path="saq/shareholders"
    />
  );
};
