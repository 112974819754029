import React from "react";

export const Send: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M5.69714 12H20.7273M5.69714 12L3.30598 19.4389C3.27055 19.5378 3.26324 19.6452 3.28494 19.7482C3.30663 19.8513 3.35641 19.9458 3.42836 20.0204C3.50031 20.095 3.59141 20.1467 3.69082 20.1691C3.79024 20.1916 3.89379 20.1841 3.98917 20.1473L20.7273 12M5.69714 12L3.30598 4.56114C3.27055 4.46222 3.26324 4.35484 3.28494 4.25175C3.30663 4.14866 3.35641 4.05419 3.42836 3.97958C3.50031 3.90497 3.59141 3.85335 3.69082 3.83085C3.79024 3.80835 3.89379 3.81593 3.98917 3.85267L20.7273 12"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
