import models from "@aeo/core/models";

export const createRequests: models.CreateRequestEntity[] = [
  {
    title: "Etapa de aplicare și examinare prealabilă",
    requests: [
      {
        name: "Retragerea cererii",
        reason: true,
        suspensionPeriod: false,
        documents: false,
        entity: "withdraw",
      },
      {
        name: "Suspendarea cererii",
        reason: true,
        suspensionPeriod: true,
        documents: false,
        entity: "suspend",
      },
    ],
    states: ["registered", "suspend_registered"],
  },
  {
    title: "Etapa de audit",
    requests: [
      {
        name: "Cererea de retragere",
        reason: true,
        entity: "withdraw",
      },
      {
        name: "Cererea de suspendare a examinării",
        reason: true,
        suspensionPeriod: true,
        entity: "suspend",
      },
      {
        name: "Cererea de reluare a examinării",
        entity: "resume",
      },
    ],
    states: [
      "suspend_pre_audit",
      "suspend_re_audit",
      "suspend_audit",
      "withdraw_pre_audit",
      "pre_audit",
      "audit",
      "approved",
    ],
  },
  {
    title: "După primirea autorizației",
    requests: [
      {
        name: "Cererea de suspendare a autorizației",
        reason: true,
        suspensionPeriod: true,
        entity: "suspend/active",
      },
      {
        name: "Cererea de reluare a autorizației",
        documents: true,
        entity: "resume/active",
      },
      {
        name: "Cererea de revocare a autorizației să aleagă motivele supsendare/reluare, numaidecît cu act confirmativ, generat automat, apoi semant electronic, apoi aprobat de SV.",
        reason: true,
        documents: true,
        entity: "withdraw/active",
      },
    ],
    states: ["active", "suspend_active", "withdraw_active"],
  },
  {
    title: "Logo-ul AEO",
    type: "logo",
    requests: [
      {
        name: "Cererea de solicitare a Logo-ului AEO",
      },
    ],
    states: ["active"],
  },
  {
    title: "Cerere către serviciul vamal",
    requests: [
      {
        name: "Cerere către serviciul vamal",
        preview: true,
      },
    ],
    excludeStates: ["draft"],
  },
];
