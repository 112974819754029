import { useEffect, useRef } from "react";
import { Form, useForm } from "ebs-design";
import { makeBEM } from "@aeo/core/utils";
import { RadioFormField } from "@aeo/core/components/form-utils";
import { LoadingButton } from "@aeo/core/components";
import { useRouter } from "@aeo/core/hooks";
import models from "@aeo/core/models";

const bem = makeBEM("auth-layout");

interface Props {
  data?: models.MpassSAML;
  isLoading?: boolean;
}

const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

export const MpassLoginForm = ({ data, isLoading }: Props) => {
  const [form] = useForm();
  const formRef = useRef<HTMLFormElement>(null);
  const { replace } = useRouter();

  useEffect(() => {
    const defaultIdno = data?.details?.organizations?.[0]?.idno;

    if (!defaultIdno && data?.saml) {
      replace("/login");
    }

    defaultIdno &&
      form.setFieldsValue({
        idno: defaultIdno,
      });
  }, [data, form, formRef, replace]);

  return (
    <Form form={form}>
      <form
        ref={formRef}
        method="POST"
        action={`${REACT_APP_BASE_API}/mpass/receive`}
      >
        <input value={data?.saml} name="SAMLResponse" hidden />

        <Form.Field name="idno">
          {() => (
            <input value={form.getFieldValue("idno")} name="idno" hidden />
          )}
        </Form.Field>

        <RadioFormField
          vertical
          name="idno"
          options={
            data?.details?.organizations?.map(({ idno, name }) => ({
              value: idno,
              text: name,
            })) || []
          }
        />

        <LoadingButton
          size="large"
          className={bem("form__btn")}
          type="primary"
          submit
          loading={isLoading}
        >
          Conectează-te
        </LoadingButton>
      </form>
    </Form>
  );
};
