import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";

// FIXME: fix any
export class TableColumnBuilder<RecordType = any> {
  private _columns: ColumnType<RecordType>[] = [];
  add(
    title: ColumnType<RecordType>["title"],
    dataIndex: ColumnType<RecordType>["dataIndex"],
    render: ColumnType<RecordType>["render"] = (v: any) => v ?? "-",
    other?: ColumnType<RecordType>,
  ) {
    this._columns.push({
      title,
      dataIndex,
      render,
      ...other,
    });
    return this;
  }
  getColumns() {
    return [...this._columns];
  }
}
