import models from "../models";

type ApplicationStates = {
  [key in models.ApplicationState]: string;
};

export const applicationStates = {
  draft: "Proiect (Draft)",

  // Aplicare
  registered: "Înregistrată",
  suspend_registered: "Suspendată (Aplicare)",
  rejected_registered: "Respinsă (Aplicare)",
  withdraw_registered: "Retrasă (Aplicare)",

  // Pre-audit (examinare)
  suspend_pre_audit: "Suspendată (Examinare)",
  withdraw_pre_audit: "Retrasă (Examinare)",
  rejected_pre_audit: "Respinsă (Examinare)",
  pre_audit: "În examinare",

  // Audit (examinare condiții)
  audit: "Examinare condiții",
  withdraw_audit: "Retragere (Examinare condiții)",
  suspend_audit: "Suspendare (Examinare condiții)",
  approved: "Acordare autorizație",
  rejected_audit: "Autorizație refuzată",

  // Monitorizare
  revoked: "Autorizație revocată",
  annul: "Autorizație anulată",
  suspend_active: "Autorizație suspendată",
  active: "Autorizație validă",
  withdraw_active: "Autorizație retrasă",

  // Re-audit (reevaluare)
  re_audit: "Reevaluare condiții",
  suspend_re_audit: "Suspendare (Reevaluare)",
  withdraw_re_audit: "Retragere (Reevaluare)",
  rejected_re_audit: "Autorizație refuzată",

  // Autorizație actualizată
  upgraded: "Autorizație actualizată",

  suspended: "Autorizație suspendată",
  anull: "Autorizație anulată",
};
