import React from "react";
import { Form, Input } from "ebs-design";
import { InputSize } from "ebs-design/dist/components/atoms/Input/Input";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { FormControl } from "@aeo/core/types";

import { FieldWithInfo } from "./FieldWithInfo";
import { relativeClassName } from "./utils";

export interface InputIDNPFormFieldProps extends FormFieldProps {
  required?: boolean;
  message?: string;
  size?: InputSize;
  info?: React.ReactNode;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
}

const normalizeIDNP = (value: string) => {
  const isNumber = /^\d+$/.test(value.charAt(value.length - 1));
  if (!isNumber) {
    return value.slice(0, value.length - 1);
  }
  return value;
};

export const InputIDNPFormField = ({
  rules = [],
  required,
  message,
  size = "large",
  info,
  hideLabel = false,
  extra,
  className,
  placeholder,
  maxLength = 13,
  disabled,
  ...restProps
}: InputIDNPFormFieldProps) => {
  const [newRules, setNewRules] = React.useState([...rules]);

  return (
    <Form.Field
      extra={<FieldWithInfo extra={extra} info={info} />}
      {...restProps}
      rules={newRules}
      {...{
        hideLabel,
      }}
      className={relativeClassName(info, className)}
    >
      {(_control, _) => {
        const { value, onChange } = _control as FormControl<string | number>;

        const handleInput = (type: boolean) => {
          if (type) {
            setNewRules([...rules]);
            onChange(normalizeIDNP(String(value || "")));
          } else {
            setNewRules(
              rules.map((item) => {
                return {
                  ...item,
                  min: undefined,
                  max: undefined,
                };
              }),
            );
          }
        };

        return (
          <Input
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
            size={size}
            value={value}
            onChange={(value) => onChange(normalizeIDNP(String(value)))}
            onBlur={() => handleInput(true)}
            onFocus={() => handleInput(false)}
          />
        );
      }}
    </Form.Field>
  );
};
