export const notifications = {
  response: "Răspunsul a fost trimis cu succes.",
  signedDocument: "Documentul a fost semnat cu succes.",
  sentRequest: "Cererea a fost expediată cu succes.",
  sentLetter: "Scrisoarea a fost expediată cu succes.",
  validatedData: "Datele au fost validate cu succes.",
  monitoringPlan: "Planul de monitorizare a fost creat.",
  personAssigned: "Persoana a fost asignată cu succes.",
  savedData: "Datele au fost salvate cu success.",
  action: "Acțiunea a fost efectuată cu succes.",
  conclusionSent: "Concluzia a fost trimisă cu succes.",
  successControlPlan: "Planul de control a fost creat.",
  successTask: "Sarcina a fost completată cu succes.",
  successObservation: "Constatările au fost salvate cu succes.",
  updatedRisk: "Riscul a fost modificat cu succes.",
};
