import { ReactNode } from "react";
import { Space, Card } from "ebs-design";
import { makeBEM } from "@aeo/core/utils";

import layoutImageBgSrc from "@aeo/core/common/assets/bg.png";
import layoutLogoSrc from "@aeo/core/common/assets/logo-eaeo.png";

const bem = makeBEM("auth-layout");

interface Props {
  authUser?: string;
  title?: string;
  subTitle?: string;
  footer?: ReactNode;
  children?: ReactNode;
}

export const Auth = ({ title, subTitle, footer = null, children }: Props) => {
  return (
    <div className={bem("container")}>
      <div className={bem("content")}>
        <div className={bem("content-container")}>
          <div className={bem("content-body")}>
            <Space direction="vertical">
              <h1 className={bem("title")}>{title}</h1>
              <Card size="large">
                <Card.Body className={bem("form")}>
                  <h2 className="mb-15">{subTitle}</h2>
                  {children}
                </Card.Body>
              </Card>
              {footer}
            </Space>
          </div>
        </div>
      </div>
      <div
        className={bem("image")}
        style={{ backgroundImage: `url(${layoutImageBgSrc})` }}
      >
        <div
          className={bem("logo")}
          style={{ backgroundImage: `url(${layoutLogoSrc})` }}
        />
      </div>
    </div>
  );
};
