import { KeyValueList, KeyValueRow, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { prefFieldName } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm, PersonListProps } from "../components";
import { useFieldValue, useScrollTo } from "@aeo/core/hooks";
import { NoteForm } from "../components/NoteForm";

export const AdvisoryValues = ({ path }: PersonListProps) => {
  const person = { ...fields.person, ...fields.personAdvisory };
  const value = useFieldValue(path);

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <KeyValueCard {...person.has_advisory} type="boolean" />
      <KeyValueList
        title={messages.SAQTabs.advisory.title}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
            <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
            <KeyValueRow {...prefFieldName(person.birthday, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.attachments, prefix)}
              label="Documente confirmative privind numele, prenumele membrilor consiliului consultativ."
              type="attachments"
            />
            <WhiteSpace v="1rem" />
            <NoteForm note={value?.[person.advisers.name]?.note} />
          </>
        )}
        name={[person.advisers.name, "value"]}
      />
    </>
  );
};

export const Advisory = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.advisory.title}
      content={<AdvisoryValues />}
      path="saq/advisory"
    />
  );
};
