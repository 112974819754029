import { Card, Form, Space, useForm, useNotify } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import {
  Grid,
  WhiteSpace,
  InputFormField,
  LoadingButton,
  InputPhoneFormField,
  InputIDNPFormField,
} from "@aeo/core/components";
import fields from "@aeo/core/fields";
import models from "@aeo/core/models";
import { getProperty, setFieldsErrors } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

import api, { querykeys } from "api";

export const SettingsForm = () => {
  const [form] = useForm();
  const notify = useNotify();

  const queryClient = useQueryClient();
  const userFields = fields.user;

  const { user, logout } = useAuth();

  const updateProfile = useMutation(
    (data: models.User) => api.profile.patch(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.loggedUser);
      },
      onError: (error) => {
        if (error.response?.status === 400)
          setFieldsErrors(getProperty(error.response?.data), form, notify);
      },
    },
  );

  const recoveryPassword = useMutation(
    (data: models.RecoveryPassword) => api.auth.recoveryPassword(data),
    {
      onSuccess: async ({ message }) => {
        await logout();
        message && notify.success({ title: message });
      },
      onError: (error: AxiosError<{ username?: string }>) => {
        notify.error({
          title: error?.response?.data?.username || error.message,
        });
      },
    },
  );

  const handleProfileSettings = (values: models.User) => {
    updateProfile.mutate(values);
  };

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={handleProfileSettings}
        initialValues={{ ...user }}
      >
        <Card>
          <Card.Header>
            <h3>Profil</h3>
          </Card.Header>
          <Card.Body>
            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...userFields?.organization_name} disabled />
              <InputFormField {...userFields?.juridic_form} disabled />
            </Grid>

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputIDNPFormField {...userFields?.idno} disabled />
            </Grid>

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...userFields.last_name} disabled />
              <InputFormField {...userFields.first_name} disabled />
            </Grid>
            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputPhoneFormField {...userFields.phone} required />
              <InputFormField {...userFields?.username} disabled />
            </Grid>

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputIDNPFormField {...userFields.idnp} />
              <InputFormField {...userFields?.function} />
            </Grid>

            <WhiteSpace />
          </Card.Body>
          <Card.Footer>
            <Space justify="space-between">
              <LoadingButton
                loading={recoveryPassword.isLoading}
                type="ghost"
                onClick={() =>
                  recoveryPassword.mutate({ username: user?.username! })
                }
              >
                Resetează parola
              </LoadingButton>
              <LoadingButton
                loading={updateProfile.isLoading}
                submit
                type="primary"
              >
                Salvează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
