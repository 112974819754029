import { GenericObject } from "@aeo/core/types";
import models from "@aeo/core/models";

import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

export const notifications = {
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/notifications`, query);
    const { data } = await apiAxios.get<
      models.WithResults<models.Notification>
    >(url);
    return data;
  },
  patch: async (id: number, body: Partial<models.Notification>) => {
    return await apiAxios.patch(`/notifications/${id}`, body);
  },
};
