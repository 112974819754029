import models from "@aeo/core/models";

export const viewStates = {
  suspend: [
    models.ApplicationState.suspend_active,
    models.ApplicationState.suspend_audit,
    models.ApplicationState.suspend_pre_audit,
    models.ApplicationState.suspend_re_audit,
    models.ApplicationState.suspend_registered,
  ],
  reject: [
    models.ApplicationState.rejected_audit,
    models.ApplicationState.rejected_registered,
  ],
  withdraw: [
    models.ApplicationState.withdraw_active,
    models.ApplicationState.withdraw_audit,
    models.ApplicationState.withdraw_pre_audit,
    models.ApplicationState.withdraw_re_audit,
    models.ApplicationState.withdraw_registered,
  ],
};
