import { Button } from "ebs-design";
import classNames from "classnames";

import { PreviewTabsEntity, PreviewTabsState } from "./types";
import { Flex } from "./Flex";

interface PreviewTabsProps {
  currentTab: PreviewTabsState;

  tabs: PreviewTabsEntity;
}

export const PreviewTabs = ({ tabs, currentTab }: PreviewTabsProps) => {
  return (
    <div>
      <Flex align="center" className="px-30 pb-20 pt-10">
        {Object.values(tabs).map((v) => (
          <Button
            key={v.title}
            onClick={v.onClick}
            className={classNames("mr-15", { active: currentTab === v.tabKey })}
          >
            {v.title}
          </Button>
        ))}
      </Flex>

      <div className="px-30 mb-30">{tabs[currentTab]?.content}</div>
    </div>
  );
};
