import { Link, useRouteMatch } from "react-router-dom";
import cn from "classnames";
import { WhiteSpace } from "@aeo/core/components";
import { makeBEM } from "@aeo/core/utils";

const bem = makeBEM("saq-tabs");

export interface ItemProps {
  nr: number;
  status?: "success" | "error";
  // static is a preserved keyword
  static_?: boolean;
  tabKey: string;
  indent?: number;
}

export const Item = ({
  nr,
  status,
  tabKey,
  static_,
  indent,
  children,
}: React.PropsWithChildren<ItemProps>) => {
  const match = useRouteMatch();
  const currentMatch = useRouteMatch({ path: `${match.url}/${tabKey}` });
  return (
    <Link
      className={cn(bem("item", [status], { static: static_ }), {
        active: currentMatch,
      })}
      to={static_ ? "#" : `${match.url}/${tabKey}`}
    >
      {!static_ ? (
        <div
          className={bem("dot")}
          style={{ ...(indent && { marginLeft: `${indent}pc` }) }}
        />
      ) : (
        <span
          className={bem("line")}
          style={{ ...(indent && { marginLeft: `${indent}pc` }) }}
        />
      )}

      <WhiteSpace h="0.5rem" />
      <div>{children}</div>
    </Link>
  );
};
