import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useNotify } from "ebs-design";

import { useModalState } from "@aeo/core/hooks";
import { DeleteModal, QueryTable } from "@aeo/core/components";
import { notifyErrors } from "@aeo/core/utils";
import { querykeys as coreQueryKeys } from "@aeo/core/api";

import api, { querykeys } from "api";

import { applicationsColumns } from "../config";

export const Applications = () => {
  const deleteModal = useModalState();
  const queryClient = useQueryClient();
  const history = useHistory();
  const notify = useNotify();

  const tableColumns = React.useMemo(
    () => applicationsColumns({ onDeleteClick: deleteModal.openWith }),
    [deleteModal.openWith],
  );

  const query = useQuery(querykeys.applications.many(), () =>
    api.application.get(),
  );

  const deleteMutation = useMutation(
    () => api.application.delete(deleteModal.data as string),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(coreQueryKeys.loggedUser);
        queryClient.invalidateQueries(querykeys.applications.many());
        queryClient.invalidateQueries(coreQueryKeys.notifications());
        deleteModal.close();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <>
      <QueryTable
        title={() => <h3>Cereri</h3>}
        query={query}
        columns={tableColumns}
        variants={["highlight-hover"]}
        onRow={(data, _index) => ({
          onClick: () => {
            if (data.state === "draft") {
              history.push(`/application/request`);
            } else {
              history.push(`/applications/${data.id}`);
            }
          },
        })}
      />
      <DeleteModal
        open={deleteModal.isOpen}
        onClose={deleteModal.close}
        deleteMutation={deleteMutation}
        title="Șterge aplicarea"
      >
        {`Doriți să ștergeți aplicarea cu numărul ${deleteModal.data}?`}
      </DeleteModal>
    </>
  );
};
