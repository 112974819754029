import React from "react";
import { QueryKey, useInfiniteQuery } from "react-query";

import { Button, Loader } from "ebs-design";

import { combineQueryPagesData, hasNextPage } from "@aeo/core/utils";
import { useIntersectionObserver } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import { INFINITE_SCROLL_MARGIN } from "@aeo/core/app-constants";
import { GenericObject } from "@aeo/core/types";

import { ChatMessage } from ".";

export interface ChatMessageListContentProps {
  querykey: (queryParams: GenericObject) => QueryKey;
  apiCall: (
    queryParams: GenericObject,
  ) => Promise<models.WithResults<models.ChatReply>>;
  justSent: models.TemporaryChatReply[];
  loadMoreRootRef: React.RefObject<HTMLDivElement | null>;
  customEmptyList?: React.ReactNode;
  onSuccess?: () => void;
}

export const ChatMessageListContent = ({
  querykey,
  apiCall,
  justSent,
  loadMoreRootRef,
  customEmptyList,
  onSuccess,
}: ChatMessageListContentProps) => {
  const loadMoreRef = React.useRef<HTMLDivElement>(null);
  const queryParams = { page_size: 20 };

  const query = useInfiniteQuery(
    querykey({ ...queryParams }),
    ({ pageParam = 1 }) =>
      apiCall({
        ...queryParams,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) =>
        hasNextPage(lastPage) ? (lastPage.page || 0) + 1 : undefined,
      onSuccess,
    },
  );

  const queryData = combineQueryPagesData(query.data);

  const data: models.TemporaryChatReply[] = [
    // Remove existing messages after refetch
    ...justSent.filter(
      (m) => !queryData.find((queryMessage) => queryMessage.id === m.id),
    ),
    ...queryData,
  ];

  useIntersectionObserver({
    rootRef: loadMoreRootRef,
    targetRef: loadMoreRef,
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage,
    rootMargin: INFINITE_SCROLL_MARGIN,
  });

  return (
    <>
      {!query.isLoading &&
        data?.map?.((message, i) => (
          <ChatMessage
            key={message.temporary_id || message.id || i}
            {...message}
          />
        ))}
      {query.isLoading && <Loader loading />}
      <div ref={loadMoreRef} className="text-center m-15">
        {data?.length
          ? query.hasNextPage && (
              <Button
                onClick={() => query.fetchNextPage()}
                disabled={!query.hasNextPage || query.isFetchingNextPage}
              >
                {query.isFetchingNextPage
                  ? "Se încarcă..."
                  : query.hasNextPage
                  ? "Încărcați mai multe"
                  : null}
              </Button>
            )
          : null}
      </div>
      {!query.isLoading &&
        !data.length &&
        (customEmptyList ?? <div className="text-center">Nu aveți mesaje</div>)}
    </>
  );
};
