import { Link } from "react-router-dom";
import { Auth } from "@aeo/core/components/auth";
import { RecoveryPasswordForm } from "../components";

export const RecoveryPassword = () => {
  return (
    <Auth
      title="AEO"
      subTitle="Recuperează parola"
      footer={
        <h4 className="fw-500">
          Ai deja un cont?
          <span>
            <Link to="/login" className="auth-layout__link">
              Login.
            </Link>
          </span>
        </h4>
      }
    >
      <p className="color-gray mb-15">
        Introdu adresa de e-mail și urmează instrucțiunile primite
      </p>
      <RecoveryPasswordForm />
    </Auth>
  );
};
