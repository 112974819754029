import { notApplicable } from "../../components/form-utils/utils";
import { extraTextareaProps } from "../common";

export const securityOne = {
  competent: {
    name: "competent",
    first_name: {
      name: ["competent", "first_name"],
      label: "Nume",
    },
    last_name: {
      name: ["competent", "last_name"],
      label: "Prenume",
    },
    function: {
      name: ["competent", "function"],
      label: "Funcția",
      placeholder: "Ex: Sef Securitate",
    },
  },

  has_assessment_risk: {
    name: "has_assessment_risk",
    label:
      "6.1.2. a) Aţi efectuat o evaluare a riscurilor sau a ameninţărilor la adresa companiei dvs?",
    info: `Autoritatea vamală se aşteptă ca o evaluare documentată a riscurilor şi a ameninţărilor să fi fost deja efectuată fie de compania-solicitant, fie de o companie de securitate, în cazul în care utilizaţi serviciile unei astfel de companii. Neprezentarea acestei evaluări în timpul vizitei echipei de audit poate avea drept rezultat recomandarea automată de respingere a cererii. Evaluarea riscurilor şi a ameninţărilor va viza toate spaţiile care sunt relevante pentru activităţile legate de domeniul vamal ale societăţii. Scopul evaluării este de a identifica riscurile şi ameninţările care ar putea apărea în acel segment al lanţului de aprovizionare internaţional în care vă desfăşuraţi activitatea, precum şi de a analiza măsurile existente, menite să reducă riscurile şi ameninţările. Aceasta trebuie să cuprindă toate riscurile legate de rolul în cadrul lanţului de aprovizionare internaţional şi trebuie să includă, de exemplu:
<ul>
<li>a) ameninţările fizice pentru spaţii şi mărfuri;</li>
<li>b) riscurile fiscale;</li>
<li>c) angajamentele contractuale pentru partenerii comerciali din cadrul lanţului de aprovizionare.</li>
</ul>
O astfel de evaluare trebuie să abordeze următoarele aspecte:
<ul>
<li>a) mărfurile care fac obiectul activităţii /schimburilor comerciale pe care le efectuaţi;</li>
<li>b) gestionarea specifică a transportului aerian de mărfuri/poştei aeriene, dacă este cazul (accesul, manipularea, depozitarea mărfurilor etc.);</li>
<li>c) spaţiile şi clădirile pentru depozitare, producţie etc.;</li>
<li>d) personalul, inclusiv recrutarea, angajarea salariaților pe perioadă determinată, lucrările subcontractate;</li>
<li>e) transportul mărfurilor, încărcarea şi descărcarea acestora;</li>
<li>f) sistemul informatic, evidenţele şi documentele contabile;</li>
<li>g) incidente de securitate raportate recent în oricare dintre domeniile amintite mai sus.
</ul>
  De asemenea, trebuie să dovediţi frecvenţa cu care sunt revizuite şi actualizate documentele, iar procedurile trebuie să includă modul în care trebuie raportate incidentele şi frecvenţa revizuirilor viitoare. Autoritatea vamală va solicita, de asemenea, dovezi privind modul şi momentul în care procedurile sunt comunicate atât personalului, cât şi vizitatorilor companiei.`,
  },
  has_security_plan: {
    name: "has_security_plan",
    label:
      "b) Există un plan de securitate pentru fiecare amplasament? (dacă este cazul)",
    info: `
      <p>Neprezentarea unui plan de securitate sau a unei evaluări a riscurilor şi ameninţărilor în timpul vizitei echipei de audit poate avea drept rezultat încetarea anticipată a vizitei sau respingerea cererii. Trebuie să existe un program de revizuire pentru planul de securitate, care trebuie să includă evidenţe ale modificărilor semnate şi datate de persoana responsabilă.</p>
      `,
    defaultDepsValue: [{ security_plan_attachments: [] }],
  },
  security_plan_attachments: {
    name: "security_plan_attachments",
    label: "Documente",
  },
  documents_reviewed: {
    name: "documents_reviewed",
    label: "Cât de des sunt revizuite şi actualizate aceste documente?",
    ...extraTextareaProps,
  },
  risks: {
    name: "risks",
    label:
      "6.1.3. Descrieţi succint ce riscuri de securitate (în cadrul companiei sau în cadrul tranzacţiilor comerciale cu clienţii, furnizorii şi prestatorii externi de servicii) aţi identificat în legătură cu criteriile de securitate AEO?",
    ...extraTextareaProps,
    info: `6.1.3. Trebuie să includeţi descrierea a cel puţin cinci riscuri principale pe care le-aţi identificat. Autoritatea vamală se aşteaptă ca să evaluaţi şi să includeţi aceste riscuri în evaluarea riscurilor şi ameninţărilor, luând în considerare probabilitatea, consecinţele şi contramăsurile. Astfel de exemple pot fi:
<ul>
<li>a) contrabandă cu mărfuri ilicite;</li>
<li>b) contaminarea produselor;</li>
<li>c) falsificarea mărfurilor pentru export;</li>
<li>d) accesul neautorizat etc.</li>
</ul>
`,
  },
  measures: {
    name: "measures",
    label:
      "6.1.4.Cum sunt puse în aplicare şi coordonate măsurile de securitate în cadrul companiei dvs. şi cine deţine responsabilitatea în acest sens?",
    ...extraTextareaProps,
    info: `6.1.4. Descrieţi pe scurt procedura de instituire, punere în aplicare, monitorizare şi revizuire a măsurilor de securitate. Va fi necesar să identificaţi persoana responsabilă şi sarcinile acesteia. Trebuie să existe o persoană în cadrul companiei responsabilă de toate măsurile de securitate şi cu autoritatea necesară pentru punerea în aplicare a măsurilor de securitate corespunzătoare atunci când situaţia o impune. Dacă nu, indicaţi diferitele departamente implicate şi operaţiile globale de coordonare şi gestionare.
Dacă se folosesc servicii externe de securitate, persoana responsabilă trebuie să gestioneze contractul şi să se asigure că există un contract adecvat privind nivelul serviciilor care să îndeplinească cerinţele AEO astfel cum reies din întrebările din această secţiune. Persoana responsabilă trebuie să poată explica şi să dispună de proceduri adecvate pentru elaborarea, revizuirea şi actualizarea tuturor măsurilor de securitate. Această persoană trebuie să fie în mod normal responsabilă de pregătirea documentelor necesare specificate la subpunctul 6.1.2 literele a) şi b).
Autoritatea vamală se aşteaptă ca procedurile să fie elaborate în aşa fel încât să permită oricărei persoane care substituie persoana responsabilă să accepte responsabilitatea şi să ducă la îndeplinire sarcina solicitată.`,
  },
  has_subdivision_measures: {
    name: "has_subdivision_measures",
    label:
      "6.1.5. Dacă compania dvs. (dacă este cazul) deține mai multe subdiviziuni, sunt măsurile de securitate puse în aplicare în mod armonizat în toate aceste subdiviziuni?",
    info: `6.1.5. Deşi în multe cazuri, măsurile de securitate sunt probabil specifice amplasamentului în cauză, procedurile aplicabile pentru stabilirea, punerea în aplicare, monitorizarea şi revizuirea măsurilor pot fi armonizate pentru toate subdiviziunile. Dacă măsurile nu sunt armonizate, acest lucru poate determina creşterea numărului de vizite la faţa locului efectuate de echipa de audit.`,
  },
  has_instructions: {
    name: "has_instructions",
    label: "6.1.6. a) Există instrucțiuni de securitate?",
    placeholder: "Detalii",
    info: `6.1.6. a) şi b) În întreprinderea trebuie să existe proceduri documentate pentru a permite şi încuraja personalul şi, dacă este posibil, vizitatorii, să raporteze incidentele de securitate, de exemplu, accesul neautorizat, furtul, folosirea unor angajaţi care nu au făcut obiectul unui control de securitate. Aceste proceduri documentate trebuie să includă modul în care trebuie să se procedeze, precum şi persoana şi locul unde se află. De asemenea, procedurile trebuie să detalieze modul în care aceste incidente trebuie investigate şi raportate, precum şi persoana care trebuie să facă acest lucru. Dacă aţi răspuns „nu", indicațimodul în care intenţionaţi să abordaţi acest aspect şi intervalul de timp.Răspunsul „da" trebuie să fie însoţit de explicaţia cu privire la modul în care sunt comunicate personalului instrucţiunile privind siguranţa şi trebuie să includă detalii despre modul în care vă asiguraţi că personalul le-a consultat. De asemenea, trebuie să explicaţi modul în care instrucţiunile privind securitatea sunt aduse la cunoştinţa vizitatorilor (a se vedea şi întrebarea de la subpunctul 6.3.2.).Instrucţiunile privind „securitatea" nu trebuie să fie confundate cu instrucţiunile necesare din motive de sănătate şi siguranţă şi trebuie să fie explicate clar vizitatorilor companiei şi personalului angajat.`,
    defaultDepsValue: [
      { staff_communication: null },
      { instructions_documented: null },
      { instructions_documented_attachments: [] },
    ],
  },
  staff_communication: {
    name: "staff_communication",
    label:
      "Cum sunt acestea comunicate personalului dvs. şi persoanelor care vizitează spaţiile companiei dvs.?",
    ...extraTextareaProps,
    dependencies: ["has_instructions"],
  },
  instructions_documented: {
    name: "instructions_documented",
    label:
      "b) Cum sunt acestea documentate (manual, orientări de lucru, fişă informativă etc.)?",
    ...extraTextareaProps,
    dependencies: ["has_instructions"],
  },
  instructions_documented_attachments: {
    name: "instructions_documented_attachments",
    label: "Documente",
  },
  has_incidents: {
    name: "has_incidents",
    label: "6.1.7. a) Ați avut parte de incidente de securitate în ultimul an?",
    dependencies: ["incidents"],
    info: `6.1.7 a) şi b) Această întrebare se referă la securitatea lanţului de aprovizionare internaţional şi nu la incidentele legate de sănătate şi siguranţă. De exemplu:
<ul>
<li>a) pierderi în depozit;</li>
<li>b) sigilii rupte;</li>
<li>c) dispozitive de protecţie împotriva modificărilor ilicite deteriorate.</li>
</ul>
Dacă au existat incidente, autoritatea vamală se aşteaptă ca procedurile privind siguranţa şi securitatea să fi fost revizuite şi modificate în vederea adoptării unor acţiuni de remediere. De asemenea, vor fi solicitate dovezi privind modul în care aceste modificări au fost comunicate ulterior personalului şi vizitatorilor companiei. În cazul în care se efectuează modificărea procedurilor privind siguranţa şi securitatea, acestea trebuie să conțină mențiunea privind revizuirea, consemnându-se data şi partea (părţile) revizuită (revizuite).`,
  },
  incidents: {
    name: "incidents",
    label:
      "Dacă răspunsul este da, furnizați o scurtă descriere a incidentelor.",
    placeholder: "Detalii",
    dependencies: ["has_incidents"],
  },
  incidents_documented: {
    name: "incidents_documented",
    label:
      "b) Păstraţi evidențe ale incidentelor de securitate şi ale măsurilor luate?",
  },
  has_certificate: {
    name: "has_certificate",
    label:
      "6.1.8. a) Ați fost deja certificat/autorizat în materie de securitate (a transportului, a aviației civile, etc.)?",
    dependencies: ["certificates"],
    defaultDepsValue: [{ certificates: [] }],
    info: `6.1.8. a), b) şi c) Trebuie să vă asiguraţi că deţineţi documentaţia originală, inclusiv raportul de evaluare, în cazul în care este disponibil, întrucât aceasta ar putea fi solicitată de autoritatea vamală pe durata vizitei la faţa locului. Certificarea pertinentă va fi luată în considerare la momentul efectuării audituluide autoritatea vamală atunci când acestea pregătesc şi efectuează auditul. De exemplu:
<ul>
<li>a) agent abilitat (certificat şi raport de evaluare)</li>
<li>b) expeditor cunoscut (certificat şi raport de evaluare)</li>
<li>c) TAPA (certificat şi raport de evaluare)</li>
<li>d) ISO (certificat şi manualul de calitate) etc.</li>
</ul>`,
  },
  certificates: {
    name: "certificates",
    label: "Dacă răspunsul este da, prezentați o copie a acestora.",
    dependencies: ["has_certificate"],
  },
  has_apply_security: {
    name: "has_apply_security",
    label:
      "b) La momentul depunerii cererii pentru acordarea statutului de AEO, intenționați să depuneți o cerere în materie de securitate?",
    dependencies: ["apply_security"],
  },
  apply_security: {
    name: "apply_security",
    label: "Dacă răspunsul este da, furnizați detalii.",
    placeholder: "Detalii",
    dependencies: ["has_apply_security"],
  },
  has_special_requirements: {
    name: "has_special_requirements",
    label:
      "6.1.9. Există cerinţe speciale de securitate şi siguranţă pentru mărfurile pe care le importaţi/exportaţi?",
    ...extraTextareaProps,
    info: `6.1.9. Răspunsul trebuie să includă, de exemplu detalii, privind eventualele substanţe chimice periculoase, mărfurile cu valoare mare sau mărfurile supuse accizelor şi trebuie să explicaţi dacă acestea fac obiectul activităţilor în mod regulat sau neregulat, de exemplu:
ambalaje speciale;cerinţe specifice de depozitare (a se vedea şi punctul 6.5.1 (procesele logistice)).`,
  },
  has_security_service: {
    name: "has_security_service",
    label: "6.1.10. a) Utilizaţi serviciile unei societăţi de securitate?",
    dependencies: ["security_service"],
    info: `6.1.10 a) şi b) Răspunsul trebuie să includă denumirea şi adresa societăţii (societăţilor) şi de când aceasta (acestea) este (sunt) angajată (angajate) în calitate de societate de securitate şi dacă prestează şi alte servicii pentru solicitant. Dacă societatea de securitate a efectuat o evaluare a ameninţărilor, răspunsul trebuie să confirme, de asemenea, că orice riscuri identificate au fost incluse în evaluarea riscurilor şi a ameninţărilor, menţionată la întrebarea 6.1.2. a). Documentele trebuie să indice data (datele) la care a fost efectuată evaluarea, precum şi pe cea (cele) la care au fost puse în aplicare eventualele recomandări. Documentul trebuie să fie disponibil în timpul vizitei.`,
    defaultDepsValue: [
      { security_service: null },
      { has_assessment_service: null },
      { risks_identified: { true: null, false: notApplicable } },
    ],
  },
  security_service: {
    name: "security_service",
    label: "Dacă da, cu ce societate colaborați?",
    placeholder: "Detalii",
    dependencies: ["has_security_service"],
    info: "a) şi b) Răspunsul trebuie să includă denumirea şi adresa societăţii (societăţilor) şi de când aceasta (acestea) este (sunt) angajată (angajate) în calitate de societate de securitate şi dacă prestează şi alte servicii pentru solicitant. Dacă societatea de securitate a efectuat o evaluare a ameninţărilor, răspunsul trebuie să confirme, de asemenea, că orice riscuri identificate au fost incluse în evaluarea riscurilor şi a ameninţărilor, menţionată la întrebarea 6.1.2. a). Documentele trebuie să indice data (datele) la care a fost efectuată evaluarea, precum şi pe cea (cele) la care au fost puse în aplicare eventualele recomandări. Documentul trebuie să fie disponibil în timpul vizitei.",
  },
  has_assessment_service: {
    name: "has_assessment_service",
    reverse: true,
    label:
      "b) A efectuat această societate o evaluare a ameninţărilor pentru compania dvs.?",
    dependencies: ["has_security_service"],
    defaultDepsValue: [
      { risks_identified: { true: null, false: notApplicable } },
    ],
  },
  risks_identified: {
    name: "risks_identified",
    label:
      "Dacă răspunsul este da, descrieţi pe scurt ce riscuri de securitate au fost identificate în legătură cu criteriile de securitate AEO.",
    placeholder: "Detalii",
    reverse: true,
    textarea: true,
    nullValue: true,
    dependencies: ["has_assessment_service", "has_security_service"],
  },
  has_client_requirements: {
    name: "has_client_requirements",
    label:
      "6.1.11. Clienţii companiei dvs. sau companiile de asigurări vă impun anumite cerinţe de siguranţă şi securitate",
    dependencies: ["client_requirements"],
    info: `6.1.11. Răspunsul trebuie să ofere indicaţii privind diferitele cerinţe ale clienţilor/ale societăţii de asigurări şi mărfurile vizate de cerinţe speciale, de exemplu, cerinţe privind ambalaje speciale sau cerinţe specifice privind depozitarea. Dacă deţineţi o gamă largă de produse şi cerinţe, rezumarea acestora este suficientă. Acestea vor fi examinate mai detaliat în timpul vizitei.`,
  },
  client_requirements: {
    name: "client_requirements",
    label: "Oferiți detalii.",
    placeholder: "Detalii",
    dependencies: ["has_client_requirements"],
    info: "Răspunsul trebuie să ofere indicaţii privind diferitele cerinţe ale clienţilor/ale societăţii de asigurări şi mărfurile vizate de cerinţe speciale, de exemplu, cerinţe privind ambalaje speciale sau cerinţe specifice privind depozitarea. Dacă deţineţi o gamă largă de produse şi cerinţe, rezumarea acestora este suficientă. Acestea vor fi examinate mai detaliat în timpul vizitei.",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
