import React from "react";
import cn from "classnames";

import { Flex, Icon, WhiteSpace } from ".";
import { useControlledState } from "../hooks";

export interface CollapseProps {
  initialOpen?: boolean;
  open?: boolean;
  onChange?: React.Dispatch<boolean>;
  header?: React.ReactNode;
  extra?: React.ReactNode;
  renderIcon?: (open: boolean) => React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export const Collapse: React.FC<CollapseProps> = ({
  initialOpen,
  children,
  header,
  extra,
  renderIcon = defaultRenderIcon,
  className,
  disabled = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useControlledState(
    props.open,
    props.onChange,
    initialOpen ?? false,
  );

  return (
    <>
      <Flex
        onClick={() => !disabled && setIsOpen((v) => !v)}
        className={cn("collapse__header", {
          "collapse__header--disabled": disabled,
          className,
        })}
      >
        <div className="flex-1">{header}</div>
        {extra && (
          <>
            <WhiteSpace h="1rem" />
            {extra}
          </>
        )}
        <WhiteSpace h="1rem" />
        {renderIcon(isOpen)}
      </Flex>
      <div className={cn("collapse__content", { open: isOpen })}>
        {children}
      </div>
    </>
  );
};

const defaultRenderIcon = (open: boolean) =>
  open ? <Icon type="minus" /> : <Icon type="plus" />;
