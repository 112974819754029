import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  InputFormField,
  AttachmentsFormField,
  LoadingButton,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";

export const LogisticThreeForm = () => {
  const logisticThree = fields.logisticThree;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/logistic_three", form);
  useDraftApplicationQuery("saq/logistic_three", form);
  const validateMutation = useValidateMutation(
    "saq/logistic_three",
    form,
    "solvency",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.logistic.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <TitleTooltip
              title={<h4>{capitalize(messages.titles.procedures)}</h4>}
              info="Pentru a îndeplini criteriul menţionat la articolul 1953 alineatul (4) litera e) din Codul vamal,trebuie să dispuneţi de proceduri corespunzătoare de arhivare a documentelor şi a informaţiilor, precum şi de protecţie împotriva pierderii datelor."
            />

            <WhiteSpace />
            <InputFormField {...logisticThree.backup_process} />
            <InputFormField {...logisticThree.backup_ttl} />
            <ExtraTextareaFormField
              radioField={logisticThree.has_emergency_plan}
              textareaField={logisticThree.emergency_plan}
            />
            <Form.Field>
              {(_, __, form) => {
                return (
                  <>
                    {form.getFieldValue(
                      logisticThree.has_emergency_plan?.name,
                    ) && (
                      <>
                        <AttachmentsFormField
                          {...logisticThree.emergency_plan_attachments}
                        >
                          Atașează documente
                        </AttachmentsFormField>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Field>

            <WhiteSpace />
            <TitleTooltip
              title={<h4>{messages.titles.protectionOfSystems}</h4>}
              info="Pentru a îndeplini condiția menţionată la 1953 alineatul (4) litera g) din Codul vamal, trebuie să dispuneţi de măsuri adecvate de securitate a tehnologiilor informaționale pentru a proteja sistemul informatic și documentația împotriva oricăror imixtiuni.0"
            />

            <WhiteSpace />

            <InputFormField {...logisticThree.protect_system} />

            <ExtraTextareaFormField
              radioField={logisticThree.has_test_auth}
              textareaField={logisticThree.test_authorization}
            />
            <WhiteSpace />
            <InputFormField {...logisticThree.incident_security} />
            <InputFormField {...logisticThree.access_rights} />
            <InputFormField {...logisticThree.protection_responsible} />
            <InputFormField {...logisticThree.internal_security} />
            <AttachmentsFormField
              {...logisticThree.internal_security_attachments}
            >
              Atașează documente
            </AttachmentsFormField>

            <InputFormField {...logisticThree.monitor_security} />
            <InputFormField {...logisticThree.server_located} />
            <InputFormField {...logisticThree.server_secured} />

            <h4>{capitalize(messages.titles.documentationSecurity)}</h4>
            <WhiteSpace />

            <InputFormField {...logisticThree.protect_information_procedure} />

            <ExtraTextareaFormField
              radioField={logisticThree.has_unauthorized_access}
              textareaField={logisticThree.unauthorized_access}
            />

            <WhiteSpace />
            <InputFormField {...logisticThree.employee_category} />
            <InputFormField {...logisticThree.allowed_change} />
            <InputFormField {...logisticThree.change_document_procedure} />
            <InputFormField {...logisticThree.security_requirements} />

            <WhiteSpace />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
