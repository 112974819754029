import React from "react";
import { useQuery } from "react-query";

import { FormInstance } from "ebs-design";
import { NamePath } from "rc-field-form/es/interface";

import { getProperty } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

import api, { querykeys } from "api";

export function useDraftApplicationQuery(
  path: api.ApplicationValidatePath,
  form: FormInstance,
  pathPrefix?: NamePath,
  normalize?: (values: any) => any,
) {
  const { user } = useAuth();
  const applicationId = React.useMemo(
    () => user?.organization?.active_application?.id || 0,
    [user?.organization?.active_application?.id],
  );
  const objPath = React.useMemo(() => path.split("/"), [path]);

  // TODO handle errors, loading
  const query = useQuery(
    querykeys.applications.one(applicationId),
    () => api.application.getById(applicationId),
    {
      enabled: !!applicationId,
      onSettled: async (data, error) => {
        const value = getProperty(data, ...objPath);
        const normalizedValue = normalize ? normalize(value) : value;

        !error &&
          (pathPrefix
            ? form.setFields([{ name: pathPrefix, value: normalizedValue }])
            : form.setFieldsValue(normalizedValue));
      },
      refetchOnMount: false,
    },
  );

  React.useEffect(() => {
    const value = getProperty(query.data, ...objPath);
    const normalizedValue = normalize ? normalize(value) : value;

    query.data &&
      (pathPrefix
        ? form.setFields([{ name: pathPrefix, value: normalizedValue }])
        : form.setFieldsValue(normalizedValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  return query;
}
