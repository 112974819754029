import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  InputFormField,
  AttachmentsFormField,
  LoadingButton,
  ExtraTextareaFormField,
  BooleanRadioFormField,
  FormList,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import { notNARadioOptions } from "@aeo/core/components/form-utils/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import {
  NotApplicableBooleanField,
  NotApplicableFormField,
  NotApplicableFormList,
} from "..";
import { OutsourseOrgFormFields } from ".";

export const LogisticOneForm = () => {
  const logisticOne = fields.logisticOne;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/logistic_one", form);
  useDraftApplicationQuery("saq/logistic_one", form);
  const validateMutation = useValidateMutation(
    "saq/logistic_one",
    form,
    "logistic_two",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.logistic.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>

          <Card.Body>
            <TitleTooltip
              title={<h4>{capitalize(messages.titles.auditTrack)}</h4>}
              info="Numeroase întreprinderi şi organizaţii au nevoie de o pistă de audit în sistemele lor automatizate din motive de securitate. O pistă de audit este un proces sau o etapă care constă în efectuarea de verificări încrucişate ale fiecărei intrări din evidenţele contabile cu sursa acesteia, pentru a controla corectitudinea înregistrărilor respective. O pistă de audit completă vă va facilita trasabilitatea activităţilor operaţionale de la momentul intrării fluxului de mărfuri şi a produselor în companie, la prelucrarea acestora şi la ieşire. O pistă de audit completă conţine şi o evidenţă istorică, care permite urmărirea fiecărei informaţii din momentul în care este introdusă în dosar până în momentul în care este eliminată din acesta. În mod normal, sistemul contabil trebuie să includă: cartea mare, registrul de vânzări, registrul de achiziţii, situaţiile  financiare, conturi de gestiune. În mod normal, sistemul logistic trebuie să includă: prelucrarea comenzilor de vânzări, prelucrarea comenzilor de achiziţii, producţia, inventarul - depozitare, antrepozitare, expedierea/transportul, listele de furnizori/clienți."
            />
            <WhiteSpace />
            <ExtraTextareaFormField
              radioField={logisticOne.has_audit_trail}
              textareaField={logisticOne.audit_trail}
            />
            <WhiteSpace />
            <h4>{capitalize(messages.titles.logisticSubsection)}</h4>
            <WhiteSpace />
            <InputFormField {...logisticOne.accounting_systems} />

            <NotApplicableBooleanField
              {...logisticOne.can_differentiate}
              options={notNARadioOptions}
            />
            <NotApplicableFormField {...logisticOne.differentiate} reverse />

            <InputFormField {...logisticOne.it_activity} />

            <BooleanRadioFormField radioField={logisticOne.has_it_outsourced} />
            <NotApplicableFormList
              dependencies={[logisticOne.has_it_outsourced.name]}
              name={"outsource_organizations"}
            >
              <FormList
                subTitle="Companie"
                name="outsource_organizations"
                Component={OutsourseOrgFormFields}
              />
            </NotApplicableFormList>
            <TitleTooltip
              title={<h4>{capitalize(messages.titles.internControl)}</h4>}
              info="Operatorul economic trebuie să dispună de un sistem de control intern care să corespundă tipului şi dimensiunii obiectului de activitate, suficient dezvoltat pentru administrarea fluxului de mărfuri şi identificarea tranzacţiilor prejudiciabile."
            />

            <WhiteSpace />
            <ExtraTextareaFormField
              radioField={logisticOne.has_internal_control}
              textareaField={logisticOne.internal_control}
            />
            <WhiteSpace />
            <BooleanRadioFormField radioField={logisticOne.has_audit_control} />
            <BooleanRadioFormField
              radioField={logisticOne.is_include_parcels}
            />
            <NotApplicableFormList
              dependencies={[logisticOne.is_include_parcels.name]}
              name={logisticOne.recent_audit_report.name}
            >
              <AttachmentsFormField
                {...logisticOne.recent_audit_report}
                multiple={false}
              >
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <WhiteSpace />
            <p>
              3.3.3. Descrieţi succint procedurile pentru verificarea fişierelor
              informatice (date permanente sau dosare principale)? Cum acoperă
              aceste proceduri următoarele riscuri:
            </p>
            <WhiteSpace />
            <InputFormField {...logisticOne.incorrect_registration} />
            <InputFormField {...logisticOne.incorrect_data} />
            <NotApplicableFormField
              {...logisticOne.incorrect_business_processes}
            />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
