import * as React from "react";

import RCUpload, { UploadProps as RcUploadProps } from "rc-upload";

import { GenericObject } from "@aeo/core/types";
import { typedOmitKeys } from "@aeo/core/utils";
import { useControlledState, useStateRef } from "@aeo/core/hooks";

import { UploadItem, UploadProps } from "./types";
import { DefaultUploadItem } from "./DefaultUploadItem";

export const Upload = <TData extends unknown, TError extends Error = Error>({
  showItems = true,
  onProgressChange,
  ...props
}: UploadProps<TData, TError>) => {
  const [items, setItems] = useControlledState(props.value, props.onChange, []);
  const [progress, setProgress] = React.useState<GenericObject<number>>({});

  React.useEffect(() => {
    if (items && items.some((i) => !i.file?.uid))
      setItems((prev) =>
        prev.map((i) => ({
          isFinished: true,
          isSuccess: true,
          ...i,
          file: { ...i.file, uid: i.file?.uid || performance.now() + "" },
        })),
      );
  }, [items, setItems]);

  React.useEffect(() => {
    onProgressChange?.(progress);
  }, [progress]);

  const uploadProps: RcUploadProps = typedOmitKeys(props, [
    "onSuccess",
    "value",
    "onChange",
    "onRemove",
    "onError",
  ]);

  // Handlers
  /**
   * Using ref because rc-upload doesn't update the handler at the right time
   */
  const onStartRef = useStateRef<RcUploadProps["onStart"]>((file) => {
    setItems((prev) =>
      props.multiple ? [...(prev || []), { file }] : [{ file }],
    );
    setProgress((prev) => ({ ...prev, [file.uid]: 0 }));

    props.onStart?.(file);
  });

  uploadProps.onStart = (file) => {
    onStartRef.current?.(file);
  };

  const onErrorRef = useStateRef<RcUploadProps["onError"]>(
    (error, ret, file) => {
      let updatedItem: UploadItem<TData, TError> | undefined;

      setItems((prev) =>
        prev?.map((item) =>
          item.file?.uid === file.uid
            ? (updatedItem = {
                file,
                error: error as TError,
                // ? data: ret as TData, // maybe?
                isError: true,
                isFinished: true,
              })
            : item,
        ),
      );

      props.onError?.(updatedItem, ret);
    },
  );
  uploadProps.onError = (error, ret, file) => {
    onErrorRef.current?.(error, ret, file);
  };

  const onSuccessRef = useStateRef<RcUploadProps["onSuccess"]>(
    (response, file, xhr) => {
      let updatedItem: UploadItem<TData, TError> | undefined;
      setItems((prev) =>
        prev?.map((item) =>
          item.file?.uid === file.uid
            ? (updatedItem = {
                file,
                data: response as TData,
                isSuccess: true,
                isFinished: true,
              })
            : item,
        ),
      );

      props.onSuccess?.(updatedItem, xhr);
    },
  );

  uploadProps.onSuccess = (response, file, xhr) => {
    onSuccessRef.current?.(response, file, xhr);
  };

  uploadProps.onProgress = (event, file) => {
    setProgress((prevState) => ({
      ...prevState,
      [file.uid]: Math.round(event.percent),
    }));

    props.onProgress?.(event, file);
  };

  const handleRemove = (item: UploadItem<TData, TError>, idx: number) => {
    setItems((prev) => prev?.filter((_, i) => idx !== i));
    props.onRemove?.(item, idx);
  };

  return (
    <>
      {showItems &&
        items?.map((item, idx) => {
          return (
            <React.Fragment key={`${item.file?.name}-${idx}`}>
              {(props.renderItem || DefaultUploadItem)({
                item,
                idx,
                progress: progress[item.file?.uid || ""],
                onRemoveClick: () => handleRemove(item, idx),
              })}
            </React.Fragment>
          );
        })}
      <RCUpload {...uploadProps}>{props.children}</RCUpload>
    </>
  );
};
