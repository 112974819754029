import React from "react";

import { iconList } from "./iconList";

export type IconType = keyof typeof iconList;
export interface IconProps extends React.SVGAttributes<SVGSVGElement> {
  type: IconType;
  size?: number | string;
}

export const Icon = ({
  type,
  size = "1.5em",
  className = "",
  style: propsStyle = {},
  ...svgProps
}: IconProps) => {
  const IconComponent = iconList[type];
  const style: React.CSSProperties = {
    fontSize: size,
    strokeWidth: 2,
  };
  Object.assign(style, propsStyle);
  return (
    <IconComponent
      {...svgProps}
      style={style}
      className={`ebs-icon ${className}`}
    />
  );
};
