import { Option } from "ebs-design/dist/components/molecules/Select/interfaces";

export enum UserRoles {
  admin = "admin",
  user = "user",
  superuser = "superuser",
  cs_admin = "cs_admin",
}

export type UserRole = keyof typeof UserRoles;
export type AEOUserRole = Exclude<UserRole, "superuser">;
export type MCSUserRole = Exclude<UserRole, "cs_admin">;

export const userRoles: { [Key in Exclude<UserRole, "cs_admin">]: string } = {
  [UserRoles.admin]: "Director SV / Director adjunct",
  [UserRoles.user]: "Auditor",
  [UserRoles.superuser]: "Șeful secției AEO",
};

export const aeoUserRoles: { [Key in Exclude<UserRole, "superuser">]: string } =
  {
    [UserRoles.admin]: "Persoana responsabilă de gestionarea companiei",
    [UserRoles.cs_admin]: "Persoana responsabilă de domeniul vamal",
    [UserRoles.user]: "Utilizator asistent",
  };

export const aeoUserRolesOptions: Option[] = Object.entries(aeoUserRoles).map(
  ([key, value]) => ({
    value: key,
    text: value,
  }),
);

export const mcsUserRolesOptions: Option[] = Object.entries(userRoles).map(
  ([key, value]) => ({
    value: key,
    text: value,
  }),
);
