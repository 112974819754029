export const downloadFile = (
  data: Blob,
  fileName = "Document.pdf",
  type = "application/pdf",
) => {
  const blob = new window.Blob([data], { type });
  const link = document.createElement("a");

  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();

  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 5000);
};

export const downloadFileAsLink = (url: string, fileName = "Document.pdf") => {
  const link = document.createElement("a");

  link.href = url;
  link.download = fileName;

  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
};
