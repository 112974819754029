import { Loader } from "ebs-design";
import { Flex } from "@aeo/core/components";
import { TitleTooltip } from "./TitleTooltip";

export const TitleWithLoading = ({
  loading,
  title,
  info,
}: {
  loading: boolean;
  title: string;
  info?: React.ReactNode;
}) => {
  return (
    <Flex justify="space-between" align="center">
      <TitleTooltip title={<h3>{title}</h3>} info={info} />
      {loading && <Loader.Inline>Se salvează...</Loader.Inline>}
    </Flex>
  );
};
