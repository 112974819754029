import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  AttachmentsFormField,
  LoadingButton,
  InputFormField,
  BooleanRadioFormField,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";

export const SecurityThreeForm = () => {
  const securityThree = fields.securityThree;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/security_three", form);
  useDraftApplicationQuery("saq/security_three", form);
  const validateMutation = useValidateMutation(
    "saq/security_three",
    form,
    "security_four",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.security.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <TitleTooltip
              title={<h4>{messages.titles.enteredGoods}</h4>}
              info="Pentru a îndeplini criteriul menţionat la articolul 1953 alineatul (1) punctul 6) litera b) din Codul vamal, trebuie să se instituie măsuri de control al accesului adecvate pentru a preveni accesul neautorizat în zonele de expediere, pe cheiurile de încărcare şi în zonele de încărcare."
            />

            <WhiteSpace />

            <InputFormField {...securityThree.security_goods_procedure} />
            <InputFormField {...securityThree.checking_security_procedure} />
            <InputFormField {...securityThree.employee_security_measures} />
            <InputFormField {...securityThree.checking_goods_seals} />

            <BooleanRadioFormField
              radioField={securityThree.has_import_goods_sealed}
            />
            <ExtraTextareaFormField
              radioField={securityThree.has_types_of_goods}
              textareaField={securityThree.import_goods_sealed}
            />
            <WhiteSpace />

            <ExtraTextareaFormField
              radioField={securityThree.has_marking_goods}
              textareaField={securityThree.marking_goods}
            />
            <WhiteSpace />

            <InputFormField {...securityThree.counting_process} />
            <InputFormField {...securityThree.counting_process_register} />
            <BooleanRadioFormField
              radioField={securityThree.has_separated_responsible}
            />

            <ExtraTextareaFormField
              radioField={securityThree.has_internal_control}
              textareaField={securityThree.internal_control}
            />
            <WhiteSpace />
            <TitleTooltip
              title={<h4>{messages.titles.goodsStorage}</h4>}
              info="Această subsecţiune vizează numai depozitarea mărfurilor care fac parte dintr-un lanţ de aprovizionare internaţional."
            />

            <WhiteSpace />

            <InputFormField {...securityThree.allocated_storage_subdivision} />
            <InputFormField {...securityThree.assigning_storage_procedure} />

            <ExtraTextareaFormField
              radioField={securityThree.has_outdoor_storage}
              textareaField={securityThree.outdoor_storage}
            />
            <WhiteSpace />

            <ExtraTextareaFormField
              radioField={securityThree.has_inventorying_procedures}
              textareaField={securityThree.inventorying_procedures}
            />
            <WhiteSpace />

            <BooleanRadioFormField
              radioField={securityThree.has_stored_separate}
            />
            <InputFormField {...securityThree.criteria_separate_storage} />
            <InputFormField {...securityThree.register_accounting_goods} />
            <InputFormField {...securityThree.protected_goods_access} />
            <InputFormField {...securityThree.procedures_verified} />

            <ExtraTextareaFormField
              radioField={securityThree.has_third_party_storage}
              textareaField={securityThree.third_party_storage}
            />
            <WhiteSpace />
            <TitleTooltip
              title={<h4>{messages.titles.goodsProduction}</h4>}
              info="Această subsecţiune vizează exclusiv producţia de mărfuri care fac parte dintr-un lanţ de aprovizionare internaţional. Răspundeți la întrebările de la subpunctele 6.8.1. - 6.8.4. numai dacă se aplică pentru societatea dvs.Producţia în acest context poate include o gamă de activităţi începând de la fabricarea din materiile prime şi terminând cu asamblarea folosind piese cumpărate."
            />

            <WhiteSpace />

            <InputFormField {...securityThree.production_subdivisions} />
            <ExtraTextareaFormField
              radioField={securityThree.has_external_production}
              textareaField={securityThree.external_production}
            />
            <WhiteSpace />
            <ExtraTextareaFormField
              radioField={securityThree.has_protect_access_measures}
              textareaField={securityThree.protect_access_measures}
            />
            <WhiteSpace />

            <InputFormField {...securityThree.product_packaging_procedure} />
            <ExtraTextareaFormField
              radioField={securityThree.has_third_party_packaging}
              textareaField={securityThree.third_party_packaging}
            />
            <WhiteSpace />
            <h4>{messages.titles.goodsLoading}</h4>
            <WhiteSpace v="1rem" />
            <InputFormField {...securityThree.goods_loading} />
            <BooleanRadioFormField
              radioField={securityThree.has_loading_procedure}
            />
            <ExtraTextareaFormField
              radioField={securityThree.has_goods_sealed}
              textareaField={securityThree.goods_sealed}
            />
            <WhiteSpace />
            <BooleanRadioFormField
              radioField={securityThree.has_seals_register}
            />

            <InputFormField {...securityThree.seals_register} />
            <InputFormField {...securityThree.client_requirements} />
            <InputFormField {...securityThree.supervision_measures} />

            <ExtraTextareaFormField
              radioField={securityThree.has_checking_completion}
              textareaField={securityThree.checking_completion}
            />
            <WhiteSpace />

            <InputFormField {...securityThree.checking_coming_orders} />
            <InputFormField {...securityThree.loading_procedure} />

            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
