export interface Statistics {
  audit?: number;
  draft?: number;
  examination?: number;
  inactive?: number;
  pre_audit?: number;
  registered?: number;
  rejected?: number;
  suspended?: number;
  total?: number;
}

export interface RequestStatus {
  id?: number;
  name?: string;
  phone: string;
  reason?: string;
  suspension_period?: string;
  application?: number;
  image?: string;
}

export enum LogoStatus {
  APPROVE = "approve",
  SUSPEND = "suspend",
  RESUME = "resume",
  WITHDRAW = "withdraw",
}
