import React, { CSSProperties } from "react";

export interface FlexProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "wrap"> {
  align?: CSSProperties["alignItems"];
  justify?: CSSProperties["justifyContent"];
  direction?: CSSProperties["flexDirection"];
  wrap?: true | CSSProperties["flexWrap"];
}

export const Flex: React.FC<FlexProps> = ({
  align,
  justify,
  direction,
  children,
  wrap,
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justify,
        alignItems: align,
        flexDirection: direction,
        flexWrap: wrap === true ? "wrap" : wrap,
      }}
      {...props}
    >
      {children}
    </div>
  );
};
