import React from "react";
import { useMutation, useQueryClient } from "react-query";
import axios, { AxiosError, CancelTokenSource } from "axios";

import { useNotify, FormInstance } from "ebs-design";
import { NamePath } from "rc-field-form/es/interface";

import models from "@aeo/core/models";
import { assignProperty, getProperty } from "@aeo/core/utils";
import { AUTO_SAVE_TIMEOUT } from "@aeo/core/app-constants";
import { processFormValues, setFieldsErrors } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

import api, { querykeys } from "api";

export function useAutoSave(
  path: api.ApplicationValidatePath,
  form: FormInstance,
  pathPrefix: NamePath = [],
) {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { user } = useAuth();
  const applicationId = user?.organization?.active_application?.id || 0;

  const mutation = useMutation<unknown, AxiosError<models.ErrorResponse>, any>(
    async (values) => {
      return await api.application.patch(
        applicationId,
        assignProperty({}, processFormValues(values), ...path.split("/")),
        cancelTokenRef.current?.token,
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(querykeys.draftApplication, data);

        // Reset all previous errors if request doesn't return any errors
        const fieldsErrors = form.getFieldsError();
        const fieldsWithoutErrors = fieldsErrors.map((field) => ({
          ...field,
          errors: [],
        }));
        form.setFields(fieldsWithoutErrors);
      },
      onError: (error) => {
        if (error.response?.status === 400)
          setFieldsErrors(
            getProperty(error.response?.data, ...path.split("/")),
            form,
            notify,
            pathPrefix,
          );
      },
      onSettled: () => {
        /** ON HOLD */
        // queryClient.invalidateQueries(querykeys.draftApplication);
      },
    },
  );

  const timeoutRef = React.useRef<any>(0);
  const cancelTokenRef = React.useRef<CancelTokenSource>();

  return [
    (values: any) => {
      clearTimeout(timeoutRef.current);
      cancelTokenRef.current?.cancel();
      cancelTokenRef.current = axios.CancelToken.source();
      timeoutRef.current = setTimeout(() => {
        mutation.mutate(values);
      }, AUTO_SAVE_TIMEOUT);
    },
    mutation,
  ] as const;
}
