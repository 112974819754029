import { notApplicable } from "../../components/form-utils/utils";

export const economicalAgent = {
  name: {
    name: "name",
    label: "Precizați denumirea companiei solicitante.",
    placeholder: "Ex: Company name",
  },
  address: {
    name: "address",
    label: "Precizați adresa companiei solicitante.",
    placeholder: "Ex: or. Chişinău, str. Puşkin 22",
  },
  created: {
    name: "created",
    label: "Precizați data înființării companiei solicitante.",
    placeholder: "Selectează data",
  },
  reorganization: {
    name: "reorganization",
    label:
      "În cazul în care compania este înregistrată de mai puțin de trei ani, precizați dacă motivul este reorganizarea unei companii  existente anterior (de exemplu, înființarea sau vânzarea unei companii comerciale). În acest caz, oferiți detalii cu privire la reorganizare.",
  },
  juridic_form: {
    name: "juridic_form",
    label: "Precizați forma juridică de organizare a companiei solicitante.",
    placeholder: "Selectează forma",
  },
  site: {
    name: "site",
    label: "După caz, includeţi URL-ul site-ului web al companiei dvs.",
    placeholder: "Ex: https://www.example.com",
  },
  part_of_group: {
    name: "part_of_group",
    label: "Compania dvs. face parte dintr-un grup?",
    defaultDepsValue: [
      { detail_group: { true: null, false: notApplicable } },
      { license_group: null },
      { license_name_group: { true: null, false: notApplicable } },
      { license_audit: null },
      { license_audit_group: { true: null, false: notApplicable } },
    ],
  },
  detail_group: {
    name: "detail_group",
    label: "Oferiți o scurtă descriere a grupului.",
    dependencies: ["part_of_group"],
  },

  license_group: {
    name: "license_group",
    label:
      "a) Oricare alte entităţi ale grupului dețin deja o autorizație AEO?",
    dependencies: ["part_of_group"],
    defaultDepsValue: [
      { license_name_group: { true: null, false: notApplicable } },
    ],
  },

  license_name_group: {
    name: "license_name_group",
    label: "Indicaţi care.",
    dependencies: ["part_of_group", "license_group"],
  },

  license_audit: {
    name: "license_audit",
    label:
      "b) Oricare alte entităţi au depus o cerere pentru acordarea statutului de AEO şi în prezent fac obiectul unui audit AEO efectuat de către autoritatea vamală națională?",
    dependencies: ["part_of_group"],
    defaultDepsValue: [
      { license_audit_group: { true: null, false: notApplicable } },
    ],
  },

  license_audit_group: {
    name: "license_audit_group",
    label: "Indicaţi care.",
    dependencies: ["part_of_group", "license_audit"],
  },

  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
