import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  AttachmentsFormField,
  LoadingButton,
  InputFormField,
  BooleanRadioFormField,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import { placeOptions } from "@aeo/core/components/form-utils/utils";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableBooleanField } from "../NotApplicableBooleanField";

export const SecurityFourForm = () => {
  const securityFour = fields.securityFour;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/security_four", form);
  useDraftApplicationQuery("saq/security_four", form);
  const validateMutation = useValidateMutation(
    "saq/security_four",
    form,
    "/application/signing",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.security.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <WhiteSpace />

            <TitleTooltip
              title={<h4>{messages.titles.securityRequirements}</h4>}
              info="Pentru a îndeplini criteriul mentionat la articolul 1953  alineatul (1)  punctul 6) litera d) din Codul vamal, trebuie să luați măsuri care permit ca partenerii  comerciali să fie identificați cu precizie şi să vă asigurati că, prin punerea în aplicare a unor aranjamente contractuale adecvate sau a altor măsuri corespunzătoare în conformitate cu modelul  de afaceri, partenerii comerciali respectivi garantează securitatea părții care le corespunde din lanțul de aprovizionare internațional. Partenerii comerciali pot fi furnizori (de mărfuri sau servicii) sau clienţi."
            />

            <WhiteSpace />

            <InputFormField {...securityFour.verification_identity_partners} />
            <InputFormField {...securityFour.safety_partner_requirements} />
            <InputFormField {...securityFour.safety_partner_procedure} />

            <ExtraTextareaFormField
              radioField={securityFour.has_partner_breaches}
              textareaField={securityFour.partner_breaches}
            />
            <WhiteSpace />
            <TitleTooltip
              title={<h4>{messages.titles.staffSecurity}</h4>}
              info={`<p><b>Pentru a îndeplini criteriul mentionat la articolul 1953  alineatul (1)  punctul 6) literele e) și g) din Codul vamal, trebuie:</b></p><ul>
              <li> a)	să fi efectuat, în măsura în care legislaţia naţională permite acest lucru, o anchetă de securitate privind eventualii viitori angajaţi care ocupă posturi sensibile sub aspectul securităţii şi să efectuaţi un control al antecedentelor angajaţilor existenţi care ocupă astfel de posturi, în mod periodic şi atunci când circumstanţele justifică acest lucru;</li>
              <li>b)	să vă asiguraţi că angajaţii  cu responsabilităţi relevante pentru chestiuni de securitate participă în mod regulat la programe care vizează sensibilizarea lor faţă de aspectele de securitate respective;</li>
              </ul>`}
            />

            <WhiteSpace />
            <InputFormField {...securityFour.security_requirements} />
            <ExtraTextareaFormField
              radioField={securityFour.has_security_procedure}
              textareaField={securityFour.security_procedure}
            />
            <AttachmentsFormField
              {...securityFour.security_procedure_attachments}
            >
              Atașează documente
            </AttachmentsFormField>
            <WhiteSpace />

            <TitleTooltip
              title={securityFour.title_6_11_2.title}
              info={securityFour.title_6_11_2.info}
            />

            <WhiteSpace />

            <InputFormField {...securityFour.checking_new_employees} />
            <InputFormField {...securityFour.checking_old_employees} />
            <InputFormField {...securityFour.access_employees} />
            <ExtraTextareaFormField
              radioField={securityFour.has_safety_training}
              textareaField={securityFour.safety_training}
            />
            <WhiteSpace />
            <NotApplicableBooleanField
              {...securityFour.has_annual_course}
              reverse
            />
            <NotApplicableBooleanField
              {...securityFour.has_conducted_sessions}
              options={placeOptions}
              reverse
            />

            <NotApplicableBooleanField
              {...securityFour.has_sessions_register}
              reverse
            />

            <WhiteSpace />
            <InputFormField {...securityFour.fixed_term_employee} />
            <ExtraTextareaFormField
              radioField={securityFour.has_security_standards}
              textareaField={securityFour.security_standards}
            />
            <WhiteSpace />
            <BooleanRadioFormField
              radioField={securityFour.has_employee_instructions}
            />
            <TitleTooltip
              title={<h4>{messages.titles.contractedServices}</h4>}
              info={`<p><b>Articolul 1953  alineatul (1)  punctul 6) litera f) din Codul Vamal</b>, prevede ca  să dispuneţi de proceduri corespunzătoare în materie de securitate pentru prestatorii externi de servicii contractaţi. Astfel de furnizori de servicii pot include aspecte precum transportul, agenţi de pază, curăţenie şi întreţinere.</p>`}
            />

            <WhiteSpace />

            <ExtraTextareaFormField
              radioField={securityFour.has_contractual_services}
              textareaField={securityFour.contractual_services}
            />
            <WhiteSpace />
            <ExtraTextareaFormField
              radioField={securityFour.has_written_agreements}
              textareaField={securityFour.written_agreements}
            />
            <WhiteSpace />

            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
