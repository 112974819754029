import models from "@aeo/core/models";
import { Flex } from "@aeo/core/components";

import { AEOInfoModalTrigger } from ".";

interface Props {
  appType?: models.AEOType;
}
export const ChangeTypeButtons = ({ appType }: Props) => {
  return (
    <Flex wrap justify="center">
      {Object.values(models.AEOType)
        .filter((t) => t !== appType)
        .map((type, i) => (
          <AEOInfoModalTrigger type={type} key={i} />
        ))}
    </Flex>
  );
};
