import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { Button, Space } from "ebs-design";

import { makeBEM } from "@aeo/core/utils";
import { useRouter } from "@aeo/core/hooks";

import { ConfirmWithMPassLinkButton } from ".";

const bem = makeBEM("confirmation");

export const Confirmation = () => {
  const { push } = useRouter();

  const [queryParams] = useQueryParams({
    name: withDefault(StringParam, ""),
    idno: withDefault(StringParam, ""),
    admin: withDefault(StringParam, ""),
    email: withDefault(StringParam, ""),
    token: withDefault(StringParam, ""),
  });

  return (
    <div>
      <h2 className="m-24">Finalizare înregistrare</h2>
      <div className="divider"></div>
      <Space direction="vertical" className={bem("content")}>
        <h1 className="auth-layout__title m-30">AEO</h1>
        <h2 className="m-30">Confirmarea identitatii</h2>
        <div className={bem("content-body")}>
          <p>
            Confirmarea veridicității informațiilor introduse:
            <span className={bem("content-item")}>{queryParams.name}</span>
          </p>
          <p>
            Cod fiscal:
            <span className={bem("content-item")}>{queryParams.idno}</span>
          </p>
          <p>
            Depusă de solicitantul:
            <span className={bem("content-item")}>{queryParams.admin}</span>
          </p>
        </div>
      </Space>
      <Space justify="space-between" className={bem("footer")}>
        <Button type="ghost" onClick={() => push("/login")}>
          Anulează
        </Button>

        <ConfirmWithMPassLinkButton />
      </Space>
    </div>
  );
};
