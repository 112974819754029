import { Form, Radio } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { Option } from "ebs-design/dist/components/atoms/Radio/Radio";

import {
  booleanRadioOptions,
  booleansMap,
  normalizeRadioDepsValues,
  relativeClassName,
} from "@aeo/core/components/form-utils/utils";
import { FieldWithInfo } from "@aeo/core/components/form-utils/FieldWithInfo";
import { FormControl } from "@aeo/core/types";
import { xor, isStringOrBoolean, tryToNumber } from "@aeo/core/utils";
import models from "@aeo/core/models";

export interface DefaultDepsValue {
  [key: string]: string | string[] | boolean | null | undefined;
}
export interface NotApplicableBooleanFieldProps extends FormFieldProps {
  type?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  reverse?: boolean;
  options?: Option[];
  info?: React.ReactNode;
  defaultDepsValue?: models.DefaultDepsValue[];
}

export const NotApplicableBooleanField = ({
  rules = [],
  options = booleanRadioOptions,
  required,
  initialValue,
  dependencies,
  reverse = false,
  extra,
  info,
  className,
  defaultDepsValue,
  ...props
}: NotApplicableBooleanFieldProps) => {
  return (
    <Form.Field
      extra={<FieldWithInfo extra={extra} info={info} />}
      rules={[{ required }, ...rules]}
      {...{
        initialValue,
        dependencies,
      }}
      {...props}
      className={relativeClassName(info, "radio-field", className)}
    >
      {(_control, _, form) => {
        const { value, onChange } = _control as FormControl<boolean>;

        const disabled = !!xor(
          reverse,
          dependencies?.some((dep) => form.getFieldValue(dep)),
        );

        return (
          <Radio
            value={isStringOrBoolean(value) ? String(value) : undefined}
            options={
              disabled
                ? options.map((o) => ({ ...o, disabled: true }))
                : options
            }
            onChange={(v) => {
              if (typeof v === "string") {
                onChange(booleansMap[v]);
              }
              if (Array.isArray(defaultDepsValue)) {
                form.setFields(
                  defaultDepsValue
                    .map((dep) =>
                      Object.entries(dep).map(([key, value]) => ({
                        name: key.split(".").map(tryToNumber),
                        value: normalizeRadioDepsValues(value, v),
                      })),
                    )
                    .flat() || [],
                );
              }
            }}
          />
        );
      }}
    </Form.Field>
  );
};
