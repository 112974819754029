import { Button } from "ebs-design";

import models from "@aeo/core/models";
import { Flex, Icon, LoadingButton, WhiteSpace } from "@aeo/core/components";
import { formattedDate } from "@aeo/core/utils";
import { useDownloadFile, useModalState } from "@aeo/core/hooks";

import { RequestFormModal } from "..";
import { ChangeTypeButtons } from "./ChangeTypeButtons";

export interface AEOAuthorizationApprovedProps {
  activeApplication?: models.ActiveApplication;
}

export const AEOAuthorizationApproved = ({
  activeApplication: activeApp,
}: AEOAuthorizationApprovedProps) => {
  const logoModal = useModalState();
  const downloadFile = useDownloadFile();
  const logoAttachment: models.Attachment = {
    title: `Logo_nr_${activeApp?.logo?.id}_application_${activeApp?.id}`,
    file_upload: activeApp?.logo?.image_url,
  };

  const isLogoApproved =
    activeApp?.logo?.state === models.ApplicationLogoState.APPROVED;
  const isLogoReview =
    activeApp?.logo?.state === models.ApplicationLogoState.REVIEW;

  return (
    <>
      <h3 className="mx-auto text-center">
        Sunteți titular {activeApp?.type} autorizația {activeApp?.id} din{" "}
        {formattedDate(activeApp?.activation_date)}
      </h3>

      <WhiteSpace v="1rem" />

      <Flex wrap justify="center">
        {activeApp?.state === models.ApplicationState.active && (
          <ChangeTypeButtons appType={activeApp?.type} />
        )}
      </Flex>

      <WhiteSpace v="1rem" />

      <Flex wrap justify="center">
        {activeApp?.authorization && (
          <LoadingButton
            type="primary"
            prefix={<Icon type="download" />}
            onClick={() => downloadFile(activeApp?.authorization!)}
            className="view-authorization__download-btn ml-8 mr-8 mb-10"
          >
            Descarcă autorizația
          </LoadingButton>
        )}
        {isLogoApproved ? (
          <>
            <LoadingButton
              type="primary"
              prefix={<Icon type="download" />}
              onClick={() => downloadFile(logoAttachment, "image/svg+xml")}
              className="view-authorization__download-btn ml-8 mr-8 mb-10"
            >
              Descarcă logo
            </LoadingButton>
            <p className="mx-auto text-center">
              Sunteți în drept să utilizați Logo-ul AEO începând cu{" "}
              <span className="color-primary">
                {formattedDate(activeApp?.logo?.active_date)}
              </span>
            </p>
          </>
        ) : null}
      </Flex>

      {!activeApp?.logo && (
        <Button
          type="ghost"
          onClick={() => logoModal.open()}
          className="dashboard__authorization__request-logo mb-10"
        >
          Solicită Logo-ul AEO
        </Button>
      )}

      {isLogoReview && (
        <p className="mx-auto text-center">
          Cererea de solicitare a Logo-ului este în procesare
        </p>
      )}

      <RequestFormModal open={logoModal.isOpen} onClose={logoModal.close} />
    </>
  );
};
