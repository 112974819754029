import { AxiosResponse } from "axios";
import models from "@aeo/core/models";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "@aeo/core/app-constants";
import { authAxios } from "./axios";

export const clearLocalStorage = () => {
  // Remove tokens from local storage
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const authentication = {
  login: async (credentials: models.LoginCredentials) => {
    const { data: tokens } = await authAxios.post<
      models.LoginCredentials,
      AxiosResponse<models.TokensResponse>
    >(`/authentication/login`, credentials);

    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens?.refresh);
    tokens?.access && localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access);

    return tokens;
  },

  refresh: async (refresh: string) => {
    const { data: tokens } = await authAxios.post<models.TokensResponse>(
      `/authentication/refresh`,
      {
        refresh: refresh,
      },
    );

    tokens?.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens?.refresh);
    localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access || "");
  },

  verify: async () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (refreshToken) {
      try {
        await authAxios.post("/authentication/verify", {
          token: localStorage.getItem(ACCESS_TOKEN_KEY) || "",
        });
      } catch (e) {
        await authentication.refresh(refreshToken);
      }
    } else {
      clearLocalStorage();
      throw new Error("Refresh token is invalid, please login again!");
    }
  },
};
