import {
  InputFormField,
  Grid,
  BooleanRadioFormField,
} from "@aeo/core/components";
import { modeOptions } from "@aeo/core/components/form-utils/utils";
import fields from "@aeo/core/fields";
import { WithIndex } from "@aeo/core/types";

export const ClientFields = ({ index }: WithIndex) => {
  const client = fields.clientStatistic;
  return (
    <Grid gap="10px">
      <InputFormField {...client.name} name={[index, client.name.name]} />
      <BooleanRadioFormField
        options={modeOptions}
        radioField={{
          ...client.direct_mode,
          name: [index, client.direct_mode.name],
        }}
      />
    </Grid>
  );
};
