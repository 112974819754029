import { useUpdateEffect } from "react-use";
import { FormInstance } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { notApplicable } from "./utils";
import { FieldProps } from ".";

interface EffectProps {
  form: FormInstance;
  onChange: React.Dispatch<string | null>;
  type?: string;
  required?: boolean;
  textarea?: boolean;
  placeholder?: string;
  radioField: FormFieldProps;
  textareaField: FieldProps;
}

export const Effect = ({ form, onChange, radioField }: EffectProps) => {
  useUpdateEffect(() => {
    const radioValue = form.getFieldValue(radioField.name || []);

    onChange(
      typeof radioValue === "boolean" && !radioValue ? notApplicable : null,
    );
  }, [form.getFieldValue(radioField.name || [])]);

  return <></>;
};
