import { useEffect } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { GenericObject } from "@aeo/core/types";

interface UseRedirectToNoteOptions {
  data?: GenericObject;
}

export const useRedirectToNote = ({ data }: UseRedirectToNoteOptions) => {
  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();

  useEffect(() => {
    const hash = location.hash;
    if (!data || !hash) return;
    const idHash = +hash.replace("#", "");

    const res =
      Object.entries(data)
        .map(([key, obj]) => {
          const recursiveSearch = (obj: GenericObject): any => {
            if (typeof obj !== "object" || Array.isArray(obj) || obj === null)
              return false;

            return Object.entries(obj).find(([_, val]) => {
              if (!val?.note && typeof val === "object") {
                return recursiveSearch(val);
              }
              return val.note?.id === idHash;
            });
          };

          const result = recursiveSearch(obj)
            ? [key, ...recursiveSearch(obj)]
            : undefined;
          return result;
        })
        .find((v) => v)
        ?.filter((v) => typeof v === "string")
        ?.join("/") + `/${hash}`;

    history.replace(`${url}/${res}`);
  }, [data, history, location.hash, location.pathname, url]);
};
