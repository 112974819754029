import { OptionValue } from "ebs-design/dist/components/molecules/Select/interfaces";
import { isPlainObject } from "./object";

export const controlSelect = (control: any, data: any) => {
  if (isPlainObject(control.value) && control.value.id) {
    control.selected = {
      value: control.value.id,
      text: control.value.name,
    };
    control.value = control.value.id;
  } else if (Array.isArray(control.value)) {
    // Handle array values
    // FIXME: Select doesn't support multiple values on selected value
    control.selected = control.value.map((value: any) => ({
      value: value.id,
      text: value.name,
    }));

    control.value = control.value.map((v: any) =>
      isPlainObject(v) ? v.id : v,
    );
  }

  const onChange = (value: OptionValue | OptionValue[]) => {
    const values = Array.isArray(value)
      ? data.filter((d: any) => value.includes(d.id!))
      : data.find((d: any) => d.id === value);

    return control.onChange(values);
  };

  return { ...control, onChange };
};
