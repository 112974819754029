import { KeyValueProps } from "../types";
import { xor } from "../utils";

import { useGetFieldValue } from "./useGetFieldValue";

export function useNAField(props: KeyValueProps) {
  /**
   * OLD
   */
  // const triggerValue = useFieldValue(props.dependencies?.[0]);

  /**
   * WARNING 'Possible' breaking changes, replace with the above if you think this is the issue
   */
  const getValue = useGetFieldValue();
  const triggerValue = !!props.dependencies?.every((dep) =>
    getValue({ name: dep }),
  );

  return !!xor(props.NA && !triggerValue, props.reverseNA);
}
