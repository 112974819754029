import { CSSProperties } from "react";

export type GenericObject<T = any> = { [key in string | number | symbol]: T };

export type DynamicCssProperties = CSSProperties & GenericObject;

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export interface FormControl<T> {
  value: T | undefined;
  onChange: React.Dispatch<T | undefined>;
}

export interface WithIndex {
  index: number;
}

export type ApplicationExaminationStateType =
  | "pre_audit"
  | "audit"
  | "re-audit";

export enum Header {
  ContentType = "content-type",
}

export enum MIME {
  json = "application/json",
}
