import { Item } from "./Item";
import { SubItemList } from "./SubItemList";
import { SubItem } from "./SubItem";
import { Content } from "./Content";

export const SAQTabs = {
  Content,
  Item,
  SubItemList,
  SubItem,
};
