import { useMutation } from "react-query";
import { Form, useForm, useNotify } from "ebs-design";

import { makeBEM, notifyErrors } from "@aeo/core/utils";
import { InputFormField } from "@aeo/core/components/form-utils";
import { AlertErrors, LoadingButton } from "@aeo/core/components";
import messages from "@aeo/core/messages";

import api from "api";

const bem = makeBEM("auth-layout");

export const RecoveryPasswordForm = () => {
  const [form] = useForm();
  const notify = useNotify();

  const { mutate, isLoading, error } = useMutation(api.auth.recoveryPassword, {
    onSuccess: (res) => {
      notify.success({
        title: res.message || "Resetarea parolei a fost trimisă cu succes",
      });
      form.resetFields();
    },
    onError: (error) => notifyErrors(notify, error),
  });

  return (
    <Form form={form} onFinish={(values) => mutate(values)}>
      <InputFormField
        name="username"
        placeholder="E-mail"
        type="email"
        rules={[
          {
            required: true,
            message: messages.validations.requiredEmail,
          },
        ]}
      />

      <LoadingButton
        size="large"
        className={bem("form__btn")}
        type="primary"
        submit
        loading={isLoading}
      >
        Trimite
      </LoadingButton>
      <AlertErrors error={error} />
    </Form>
  );
};
