import { AxiosTransformer } from "axios";
import queryString from "query-string";

import { GenericObject, Header, MIME } from "@aeo/core/types";

export const stringifyUrl = (url: string, query: GenericObject = {}): string =>
  queryString.stringifyUrl(
    {
      url,
      query,
    },
    { skipEmptyString: true, skipNull: true },
  );

/**
 * This is needed when you have `responseType: "arraybuffer"` on axios request, but the
 * error response type is json
 */
export const jsonFromBufferTransformer: AxiosTransformer = (data, headers) => {
  if (headers?.[Header.ContentType] === MIME.json) {
    return JSON.parse(new TextDecoder().decode(data));
  }
  return data;
};
