import { useState } from "react";
import { useMutation } from "react-query";
import { Alert, Form, useForm, useNotify } from "ebs-design";

import {
  confirmPasswordValidatorRule,
  passwordValidatorRule,
} from "@aeo/core/utils/validators";
import {
  InputPhoneFormField,
  InputPasswordShowField,
  InputFormField,
  InputIDNPFormField,
} from "@aeo/core/components/form-utils";

import models from "@aeo/core/models";
import { makeBEM, notifyErrors } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import {
  AlertErrors,
  LoadingButton,
  NomenclaturesSelect,
} from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useAuth } from "@aeo/core/hooks";

const bem = makeBEM("auth-layout");

export const RegisterForm = () => {
  const [showAlert, setShowAlert] = useState(false);
  const auth = useAuth();
  const [form] = useForm();
  const notify = useNotify();

  const registerMutation = useMutation(auth.register, {
    onSuccess: () => {
      setShowAlert(true);
      form.resetFields();
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const submitHandler = (values: models.RegisterCredentials) => {
    const data = {
      organization: values.organization,
      first_name: values.organization.admin_first_name,
      last_name: values.organization.admin_last_name,
      idnp: values.idnp,
      username: values.username,
      phone: values.phone,
      password: values.password,
    };
    registerMutation.mutate(data);
  };

  return (
    <>
      <AlertErrors error={registerMutation.error} alertsClassName="my-15" />

      <Form form={form} onFinish={submitHandler} autoComplete="off">
        <input name="phone" className="d-n" type="text" />
        <input name="password" className="d-n" type="password" />
        <InputIDNPFormField
          name={["organization", "idno"]}
          placeholder="IDNO"
          rules={[
            {
              min: 13,
              max: 13,
              required: true,
              message: messages.validations.IDNO,
            },
          ]}
        />
        <InputFormField
          name={["organization", "name"]}
          placeholder="Nume companie"
          rules={[
            {
              required: true,
              message: messages.validations.requiredCompanyName,
            },
          ]}
        />

        <NomenclaturesSelect
          entityName={models.Nomenclatures.Juridics}
          name={["organization", "juridic_form"]}
          placeholder={fields.request.juridic_form.label}
          labelProp="full_name"
          keepPreviousData={false}
        />

        <InputFormField
          name={["organization", "admin_last_name"]}
          placeholder="Prenume administrator"
        />
        <InputFormField
          name={["organization", "admin_first_name"]}
          placeholder="Nume administrator"
        />
        <InputIDNPFormField
          name="idnp"
          placeholder="IDNP"
          rules={[
            {
              min: 13,
              max: 13,
              required: true,
              message: messages.validations.IDNP,
            },
          ]}
        />
        <InputFormField
          name="username"
          rules={[
            {
              required: true,
              message: messages.validations.requiredEmail,
            },
          ]}
          type="email"
          placeholder="E-mail"
        />
        <InputPhoneFormField
          name="phone"
          required
          message={messages.validations.requiredPhone}
        />
        <InputPasswordShowField
          size="large"
          placeholder="Parola"
          name="password"
          validateTrigger="onSubmit"
          rules={[
            {
              validateTrigger: "onSubmit",
            },
            passwordValidatorRule(),
          ]}
        />
        <InputPasswordShowField
          size="large"
          placeholder="Confirmă parola"
          name="confirm-password"
          validateTrigger="onSubmit"
          rules={[
            {
              validateTrigger: "onSubmit",
            },
            confirmPasswordValidatorRule(form),
          ]}
        />
        <LoadingButton
          size="large"
          className={bem("form__btn")}
          type="primary"
          submit
          loading={registerMutation.isLoading}
        >
          Finalizează înregistrarea
        </LoadingButton>
        {showAlert && (
          <Alert closable={false} outlined type="info">
            Va rugam să verificați poșta electronică pentru a continua
            înregistrarea.
          </Alert>
        )}
      </Form>
    </>
  );
};
