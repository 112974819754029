import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  AttachmentsFormField,
  LoadingButton,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";
import models from "@aeo/core/models";
import { useAuth } from "@aeo/core/hooks";

import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { NotApplicableFormList } from "../NotApplicableFormList";

export const FinancialSolvencyForm = () => {
  const solvency = fields.financialSolvency;
  const { user } = useAuth();
  const [form] = useForm();

  const handleNextStep = () => {
    return user?.organization?.active_application?.type === models.AEOType.AEOS
      ? "security_one"
      : "standard";
  };

  const [save, saveMutation] = useAutoSave("saq/solvency", form);
  useDraftApplicationQuery("saq/solvency", form);
  const validateMutation = useValidateMutation(
    "saq/solvency",
    form,
    handleNextStep(),
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              info={messages.SAQTabs.solvency.info}
              title={messages.SAQTabs.solvency.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <ExtraTextareaFormField
              radioField={solvency.has_insolvency_proceedings}
              textareaField={solvency.insolvency_proceedings}
            />

            <ExtraTextareaFormField
              radioField={solvency.good_financial_situation}
              textareaField={solvency.financial_situation}
            />

            <NotApplicableFormList
              dependencies={[solvency.good_financial_situation.name]}
              name={solvency.financial_situation_attachments.name}
            >
              <AttachmentsFormField
                dependencies={[solvency.good_financial_situation.name]}
                {...solvency.financial_situation_attachments}
              >
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <AttachmentsFormField {...solvency.actual_financial_reports}>
              Atașează documente
            </AttachmentsFormField>

            <ExtraTextareaFormField
              radioField={solvency.has_problems_solvency}
              textareaField={solvency.problems_solvency}
            />

            <WhiteSpace />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
