import { useCallback, useMemo } from "react";
import { CKEditor } from "ckeditor4-react";
import React from "react";
import { editorRemoveButtons, editorToolbarGroups } from "./configs";

interface EditorProps {
  height?: number;
  value?: string;
  onChange?: (content: string) => void;
  templateData?: any;
}

export const Editor = ({
  height = 350,
  value,
  onChange = () => null,
}: EditorProps) => {
  const onBeforeLoad = useCallback((CKEDITOR) => {
    CKEDITOR.disableAutoInline = true;
  }, []);

  const config = useMemo(
    () => ({
      removeButtons: editorRemoveButtons,
      height,
      language: "ro",
      allowedContent: true,
      toolbarGroups: editorToolbarGroups,
      extraPlugins:
        "justify,clipboard,lineutils,basicstyles,copyformatting,font",
      enterMode: 2,
      autoParagraph: false,
      versionCheck: false,
    }),
    [height],
  );

  return (
    <CKEditor
      onBeforeLoad={onBeforeLoad}
      initData={value}
      config={config}
      onChange={(event) => onChange(event.editor.document.getBody().getHtml())}
    />
  );
};
