import React from "react";

export function useModalState<Data>(initialData?: Data) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState<Data | undefined>(initialData);

  return React.useMemo(
    () => ({
      isOpen,
      data,
      open: () => setIsOpen(true),
      openWith: (value: React.SetStateAction<Data | undefined>) => {
        setData(value);
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
        setData(undefined);
      },
      reset: () => {
        setIsOpen(false);
        setData(undefined);
      },
    }),
    [isOpen, data],
  );
}
