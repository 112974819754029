import { Link } from "react-router-dom";
import { Auth } from "@aeo/core/components/auth";
import { LoginForm } from "../components";

export const Login = () => {
  return (
    <Auth
      title="AEO"
      subTitle="Panoul de administrare"
      footer={
        <h4 className="fw-500">
          Nu ai cont?
          <span>
            <Link to="/register" className="auth-layout__link">
              Înregistrează-te.
            </Link>
          </span>
        </h4>
      }
    >
      <LoginForm />
    </Auth>
  );
};
