import { GenericObject } from "types";

export const omitKeys = (obj: GenericObject, keys: string[]): GenericObject => {
  const updatedObj = { ...obj };
  for (const n of keys) delete updatedObj[n];
  return updatedObj;
};

export const typedOmitKeys = <T extends object, TKeys extends keyof T>(
  obj: T,
  keys: TKeys[],
): Omit<T, TKeys> => {
  const updatedObj = { ...obj };
  for (const key of keys) delete updatedObj[key];
  return updatedObj;
};

export const typedPickKeys = <T extends object, TKeys extends keyof T>(
  obj: T,
  keys: TKeys[],
): Pick<T, TKeys> => {
  const picked: Partial<Pick<T, TKeys>> = {};
  for (const key of keys) picked[key] = obj[key];
  return picked as Pick<T, TKeys>;
};

export const falsyToUndefined = (obj: any): any =>
  obj instanceof Array
    ? obj.map((i) => falsyToUndefined(i))
    : obj instanceof Date
    ? obj
    : obj instanceof Object
    ? Object.fromEntries(
        Object.entries(obj).map((e) => [e[0], falsyToUndefined(e[1])]),
      )
    : obj || undefined;

export function getProperty<TReturn = any>(
  obj: any,
  ...args: (string | number | symbol)[]
): TReturn | undefined {
  for (let i = 0; i < args.length; i++) {
    if (obj instanceof Object && obj.hasOwnProperty(args[i])) {
      obj = obj[args[i]];
    } else return undefined;
  }
  return obj as TReturn;
}

export function assignProperty(
  obj: GenericObject,
  value: any,
  ...args: (string | number | symbol)[]
): any {
  let current = obj;
  let i = 0;
  for (; i < args.length - 1; i++) {
    current = current[args[i]] = {};
  }
  if (args.length) {
    current[args[i]] = value;
  }
  return obj;
}

export function isPlainObject(obj: GenericObject): boolean {
  return (
    obj instanceof Object && Object.getPrototypeOf(obj) == Object.prototype
  );
}

export const transformDataToProgress = (data: any) => {
  if (!data) return;

  const countCompletedAndTotalQuestions = (data: any) => {
    delete data.value;

    if (typeof data === "object" && data.hasOwnProperty("confirmed")) {
      return {
        completed: data.confirmed === true ? 1 : 0,
        notCompleted: data.confirmed === false ? 1 : 0,
        total: 1,
      };
    }

    let temp: (number | null)[] = Object.values(data || {}).map((item: any) => {
      return typeof item?.confirmed === "boolean"
        ? Number(item?.confirmed)
        : null;
    });

    return {
      completed: temp.filter((i) => i === 1).length,
      notCompleted: temp.filter((i) => i === 0).length,
      total: temp.length,
    };
  };

  const requestProgress = countCompletedAndTotalQuestions(data.request);
  let saqProgress = {};

  for (const [key, val] of Object.entries(data.saq || {})) {
    Object.assign(saqProgress, {
      [key]: countCompletedAndTotalQuestions(data.saq[key] || {}),
    });
  }

  return {
    request: requestProgress,
    saq: saqProgress,
  };
};
