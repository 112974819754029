import { Form, Radio } from "ebs-design";

import { isStringOrBoolean, tryToNumber } from "@aeo/core/utils";

import { RadioProps } from "..";
import {
  booleanRadioOptions,
  booleansMap,
  normalizeRadioDepsValues,
  relativeClassName,
} from "./utils";
import { FieldWithInfo } from "./FieldWithInfo";

export const BooleanRadioFormField = ({
  options = booleanRadioOptions,
  radioField,
  rules = [],
  required,
  initialValue,
  textareaName,
  extra,
  info,
  className,
  nullable,
  ...props
}: RadioProps) => {
  const valueWhenCleared = nullable ? null : undefined;

  return (
    <Form.Field
      extra={<FieldWithInfo extra={extra} info={info || radioField?.info} />}
      className={relativeClassName(
        info || radioField?.info,
        "radio-field",
        className,
      )}
      rules={[{ required }, ...rules]}
      {...{
        initialValue,
      }}
      {...radioField}
      {...props}
    >
      {(control, _, form) => {
        return (
          <Radio
            value={
              isStringOrBoolean(control.value)
                ? String(control.value)
                : ''
            }
            options={options}
            onChange={(v) => {
              if (typeof v === "string") {
                control.onChange(booleansMap[v]);
              } else if (!required && !v) {
                control.onChange(valueWhenCleared);
              }

              if (Array.isArray(radioField?.defaultDepsValue)) {
                form.setFields(
                  radioField?.defaultDepsValue
                    .map((dep) =>
                      Object.entries(dep).map(([key, value]) => ({
                        name: key.split(".").map(tryToNumber),
                        value: normalizeRadioDepsValues(value, v),
                      })),
                    )
                    .flat() || [],
                );
              }
            }}
          />
        );
      }}
    </Form.Field>
  );
};
