import { Grid, InputFormField } from "@aeo/core/components";

import fields from "@aeo/core/fields";
import { WithIndex } from "@aeo/core/types";
import { NotApplicableIDNOFormField } from "../../NotApplicableIDNOFormField";

export const OutsourseOrgFormFields = ({ index }: WithIndex) => {
  const outsourceOrganization = fields.outsourceOrganization;
  return (
    <>
      <Grid cols="1fr 1fr 1fr" gap="20px">
        <InputFormField
          {...outsourceOrganization.name}
          name={[index, ...outsourceOrganization.name.name]}
        />
        <InputFormField
          {...outsourceOrganization.address}
          name={[index, ...outsourceOrganization.address.name]}
        />
        <NotApplicableIDNOFormField
          {...outsourceOrganization.code_fiscal}
          name={[index, ...outsourceOrganization.code_fiscal.name]}
        />
      </Grid>
      <InputFormField
        {...outsourceOrganization.description}
        name={[index, ...outsourceOrganization.description.name]}
      />
    </>
  );
};
