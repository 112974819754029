/* eslint-disable no-template-curly-in-string */
import { Form } from "ebs-design";

const typeTemplate = "${label} nu conține tipul corect (${type})";
export const FormValidateMessages = {
  default: "Eroare la validarea câmpului ${label}",
  required: "Acest câmp este obligatoriu",
  enum: "${label} trebuie să fie una din valorile [${enum}]",
  whitespace: "${label} nu poate fi gol",
  date: {
    format: "${label} - data nu este în formatul corect",
    parse: "${label} nu poate fi convertit la o dată",
    invalid: "${label} este o dată invalidă",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "${label} trebuie să conțină ${len} caractere",
    min: "${label} trebuie să conțină cel puțin ${min} caractere",
    max: "${label} trebuie să conțină cel mult ${max} caractere",
    range: "${label} trebuie să conțină între ${min}-${max} caractere",
  },
  number: {
    len: "${label} trebuie să conțină ${len} cifre",
    min: "${label} trebuie să fie minim ${min}",
    max: "${label} trebuie să fie maxim ${max}",
    range: "${label} trebuie să fie între ${min}-${max}",
  },
  array: {
    len: "${label} trebuie să conțină ${len} elemente",
    min: "${label} trebuie să conțină cel puțin ${min} elemente",
    max: "${label} trebuie să conțină cel mult ${max} elemente",
    range: "${label} trebuie să conțină între ${min}-${max} elemente",
  },
  pattern: {
    mismatch: "${label} nu respectă șablonul ${pattern}",
  },
};

export function initFormLocale() {
  if (!Form.defaultProps) Form.defaultProps = {};
  Form.defaultProps.validateMessages = FormValidateMessages;
}
