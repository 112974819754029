import { makeBEM } from "@aeo/core/utils";

const bem = makeBEM("dashboard__row-4__news");

export const NewsItem = () => {
  return (
    <div className={bem("item")}>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={bem("item__date")}>20.05.21</p>
    </div>
  );
};
