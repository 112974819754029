import { Card } from "ebs-design";
import { NamePath } from "rc-field-form/es/interface";

import { KeyValueProps } from "@aeo/core/types";
import { KeyValueRow } from "@aeo/core/components";
import { makeNamePath } from "@aeo/core/utils";
import { useFieldValue } from "@aeo/core/hooks";
import { NoteForm } from "./NoteForm";

export interface KeyValueCardCardProps extends KeyValueProps {
  keyList?: React.ReactNode;
  customPathName?: NamePath;
}

export const KeyValueCard = ({
  name,
  keyList,
  customPathName,
  ...props
}: KeyValueCardCardProps) => {
  const value = useFieldValue(name);

  return (
    <Card className="bordered mb-10" id={value?.note?.id || ""}>
      <Card.Body className="p-0">
        {keyList ?? (
          <div className="px-10">
            <KeyValueRow
              name={
                !!customPathName ? customPathName : makeNamePath(name, "value")
              }
              {...props}
            />
          </div>
        )}
        <div className="px-10">
          <NoteForm note={value?.note} />
        </div>
      </Card.Body>
    </Card>
  );
};
