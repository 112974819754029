import { AxiosResponse, CancelToken } from "axios";

import models from "@aeo/core/models";
import {
  ApplicationExaminationStateType,
  GenericObject,
} from "@aeo/core/types";
import { jsonFromBufferTransformer, stringifyUrl } from "@aeo/core/api/utils";
import { apiAxios } from "@aeo/core/api/axios";

export type ApplicationValidatePath = "request" | `saq/${models.SAQTabKey}`;

export const application = {
  downgrade: async (id: number, values?: { type: models.AEOType }) => {
    const { data } = await apiAxios.patch(
      `/aeo/applications/transform/${id}/downgrade`,
      values,
    );
    return data;
  },
  upgrade: async (id: number, values?: { type: models.AEOType }) => {
    const { data } = await apiAxios.patch(
      `/aeo/applications/transform/${id}/upgrade`,
      values,
    );
    return data;
  },
  createDraft: async (type: models.AEOType) => {
    const { data } = await apiAxios.post<
      { type: models.AEOType },
      AxiosResponse<{ id?: number }>
    >(`/aeo/applications/draft`, { type });

    return data;
  },
  patch: async (id: number, application: any, cancelToken?: CancelToken) => {
    const { data } = await apiAxios.patch(
      `/aeo/applications/register/${id}`,
      application,
      { cancelToken },
    );
    return data;
  },
  validate: async (path: ApplicationValidatePath, what: any) => {
    const { data } = await apiAxios.post(
      `/aeo/applications/validate/${path} `,
      what,
    );
    return data;
  },
  get: async (query: GenericObject = {}) => {
    const url = stringifyUrl(`/aeo/applications`, query);
    const { data } = await apiAxios.get(url);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<models.ActiveApplication>(
      `/aeo/applications/${id}`,
    );
    return data;
  },
  getDocument: async (id: number) => {
    const { data } = await apiAxios.get<string>(
      `/aeo/applications/${id}/document`,
      {
        responseType: "blob",
        transformResponse: [
          (data) => {
            let blob = new window.Blob([data], { type: "application/pdf" });
            return window.URL.createObjectURL(blob);
          },
        ],
      },
    );

    return data;
  },
  delete: async (id?: string) => {
    const url = stringifyUrl(`/aeo/applications/draft/${id}`);
    await apiAxios.delete(url);
  },
  sign: async (id?: number, bodyData?: { type?: string }) => {
    const { data } = await apiAxios.post(
      `/aeo/applications/draft/${id}/sign`,
      bodyData,
    );
    return data;
  },
  resign: async (id?: number, bodyData?: { type?: string }) => {
    const { data } = await apiAxios.post(
      `/aeo/applications/pre-audit/${id}/resign`,
      bodyData,
    );
    return data;
  },
  updateType: async (id: number, type: { type: string }) => {
    const { data } = await apiAxios.patch(
      `/aeo/applications/draft/${id}`,
      type,
    );
    return data;
  },
  getLogo: async (id: number) => {
    const { data } = await apiAxios.get<models.RequestStatus>(
      `/aeo/applications/logo/${id}`,
    );
    return data;
  },
  patchLogo: async (
    id: number,
    values: { type: string; body: models.RequestStatus },
  ) => {
    const { data } = await apiAxios.patch<models.RequestStatus>(
      `/aeo/applications/logo/${id}/${values.type}`,
      values.body,
    );
    return data;
  },
  postLogo: async (body: models.RequestStatus) => {
    const { data } = await apiAxios.post<models.RequestStatus>(
      "/aeo/applications/logo",
      body,
    );
    return data;
  },
  patchState: async (
    id: number,
    body: models.RequestStatus,
    entity: string,
  ) => {
    const { data } = await apiAxios.patch(
      `/aeo/applications/${id}/${entity}`,
      body,
    );
    return data;
  },
  getNotes: async (id: number) => {
    const { data } = await apiAxios.get(`/aeo/applications/${id}/notes`);
    return data;
  },
  patchNote: async (id: number, body: models.NoteResponse) => {
    const { data } = await apiAxios.patch(
      `/aeo/applications/${id}/notes`,
      body,
    );
    return data;
  },
  getApprovals: async (id: number, query: GenericObject = {}) => {
    const url = stringifyUrl(`/aeo/applications/${id}/approvals`, query);
    const { data } = await apiAxios.get<models.WithResults<models.AeoApproval>>(
      url,
    );

    return data;
  },
  approvalResponse: async (
    id?: number,
    state?: models.ApprovalDocumentType,
    body?: models.ContentEntityWithAttachment,
  ) => {
    const { data } = await apiAxios.patch(
      `/aeo/approvals/${id}/${state}/approve`,
      body,
    );
    return data;
  },
  getRequest: async (id: number, query: GenericObject = {}) => {
    const url = stringifyUrl(`/aeo/applications/${id}/requests`, query);
    const { data } = await apiAxios.get<models.WithResults<models.AeoApproval>>(
      url,
    );

    return data;
  },
  download: async (url: string): Promise<Blob> => {
    const { data } = await apiAxios.get(url, {
      responseType: "blob",
    });

    return data;
  },
  getRecommendations: async (
    id: number,
    type: ApplicationExaminationStateType,
  ) => {
    const { data } = await apiAxios.get<
      models.Recommendation[] | models.ReAuditRecommendation[]
    >(`/aeo/applications/${id}/${type}/recommendations`);
    return data;
  },
  patchRecommendation: async (
    id: number,
    type: ApplicationExaminationStateType,
    values?: models.Recommendation,
  ) => {
    const { data } = await apiAxios.patch(
      `/aeo/applications/${id}/${type}/recommendations`,
      values,
    );
    return data;
  },
  signRecommendation: async (id: number) => {
    const { data } = await apiAxios.post(
      `/aeo/applications/${id}/recommendation/sign`,
    );
    return data;
  },
  getRecommendationDocument: async (id: number) => {
    const { data } = await apiAxios.get<string>(
      `/aeo/applications/${id}/recommendation/document`,
      {
        responseType: "blob",
        transformResponse: [
          (data) => {
            let blob = new window.Blob([data], { type: "application/pdf" });
            return window.URL.createObjectURL(blob);
          },
        ],
      },
    );

    return data;
  },

  requestAction: async (id: number, body: models.ContentEntity) => {
    const { data } = await apiAxios.post(
      `/aeo/applications/${id}/request`,
      body,
    );
    return data;
  },

  requestActionPreview: async (id: number, body: models.ContentEntity) => {
    const { data } = await apiAxios.post<
      models.ContentEntityWithHeader,
      AxiosResponse<ArrayBuffer>
    >(`/aeo/applications/${id}/request/preview`, body, {
      responseType: "arraybuffer",
      transformResponse: jsonFromBufferTransformer,
    });

    return data;
  },
};
