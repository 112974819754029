import { Redirect } from "react-router-dom";

import { TabsWithRoute } from "@aeo/core/components/TabsWithRoute";
import { applicationsTabs } from "features/application/config";
import models from "@aeo/core/models";
import { useAuth } from "@aeo/core/hooks";

export const Application = () => {
  const { user } = useAuth();

  if (
    user?.organization?.active_application?.state !==
    models.ApplicationState.draft
  ) {
    return <Redirect to="/" />;
  }

  return <TabsWithRoute tabItems={applicationsTabs} />;
};
