import { AvatarInline, Button, Space } from "ebs-design";
import { Icon } from "@aeo/core/components/icons";
import { Dropdown } from "@aeo/core/components";
import { Notifications } from "@aeo/core/components";
import { getFullName } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

import { TopBarTooltip } from "../TopBarTooltip";

export const AppLayoutTopbar = () => {
  const { user } = useAuth();

  return (
    <div className="top-bar">
      <Space align="end" justify="end" size="small">
        <Notifications count={user?.unread_notifications} />
        <AvatarInline circle alt={getFullName(user)} size="small" />
        <Dropdown>
          <Button
            type="text"
            size="small"
            icon={() => <Icon type="down-chevrone" />}
          />
          <Dropdown.Content position="right" cover={false}>
            <section>
              <TopBarTooltip />
            </section>
          </Dropdown.Content>
        </Dropdown>
      </Space>
    </div>
  );
};
