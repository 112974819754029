import $axios from "axios";

import { API_POST_CONTENT_TYPE } from "@aeo/core/app-constants";

import {
  authInterceptor,
  cancelInterceptors,
  redirectInterceptor,
  createRefreshTokenInterceptors,
} from "./interceptors";

export const apiAxios = $axios.create();

if (!apiAxios.defaults.headers) apiAxios.defaults.headers = {};
apiAxios.defaults.headers["Content-Type"] = API_POST_CONTENT_TYPE;
apiAxios.defaults.headers["Accept-Language"] = "ro";
if (process.env.REACT_APP_API_SERVICE_TOKEN)
  apiAxios.defaults.headers["Service-Token"] =
    process.env.REACT_APP_API_SERVICE_TOKEN;
apiAxios.defaults.baseURL = process.env.REACT_APP_BASE_API;

apiAxios.interceptors.request.use(authInterceptor);
apiAxios.interceptors.response.use(redirectInterceptor);
apiAxios.interceptors.response.use(...cancelInterceptors);
