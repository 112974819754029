import { Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { xor } from "@aeo/core/utils";

export interface NotApplicableFormListProps extends FormFieldProps {
  placeholder?: string;
  reverse?: boolean;
  children: JSX.Element;
  resetFields?: boolean;
}

export const NotApplicableFormList = ({
  dependencies,
  name,
  children,
  initialValue,
  reverse,
  resetFields,
  ...props
}: NotApplicableFormListProps) => {
  return (
    <Form.Field
      dependencies={dependencies}
      initialValue={initialValue}
      {...props}
    >
      {(_, __, form) => {
        const errors = form.getFieldError(name as string);

        const depsHaveValue = !!xor(
          reverse,
          dependencies?.some((dep) => form.getFieldValue(dep)),
        );

        return (
          <>
            <Form.Field name={name} />
            {depsHaveValue ? (
              <>
                {errors ? <p className="color-danger mb-10">{errors}</p> : null}
                {children}
              </>
            ) : null}
          </>
        );
      }}
    </Form.Field>
  );
};
