import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useUpdateEffect } from "react-use";
import { Button, useNotify } from "ebs-design";
import { Option } from "ebs-design/dist/components/molecules/Select/interfaces";
import { ArrayParam, useQueryParams } from "use-query-params";

import {
  WhiteSpace,
  QueryTable,
  TableTitle,
  ChoiceFilter,
  Filters,
} from "@aeo/core/components";
import {
  useAuth,
  useModalState,
  useOrderingQueryParam,
  useQueryPagination,
} from "@aeo/core/hooks";
import {
  aeoUserRolesOptions,
  forwardSetState,
  notifyErrors,
} from "@aeo/core/utils";
import models from "@aeo/core/models";
import messages from "@aeo/core/messages";

import api, { querykeys } from "api";
import { orderingOptions, usersTableColumns } from "../config";
import { DeleteUserModal, UserFormModal } from "../components";

const userBlockChoise: { [key: string]: string } = {
  true: "Blocat",
  false: "Neblocat",
};

export const Users = () => {
  const [search, setSearch] = React.useState("");
  const notify = useNotify();
  const { user } = useAuth();

  const pagination = useQueryPagination();
  const queryClient = useQueryClient();

  const userModal = useModalState<models.User>();
  const deleteModal = useModalState<models.User>();

  const [ordering, setOrdering] = useOrderingQueryParam();

  const [filterParams, setFilterParams] = useQueryParams({
    profile__role: ArrayParam,
    is_blocked: ArrayParam,
  });

  const queryParams = {
    ...pagination.queryParams,
    search,
    ...filterParams,
    ordering,
  };

  const query = useQuery(querykeys.users.many(queryParams), () =>
    api.users.get(queryParams),
  );

  const { mutate: blockUser } = useMutation(api.users.blockUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(querykeys.users.many());
      notify.success({
        title: data?.message || messages.userAction.blockUser,
      });
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const { mutate: unblockUser } = useMutation(api.users.unblockUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(querykeys.users.many());
      notify.success({
        title: data?.message || messages.userAction.unblockUser,
      });
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const { mutate: resendConfirmation } = useMutation(
    api.users.resendConfirmation,
    {
      onSuccess: (data) => {
        notify.success({
          title: data?.message || messages.userAction.resendInvitation,
        });
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  const tableColumns = React.useMemo(
    () =>
      usersTableColumns({
        onResendConfirmation: resendConfirmation,
        onEditClick: userModal.openWith,
        onDeleteClick: deleteModal.openWith,
        onChangeUserStatus: (value) =>
          value.is_blocked ? unblockUser(value.id) : blockUser(value.id),
        currentUser: user,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userModal.openWith, deleteModal.openWith],
  );

  useUpdateEffect(() => pagination.setPage(1), [search, filterParams]);

  return (
    <>
      <h2>Utilizatori</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="24px" />
      <QueryTable
        title={() => (
          <TableTitle
            search={search}
            setSearch={setSearch}
            filters={
              <Filters
                queryParams={filterParams}
                setQueryParams={setFilterParams}
              >
                <ChoiceFilter
                  value={filterParams.profile__role}
                  setValue={forwardSetState(setFilterParams, "profile__role")}
                  title="Rol"
                  choice={aeoUserRolesOptions.map((role: Option) => ({
                    key: role.value as string,
                    value: role.text,
                  }))}
                />

                <ChoiceFilter
                  value={filterParams.is_blocked}
                  setValue={forwardSetState(setFilterParams, "is_blocked")}
                  title="Blocare"
                  choice={Object.values(["true", "false"]).map(
                    (is_blocked) => ({
                      key: is_blocked,
                      value: userBlockChoise[is_blocked],
                    }),
                  )}
                />
              </Filters>
            }
            onSortChange={setOrdering}
            sortOptions={orderingOptions}
            extra={
              <Button type="primary" onClick={userModal.open}>
                Adaugă utilizator
              </Button>
            }
          />
        )}
        query={query}
        columns={tableColumns}
        pagination={pagination}
        variants={["highlight-hover"]}
        onRow={(data, _index) => ({
          ...(data.id !== user?.id && {
            onClick: () => userModal.openWith(data),
          }),
        })}
      />

      {userModal.isOpen && (
        <UserFormModal
          open={userModal.isOpen}
          data={userModal.data}
          onClose={userModal.close}
        />
      )}

      {deleteModal.isOpen && (
        <DeleteUserModal
          open={deleteModal.isOpen}
          data={deleteModal.data}
          onClose={deleteModal.close}
        />
      )}
    </>
  );
};
