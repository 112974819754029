import { Card, Form, Alert, useForm } from "ebs-design";
import {
  Flex,
  WhiteSpace,
  LoadingButton,
  FormList,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import { PersonFormFields, JuridicFormFields } from ".";
import { FieldWithInfo } from "@aeo/core/components/form-utils/FieldWithInfo";

// TODO: Use this function for form list fields
const normalizeFormListValues = (values: any) => {
  const updatedValues: any = {};
  Object.entries(values).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      updatedValues[key] = value.some((v) =>
        Object.values(v).some((val) => val),
      )
        ? value
        : [];
    }
  });

  return updatedValues;
};

export const ShareholdersForm = () => {
  const person = fields.person;
  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/shareholders", form);
  useDraftApplicationQuery("saq/shareholders", form);
  const validateMutation = useValidateMutation(
    "saq/shareholders",
    form,
    "directors",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          const normalizedValues = normalizeFormListValues(values);
          saveMutation.mutate(normalizedValues, {
            onSuccess: () => validateMutation.mutate(normalizedValues),
          });
        }}
        onValuesChange={() => {
          setTimeout(
            () => save(normalizeFormListValues(form.getFieldsValue())),
            0,
          );
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.shareholders.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <Flex justify="space-between">
              <h4>
                a) Date complete privind proprietarii sau principalii acţionari,
                inclusiv procentul de acțiuni deţinute de aceştia.
              </h4>
              <div style={{ position: "relative", width: "30px" }}>
                <FieldWithInfo info="Indicați detalii complete privind proprietarii sau principalii acţionari, inclusiv procentul de acțiuni deţinute de aceştia." />
              </div>
            </Flex>
            <WhiteSpace v="1em" />
            <h4>{capitalize(messages.titles.physicPerson)}</h4>

            <FormList
              subTitle={messages.SAQTabs.shareholders.subTitle}
              name={person.physic.name}
              initialValue={[]}
              Component={PersonFormFields}
              componentProps={{
                showPercentageShares: true,
                attachmentsLabel:
                  "Documente confirmative privind numele, prenumele proprietarilor/acționarilor principali.",
                informationBox: (
                  <Alert type="info" closable>
                    Pentru a adăuga alți acționari, puteți adăuga manual sau să
                    atașați lista.
                  </Alert>
                ),
              }}
            />

            <WhiteSpace />

            <h4>{capitalize(messages.titles.juridicPerson)}</h4>
            <FormList
              subTitle={messages.SAQTabs.shareholders.subTitle}
              name={person.juridic.name}
              initialValue={[]}
              Component={JuridicFormFields}
              componentProps={{
                showPercentageShares: true,
                attachmentsLabel:
                  "Documente confirmative privind denumirea proprietarilor/acționarilor principali.",
                informationBox: (
                  <Alert type="info" closable>
                    Pentru a adăuga alți acționari, puteți adăuga manual sau să
                    atașați lista.
                  </Alert>
                ),
              }}
            />
          </Card.Body>
          <Card.Footer>
            <Flex justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
