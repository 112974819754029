import * as React from "react";
import cn from "classnames";
import { Tooltip, Button } from "ebs-design";
import { ButtonSize } from "ebs-design/dist/components/atoms/Button/Button";

import { Dropdown, Flex, WhiteSpace } from "@aeo/core/components";

interface Sort {
  title: React.ReactNode;
  value: string;
  type?: SortDirection;
}

enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export interface SortByProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  title?: string;
  sortByTitle?: string;
  value?: string;
  options: Sort[];
  size?: ButtonSize;
  onChange: (newValue: string) => void;
}

export const SortBy: React.FC<SortByProps> = ({
  title = "Sortare",
  sortByTitle = "",
  size = "medium",
  options,
  value,
  onChange,
  ...props
}) => {
  const [selected, setSelected] = React.useState(
    options.find((item) => item.value === value?.replace("-", ""))
  );
  const [type, setType] = React.useState(
    value?.charAt(0) === "-" ? SortDirection.DESC : SortDirection.ASC
  );

  React.useEffect(() => {
    if (selected) {
      onChange((type === SortDirection.ASC ? "" : "-") + selected?.value);
    } else {
      onChange("");
    }
  }, [selected, type]);

  const onChangeHandler = React.useCallback((newValue) => {
    setSelected((prevActive) =>
      prevActive?.value !== newValue.value ? newValue : undefined
    );
  }, []);

  const onChangeType = (): void => {
    if (!selected && options.length) {
      setSelected(options[0]);
    }
    setType((s) =>
      s === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
    );
  };

  return (
    <div className={`ebs-sort-by__wrapper ebs-sort-${type}`} {...props}>
      <Button type="ghost" icon="sort" size={size} onClick={onChangeType} />
      <Dropdown>
        <div className="ebs-tooltip__trigger">
          <Button
            type="ghost"
            size={size}
            className={`ebs-sort-by__tooltip-button ebs-sort-${type}`}
          >
            {selected ? `${sortByTitle} ${selected.title}` : title}
          </Button>
        </div>
        <Dropdown.Content
          className="ebs-sort-by__tooltip"
          style={{ width: "250px" }}
        >
          <section>{title}</section>
          <div className="ebs-sort-by__tooltip-items">
            {options &&
              options.map((item) => {
                const active = selected?.value === item.value;
                return (
                  <div
                    key={item.value}
                    className={cn("ebs-sort-by__tooltip-item", {
                      "ebs-sort-by__tooltip-item--active": active,
                    })}
                    onClick={() => onChangeHandler(item)}
                  >
                    <Flex align="center">
                      <div className="ebs-sort-by__check-circle"></div>
                      <WhiteSpace h="0.5rem" />
                      {item.title}
                    </Flex>
                  </div>
                );
              })}
          </div>
        </Dropdown.Content>
      </Dropdown>
    </div>
  );
};
