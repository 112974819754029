export function noop() { }

type ThrottledFunction<T extends (...args: any) => any> = (
  ...args: Parameters<T>
) => ReturnType<T>;

export function throttle<T extends (...args: any) => any>(
  func: T,
  limit: number,
): ThrottledFunction<T> {
  let inThrottle: boolean;
  let lastResult: ReturnType<T>;

  return function (this: any): ReturnType<T> {
    const args = arguments;
    const context = this;

    if (!inThrottle) {
      inThrottle = true;

      setTimeout(() => {
        inThrottle = false;
        // Need to call the function after the timeout passes
        lastResult = func.apply(context, args as any);
      }, limit);

      lastResult = func.apply(context, args as any);
    }

    return lastResult;
  };
}

export function debouncedFn<Arguments extends unknown[]>(
  func: (...args: Arguments) => void,
  timeout: number,
) {
  let timeoutId = 0;

  return (...args: Arguments) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
      // There's a wrong return type for setTimeout in typescript
    }, timeout) as unknown as number;
  };
}
