import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const CustomerValues = () => {
  const person = { ...fields.person, ...fields.personCustomer };

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <KeyValueCard {...person.first_name} />
      <KeyValueCard {...person.last_name} />
      <KeyValueCard {...person.birthday} type="date" />
      <KeyValueCard {...person.idnp} />
      <KeyValueCard {...person.phone} />
      <KeyValueCard {...person.email} />
      <KeyValueCard {...person.address} />
      <KeyValueCard {...person.attachments_persons} type="attachments" />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...person.attachments} type="attachments" />
    </>
  );
};

export const Customer = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.customer.title}
      content={<CustomerValues />}
      path="saq/customer"
    />
  );
};
