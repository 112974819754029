import * as dateFNS from "date-fns";

export const formattedDate = (value?: string) => {
  if (!value) {
    return "---";
  }
  const [date] = value.split("T");

  return date;
};

export const formattedTime = (value?: string) => {
  if (!value) {
    return "---";
  }
  const [, time] = value.split("T");

  return time?.replace("Z", "") || "";
};

export const formattedDateTime = (value?: string) => {
  if (!value) {
    return "---";
  }
  const [date, time] = value.split("T");

  return `${date}${time ? `, ${time.replace("Z", "")}` : ""}`;
};

export function dateToInputFormat(date?: Date | string) {
  date = new Date(date as Date);
  if (isNaN(date as unknown as number)) return "";

  return [
    ("0000" + date.getFullYear()).slice(-4),
    ("00" + (date.getMonth() + 1)).slice(-2),
    ("00" + date.getDate()).slice(-2),
  ].join("-");
}

export function getDateFormat(locale = "ro-RO") {
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case "day":
          return "dd";
        case "month":
          return "MM";
        case "year":
          return "yyyy";
        default:
          return obj.value;
      }
    })
    .join("");
}

export const roDateFormat = getDateFormat("ro-RO");

export const minDate = (d1: Date, d2: Date) =>
  new Date(d1) < new Date(d2) ? d1 : d2;
export const maxDate = (d1: Date, d2: Date) =>
  new Date(d1) > new Date(d2) ? d1 : d2;

export const checkIfDateIsLessThen = (date: string, periodInAge: number) => {
  const initialDate = new Date(date);

  const todayMinusPeriodInAge = new Date(
    new Date(new Date().setFullYear(new Date().getFullYear() - periodInAge)),
  );

  return initialDate < todayMinusPeriodInAge;
};

/**
 * Parse date with multiple available formats
 */
export const parseDate = (dateString: string, formats: string[]) => {
  let result: Date | undefined;

  for (let format of formats) {
    const date = dateFNS.parse(dateString, format, new Date());
    /** Check if it's not an invalid date */
    if (!isNaN(date as any)) {
      result = date;
    }
  }

  return result;
};
