import React from "react";

export const EconomicalAgents: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M17.5023 21.0037H6.49771C5.96701 21.0037 5.458 20.7931 5.08274 20.4179C4.70748 20.0426 4.49687 19.5336 4.49687 19.0029V4.99708C4.49687 4.46638 4.70748 3.95738 5.08274 3.58212C5.458 3.20686 5.96701 2.99625 6.49771 2.99625H17.5023C18.033 2.99625 18.542 3.20686 18.9173 3.58212C19.2925 3.95738 19.5031 4.46638 19.5031 4.99708V19.0029C19.5031 19.5336 19.2925 20.0426 18.9173 20.4179C18.542 20.7931 18.033 21.0037 17.5023 21.0037Z"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12.041 5.87244C12.0079 5.87232 11.9761 5.88549 11.9528 5.909C11.9295 5.93251 11.9166 5.96439 11.917 5.99749C11.917 6.04816 11.9475 6.09382 11.9944 6.11312C12.0413 6.13242 12.0951 6.12153 12.1308 6.08556C12.1665 6.04959 12.1769 5.99564 12.1573 5.94894C12.1376 5.90225 12.0917 5.87204 12.041 5.87244"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.99834 9.99917H16.0017"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M13.0004 16.0017H7.99834"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.99834 13.0004H16.0017"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
