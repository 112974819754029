import React from "react";
import { useQuery } from "react-query";
import { Button } from "ebs-design";

import { QueryTable, TableTitle, WhiteSpace } from "@aeo/core/components";
import { useModalState, useQueryPagination } from "@aeo/core/hooks";

import api, { querykeys } from "api";

import { complaintsTableColumns, sortOptions } from "../config";
import { ComplaintFormModal } from "../components";
import models from "@aeo/core/models";

export const Complaints = () => {
  const [ordering, setOrdering] = React.useState("");
  const [search, setSearch] = React.useState("");
  const pagination = useQueryPagination();

  const formModal = useModalState<models.Complaint>();

  const queryParams = {
    search,
    ordering,
    page: pagination.page,
    page_size: pagination.pageSize,
  };

  const query = useQuery(querykeys.complaints.many(queryParams), () =>
    api.complaints.get(queryParams),
  );

  const tableColumns = complaintsTableColumns();

  return (
    <>
      <h2>Sesizări</h2>
      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="24px" />
      <QueryTable
        pagination={pagination}
        title={() => (
          <TableTitle
            sortOptions={sortOptions}
            onSortChange={setOrdering}
            search={search}
            setSearch={setSearch}
            extra={
              <Button type="primary" onClick={formModal.open}>
                Adaugă sesizare
              </Button>
            }
          />
        )}
        query={query}
        columns={tableColumns}
      />
      {formModal.isOpen && (
        <ComplaintFormModal open={formModal.isOpen} onClose={formModal.close} />
      )}
    </>
  );
};
