import React from "react";
import cn from "classnames";
import { useMutation } from "react-query";
import { Button, useNotify } from "ebs-design";

import { makeBEM, notifyErrors, tabsAccess } from "@aeo/core/utils";
import { Collapse, EditorModal } from "@aeo/core/components";
import { useAuth, useModalState, usePopupClose } from "@aeo/core/hooks";
import { OtherRequest } from "@aeo/core/types";
import { notifications } from "@aeo/core/messages/notifications";
import models from "@aeo/core/models";

import api from "api";
import { createRequests } from "../createRequests";
import { RequestFormModal } from ".";

const bem = makeBEM("other-requests");

interface Props {
  activeApp: models.Application;
}
export const CreateRequests = ({ activeApp }: Props) => {
  const { user } = useAuth();

  const [isOpen, setIsOpen] = React.useState(false);
  const [pdfFile, setPdfFile] = React.useState<ArrayBuffer>();

  const formModal = useModalState<OtherRequest>();
  const notify = useNotify();

  const overlayRef = React.useRef<HTMLDivElement>(null);

  const requestsPermissions: models.CreateRequestEntity[] = tabsAccess(
    createRequests,
    activeApp.state,
    true,
  );

  usePopupClose(overlayRef, () => setIsOpen(false));

  React.useEffect(() => {
    window.addEventListener("resize", () => setIsOpen(false));

    return () => window.removeEventListener("resize", () => setIsOpen(false));
  });

  const actionMutation = useMutation(
    api.application.requestAction.bind(null, activeApp?.id!),
    {
      onSuccess: () => {
        notify.success({ title: notifications.sentRequest });
        formModal.close();
      },
      onError: notifyErrors.bind(null, notify),
    },
  );

  const previewMutation = useMutation(
    api.application.requestActionPreview.bind(null, activeApp?.id!),
    {
      onSuccess: (value) => setPdfFile(value),
      onError: (errors) => {
        notifyErrors(notify, errors);
        setPdfFile(undefined);
      },
    },
  );

  return (
    <>
      <Button
        className="no-wrap"
        type="ghost"
        size="medium"
        onClick={() => setIsOpen(true)}
        disabled={activeApp?.state === models.ApplicationState.draft}
      >
        Creare cerere
      </Button>

      {isOpen && <div className={cn(bem("overlay"))} ref={overlayRef} />}

      <div className={cn(bem("surface", { open: isOpen }), "surface")}>
        <div className={bem("container")}>
          {requestsPermissions.map((item, index) => (
            <React.Fragment key={index}>
              <Collapse
                header={<h4 className="color-gray pointer">{item.title}</h4>}
                key={item.title}
                className={cn({
                  [`collapse__header--disabled`]: !item.access,
                })}
                disabled={!item.access}
              >
                {item?.requests?.map((request) => (
                  <Button
                    type="text"
                    onClick={() =>
                      item.access &&
                      formModal.openWith({ ...request, type: item.type })
                    }
                    disabled={
                      !user?.organization?.active_application?.can_request
                    }
                    className={bem("item")}
                    key={request.name}
                  >
                    &#8226; {request.name}
                  </Button>
                ))}
              </Collapse>
              {index < createRequests.length - 1 && <div className="divider" />}
            </React.Fragment>
          ))}
          {!formModal?.data?.preview && formModal.isOpen && (
            <RequestFormModal
              open={formModal.isOpen}
              data={formModal.data}
              onClose={formModal.close}
            />
          )}
          {formModal?.data?.preview && formModal.isOpen && (
            <EditorModal
              open={formModal.isOpen}
              onClose={formModal.close}
              title="Cerere către serviciul vamal"
              mutation={actionMutation}
              previewMutation={previewMutation}
              pdfFile={pdfFile}
              setPdfFile={setPdfFile}
              withAttachments
            />
          )}
        </div>
      </div>
    </>
  );
};
