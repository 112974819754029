import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  AttachmentsFormField,
  LoadingButton,
  ExtraTextareaFormField,
  InputFormField,
  BooleanRadioFormField,
  Grid,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import {
  NotApplicableBooleanField,
  NotApplicableFormField,
  NotApplicableFormList,
} from "..";

export const SecurityTwoForm = () => {
  const securityTwo = fields.securityTwo;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/security_two", form);
  useDraftApplicationQuery("saq/security_two", form);
  const validateMutation = useValidateMutation(
    "saq/security_two",
    form,
    "security_three",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.security.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <TitleTooltip
              title={<h4>{messages.titles.buildingSecurity}</h4>}
              info="Pentru a îndeplini criteriul menţionat la articolul 1953 alineatul (1)  punctul 6) litera a) din Codul vamal,  trebuie să asiguraţi că acele clădiri care urmează să fie utilizate în cadrul operaţiunilor legate de autorizaţia AEOS oferă protecţie împotriva intruziunii şi sunt construite din materiale care rezistă la tentativele de acces ilegal."
            />

            <WhiteSpace />
            <InputFormField {...securityTwo.location_secured} />
            <InputFormField {...securityTwo.procedures_verified} />
            <InputFormField {...securityTwo.checks_buildings} />
            <InputFormField {...securityTwo.register_checks} />
            <InputFormField {...securityTwo.register_checks_solution} />
            <InputFormField {...securityTwo.access_possibilities} />
            <InputFormField {...securityTwo.access_respected} />
            <InputFormField {...securityTwo.access_limits} />
            <InputFormField {...securityTwo.premises_light} />
            <InputFormField {...securityTwo.manage_keys} />

            <BooleanRadioFormField
              radioField={securityTwo.has_described_procedure}
            />
            <NotApplicableFormList
              dependencies={[securityTwo.has_described_procedure.name]}
              name={securityTwo.described_procedure_attachments.name}
            >
              <AttachmentsFormField
                {...securityTwo.described_procedure_attachments}
              >
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <ExtraTextareaFormField
              radioField={securityTwo.has_private_parking}
              textareaField={securityTwo.parking_person}
            />
            <NotApplicableFormField {...securityTwo.parking_approval} />

            <NotApplicableBooleanField
              {...securityTwo.has_parking_verification}
            />
            <NotApplicableBooleanField {...securityTwo.has_parking_procedure} />
            <AttachmentsFormField
              {...securityTwo.parking_procedure_attachments}
            >
              Atașează documente
            </AttachmentsFormField>
            <TitleTooltip
              title={<h4>{messages.titles.premisesAccess}</h4>}
              info="Pentru a preveni manipularea frauduloasă a mărfurilor, în conformitate cu articolul 1953 alineatul (1) punctul 6) litera b) din Codul vamal, solicitantul va lua măsuri adecvate pentru a împiedica accesul neautorizat în birouri, zone de expediere, zone de încărcare şi alte locuri relevante."
            />

            <WhiteSpace />

            <InputFormField {...securityTwo.access_buildings} />
            <InputFormField {...securityTwo.verification_person} />
            <InputFormField {...securityTwo.unauthorized_procedure} />
            <InputFormField {...securityTwo.procedure_communicated} />
            <InputFormField {...securityTwo.plan_subdivisions} />
            <AttachmentsFormField {...securityTwo.plan_subdivisions_documents}>
              Atașează documente
            </AttachmentsFormField>
            <InputFormField {...securityTwo.specify_location} />
            <TitleTooltip
              title={<h4>{messages.titles.loadingUnits}</h4>}
              info="Pentru a îndeplini criteriul menţionat la articolul 1953 alineatul (1) punctul 6) litera c) din Codul vamal, trebuie instituite măsuri privind manipularea mărfurilor care să includă protecţia împotriva introducerii sau substituirii neautorizate, a manipulării greşite a mărfurilor şi împotriva manipulării frauduloase a unităţilor de încărcare. Unităţile de încărcare includ containere, cisterne, furgonete, camioane, vehicule, conducte şi alte mijloace în care sunt transportate mărfurile. Trebuie să existe proceduri pentru examinarea integrităţii unităţii de încărcare înainte de încărcare. Detaliile privind proprietarii/furnizorii unităţilor de încărcare trebuie să fie puse la dispoziţie în timpul vizitei."
            />

            <WhiteSpace />

            <ExtraTextareaFormField
              radioField={securityTwo.has_restrictions_loading}
              textareaField={securityTwo.restrictions_loading}
            />
            <WhiteSpace />

            <InputFormField {...securityTwo.unauthorized_access_measures} />

            <BooleanRadioFormField radioField={securityTwo.has_own_seals} />

            <NotApplicableFormList
              dependencies={[securityTwo.has_own_seals.name]}
              name={securityTwo.seals_details.name}
            >
              <InputFormField {...securityTwo.seals_details} />
            </NotApplicableFormList>
            <NotApplicableFormList
              dependencies={[securityTwo.has_own_seals.name]}
              name={securityTwo.seals_not_used.name}
              reverse
            >
              <InputFormField {...securityTwo.seals_not_used} />
            </NotApplicableFormList>

            <InputFormField {...securityTwo.load_unit_measures} />
            <InputFormField {...securityTwo.load_unit_operators} />
            <InputFormField {...securityTwo.load_unit_maintains} />

            <BooleanRadioFormField
              radioField={securityTwo.has_regular_maintenance}
            />
            <BooleanRadioFormField
              radioField={securityTwo.has_external_maintenance}
            />

            <h4>{messages.titles.logisticsProcesses}</h4>
            <WhiteSpace />

            <InputFormField {...securityTwo.transportation_methods} />
            <InputFormField {...securityTwo.external_service} />
            <InputFormField {...securityTwo.external_security_standards} />
            <InputFormField {...securityTwo.external_measures} />

            <WhiteSpace />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <Grid gap="20px" cols="auto 1fr" items="center">
              <AttachmentsFormField name="attachments">
                Atașează documente
              </AttachmentsFormField>
              <p className="mb-20">
                (de ex. lista de furnizori de servicii externe, (de exemplu,
                expeditori / transportatori))
              </p>
            </Grid>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
