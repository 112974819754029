import { ActionPlan } from "./svgs/ActionPlan";
import { EconomicalAgents } from "./svgs/EconomicalAgents";
import { Applications } from "./svgs/Applications";
import { Approvals } from "./svgs/Approvals";
import { ArrowLeft } from "./svgs/ArrowLeft";
import { ArrowRight } from "./svgs/ArrowRight";
import { Attachment } from "./svgs/Attachment";
import { Back } from "./svgs/Back";
import { Category } from "./svgs/Category";
import { Chat } from "./svgs/Chat";
import { Check } from "./svgs/Check";
import { ChevroneRight } from "./svgs/ChevroneRight";
import { CircleCheck } from "./svgs/CircleCheck";
import { CircleDots } from "./svgs/CircleDots";
import { CircleQuestion } from "./svgs/CircleQuestion";
import { CircleWarning } from "./svgs/CircleWarning";
import { Close } from "./svgs/Close";
import { ClosedEye } from "./svgs/ClosedEye";
import { ArrowsMinimize } from "./svgs/ArrowsMinimize";
import { Collapse } from "./svgs/Collapse";
import { Dashboard } from "./svgs/Dashboard";
import { Delete } from "./svgs/Delete";
import { DotsVertical } from "./svgs/DotsVertical";
import { DownChevrone } from "./svgs/DownChevrone";
import { Download } from "./svgs/Download";
import { Edit } from "./svgs/Edit";
import { Examined } from "./svgs/Examined";
import { ArrowsMaximize } from "./svgs/ArrowsMaximize";
import { Expand } from "./svgs/Expand";
import { Filter } from "./svgs/Filter";
import { Help } from "./svgs/Help";
import { IconContainer } from "./svgs/IconContainer";
import { Information } from "./svgs/Information";
import { LogOut } from "./svgs/LogOut";
import { Message } from "./svgs/Message";
import { Minus } from "./svgs/Minus";
import { Notification } from "./svgs/Notification";
import { News } from "./svgs/News";
import { OpenEye } from "./svgs/OpenEye";
import { Plus } from "./svgs/Plus";
import { Print } from "./svgs/Print";
import { Reply } from "./svgs/Reply";
import { Tasks } from "./svgs/Tasks";
import { Send } from "./svgs/Send";
import { Complaints } from "./svgs/Complaints";
import { Settings } from "./svgs/Settings";
import { Signature } from "./svgs/Signature";
import { Smile } from "./svgs/Smile";
import { SortAscending } from "./svgs/SortAscending";
import { SortDescending } from "./svgs/SortDescending";
import { Sorting } from "./svgs/Sorting";
import { Task } from "./svgs/Task";
import { Upload } from "./svgs/Upload";
import { Users } from "./svgs/Users";
import { VerificationList } from "./svgs/VerificationList";
import { Table } from "./svgs/Table";
import { Orders } from "./svgs/Orders";

export const iconList = {
  "action-plan": ActionPlan,
  "economical-agents": EconomicalAgents,
  applications: Applications,
  approvals: Approvals,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  attachment: Attachment,
  back: Back,
  category: Category,
  chat: Chat,
  check: Check,
  "chevrone-right": ChevroneRight,
  "circle-check": CircleCheck,
  "circle-dots": CircleDots,
  "circle-question": CircleQuestion,
  "circle-warning": CircleWarning,
  close: Close,
  "closed-eye": ClosedEye,
  "arrows-minimize": ArrowsMinimize,
  collapse: Collapse,
  dashboard: Dashboard,
  delete: Delete,
  "dots-vertical": DotsVertical,
  "down-chevrone": DownChevrone,
  download: Download,
  edit: Edit,
  examined: Examined,
  "arrows-maximize": ArrowsMaximize,
  expand: Expand,
  filter: Filter,
  help: Help,
  "icon-container": IconContainer,
  information: Information,
  "log-out": LogOut,
  message: Message,
  minus: Minus,
  notification: Notification,
  news: News,
  "open-eye": OpenEye,
  plus: Plus,
  print: Print,
  reply: Reply,
  tasks: Tasks,
  send: Send,
  complaints: Complaints,
  settings: Settings,
  signature: Signature,
  smile: Smile,
  "sort-ascending": SortAscending,
  "sort-descending": SortDescending,
  sorting: Sorting,
  task: Task,
  upload: Upload,
  users: Users,
  "verification-list": VerificationList,
  table: Table,
  orders: Orders,
};
