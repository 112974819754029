import React from "react";
import { NamePath } from "rc-field-form/es/interface";

import { getProperty } from "@aeo/core/utils";
import { ApplicationContext, ApplicationPathContext } from "../contexts";

export function useFieldValue(name: NamePath | undefined) {
  const values = React.useContext(ApplicationContext);
  const path = React.useContext(ApplicationPathContext);

  return getProperty(
    values,
    ...(path?.split("/") || []),
    ...(name ? (name instanceof Array ? name : [name]) : []),
  );
}
