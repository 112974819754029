import { Form, Textarea } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import { Option } from "ebs-design/dist/components/molecules/Select/interfaces";

import { BooleanRadioFormField, Effect } from "..";

export interface FieldProps extends FormFieldProps {
  name: string | string[];
  placeholder?: string;
}

interface ExtraTextareaFormFieldProps extends FormFieldProps {
  required?: boolean;
  textarea?: boolean;
  options?: Option[];
  radioField: FieldProps;
  textareaField: FieldProps;
}

export const ExtraTextareaFormField = (
  inProps: ExtraTextareaFormFieldProps,
) => {
  const { radioField, textareaField, rules = [], required } = inProps;

  return (
    <>
      <BooleanRadioFormField
        radioField={radioField}
        textareaName={textareaField.name as string}
        options={inProps.options}
      />

      <Form.Field
        dependencies={[radioField.name]}
        label={textareaField.label}
        name={textareaField.name}
        rules={[{ required }, ...rules]}
      >
        {(control, __, form) => {
          return (
            <>
              <Textarea
                {...control}
                disabled={!form.getFieldValue(radioField?.name)}
                placeholder={textareaField.placeholder}
              />
              <Effect form={form} onChange={control.onChange} {...inProps} />
            </>
          );
        }}
      </Form.Field>
    </>
  );
};
