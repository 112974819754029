import { UseInfiniteQueryResult } from "react-query";
import models from "@aeo/core/models";

export const combineQueryPagesData = <T>(
  data: Required<UseInfiniteQueryResult<models.WithResults<T>>["data"]>
) =>
  (data?.pages
    .map((p) => p.results)
    .flat()
    .filter((_) => _) as T[]) || [];

export const hasNextPage = (lastPage: models.WithResults<unknown>) =>
  (lastPage.num_pages || 0) > (lastPage.page || 0);

export const getNextPageParam = (lastPage: models.WithResults<unknown>) =>
  hasNextPage(lastPage) ? (lastPage.page || 0) + 1 : undefined
