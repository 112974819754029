import { Link } from "react-router-dom";
import { Auth } from "@aeo/core/components/auth";
import { ResetPasswordForm } from "../components";

export const ResetPassword = () => {
  return (
    <Auth
      title="AEO"
      subTitle="Setare parolă"
      footer={
        <h4 className="fw-500">
          Ai deja un cont?
          <span>
            <Link to="/login" className="auth-layout__link">
              Login.
            </Link>
          </span>
        </h4>
      }
    >
      <ResetPasswordForm submitTitle="Setare parolă" />
    </Auth>
  );
};
