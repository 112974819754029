import {
  TableColumnBuilder,
  formattedDateTime,
  getFullName,
} from "@aeo/core/utils";

export const complaintsTableColumns = () =>
  new TableColumnBuilder()
    .add("Număr de înregistrare", "id")
    .add("Data și ora", "created_at", formattedDateTime)
    .add("Sesizare", "body")
    .add("Titularul sesizării", "user", getFullName)
    .add("Răspuns", ["reply", "body"], (value) => value || "-")
    .add("Persoana responsabilă SV", ["organization", "manager"], getFullName)
    .getColumns();

export const sortOptions = [{ title: "Data", value: "created_at" }];
