import React from "react";

export const CircleQuestion: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M10 10C10 9.60218 10.1844 9.22064 10.5126 8.93934C10.8408 8.65804 11.2859 8.5 11.75 8.5H12.25C12.7142 8.5 13.1593 8.65804 13.4875 8.93934C13.8157 9.22064 14 9.60218 14 10C14.0184 10.3246 13.9309 10.6464 13.7505 10.917C13.5702 11.1875 13.3068 11.3921 13 11.5C12.6933 11.6438 12.4299 11.9166 12.2495 12.2773C12.0692 12.6381 11.9816 13.0672 12 13.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 16.5V16.505"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
