import { Checkbox, Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { FieldWithInfo } from "@aeo/core/components/form-utils/FieldWithInfo";
import { relativeClassName } from "@aeo/core/components/form-utils/utils";
import { FormControl } from "@aeo/core/types";
import models from "@aeo/core/models";
import { tryToNumber } from "@aeo/core/utils";

export interface NotApplicableCheckboxTriggerProps extends FormFieldProps {
  info?: React.ReactNode;
  defaultDepsValue?: models.DefaultDepsValue[];
}

export const NotApplicableCheckboxTrigger = ({
  dependencies,
  info,
  extra,
  className,
  defaultDepsValue,
  ...props
}: NotApplicableCheckboxTriggerProps) => {
  return (
    <Form.Field
      dependencies={dependencies}
      initialValue={false}
      className={relativeClassName(info, "radio-field", className)}
      extra={<FieldWithInfo extra={extra} info={info} />}
      {...props}
    >
      {(_control, _, form) => {
        const { value, onChange } = _control as FormControl<boolean>;

        return (
          <div className="bordered-checkbox-wrapper">
            <Checkbox
              text={"Nu se aplică"}
              checked={value}
              onChange={(v) => {
                if (v && Array.isArray(defaultDepsValue)) {
                  form.setFields(
                    defaultDepsValue
                      .map((dep) =>
                        Object.entries(dep).map(([key, value]) => ({
                          name: key.split(".").map(tryToNumber),
                          value: value,
                        })),
                      )
                      .flat() || [],
                  );
                }
                onChange?.(v);
              }}
            />
          </div>
        );
      }}
    </Form.Field>
  );
};
