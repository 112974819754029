import { Link, useRouteMatch } from "react-router-dom";
import cn from "classnames";
import { makeBEM } from "@aeo/core/utils";

const bem = makeBEM("saq-tabs");

export interface SubItemProps {
  nr: number | string;
  status?: "success" | "error";
  tabKey: string;
}

export const SubItem = ({
  nr,
  status,
  tabKey,
}: React.PropsWithChildren<SubItemProps>) => {
  const match = useRouteMatch();
  const currentMatch = useRouteMatch({ path: `${match.url}/${tabKey}` });

  return (
    <Link
      className={cn(bem("subitem", [status]), {
        active: currentMatch,
      })}
      to={`${match.url}/${tabKey}`}
    >
      <div className={bem("number")}>{nr}</div>
    </Link>
  );
};
