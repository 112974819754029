import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";

export interface TabContentProps {
  tabKey: string;
  defaultActive?: boolean;
}

export const Content = ({
  tabKey,
  children,
  defaultActive,
}: React.PropsWithChildren<TabContentProps>) => {
  const match = useRouteMatch();
  return (
    <>
      <Route path={`${match.path}/${tabKey}`} key={tabKey}>
        {children}
      </Route>
      {defaultActive && (
        <Switch>
          <Redirect to={`${match.url}/${tabKey}`} from={match.url} exact />
        </Switch>
      )}
    </>
  );
};
