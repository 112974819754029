import React from "react";
import { useQuery } from "react-query";
import { Button, Modal } from "ebs-design";

import { Flex, Pagination, QueryTable } from "@aeo/core/components";
import { useDownloadFile, useModalState, usePagination } from "@aeo/core/hooks";
import { makeBEM } from "@aeo/core/utils";

import api, { querykeys } from "api";

import { decisionsColumns } from "../config";

const bem = makeBEM("dashboard");

export const Requests = ({ id }: { id: number }) => {
  const decisionsModal = useModalState();
  const pagination = usePagination();
  const downloadFile = useDownloadFile();

  const queryParams = {
    page: pagination.page,
    page_size: pagination.pageSize,
  };
  const query = useQuery(
    querykeys.applications.many(queryParams),
    () => api.application.getRequest(id, queryParams),
    { enabled: decisionsModal.isOpen },
  );

  const tableColumns = React.useMemo(
    () => decisionsColumns({ downloadFile, documentKey: "request_document" }),
    [downloadFile],
  );

  return (
    <>
      <Button onClick={() => decisionsModal.open()}>Cereri</Button>

      {decisionsModal && (
        <Modal
          title="Cereri"
          open={decisionsModal.isOpen}
          onClose={decisionsModal.close}
          size="large"
        >
          <Modal.Content>
            <QueryTable
              className={bem("decisions-table")}
              query={query}
              columns={tableColumns}
              emptyText={
                <div className="text-center color-gray p-30">
                  La moment nu exista nici un fișier
                </div>
              }
            />
          </Modal.Content>
          <Modal.Footer>
            <Flex justify="end">
              <Pagination
                data={query.data}
                pagination={pagination}
                pageSizeChoice={[5, 10, 15]}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
