import { Card, Form, Space, useForm } from "ebs-design";
import {
  WhiteSpace,
  AttachmentsFormField,
  LoadingButton,
  Grid,
  InputFormField,
  BooleanRadioFormField,
  ExtraTextareaFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleTooltip, TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";

import {
  NotApplicableBooleanField,
  NotApplicableExtraTextarea,
  NotApplicableFormField,
  NotApplicableFormList,
} from "../";

export const SecurityOneForm = () => {
  const securityOne = fields.securityOne;

  const [form] = useForm();

  const [save, saveMutation] = useAutoSave("saq/security_one", form);
  useDraftApplicationQuery("saq/security_one", form);
  const validateMutation = useValidateMutation(
    "saq/security_one",
    form,
    "security_two",
  );

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              title={messages.SAQTabs.security.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <h4>{capitalize(messages.titles.securityOneSub)}</h4>
            <WhiteSpace v="1rem" />
            <TitleTooltip
              title={
                <p>
                  6.1.1. Indicați numele, prenumele și funcția persoanei
                  competente pentru întrebări legate de securitate și siguranță.
                </p>
              }
              info={`<p>În conformitate cu articolul 1953 alineatul (1)  punctul 6) din Codul vamal, este obligatorie numirea unei persoane de contact competentă pentru întrebările în materie de securitate şi siguranţă. În acest context, siguranţa şi securitatea se referă doar la criteriul AEO. Este de remarcat faptul că această condiţie nu are legătură cu „siguranţa la locul de muncă", întrucât aceasta nu face obiectul criteriului de siguranţă şi securitate.</p>`}
            />

            <WhiteSpace />
            <Grid cols="1fr 1fr 1fr" gap="20px">
              <InputFormField {...securityOne.competent.first_name} />
              <InputFormField {...securityOne.competent.last_name} />
              <InputFormField {...securityOne.competent.function} />
            </Grid>
            <WhiteSpace />

            <BooleanRadioFormField
              radioField={securityOne.has_assessment_risk}
            />

            <NotApplicableExtraTextarea
              radioField={securityOne.has_security_plan}
              textareaField={securityOne.documents_reviewed}
            />

            <NotApplicableFormList
              dependencies={[securityOne.has_security_plan.name]}
              name={securityOne.security_plan_attachments.name}
            >
              <AttachmentsFormField {...securityOne.security_plan_attachments}>
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>
            <InputFormField {...securityOne.risks} />
            <InputFormField {...securityOne.measures} />

            <BooleanRadioFormField
              radioField={securityOne.has_subdivision_measures}
            />
            <BooleanRadioFormField radioField={securityOne.has_instructions} />

            <NotApplicableFormField
              {...securityOne.staff_communication}
              reverse
            />
            <NotApplicableFormField
              {...securityOne.instructions_documented}
              reverse
            />

            <NotApplicableFormList
              dependencies={[securityOne.has_instructions.name]}
              name={securityOne.instructions_documented_attachments.name}
            >
              <AttachmentsFormField
                {...securityOne.instructions_documented_attachments}
              >
                Atașează documente
              </AttachmentsFormField>
            </NotApplicableFormList>

            <ExtraTextareaFormField
              radioField={securityOne.has_incidents}
              textareaField={securityOne.incidents}
            />
            <WhiteSpace />

            <BooleanRadioFormField
              radioField={securityOne.incidents_documented}
            />

            <BooleanRadioFormField radioField={securityOne.has_certificate} />
            <NotApplicableFormList
              dependencies={[securityOne.has_certificate.name]}
              name={securityOne.certificates.name}
            >
              <AttachmentsFormField {...securityOne.certificates} />
            </NotApplicableFormList>

            <ExtraTextareaFormField
              radioField={securityOne.has_apply_security}
              textareaField={securityOne.apply_security}
            />
            <WhiteSpace />

            <BooleanRadioFormField
              radioField={securityOne.has_special_requirements}
            />

            <ExtraTextareaFormField
              radioField={securityOne.has_security_service}
              textareaField={securityOne.security_service}
            />
            <WhiteSpace />

            <NotApplicableBooleanField
              {...securityOne.has_assessment_service}
            />
            <NotApplicableFormField {...securityOne.risks_identified} />

            <WhiteSpace />

            <ExtraTextareaFormField
              radioField={securityOne.has_client_requirements}
              textareaField={securityOne.client_requirements}
            />
            <WhiteSpace />

            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />
            <AttachmentsFormField name="attachments">
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Space>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
