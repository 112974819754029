import models from "../models";
import { arrIncludes } from "./array";

export const tabsAccess = (
  tabs: (models.CreateRequestEntity | models.TabsEntity)[],
  state?: models.ApplicationState,
  withPreviousDisabled = false,
) => {
  let accessArray = tabs.map((tab) => {
    let access = false;
    if (tab.states) access = arrIncludes(tab.states, state);
    if (tab.excludeStates) access = !arrIncludes(tab.excludeStates, state);

    return { ...tab, access };
  });

  if (withPreviousDisabled) {
    return accessArray;
  }

  return [
    ...accessArray
      .slice(0, accessArray.findIndex((v) => v.access) + 1)
      .map((m) => ({ ...m, access: true })),
    ...accessArray.slice(
      accessArray.findIndex((v) => v.access) + 1,
      accessArray.length,
    ),
  ];
};
