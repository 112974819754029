import { extraTextareaProps } from "../common";
import { declaration, declarationDeps } from "../utils";

export const turnover = {
  has_annual_turnover: {
    name: "has_annual_turnover",
    defaultDepsValue: [{ annual_turnover: null }],
  },
  annual_turnover: {
    label:
      "1.2.1. a) Indicați cifra de afaceri anuală. În cazul unei companii noi, indicați „nu se aplică”. ",
    name: "annual_turnover",
    placeholder: "Ex: 12",
    infoTitle:
      "1.2.1. Dacă sunteţi o companie nou înfiinţată şi aveţi mai puţin de trei situaţii financiare anuale complete, furnizaţi detalii din cele deja finalizate. Dacă nu aţi desfăşurat activitate comercială o perioadă suficient de îndelungată pentru a prezenta o situaţie financiară anuală finalizată, răspundeţi prin formula „nu se aplică",
  },
  has_annual_profit: {
    name: "has_annual_profit",
    defaultDepsValue: [{ annual_profit: null }],
  },
  annual_profit: {
    label:
      "1.2.1. b) Indicați profitul anual net sau cifra pierderilor anuale nete. În cazul unei companii noi, indicați „nu se aplică”.",
    name: "annual_profit",
    placeholder: "Ex: 1 000 000 LEI",
  },
  rented_space: {
    label:
      "1.2.2. În cazul în care utilizați spații de depozitare închiriate indicați de la cine aţi închiriat spaţiile respective. Dacă nu utilizați, specificați acest lucru.",
    name: "rented_space",
    textarea: true,
    nullValue: true,
    placeholder: "Ex: Lorem Ipsum Ltd.",
    info: "1.2.2. Precizați adresa acestor spaţii de depozitare închiriate (dacă aveţi mai mult de cinci spaţii, indicaţi cele principale).",
  },

  declarations: {
    label:
      "1.2.3. Pentru fiecare dintre elementele de mai jos, prezentaţi o estimare a numărului şi valorii declaraţiilor pe care le-aţi perfectat în ultimii trei ani. În cazul unei companii noi, indicaţi „nu se aplică”.",
    infoTitle:
      "1.2.3. Indicați numărul declaraţiilor vamale şi valoarea mărfurilor aferente acestora pentru fiecare opțiune.",
  },
  has_declarations: {
    name: "has_declarations",
    ...declaration("import_declaration", "has_declarations", "Ex: 1234"),
    dependencies: [
      ...declarationDeps("import_declaration"),
      ...declarationDeps("export_declaration"),
      ...declarationDeps("suspended_mode"),
      ...declarationDeps("customs_destination"),
    ],

    defaultDepsValue: [
      { "import_declaration.number": null },
      { "import_declaration.value": null },
      { "export_declaration.number": null },
      { "export_declaration.value": null },
      { "suspended_mode.number": null },
      { "suspended_mode.value": null },
      { "customs_destination.number": null },
      { "customs_destination.value": null },
    ],
  },
  import_declaration: {
    name: "import_declaration",
    ...declaration("import_declaration", "has_declarations", "Ex: 1234"),
    label: "a) Import",
    dependencies: ["has_declarations"],
  },
  export_declaration: {
    name: "export_declaration",
    ...declaration("export_declaration", "has_declarations", "Ex: 1234"),
    label: "b) Export/Reexport",
    dependencies: ["has_declarations"],
  },
  suspended_mode: {
    name: "suspended_mode",
    ...declaration("suspended_mode", "has_declarations", "Ex: 1234"),
    label: "c) Regimuri suspensive",
    dependencies: ["has_declarations"],
  },
  customs_destination: {
    name: "customs_destination",
    ...declaration("customs_destination", "has_declarations", "Ex: 1234"),
    label: "d) Destinații vamale",
    dependencies: ["has_declarations"],
  },
  tax: {
    name: "tax",
    info: "1.2.4. Indicați cuantumul drepturilor de import/export achitate.",
    has_tax: {
      name: ["tax", "has_tax"],
      customPathName: ["tax", "value", "has_tax"],
      dependencies: [
        ["tax", "customs_taxes"],
        ["tax", "customs_procedures"],
        ["tax", "vat"],
        ["tax", "excise"],
        ["tax", "attachments"],
      ],
      defaultDepsValue: [
        { "tax.customs_taxes": null },
        { "tax.customs_procedures": null },
        { "tax.vat": null },
        { "tax.excise": null },
      ],
    },
    label:
      "1.2.4. Pentru fiecare dintre elementele de mai jos, prezentaţi o estimare a drepturilor de import plătite în fiecare din ultimii trei ani. În cazul în care sunteți o companie nouă care desfășoară activități de mai puțin de trei ani, oferiți detalii pe perioada respectivă. În cazul unei companii recent înființate, indicaţi „nu se aplică.",
    customs_taxes: {
      name: ["tax", "customs_taxes"],
      customPathName: ["tax", "value", "customs_taxes"],
      label: "a) Taxa vamală",
      placeholder: "Ex: 1234",
      dependencies: [["tax", "has_tax"]],
      title: "1.2.4. Taxa vamală",
    },
    customs_procedures: {
      name: ["tax", "customs_procedures"],
      label: "b) Taxa pentru proceduri vamale",
      placeholder: "Ex: 1234",
      dependencies: [["tax", "has_tax"]],
      customPathName: ["tax", "value", "customs_procedures"],
    },
    vat: {
      name: ["tax", "vat"],
      label: "c) Taxa pe valoare adaugată",
      placeholder: "Ex: 1234",
      dependencies: [["tax", "has_tax"]],
      customPathName: ["tax", "value", "vat"],
    },
    excise: {
      name: ["tax", "excise"],
      label: "d) Accize",
      placeholder: "Ex: 1234",
      dependencies: [["tax", "has_tax"]],
      customPathName: ["tax", "value", "excise"],
    },
  },
  any_structural_changes: {
    name: "any_structural_changes",
    label:
      "1.2.5. a) Prevedeți careva modificări structurale în compania dvs. în următorii doi ani?",
    info: "1.2.5. Modificările viitoare cunoscute sunt cele care ar putea influenţa organizarea companiei, îndeplinirea condițiilor AEO sau evaluarea riscurilor din cadrul lanţului de aprovizionare internaţional. Acestea pot include, de exemplu, modificări la nivelul personalului cheie, modificări ale sistemului contabil, crearea unor noi subdiviziuni, atribuirea unor noi contracte de logistică etc.",
  },
  structural_changes: {
    name: "structural_changes",
    label: "Dacă răspunsul este da, descrieţi succint modificările.",
    ...extraTextareaProps,
  },
  any_supply_chain_changes: {
    name: "any_supply_chain_changes",
    label:
      "1.2.5. b) Prevedeți modificări majore în lanțul de aprovizionare în care este implicată compania dvs. în următorii doi ani?",
  },
  supply_chain_changes: {
    name: "supply_chain_changes",
    label: "Dacă răspunsul este da, descrieţi succint modificările.",
    ...extraTextareaProps,
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
