interface Props {
  title: string;
  onClick?: () => void;
}

export const BadgeTabLabel = ({ title, onClick }: Props) => {
  return (
    <div className="badge-tabs__label" onClick={onClick}>
      <p>{title}</p>
    </div>
  );
};
