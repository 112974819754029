import { Card, Form, useForm } from "ebs-design";
import {
  Flex,
  InputFormField,
  Grid,
  WhiteSpace,
  FormList,
  DatePickerFormField,
  BooleanRadioFormField,
  AttachmentsFormField,
  LoadingButton,
  InputIDNPFormField,
  InputPhoneFormField,
} from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";
import messages from "@aeo/core/messages";
import fields from "@aeo/core/fields";

import { TitleWithLoading } from "components";
import {
  useAutoSave,
  useDraftApplicationQuery,
  useValidateMutation,
} from "hooks";
import {
  NotApplicableBooleanField,
  NotApplicableFormField,
  NotApplicableFormList,
} from "..";
import { PersonFormFields, JuridicFormFields } from "./fields";

// Persoana responsabilă din cadrul companiei
export const PersonContactForm = () => {
  const [form] = useForm();
  const person = { ...fields.person, ...fields.personContact };

  const [save, saveMutation] = useAutoSave("saq/contact", form);
  useDraftApplicationQuery("saq/contact", form);
  const validateMutation = useValidateMutation("saq/contact", form, "customer");

  return (
    <div className="container-card">
      <Form
        form={form}
        onFinish={(values) => {
          saveMutation.mutate(values, {
            onSuccess: () => validateMutation.mutate(values),
          });
        }}
        onValuesChange={() => {
          setTimeout(() => save(form.getFieldsValue()), 0);
        }}
      >
        <Card>
          <Card.Header>
            <TitleWithLoading
              info={messages.SAQTabs.contact.info}
              title={messages.SAQTabs.contact.title}
              loading={saveMutation.isLoading}
            />
          </Card.Header>
          <Card.Body>
            <h4>{capitalize(messages.titles.responsible)}</h4>

            <WhiteSpace />

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputFormField {...person.first_name} />

              <InputFormField {...person.last_name} />
            </Grid>

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <DatePickerFormField {...person.birthday} />

              <InputIDNPFormField {...person.idnp} />
            </Grid>

            <Grid rows="1fr" cols="1fr 1fr" gap="20px">
              <InputPhoneFormField {...person.phone} />
              <InputFormField {...person.email} />
            </Grid>
            <Grid rows="1fr" gap="20px" cols="1fr 1fr">
              <InputFormField {...person.address} />
              <AttachmentsFormField {...person.attachments_persons}>
                Atașează cazierul judiciar
              </AttachmentsFormField>
            </Grid>
            <WhiteSpace />

            <InputFormField {...person.departments_involved} textarea />

            <NotApplicableBooleanField {...person.has_customs_consulting} />
            <NotApplicableFormField
              {...person.customs_consulting}
              reverse
              textarea
            />

            <WhiteSpace />

            <BooleanRadioFormField
              radioField={person.has_third_party_involved}
            />
            <NotApplicableFormList {...person.third_party_physic}>
              <>
                <h4>{capitalize(messages.titles.physicPerson)}</h4>
                <FormList
                  subTitle={messages.titles.physicPerson}
                  name={person.third_party_physic.name}
                  initialValue={[]}
                  Component={PersonFormFields}
                />
              </>
            </NotApplicableFormList>
            <NotApplicableFormList {...person.third_party_juridic}>
              <>
                <h4>{capitalize(messages.titles.juridicPerson)}</h4>
                <FormList
                  subTitle={messages.titles.juridicPerson}
                  name={person.third_party_juridic.name}
                  initialValue={[]}
                  Component={JuridicFormFields}
                />
              </>
            </NotApplicableFormList>

            <WhiteSpace v="20px" />
            <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
            <WhiteSpace />

            <AttachmentsFormField {...person.attachments}>
              Atașează documente
            </AttachmentsFormField>
          </Card.Body>
          <Card.Footer>
            <Flex justify="end">
              <LoadingButton
                loading={saveMutation.isLoading || validateMutation.isLoading}
                type="primary"
                submit
              >
                Validează
              </LoadingButton>
            </Flex>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
};
