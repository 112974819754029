import React from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { Button, Card, Loader, Tooltip, useNotify } from "ebs-design";
import { Link } from "react-router-dom";

import {
  combineQueryPagesData,
  hasNextPage,
  makeBEM,
  notifyErrors,
  formattedDateTime,
} from "@aeo/core/utils";
import { Flex, Icon } from "@aeo/core/components";
import { useIntersectionObserver, useQueryPagination } from "@aeo/core/hooks";
import api, { querykeys } from "@aeo/core/api";
import models from "@aeo/core/models";
import { REFETCH_NOTIFICATION_INTERVAL } from "@aeo/core/app-constants";

const bem = makeBEM("notifications");

export const Notifications = ({ ...props }) => {
  const loadMore = React.useRef<null>(null);
  const pagination = useQueryPagination();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const queryParams = { ...pagination.queryParams };

  const query = useInfiniteQuery(
    querykeys.notifications({ ...queryParams }),
    ({ pageParam = 1 }) =>
      api.notifications.get({
        ...queryParams,
        page: pageParam,
      }),
    {
      refetchOnMount: false,
      refetchInterval: REFETCH_NOTIFICATION_INTERVAL,
      getNextPageParam: (lastPage) =>
        hasNextPage(lastPage) ? (lastPage.page || 0) + 1 : undefined,
    },
  );

  useIntersectionObserver({
    targetRef: loadMore,
    onIntersect: query.fetchNextPage,
    enabled: query.hasNextPage,
  });

  const data = combineQueryPagesData(query.data);

  const { mutate: mutateNotification } = useMutation(
    ({ id, ...rest }: Pick<models.Notification, "id" | "is_read">) =>
      api.notifications.patch(id, rest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(querykeys.notifications());
      },
      onError: (errors) => {
        notifyErrors(notify, errors);
      },
    },
  );

  return (
    <Card {...props}>
      <Card.Header>
        <h3>Notificări</h3>
      </Card.Header>
      <Card.Body>
        <div className={bem("container")}>
          {data?.map((notification: models.Notification) => {
            const withLink = notification?.action
              ? new URL(notification.action)
              : null;

            return (
              <div
                className={bem("item", { unread: !notification.is_read })}
                key={notification.id}
                style={!notification.is_read ? { background: "#01689a10" } : {}}
                onClick={() => {
                  if (!notification.is_read) {
                    mutateNotification({
                      id: notification.id,
                      is_read: true,
                    });
                  }
                }}
              >
                <Flex justify="space-between">
                  {withLink ? (
                    <Link to={withLink.pathname} className={bem("item-title")}>
                      {notification.title}
                    </Link>
                  ) : (
                    notification.title
                  )}

                  <Tooltip
                    placement="top"
                    trigger="hover"
                    delayHide={300}
                    tooltip={
                      notification.is_read
                        ? "Marchează ca necitit"
                        : "Marchează ca citit"
                    }
                  >
                    <div onClick={(e) => e.stopPropagation()}>
                      <Icon
                        className="icon"
                        type={notification.is_read ? "open-eye" : "closed-eye"}
                        onClick={() =>
                          mutateNotification({
                            id: notification.id,
                            is_read: !notification.is_read,
                          })
                        }
                      />
                    </div>
                  </Tooltip>
                </Flex>

                <Flex justify="space-between">
                  <h5 className={bem("item-date")}>
                    {formattedDateTime(notification.created_at)}
                  </h5>
                </Flex>
                <p className="mb-10 mt-5">{notification.body}</p>
              </div>
            );
          })}
          <div ref={loadMore} className="text-center m-15">
            <Button
              onClick={() => query.fetchNextPage()}
              disabled={!query.hasNextPage || query.isFetchingNextPage}
            >
              {query.isFetchingNextPage
                ? "Se încarcă..."
                : query.hasNextPage
                ? "Încărcați mai multe"
                : "Nimic de încărcat"}
            </Button>
          </div>
          <Loader loading={query.isFetching && !query.isFetchingNextPage} />
        </div>
      </Card.Body>
    </Card>
  );
};
