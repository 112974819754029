import {
  Grid,
  WhiteSpace,
  InputFormField,
  DatePickerFormField,
  AttachmentsFormField,
  InputIDNPFormField,
  InputPhoneFormField,
} from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";

interface Props {
  index: number;
  showPercentageShares?: boolean;
  attachmentsLabel?: string;
  informationBox?: React.ReactNode;
}

export const JuridicFormFields = ({
  index,
  showPercentageShares,
  attachmentsLabel,
  informationBox,
}: Props) => {
  const person = fields.person;

  return (
    <>
      {informationBox ? (
        <>
          <WhiteSpace />
          {informationBox}
          <WhiteSpace />
        </>
      ) : null}
      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField {...person.name} name={[index, person.name.name]} />

        <InputIDNPFormField
          {...person.idno}
          maxLength={100}
          name={[index, person.idno.name]}
        />
      </Grid>

      <WhiteSpace />

      <Grid cols="1fr 1fr" gap="20px">
        <DatePickerFormField
          {...person.registration_date}
          name={[index, person.registration_date.name]}
        />

        <InputPhoneFormField
          {...person.phone}
          maxLength={100}
          name={[index, person.phone.name]}
        />
      </Grid>

      <Grid cols="1fr 1fr" gap="20px">
        <InputFormField
          {...person.registration_place}
          name={[index, person.registration_place.name]}
        />
        {showPercentageShares ? (
          <InputFormField
            {...person.percentage_shares}
            name={[index, person.percentage_shares.name]}
            suffix={<>%</>}
          />
        ) : null}
      </Grid>

      {!attachmentsLabel && (
        <>
          <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
          <WhiteSpace />
        </>
      )}

      <AttachmentsFormField
        label={attachmentsLabel}
        name={[index, "attachments"]}
      >
        Atașează documente
      </AttachmentsFormField>
    </>
  );
};
