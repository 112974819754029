import { extraTextareaProps } from "../common";

export const financialSolvency = {
  has_insolvency_proceedings: {
    name: "has_insolvency_proceedings",
    label:
      "4.1. În ceea ce priveşte activele companiei dvs. s-au iniţiat în ultimii trei ani proceduri de faliment sau de insolvabilitate?",
    dependencies: ["insolvency_proceedings"],
    info: "4.1. Precizați detaliile privind procedurile de insolvabilitate, faliment sau lichidare care au fost iniţiate împotriva societăţii sau a activelor acesteia în ultimii trei ani.",
  },
  insolvency_proceedings: {
    name: "insolvency_proceedings",
    label: "Dacă răspunsul este da, furnizaţi detalii.",
    placeholder: "Detalii",
    dependencies: ["has_insolvency_proceedings"],
    info: "Precizați detaliile privind procedurile de insolvabilitate, faliment sau lichidare care au fost iniţiate împotriva societăţii sau a activelor acesteia în ultimii trei ani.",
  },
  good_financial_situation: {
    name: "good_financial_situation",
    label:
      "4.2. A avut compania dvs. o situație financiară bună în mod constant, suficientă pentru a-i permite îndeplinirea angajamentelor financiare în ultimii trei ani?",
    info: `<p>4.2. Dovezile sau informaţiile solicitate pot avea legătură şi cu alte datorii contingente sau provizioane, cu poziţia activelor circulante nete sau cu poziţia activelor nete şi cu valoarea imobilizărilor necorporale.
<p>În anumite situaţii, o practică normală ar putea fi ca o companie să deţină active nete negative, de exemplu, atunci când este înfiinţată de o societate-mamă în scopuri de cercetare şi dezvoltare, când pasivele pot fi finanţate printr-un împrumut acordat de societatea-mamă sau de o bancă. În aceste circumstanţe, este posibil ca activele nete negative să nu constituie un indicator al incapacităţii companiei respective de a-şi plăti datoriile legale, însă organele vamale ar putea solicita prezentarea mai multor dovezi, precum un angajament al creditorului, o menţiune privind utilizarea unei garanţii din partea societăţii-mamă sau o scrisoare bancară de creditare ori, în cazul în care compania este singurul proprietar sau, de exemplu, o companie în nume colectiv, o listă a activelor proprii folosite în sprijinul solvabilităţii societăţii comerciale.
<p>Pentru a stabili solvabilitatea financiară a companiei Serviciul Vamal poate solicita să transmiteţi conturile anuale actualizate. Este posibil să fie necesar ca auditorii vamali să examineze copii ale conturilor anuale sau ale situaţiilor financiare complete pentru ultimii trei ani în timpul vizitei pe care o efectuează. De asemenea, vă pot solicita să prezentaţi ultimele conturi de gestiune pentru a stabili o situaţie financiară cât mai actualizată.`,
    defaultDepsValue: [{ financial_situation_attachments: [] }],
  },
  financial_situation_attachments: {
    name: "financial_situation_attachments",
    label: "Documente",
  },
  financial_situation: {
    name: "financial_situation",
    label:
      "Dacă răspunsul este da, prezentaţi dovezi, cum ar fi rapoartele de audit financiar efectuate, dovezi din partea băncii și copiile rapoartelor financiare pentru ultimii trei ani. Dacă răspunsul este nu, oferiți detalii complete.",
    ...extraTextareaProps,
  },
  actual_financial_reports: {
    name: "actual_financial_reports",
    label:
      "4.3. În cazul în care compania dvs. este recent înregistrată, furnizaţi toate registrele şi informaţiile referitoare la situaţia dvs.financiară, de exemplu raportul financiar actualizat la zi, cel mai recent flux de numerar şi previziunile privind profitul şi pierderile aprobate de directori/parteneri/proprietarul unic.",
  },
  has_problems_solvency: {
    name: "has_problems_solvency",
    label:
      "4.4. Sunteţi la curent cu existenţa unui element care ar putea influenţa solvabilitatea financiară a companiei dvs. într-un viitor previzibil?",
    dependencies: ["problems_solvency"],
  },
  problems_solvency: {
    name: "problems_solvency",
    label: "Dacă da, oferiţi mai multe detalii.",
    dependencies: ["has_problems_solvency"],
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
