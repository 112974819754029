import { QueryClient, QueryClientProvider } from "react-query";
import { QueryParamProvider } from "use-query-params";
import { BrowserRouter, Route } from "react-router-dom";

import { NotifyProvider, NotifyContainer, Loader } from "ebs-design";

import { AttachmentsUploadContext } from "@aeo/core/components";
import { AuthProvider } from "@aeo/core/contexts";

import { AppRouter } from "components";
import api from "api";
import { useAutoLogin } from "hooks";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  if (useAutoLogin()) {
    return <Loader loading={true} />;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <AuthProvider {...api.auth}>
            <AttachmentsUploadContext.Provider
              value={{ path: "/aeo/documents" }}
            >
              <NotifyProvider>
                <NotifyContainer />
                <AppRouter />
              </NotifyProvider>
            </AttachmentsUploadContext.Provider>
          </AuthProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
