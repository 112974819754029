import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { useScrollTo } from "@aeo/core/hooks";
import messages from "@aeo/core/messages";
import { capitalize } from "@aeo/core/utils";
import { KeyValueCard, NotesCardForm } from "../components";

export const FinancialSolvencyValues = () => {
  const solvency = fields.financialSolvency;

  //If id of mention exists in url scroll to it.
  useScrollTo();

  return (
    <>
      <KeyValueCard {...solvency.has_insolvency_proceedings} type="boolean" />
      <KeyValueCard {...solvency.insolvency_proceedings} NA />
      <WhiteSpace v="1rem" />
      <KeyValueCard {...solvency.good_financial_situation} type="boolean" />
      <KeyValueCard {...solvency.financial_situation} />
      <KeyValueCard
        {...solvency.financial_situation_attachments}
        type="attachments"
      />
      <WhiteSpace v="1rem" />
      <KeyValueCard {...solvency.actual_financial_reports} type="attachments" />
      <KeyValueCard {...solvency.has_problems_solvency} type="boolean" />
      <KeyValueCard {...solvency.problems_solvency} NA />
      <WhiteSpace />
      <h4>{capitalize(messages.titles.requiredDocuments)}</h4>
      <WhiteSpace />
      <KeyValueCard {...solvency.attachments} type="attachments" />
    </>
  );
};

export const FinancialSolvency = () => {
  return (
    <NotesCardForm
      title={messages.SAQTabs.solvency.title}
      content={<FinancialSolvencyValues />}
      path="saq/solvency"
    />
  );
};
