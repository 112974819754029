import { extraTextareaProps } from "../common";

export const logisticTwo = {
  registration_procedure: {
    name: "registration_procedure",
    label:
      "3.4.1. Descrieţi succint procedura de înregistrare pentru fluxul mărfurilor, începând de la primirea, stocarea, până la producţie şi expediere. Cine ține evidența mărfurilor şi unde sunt păstrate acestea?",
    ...extraTextareaProps,
    info: `3.4.1. Procedurile de înregistrare trebuie să includă:
  1) Înainte de şi la recepţia mărfurilor:
  <ul>
<li>a) proceduri de comandă a achiziţiilor:</li>
<li>b) confirmarea comenzii;</li>
<li>c) expedierea/transportul mărfurilor;</li>
<li>d) cerinţe privind documentele justificative;</li>
<li>e) transportul mărfurilor de la frontieră la sediile sau ale clienţilor;</li>
<li>f) primirea mărfurilor în sediile sau ale clienţilor;</li>
<li>g) plată/achitare;</li>
<li>h) modul în care se înregistrează mărfurile în evidenţa operativă, momentul în care are loc această opraţiune şi persoana care o realizează.</li>
  </ul>
  2) În timpul depozitării mărfurilor:
<ul>
<li>a) atribuirea clară a unui spaţiu de depozitare pentru mărfurile respective;</li>
<li>b) depozitarea în condiţii de siguranţă a mărfurilor periculoase;</li>
<li>c) dacă stocul este înregistrat în funcţie de valoare şi/sau cantitate;</li>
<li>d) existenţa şi frecvenţa inventarierii;</li>
<li>e) dacă se foloseşte spaţiul unui terţ pentru depozitarea mărfurilor , acorduri care să includă reconcilierea dintre evidenţa operativă ţinută desolicitant şi cea a terţului;</li>
<li>f) dacă este ales un spaţiu temporar de depozitare a mărfurilor.</li>
  </ul>
  3)În timpul procesului de producţie a mărfurilor:
<ul>
<li>a) elaborarea comenzii de lucru;</li>
<li>b) comandarea articolelor din inventar şi livrarea de la depozit;</li>
<li>c) procesul de producţie, responsabilităţile personalului şi evidenţele păstrate;</li>
<li>d) codurile de fabricaţie;</li>
<li>e) înregistrarea produsului fabricat şi a stocului neutilizat în evidenţa operativă;</li>
<li>f) utilizarea metodelor standard de producţie în cadrul procesului de fabricare.</li>
  </ul>
  4) În timpul procesului de expediere a mărfurilor:
<ul>
<li>a) primirea comenzii clientului şi elaborarea comenzii de lucru sau de achiziţii;</li>
<li>b) înştiinţarea depozitului cu privire la comanda de vânzare/expedierea mărfurilor;</li>
<li>c) instrucţiuni pentru părţile terţe în cazul în care mărfurile sunt depozitate în altă parte;</li>
<li>d) ridicarea mărfurilor;</li>
<li>e) procedurile de ambalare;</li>
<li>f) modul în care este actualizată evidenţa operativă, momentul în care are loc această operaţiune şi persoana care o realizează.</li>
  </ul>`,
  },
  stock_procedure: {
    name: "stock_procedure",
    label:
      "3.4.2. Descrieţi succint procedurile instituite pentru verificarea nivelului stocurilor, inclusiv frecvenţa acestor verificări şi modul în care sunt soluţionate discrepanţele (de exemplu, verificarea stocurilor şi inventarul)?",
    ...extraTextareaProps,
    info: `3.4.2. Procedurile de verificare şi de control al calităţii trebuie să includă:
  1) În timpul sosirii mărfurilor:
<ul>
<li>a) reconcilierea dintre comanda de achiziţii şi mărfurile primite;</li>
<li>b) proceduri pentru returnarea/respingerea mărfurilor;</li>
<li>c) proceduri pentru înregistrarea contabilă şi raportarea transporturilor în minus şi în plus;</li>
<li>d) proceduri pentru identificarea şi modificarea intrărilor incorecte în evidenţa operativă;</li>
<li>e) identificarea mărfurilor provenite din import.</li>
  </ul>
  2) În timpul depozitării mărfurilor:
<ul>
<li>a) înregistrarea şi controlarea stocurilor;</li>
<li>b) identificarea mărfurilor străine de cele autohtone (nu se aplică pentru un AEOS). </li>
<li>c) deplasarea şi înregistrarea mărfurilor între spaţii din aceeaşi unitate sau din unităţi diferite;</li>
<li>d) proceduri privind gestionarea situaţiilor neobişnuite, a deteriorării sau a distrugerii mărfurilor, a pierderilor şi a variaţiilor de stocuri.</li>
<li>3) În timpul procesului de producţie:</li>
<li>a) monitorizarea şi controalele de gestiune ale procesului de producţie, de exemplu rata de randament;</li>
<li>b) cum trataţi neregulile, variaţiile, deşeurile, produsele secundare şi pierderile din procesul de producţie;</li>
<li>c) inspecţia calităţii mărfurilor produse şi înregistrarea rezultatelor;</li>
<li>d) eliminarea, în condiţii de siguranţă, a mărfurilor periculoase.</li>
  </ul>
  4) În timpul procedurii de expediere a mărfurilor:
<ul>
<li>a) borderouri de expediţie/bonuri de ridicare a mărfurilor;</li>
<li>b) transportul mărfurilor la clienţi sau la frontieră pentru exportare sau reexportare;</li>
<li>c) crearea facturilor de vânzări;</li>
<li>d) instrucţiuni către organul vamal în ceea ce priveşte exportarea/reexportarea şi crearea/punerea la dispoziţie/controlul documentelor justificative;</li>
<li>e) confirmarea de primire/dovada expedierii mărfurilor;</li>
<li>f) mărfuri returnate - inspecţie, numărare şi înregistrare în inventar;</li>
<li>g) note de plată şi note de credit; </li>
<li>h) tratarea neregulilor, a transporturilor incomplete şi a variaţiilor de cantitate livrată.</li>
</ul>
`,
  },
  has_declaration_process: {
    name: "has_declaration_process",
    label:
      "3.5.1. Dispuneţi de proceduri documentate pentru verificarea exactităţii declaraţiilor vamale, inclusiv a celor depuse în numele dvs., de un broker vamal?",
    dependencies: ["declaration_process"],
    defaultDepsValue: [
      {
        declaration_process_attachments: [],
        has_checking_declaration: null,
        checking_declaration: null,
      },
    ],
    info: `3.5.1. În calitate de importatori, exportatori, antrepozitari, procedurile trebuie să includă:
<ul>
<li>a) modul în care asiguraţi caracterul complet, exact şi prompt al declaraţiilor vamale pe care le întocmiţi , inclusiv realizarea controalelor de gestiune;</li>
<li>b) prezentarea documentelor justificative; </li>
<li>c) detalii actualizate (denumiri şi adrese) ale brokerilor vamali/terţilor utilizaţi;</li>
<li>d) modul în care sunt numiţi brokerii vamali, de exemplu, verificările privind credibilitatea şi caracterul adecvat înainte de numirea acestora;</li>
<li>e) împrejurările în care se apelează la aceştia;</li>
<li>f) contractele care detaliază responsabilităţile, inclusiv tipul de reprezentare prin brokerul vamal de exemplu reprezentare directă sau indirectă;</li>
<li>g) modul în care furnizaţi brokerului vamal instrucţiuni clare şi precise;</li>
<li>h) modul în care furnizaţi brokerului vamal documente justificative (de exemplu licenţe, certificate etc.) inclusiv prezentarea şi reţinerea/returnarea acestora;</li>
<li>i) ce trebuie să facă brokerul vamal în cazul în care instrucţiunile sunt neclare;</li>
<li>j) verificarea/examinarea de către a caracterului exact şi prompt al activităţii brokerului vamal;</li>
<li>k) modul în care îl înştiinţaţi pe brokerul cu privire la erorile/modificările referitoare la intrările şterse;</li>
<li>l) soluţionarea neregulilor;</li>
<li>m) informarea voluntară a organelor vamale cu privire la erori. </li>
</ul>
    În calitate de reprezentant al unui terţ, procedurile trebuie să includă:
<ul>
<li>a) contracte care să detalieze responsabilităţile, inclusiv tipul de reprezentare pe care urmează să o utilizaţi, de exemplu, directă sau indirectă;</li>
<li>b) modul în care asiguraţi caracterul complet, exact şi prompt al declaraţiilor vamale pe care le întocmiţi, inclusiv realizarea controalelor de gestiune;</li>
<li>c) prezentarea sau punerea la dispoziţie cu promptitudine a documentelor justificative;</li>
<li>d) modul în care se aduc la cunoştinţă personalului cerinţele clienţilor şi cele contractuale;</li>
<li>e) cum procedaţi dacă instrucţiunile clienţilor sunt neclare sau detaliile furnizate sunt incorecte;</li>
<li>f) cum procedaţi dacă descoperiţi o eroare/modificare în ceea ce priveşte intrările eliminate;</li>
<li>g) informarea voluntară a organelor vamale cu privire la erori.</li>
</ul>
`,
  },
  declaration_process: {
    name: "declaration_process",
    label: "Dacă răspunsul este da, descrieţi succint procedurile. ",
    placeholder: "Detalii",
    dependencies: ["has_declaration_process"],
  },
  declaration_process_attachments: {
    name: "declaration_process_attachments",
    label: "Documente",
  },
  has_checking_declaration: {
    name: "has_checking_declaration",
    label:
      "Dacă răspunsul este nu, dvs. verificați exactitatea declaraţiei vamale?",
    dependencies: ["checking_declaration"],
  },
  checking_declaration: {
    name: "checking_declaration",
    label: "Dacă răspunsul este da, în ce mod?",
    placeholder: "Detalii",
    dependencies: ["has_checking_declaration"],
  },
  has_irregularities_instruction: {
    name: "has_irregularities_instruction",
    label:
      "3.5.2. a) Dispune compania dvs. de instrucţiuni sau orientări privind notificarea neregulilor către organele vamale (de exemplu, suspiciuni de furt, contrabandă în legătură cu mărfurile care fac obiectul formalităților vamale)?",
    info: "3.5.2. Atunci când aceste instrucţiuni sunt documentate, trebuie să furnizaţi dovezi, în timpul vizitei auditorilor vamali, care să ateste că acestea sunt revizuite în mod regulat şi complet, că documentaţi modificările şi înştiinţaţi personalul vizat în legătură cu modificările respective.",
    defaultDepsValue: [
      { has_documented_instruction: null },
      { documented_instruction_attachments: [] },
    ],
  },
  has_documented_instruction: {
    name: "has_documented_instruction",
    label:
      "Sunt aceste instrucţiuni documentate (de exemplu, instrucţiuni de locul de muncă, alte documente orientative)?",
    dependencies: ["has_irregularities_instruction"],
  },
  documented_instruction_attachments: {
    name: "documented_instruction_attachments",
    label: "Documente",
  },
  has_identified_violation: {
    name: "has_identified_violation",
    label:
      "b) În ultimul an aţi identificat nereguli (sau presupuse nereguli) şi le-aţi notificat organelor vamale?",
  },
  identified_violation: {
    name: "identified_violation",
    label: "Dacă răspunsul este da, indicaţi detalii.",
  },
  has_licensed_goods: {
    name: "has_licensed_goods",
    label:
      "3.5.3. a) Comercializați mărfuri care fac obiectul unor licenţe/autorizații de import și export legate de prohibiții și restricții?",
    info: "3.5.3. În cazul în care există proceduri pentru gestionarea licenţelor şi a autorizaţiilor, trebuie să furnizaţi dovezi, în timpul vizitei auditorilor vamali, care să ateste că acestea sunt revizuite în mod regulat şi complet, că documentaţi orice modificare şi înştiinţaţi personalul vizat în legătură cu modificările respective.",
    defaultDepsValue: [{ licensed_goods_attachments: [] }],
  },
  licensed_goods_attachments: {
    name: "licensed_goods_attachments",
    label: "Documente",
  },
  has_external_license: {
    name: "has_external_license",
    label:
      "b) Comercializați mărfuri care fac obiectul altor licențe și/sau autorizații de import  sau de export?",
    dependencies: ["external_license"],
    defaultDepsValue: [{ external_license_attachments: [] }],
  },
  external_license_attachments: {
    name: "external_license_attachments",
    label: "Documente",
  },
  external_license: {
    name: "external_license",
    label:
      "c) Dacă răspunsul este da, specificați tipurile de mărfuri, dacă aveți instituite proceduri pentru gestionarea acestora  și modul în care sunt actualizate.",
    placeholder: "Detalii",
    dependencies: ["has_external_license"],
  },
  has_goods_under_law: {
    name: "has_goods_under_law",
    label:
      "3.5.4. Comercializați mărfuri care cad sub incidența Legii nr. 1163/ 2000 cu privire la controlul exportului, reexportulu, importului și tranzitului de mărfuri strategice?",
    dependencies: ["goods_under_law"],
    info: "3.5.4. În cazul în care gestionaţi mărfuri care intră sub incidenţa Legii nr. 1163/2000 cu privire la controlul exportului, reexportului, importului și tranzitului de mărfuri strategice, prezentaţi autorităţii competente o listă cu aceste mărfuri. De asemenea, trebuie să informaţi organele vamale în cazul în care aţi pus în aplicare un program intern de asigurare a conformităţii (ICP).",
  },
  goods_under_law: {
    name: "goods_under_law",
    label:
      "Dacă răspunsul este da, specificați tipurile de mărfuri, dacă aveți instituite proceduri pentru gestionarea autorizațiilor/certificatelor și modul în care sunt actualizate acestea.",
    placeholder: "Detalii",
    dependencies: ["has_goods_under_law"],
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
