import { Icon } from "@aeo/core/components/icons";
import { makeBEM } from "@aeo/core/utils";

const bem = makeBEM("tabs__label");

interface Props {
  index: string | number;
  title: string;
  icon?: boolean;
}

export const TabLabel = ({ index, title }: Props) => {
  return (
    <div className="tabs__label">
      <div className={bem("content")}>
        <div className={bem("content__circle")}>{index}</div>
        <p>{title}</p>
      </div>
      <Icon className={bem("content__icon")} type="chevrone-right" />
    </div>
  );
};
