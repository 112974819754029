import React from "react";
import { Tooltip } from "ebs-design";
import { Flex, Icon } from "@aeo/core/components";

export const TitleTooltip = ({
  title,
  info,
}: {
  title: React.ReactNode;
  info: React.ReactNode;
}) => {
  if (!info) return <>{title}</>;

  return (
    <Flex className="title-tooltip" justify="space-between">
      {title}
      <Tooltip
        bodyClass="title-tooltip__info"
        placement="top"
        tooltip={<div dangerouslySetInnerHTML={{ __html: String(info) }} />}
        trigger="hover"
        offset={[0, 14]}
        delayHide={100}
      >
        <Icon size={18} type="information" stroke="#01689a" />
      </Tooltip>
    </Flex>
  );
};
