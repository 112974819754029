import { AxiosResponse } from "axios";
import models from "@aeo/core/models";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "@aeo/core/app-constants";
import { authAxios, apiAxios } from "@aeo/core/api/axios";
import coreApi from "@aeo/core/api";
import api from ".";

const REFETCH_INTERVAL = 30000; // 10 seconds

export const clearLocalStorage = () => {
  // Remove tokens from local storage
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const auth = {
  // Query options for user profile request
  userQueryOptions: {
    // Refetch profile to get unread notifications and chat messages
    refetchInterval: (data: models.Profile | undefined) => {
      // If there is no data, do not refetch the profile because it's logout
      if (!data) {
        return false;
      }

      return REFETCH_INTERVAL;
    },
  },

  getUser: async () => {
    try {
      // Verify if the user token is valid
      await coreApi.authentication.verify();
      const user = await api.profile.get();

      return user;
    } catch (e) {
      // If the user token is not valid, clear the local storage
      clearLocalStorage();
      throw e;
    }
  },

  register: async (credentials: models.RegisterCredentials) => {
    await authAxios.post<
      models.RegisterCredentials,
      AxiosResponse<models.TokensResponse>
    >(`/aeo/auth/register`, credentials);
  },

  confirmRegister: async (credentials: models.ConfirmCredentials) => {
    const { headers } = await authAxios.post<
      models.ConfirmCredentials,
      AxiosResponse
    >(`/aeo/management/users/confirm`, credentials);

    if (headers.location) {
      window?.open(headers.location, "_blank")?.focus();
    }
  },

  logout: async () => {
    try {
      await authAxios.get("/aeo/auth/logout", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
        },
      });
    } finally {
      clearLocalStorage();
    }
  },

  login: async () => {
    const response = await authAxios.post<
      null,
      AxiosResponse<models.TokensResponse>
    >(`/authentication/login`, null);
    response?.data?.refresh &&
      localStorage.setItem(REFRESH_TOKEN_KEY, response?.data?.refresh);
    response?.data?.access &&
      localStorage.setItem(ACCESS_TOKEN_KEY, response?.data?.access);
  },

  // Recovery password
  recoveryPassword: async (body: models.RecoveryPassword) => {
    const { data } = await apiAxios.post<
      models.RecoveryPassword,
      AxiosResponse<models.ResponseWithSuccess>
    >(`/aeo/auth/restore-request`, body);

    return data;
  },

  // Reset password
  resetPassword: async (body: models.ResetPassword) => {
    const { data } = await apiAxios.post<
      models.ResetPassword,
      AxiosResponse<models.ResponseWithSuccess>
    >(`/aeo/auth/restore-confirm`, body);

    return data;
  },

  mpassReceive: async (body: models.MpassReceive) => {
    const { data } = await apiAxios.post<models.MpassReceive>(
      `/mpass/receive`,
      body,
    );

    return data;
  },

  getMpassSAML: async (id: string) => {
    const { data } = await apiAxios.get<models.MpassSAML>(`/saml/${id}`);

    return data;
  },
};
