import { useMemo } from "react";
import { AttachmentItem, Grid } from "@aeo/core/components";
import models from "@aeo/core/models";
import { formattedDateTime, makeBEM } from "@aeo/core/utils";
import { useAuth } from "@aeo/core/hooks";

export interface ChatMessageProps extends models.TemporaryChatReply {
  error?: boolean;
  loading?: boolean;
}

const bem = makeBEM("chat-message");
const bgLoaderBem = makeBEM("background-loader");

export const ChatMessage = ({
  created_at,
  body,
  user: sender,
  attachments,
  error,
  loading,
}: ChatMessageProps) => {
  const { user } = useAuth();

  const senderFullName = useMemo(
    () => `${sender?.first_name || ""} ${sender?.last_name || ""}`,
    [sender],
  );
  const senderFunction = sender?.function ? ` - ${sender.function}` : "";

  return (
    <div
      className={bem(null, [user?.id === sender?.id ? "sent" : "received"], {
        loading,
      })}
    >
      <h5 className={bem("name")} title={`${senderFullName} ${senderFunction}`}>
        {`${senderFullName} ${senderFunction}`}
      </h5>
      <div className={bem("content")}>
        <div>{body}</div>
        {attachments?.length ? (
          <Grid gap="0.5rem">
            <div></div>
            {attachments?.map((a, idx) => (
              <AttachmentItem key={idx} {...a} color="#252c32" />
            ))}
          </Grid>
        ) : null}
        {/** Chose different loader */}
        {loading && <div className={bgLoaderBem(null, { loading })}></div>}
      </div>
      <div className={bem("info", { error })}>
        {error ? "Eroare" : formattedDateTime(created_at)}
      </div>
    </div>
  );
};
