import { AxiosResponse } from "axios";
import models from "@aeo/core/models";
import { GenericObject } from "../types";

import { apiAxios } from "./axios";
import { jsonFromBufferTransformer, stringifyUrl } from "./utils";

export const nomenclatures = {
  getEntityList: async <T = models.IdName>(
    entity: string,
    query: GenericObject = {},
  ) => {
    const url = stringifyUrl(`/nomenclatures/${entity}`, query);
    const { data } = await apiAxios.get<models.WithResults<T>>(url);
    return data;
  },
  getEntityById: async <T = models.IdName>(
    entity: string,
    id: string,
    query: GenericObject = {},
  ) => {
    const url = stringifyUrl(`/nomenclatures/${entity}/${id}`, query);
    const { data } = await apiAxios.get<T>(url);
    return data;
  },
  editEntity: async <T = models.IdName>(
    entity: string,
    id: string,
    body: any,
  ) => {
    return await apiAxios.patch<models.WithResults<T>>(
      `/nomenclatures/${entity}/${id}`,
      body,
    );
  },
  createEntity: async <T = models.IdName>(entity: string, body: any) => {
    return await apiAxios.post<T>(
      `/nomenclatures/${entity}`,
      body,
    );
  },
  deleteEntity: async <T = models.IdName>(entity: string, id: string) => {
    return await apiAxios.delete<models.WithResults<T>>(
      `/nomenclatures/${entity}/${id}`,
    );
  },
  phases: {
    get: async (query: GenericObject = {}) => {
      const url = stringifyUrl(`/nomenclatures/phases`, query);
      const { data } = await apiAxios.get<
        models.WithResults<models.PhaseNomenclature>
      >(url);
      return data;
    },
  },
  criterions: {
    get: async (query: GenericObject = {}) => {
      const url = stringifyUrl(`/nomenclatures/criterions`, query);
      const { data } = await apiAxios.get<
        models.WithResults<models.CriterionNomenclature>
      >(url);
      return data;
    },
    getById: async (id: number) => {
      const { data } = await apiAxios.get<models.CriterionNomenclature>(
        `/nomenclatures/criterions/${id}`,
      );
      return data;
    },
  },
  orderPreview: async (body: models.ContentEntity) => {
    const { data } = await apiAxios.post<
      models.ContentEntityWithHeader,
      AxiosResponse<ArrayBuffer>
    >("/nomenclatures/templates/preview", body, {
      responseType: "arraybuffer",
      transformResponse: jsonFromBufferTransformer,
    });
    return data;
  },
};
