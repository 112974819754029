import { extraTextareaProps } from "../common";

export const organizationStructure = {
  departament_description: {
    label:
      "1.1.7. Descrieți structura organizatorica interna a companiei dvs. și sarcinile/responsabilitățile fiecărui departament. ",
    name: "departament_description",
    ...extraTextareaProps,
    info: "1.1.7. Furnizați o organigramă detaliată care să includă toate domeniile/departamentele companiei, funcţiile/responsabilităţile acestora şi lanţul de gestionare.",
  },
  employee_description: {
    label:
      "1.1.8. Specificați numele persoanelor de conducere (director general, adjuncți, şefi de departamente, contabil şef, şef aldepartamentului aferent domeniului vamal etc.) din cadrul companiei şi descrieţi succint regulamentul de ordine interioară.",
    name: "employee_description",
    ...extraTextareaProps,
    info: "1.1.8 În cazul în care nu s-a menţionat deja la întrebările de la subpunctul 1.1.2 de la literele b) şi c), precizați numele şi adresa completă, data naşterii şi codul numeric personal.Procedurile trebuie să evidenţieze măsurile luate pentru suplinirea absenţelor temporare sau de scurtă durată ale personalului cheie, de exemplu persoana responsabilă de domeniul vamal sau agentul de import, inclusiv modul în care sunt preluate responsabilităţile obişnuite ale acestora şi persoana care le preia.",
  },
  category: {
    label: (
      <>
        1.1.9 Cați angajați are compania dvs.? <br />
        Indicați din urmatoarele opțiuni:
      </>
    ),
    name: "category",
    info: `1.1.9 Specificaţi numărul (aproximativ) angajaților  la data depunerii cererii. Totodată bifați tipul companiei, astfel cum este definit în art. 5 al Legii nr. 179/2016 cu privire la întrerpinderile mici și mijlocii, după cum urmează: 
    <table style="border-collapse:collapse;border-spacing:0;">
    <thead>
      <tr>
        <th style="border:1px solid black; font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">Categoria de
        întreprindere
        </th>
        <th style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">Numărul de
        Angajaţi
        </th>
        <th style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">Cifra anuală de afaceri</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">Mare</td>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;"> 250</td>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">Oricare</td>
      </tr>
      <tr>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">Mijlocie</td>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">< 249</td>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">< 50 milioane lei</td>
      </tr>
      <tr>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">Mică</td>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">< 49</td>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">< 25 milioane lei</td>
      </tr>
      <tr>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">Microîntreprindere</td>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">< 9</td>
        <td style="border: 1px solid black;font-family:Arial, sans-serif;font-size:14px;
        overflow:hidden;padding:10px 5px;word-break:normal;">< 9 milioane lei</td>
      </tr>
    </tbody>
    </table>`,
  },
  employee_count: {
    name: "employee_count",
    label: "Numărul angajaților",
  },
  agreement_publication: {
    name: "agreement_publication",
    label:
      "Prin prezenta, îmi exprim acordul la publicarea și schimbul informațiilor cuprinse în autorizația AEO.",
    info: `<p><b>ACORD PENTRU PUBLICAREA INFORMAŢIILOR</b></p><p><b>Baza de date a autorizațiilor AEO:</b> Datele privind cererile de autorizare, emiterea sau respingerea acestora sunt păstrate în evidenţa Serviciului Vamal. În cazul în care operatorul economic este de acord să fie inclus în lista menţionată solicitantul va completa și va semna acordul de a publica informații privind AEO pe site-ul web al Serviciului Vamal. Chestionarul trebuie semnat de administrator, asociatul unic, unul dintre asociaţi, un director sau acţionar majoritar, în măsura în care această persoană reprezintă legal operatorul economic.</p>`,
  },
  partial_agreement_publication: {
    name: "partial_agreement_publication",
    label:
      "Prin prezența îmi exprim acordul cu privire la schimbul de informații din autorizația AEO pentru a permite punerea în aplicare a acordurilor internaționale cu state terțe privind recunoașterea reciprocă a statutului de AEO și a măsurilor legate de securitate.",
    defaultDepsValue: [{ employee_name: null }, { employee_function: null }],
  },
  employee_name: {
    name: "employee_name",
    label: "Nume și Prenume angajat",
    placeholder: "Ex: John John",
  },
  employee_function: {
    name: "employee_function",
    label: "Funcție angajatului",
    placeholder: "Funcția angajatului",
  },
  attachments: {
    name: "attachments",
    label: "Documente",
  },
};
