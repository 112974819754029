import cn from "classnames";

import { makeBEM } from "@aeo/core/utils";

type HTMLAnchorAttributes = JSX.IntrinsicElements["a"];
interface Props extends HTMLAnchorAttributes {}

const bem = makeBEM("login-with-mpass-link-button");

const loginPageURL = process.env.REACT_APP_MPASS_LOGIN_URL;
const mPassBaseURL = process.env.REACT_APP_MPASS_BASE_URL;

export const LoginWithMPassLinkButton = ({ className, ...props }: Props) => {
  if (!loginPageURL || !mPassBaseURL) return null;

  const logoURL = new URL(
    "/images/logos/mpass-logo.png",
    mPassBaseURL,
  ).toString();

  return (
    <a className={cn(className, bem())} href={loginPageURL} {...props}>
      <span>Loghează-te prin </span>
      <img className={bem("logo")} src={logoURL} alt="MPass" />
    </a>
  );
};
