import React from "react";
import cn from "classnames";
import { useQueryClient } from "react-query";
import { Button, Badge } from "ebs-design";

import {
  ChatMessageListContent,
  Flex,
  Icon,
  SendMessageForm,
} from "@aeo/core/components";
import { usePopupClose, useAuth } from "@aeo/core/hooks";
import { makeBEM } from "@aeo/core/utils";
import models from "@aeo/core/models";

import api, { querykeys } from "api";

const bem = makeBEM("chat");

export const Chat = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const queryClient = useQueryClient();

  const overlayRef = React.useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  usePopupClose(overlayRef, () => setIsOpen(false));

  const [justSent, setJustSent] = React.useState<models.TemporaryChatReply[]>(
    [],
  );

  const loadMoreRootRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={bem("trigger")}>
        <Badge
          className={cn(bem("badge"), "ebs-badge--pulse")}
          count={user?.unread_messages}
          type="danger"
        >
          <Button
            type="primary"
            round
            icon={() => <Icon type="chat" />}
            onClick={() => setIsOpen(true)}
          />
        </Badge>
      </div>

      {isOpen && <div className={cn(bem("overlay"))} ref={overlayRef}></div>}

      {isOpen && (
        <div className={cn(bem("surface", { open: isOpen }), "surface")}>
          <section>
            <Flex justify="space-between" align="center">
              <h3 className={bem("title")}>Centrul de ajutor</h3>
              <Icon
                className={bem("close")}
                onClick={() => setIsOpen(false)}
                type="close"
                stroke="#01689a"
                size={20}
              />
            </Flex>
          </section>
          <section className={bem("info-box")}>
            <p>
              Centrul de ajutor reprezintă o comunicare generală, nu poartă nici
              un caracter juridic și nu poate servi ca bază juridică.
            </p>
            <p>Luni - Vineri : 09:00 – 17:00</p>
          </section>
          <section className={bem("container")}>
            <div className={bem("messages-wrapper")}>
              <div className={bem("messages-list")} ref={loadMoreRootRef}>
                <ChatMessageListContent
                  querykey={querykeys.chat.replies}
                  loadMoreRootRef={loadMoreRootRef}
                  justSent={justSent}
                  apiCall={api.chat.getReplies}
                  onSuccess={() =>
                    queryClient.invalidateQueries(querykeys.loggedUser)
                  }
                  customEmptyList={
                    <div className={bem("empty-messages-container")}>
                      <div>
                        Operatorul o să vă răspundă cât de curând posibil.
                      </div>
                      <div>Pentru ajutor puteți să ne scrie-ți.</div>
                    </div>
                  }
                />
              </div>
            </div>
            <SendMessageForm
              uploadUrl={"/aeo/chat/files"}
              apiCall={api.chat.postReply}
              setJustSent={setJustSent}
            />
          </section>
        </div>
      )}
    </>
  );
};
