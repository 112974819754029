import { Button } from "ebs-design";

import cn from "classnames";

import { Icon, ViewPdfModal, WhiteSpace } from ".";

import { NO_TITLE_PLACEHOLDER } from "../app-constants";
import models from "../models";
import { DynamicCssProperties } from "../types";
import { makeBEM, checkFileType } from "../utils";
import { useDownloadFile, useModalState } from "../hooks";

type ToExtend = Omit<React.HTMLProps<HTMLDivElement>, "id"> & models.Attachment;
export interface AttachmentItemProps extends ToExtend {
  /** 0-100 */
  progress?: number;
  loading?: boolean;
  onRemove?: () => void;
  error?: Error;
  color?: string;
  downloadOnly?: boolean;
}

const bem = makeBEM("attachment-item");
const bgLoaderBem = makeBEM("background-loader");

export const AttachmentItem = ({
  title,
  file_upload,
  progress,
  loading,
  onRemove,
  error,
  created_at,
  id,
  signed,
  className,
  style,
  color,
  downloadOnly,
  ...props
}: AttachmentItemProps) => {
  const modalPdf = useModalState();
  const downloadFile = useDownloadFile();
  const isPdf = checkFileType(file_upload);

  const attachment: models.Attachment = { file_upload, title };

  return (
    <>
      <div
        className={cn(className, bem(null, { loading }))}
        style={{ "--progress": progress, ...style } as DynamicCssProperties}
        onClick={() =>
          isPdf && !downloadOnly
            ? modalPdf.openWith(true)
            : downloadFile(attachment)
        }
        {...props}
      >
        {error ? (
          <div className="color-danger flex-1">{error.message}</div>
        ) : (
          <span style={{ "--attachment-color": color } as DynamicCssProperties}>
            {title || `(${NO_TITLE_PLACEHOLDER})`}
          </span>
        )}
        {onRemove && (
          <>
            <WhiteSpace h="0.5rem" />
            <Button
              className="btn-xsm"
              round
              icon={() => <Icon type="close" size="1em" />}
              onClick={onRemove}
            />
          </>
        )}
        {!error && progress !== undefined && (
          <div className={bem("progress")}></div>
        )}
        {loading && <div className={bgLoaderBem(null, { loading })}></div>}
      </div>
      {modalPdf.isOpen && (
        <ViewPdfModal
          file={file_upload}
          fileTitle={title}
          open={modalPdf.isOpen}
          onClose={modalPdf.close}
          title={title}
          size="large"
        />
      )}
    </>
  );
};
