import { Button, Switch, Tooltip } from "ebs-design";

import { Flex, Icon, WhiteSpace } from "@aeo/core/components";
import {
  TableColumnBuilder,
  getFullName,
  aeoUserRoles,
  AEOUserRole,
} from "@aeo/core/utils";
import models from "@aeo/core/models";

interface Args {
  onResendConfirmation: (username: string) => void;
  onEditClick: React.Dispatch<models.User>;
  onDeleteClick: React.Dispatch<models.User>;
  onChangeUserStatus: React.Dispatch<Pick<models.User, "is_blocked" | "id">>;
  currentUser: models.Profile | null;
}

export const usersTableColumns = ({
  onResendConfirmation,
  onEditClick,
  onDeleteClick,
  onChangeUserStatus,
  currentUser,
}: Args) =>
  new TableColumnBuilder<models.User>()
    .add("Nume și Prenume", "", getFullName)
    .add("IDNP", "idnp")
    .add("E-mail", "", (record) => record.email || record.username)
    .add("Telefon", "phone")
    .add("Funcție", ["profile", "function"])
    .add(
      "Rol utilizator",
      ["profile", "role"],
      (value: AEOUserRole) => aeoUserRoles[value],
    )
    .add("Blocare", "", (record) => (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Switch
          checked={record.is_blocked}
          disabled={currentUser?.id === record.id}
          onChange={() => onChangeUserStatus(record)}
        />
      </div>
    ))
    .add(
      "",
      "",
      (record) => (
        <Flex justify="flex-end" onClick={(e) => e.stopPropagation()}>
          {!record.last_login && (
            <Tooltip
              placement="top"
              title="Retrimite invitația"
              trigger="hover"
              delayHide={300}
            >
              <Button
                onClick={() => onResendConfirmation(record.username)}
                size="small"
                icon={() => <Icon type="send" />}
              ></Button>
            </Tooltip>
          )}

          {record.id !== currentUser?.id && (
            <>
              <WhiteSpace h="1rem" />
              <Button
                onClick={() => onEditClick(record)}
                size="small"
                icon={() => <Icon type="edit" />}
              />
              <WhiteSpace h="1rem" />
              <Button
                onClick={() => onDeleteClick(record)}
                size="small"
                icon={() => <Icon type="delete" />}
              />
            </>
          )}
        </Flex>
      ),
      { width: 1 },
    )
    .getColumns();
