import { makeBEM } from "@aeo/core/utils";
import { getImageSrc } from "./utils";

import fileShapeSrc from "./assets/File_shape.svg";

export interface AttachmentImageProps {
  ext: string;
}

const bem = makeBEM("attachment-image");

export const AttachmentImage = ({ ext }: AttachmentImageProps) => {
  const src = getImageSrc(ext);

  return (
    <div className={bem("wrapper")}>
      <img src={fileShapeSrc} alt="" className={bem("file-shape")} />
      <img src={src} alt="" className={bem("image")} />
    </div>
  );
};
