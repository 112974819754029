import { apiAxios } from "@aeo/core/api/axios";
import { stringifyUrl } from "@aeo/core/api/utils";
import { GenericObject } from "@aeo/core/types";

export const complaints = {
  get: async (queryParams: GenericObject) => {
    const url = stringifyUrl(`/aeo/complaints`, queryParams);
    const { data } = await apiAxios.get(url);
    return data;
  },
  post: async (complaint: string) => {
    const { data } = await apiAxios.post(`/aeo/complaints`, complaint);
    return data;
  },
  delete: async (id?: string) => {
    const url = stringifyUrl(`/aeo/complaints/${id}`);
    await apiAxios.delete(url);
  },
  patch: async (id: string, complaint: string) => {
    const { data } = await apiAxios.patch(`/aeo/complaints/${id}`, complaint);
    return data;
  },
};
