import ReactPaginate from "react-paginate";

import { ButtonGroup, Button } from "ebs-design";

import { Flex, Icon, WhiteSpace } from "@aeo/core/components";
import { UsePaginationResult } from "@aeo/core/hooks";
import models from "@aeo/core/models";
import defaults from "@aeo/core/app-defaults";

export interface PaginationProps {
  pagination: UsePaginationResult;
  data: models.WithResults<unknown> | undefined;
  pageSizeChoice?: number[];
}

export const Pagination = ({
  pagination,
  data,
  pageSizeChoice = defaults.pagination.pageSizeChoice,
}: PaginationProps) => {
  return (
    <>
      {data?.results?.length ? (
        <Flex align="center">
          <ButtonGroup>
            {pageSizeChoice.map((choice) => (
              <Button
                key={choice}
                size="small"
                type={choice === pagination.pageSize ? "primary" : "ghost"}
                onClick={() => pagination.setPageSize(choice)}
              >
                {choice}
              </Button>
            ))}
          </ButtonGroup>
          <WhiteSpace h="1rem" />

          <ReactPaginate
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={data.num_pages || 0}
            previousLabel={<Icon type="arrow-left" />}
            nextLabel={
              <Icon type="arrow-left" style={{ transform: "rotate(180deg)" }} />
            }
            containerClassName="pagination"
            nextClassName="pagination__move"
            previousClassName="pagination__move"
            breakClassName="pagination__break"
            pageLinkClassName="pagination__page"
            forcePage={pagination.page - 1}
            onPageChange={({ selected }: { selected: number }) =>
              pagination.setPage(selected + 1)
            }
          />
        </Flex>
      ) : null}
    </>
  );
};
