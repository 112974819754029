import { useState } from "react";
import cn from "classnames";
import { Input, Form } from "ebs-design";
import { InputSize } from "ebs-design/dist/components/atoms/Input/Input";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { Icon } from "@aeo/core/components/icons";

import { FieldWithInfo } from "./FieldWithInfo";
import { relativeClassName } from "./utils";

export interface InputPasswordShowField extends FormFieldProps {
  size?: InputSize;
  placeholder?: string;
  info?: React.ReactNode;
}

export const InputPasswordShowField = ({
  size = "large",
  placeholder,
  info,
  extra,
  className,
  ...restProps
}: InputPasswordShowField) => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <Form.Field
      {...restProps}
      extra={<FieldWithInfo extra={extra} info={info} />}
      className={relativeClassName(info, className)}
    >
      <Input
        type={passwordShown ? "text" : "password"}
        size={size}
        placeholder={placeholder}
        className={cn({ "icon-active": passwordShown })}
        suffix={
          <Icon
            onClick={() => setPasswordShown(!passwordShown)}
            type="open-eye"
            className={cn({ "icon-active": passwordShown })}
          />
        }
      />
    </Form.Field>
  );
};
