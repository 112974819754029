import React from "react";

import { Button, Card, Form, List } from "ebs-design";
import { NamePath } from "rc-field-form/es/interface";

import { Icon, Flex } from "@aeo/core/components";
import { capitalize } from "@aeo/core/utils";

export interface FormListProps {
  subTitle: string;
  name: NamePath;
  initialValue?: any;
  componentProps?: any;
  Component: React.ComponentType<{
    index: number;
    people?: string;
    name?: string;
  }>;
  disableAdd?: boolean;
  emptyList?: boolean;
}

export const FormList = ({
  subTitle,
  Component,
  componentProps,
  name,
  emptyList,
  initialValue = emptyList ? [] : [{}],
}: FormListProps) => {
  return (
    <>
      <Form.Field dependencies={[name]}>
        {(_, __, form) => {
          return React.createElement(() => {
            React.useLayoutEffect(() => {
              if (form.getFieldValue(name)?.length === 0) {
                form.resetFields([name]);
              }
            }, []);
            return <></>;
          });
        }}
      </Form.Field>
      <List name={name} initialValue={initialValue}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => (
                <Card className="bordered mt-20" key={index}>
                  <Card.Body>
                    <Flex justify="space-between">
                      <h4>
                        {capitalize(subTitle)} {index + 1}
                      </h4>

                      <Form.Field key={field.key}>
                        {(_, __, form) => (
                          <Button
                            size="small"
                            icon={() => (
                              <Icon
                                type="close"
                                onClick={() => {
                                  remove(index);

                                  /**
                                   * Form list isn't handling properly item removing
                                   *
                                   * If you have 3 items and you delete the second, then
                                   * the errors from the second will go to the item that was
                                   * previously third (the errors keep their index)
                                   */
                                  form.validateFields();
                                }}
                              />
                            )}
                          />
                        )}
                      </Form.Field>
                    </Flex>
                    <Component index={index} name={name} {...componentProps} />
                  </Card.Body>
                </Card>
              ))}
              <Button
                size="large"
                onClick={() => (emptyList ? add() : add({}))}
                block
                className="mt-20"
              >
                <Flex justify="center">
                  <Icon type="plus" className="mr-5" />
                  <span>Adaugă {String(subTitle).toLowerCase()}</span>
                </Flex>
              </Button>
            </>
          );
        }}
      </List>
    </>
  );
};
