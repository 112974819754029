import React from "react";
import { AxiosError } from "axios";
import { UseMutationResult } from "react-query";
import { Button, Form, useForm } from "ebs-design";

import {
  AttachmentsFormField,
  Editor,
  Flex,
  LoadingButton,
  WhiteSpace,
} from "@aeo/core/components";
import models from "@aeo/core/models";

interface TableEditorProps {
  documentId?: number;
  state?: models.ApprovalDocumentType;
  mutation?: UseMutationResult<unknown, AxiosError, any, unknown>;
  onCloseRow: () => void;
}
export const RowEditor = ({
  documentId,
  state,
  mutation,
  onCloseRow,
}: TableEditorProps) => {
  const [editorState, setEditorState] = React.useState("");

  const [form] = useForm();

  const approvalDocumentType = {
    [models.ApprovalDocumentType.ReportProjectAuditApproval]: "report",
    [models.ApprovalDocumentType.ReportProjectReAuditApproval]: "re-report",
  };

  const handleClick = () => {
    mutation?.mutate({
      id: documentId,
      body: {
        content: editorState,
        attachments: form.getFieldValue("attachments"),
      },
      documentState: approvalDocumentType[state!],
    });
  };

  return (
    <Form form={form} className="editor-form">
      <Editor value={editorState} onChange={setEditorState} />

      <div className="editor-form__footer ">
        <Flex justify="space-between">
          <AttachmentsFormField className="ml-15" />
          <Flex align="baseline">
            <Button onClick={onCloseRow}>Anulează</Button>
            <WhiteSpace h="1rem" />
            <LoadingButton
              className="mr-15"
              type="primary"
              onClick={handleClick}
              loading={mutation?.isLoading}
            >
              Transmite raspuns
            </LoadingButton>
          </Flex>
        </Flex>
      </div>
    </Form>
  );
};
