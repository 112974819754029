import { Card, Form, useNotify } from "ebs-design";
import { useMutation } from "react-query";

import models from "@aeo/core/models";
import { notifyErrors } from "@aeo/core/utils";
import {
  AttachmentsFormField,
  Flex,
  InputFormField,
  LoadingButton,
} from "@aeo/core/components";
import { ApplicationExaminationStateType } from "@aeo/core/types";
import { notifications } from "@aeo/core/messages/notifications";

import api from "api";

interface Props {
  title: string;
  recommendation: models.Recommendation;
  application: {
    id: number;
    type: ApplicationExaminationStateType;
  };
}

export const RecommendationForm = ({
  title,
  recommendation,
  application,
}: Props) => {
  const notify = useNotify();

  const { mutate, isLoading } = useMutation(
    (body: models.Recommendation) =>
      api.application.patchRecommendation(
        application.id,
        application.type,
        body,
      ),
    {
      onSuccess: () => notify.success({ title: notifications.response }),
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <Form key={recommendation.id} onFinish={mutate}>
      <Card className="bordered">
        <Card.Body className="pb-0 pt-10 pl-20 pr-20">
          <h4 className="mb-5 mt-10">{title}</h4>
          <p>{recommendation?.description}</p>

          <h4 className="mb-5 mt-10">Răspuns recomandare:</h4>
          <Form.Field name="id" initialValue={recommendation.id} />
          <InputFormField
            name="response"
            textarea
            initialValue={recommendation.response}
          />

          {recommendation?.can_response && (
            <Flex justify="space-between" align="end">
              <AttachmentsFormField
                key={recommendation.id}
                name="attachment"
                multiple={false}
                initialValue={recommendation.attachment}
              />
              <LoadingButton
                submit
                className="mb-20"
                type="primary"
                loading={isLoading}
              >
                Răspunde
              </LoadingButton>
            </Flex>
          )}
        </Card.Body>
      </Card>
    </Form>
  );
};
