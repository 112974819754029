import { RuleObject } from "rc-field-form/es/interface";

/**
 * The provided pattern accepts letters (any language), comma `,`, apostrophe `'`, dot `.` and dash `-`
 */
export const nameRule: RuleObject = {
  pattern: /^[,.\-'\p{L}]+$/gu,
  message: "Introduceți un nume valid",
};

/**
 * The provided pattern doesn't accept only spaces in field
 */
export const noSpaceRule: RuleObject = {
  pattern: /[^ ]+/g,
  message: "Introduceți o valoare validă",
};
