import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { NumberParam } from "use-query-params";
import { Card, Button, Space, useNotify } from "ebs-design";

import { useParam } from "@aeo/core/hooks";
import { Tab } from "@aeo/core/utils/TabBuilder";
import { SignModal, WhiteSpace, AutoScrollToTop } from "@aeo/core/components";
import messages from "@aeo/core/messages";
import models from "@aeo/core/models";
import { arrIncludes, notifyErrors } from "@aeo/core/utils";
import { notifications } from "@aeo/core/messages/notifications";

import api, { querykeys } from "api";
import { ItemProps } from "features/saq/components/SAQTabs/Item";
import { SAQTabs } from "features/saq/components";

interface SaqProps {
  tabs?: Tab[];
  withNotes?: boolean;
}

export const SaqDetails = ({ tabs }: SaqProps) => {
  const saqContent = messages.SAQTabsContent;
  const notify = useNotify();
  const applicationId = useParam("id", NumberParam.decode) || 0;
  const [signModalIsOpen, setSignModalIsOpen] = useState(false);

  // Get application by id and not by active application
  const { data } = useQuery(
    querykeys.applications.one(applicationId),
    () => api.application.getById(applicationId),
    { enabled: !!applicationId },
  );

  const { data: document } = useQuery(
    querykeys.applications.documents(applicationId),
    () => api.application.getDocument(applicationId),
    {
      enabled: signModalIsOpen,
      onError: (error) => notifyErrors(notify, error),
    },
  );

  // Sign notes response
  const signMutation = useMutation(
    async () =>
      api.application.resign(applicationId, {
        type: data?.type,
      }),
    {
      onSuccess: () => notify.success({ title: notifications.signedDocument }),
      onError: (error) => notifyErrors(notify, error),
    },
  );

  let current = 1;
  const next = () => current++;

  // Reset tab state to default because notes doesn't have errors
  const getProps = (key: any): Pick<ItemProps, "tabKey" | "status"> => {
    return {
      tabKey: key,
      status: undefined,
    };
  };

  return (
    <div className="saq-grid">
      {tabs?.map((tab, i) => (
        <SAQTabs.Content
          tabKey={tab.tabKey}
          key={i}
          defaultActive={tab.defaultActive}
        >
          <AutoScrollToTop />
          {tab.content}
        </SAQTabs.Content>
      ))}

      <Card className="saq-tabs">
        <Card.Body>
          <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
            {saqContent.economicAgentInfo.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            static_
            tabKey={String(current)}
            indent={saqContent.companyGeneralInfo.indent}
          >
            {saqContent.companyGeneralInfo.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.economicAgent.key)}
            indent={saqContent.economicAgent.indent}
          >
            {saqContent.economicAgent.title}
          </SAQTabs.Item>

          <SAQTabs.Item
            nr={next()}
            static_
            indent={saqContent.shareholders.indent}
            tabKey={String(current)}
          >
            {saqContent.shareholders.title}
          </SAQTabs.Item>

          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.shareholdersOne.key)}
            indent={saqContent.shareholdersOne.indent}
          >
            {saqContent.shareholdersOne.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.directors.key)}
            indent={saqContent.directors.indent}
          >
            {saqContent.directors.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.advisory.key)}
            indent={saqContent.advisory.indent}
          >
            {saqContent.advisory.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.contactPerson.key)}
            indent={saqContent.contactPerson.indent}
          >
            {saqContent.contactPerson.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            indent={saqContent.customer.indent}
            {...getProps(saqContent.customer.key)}
          >
            {saqContent.customer.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            indent={saqContent.commercial.indent}
            {...getProps(saqContent.commercial.key)}
          >
            {saqContent.commercial.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            indent={saqContent.structure.indent}
            {...getProps(saqContent.structure.key)}
          >
            {saqContent.structure.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.turnover.key)}
            indent={saqContent.turnover.indent}
          >
            {saqContent.turnover.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.statistic.key)}
            indent={saqContent.statistic.indent}
          >
            {saqContent.statistic.title}
          </SAQTabs.Item>
          <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
            {saqContent.legislation.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.legislationOne.key)}
            indent={saqContent.legislationOne.indent}
          >
            {saqContent.legislationOne.title}
          </SAQTabs.Item>
          <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
            {saqContent.logistic.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.logisticOne.key)}
            indent={saqContent.logisticOne.indent}
          >
            {saqContent.logisticOne.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.logisticTwo.key)}
            indent={saqContent.logisticTwo.indent}
          >
            {saqContent.logisticTwo.title}
          </SAQTabs.Item>
          <SAQTabs.Item
            nr={next()}
            {...getProps(saqContent.logisticThree.key)}
            indent={saqContent.logisticThree.indent}
          >
            {saqContent.logisticThree.title}
          </SAQTabs.Item>
          <SAQTabs.Item nr={next()} {...getProps(saqContent.solvency.key)}>
            {saqContent.solvency.title}
          </SAQTabs.Item>

          {arrIncludes(
            [models.AEOType.AEOC, models.AEOType.AEOF],
            data?.type,
          ) && (
            <>
              <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
                {saqContent.standard.title}
              </SAQTabs.Item>
              <SAQTabs.Item
                nr={next()}
                {...getProps(saqContent.standardOne.key)}
                indent={saqContent.standardOne.indent}
              >
                {saqContent.standardOne.title}
              </SAQTabs.Item>
            </>
          )}

          {arrIncludes(
            [models.AEOType.AEOS, models.AEOType.AEOF],
            data?.type,
          ) && (
            <>
              <SAQTabs.Item nr={next()} static_ tabKey={String(current)}>
                {saqContent.security.title}
              </SAQTabs.Item>
              <SAQTabs.Item
                nr={next()}
                {...getProps(saqContent.securityOne.key)}
                indent={saqContent.securityOne.indent}
              >
                {saqContent.securityOne.title}
              </SAQTabs.Item>
              <SAQTabs.Item
                nr={next()}
                {...getProps(saqContent.securityTwo.key)}
                indent={saqContent.securityTwo.indent}
              >
                {saqContent.securityTwo.title}
              </SAQTabs.Item>
              <SAQTabs.Item
                nr={next()}
                {...getProps(saqContent.securityThree.key)}
                indent={saqContent.securityThree.indent}
              >
                {saqContent.securityThree.title}
              </SAQTabs.Item>
              <SAQTabs.Item
                nr={next()}
                {...getProps(saqContent.securityFour.key)}
                indent={saqContent.securityFour.indent}
              >
                {saqContent.securityFour.title}
              </SAQTabs.Item>
            </>
          )}
          <WhiteSpace v="1rem" />
          <Space justify="end">
            <Button
              onClick={() => setSignModalIsOpen(true)}
              type="primary"
              disabled={!data?.is_valid}
            >
              Spre semnare
            </Button>
          </Space>

          {signModalIsOpen && (
            <SignModal
              open={signModalIsOpen}
              onClose={() => setSignModalIsOpen(false)}
              file={document}
              mutation={signMutation}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
