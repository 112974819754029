import { Form, Textarea } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { Effect, FieldProps } from "@aeo/core/components";

import { NotApplicableBooleanField } from ".";

interface NotApplicableExtraTextareaProps extends FormFieldProps {
  type?: string;
  required?: boolean;
  textarea?: boolean;
  placeholder?: string;
  radioField: FormFieldProps;
  textareaField: FieldProps;
}

export const NotApplicableExtraTextarea = (
  inProps: NotApplicableExtraTextareaProps,
) => {
  const {
    radioField,
    textareaField,
    rules = [],
    required,
    initialValue,
  } = inProps;
  return (
    <div>
      <NotApplicableBooleanField {...radioField} />

      <Form.Field
        dependencies={[
          ...(radioField.name ? [radioField.name] : []),
          ...(radioField.dependencies || []),
        ]}
        label={textareaField.label}
        name={textareaField.name}
        rules={[{ required }, ...rules]}
        {...{
          initialValue,
        }}
      >
        {(control, _, form) => {
          const disabled = textareaField.dependencies?.some((dep) =>
            form.getFieldValue(dep),
          );

          return (
            <>
              <Textarea
                {...control}
                disabled={
                  disabled ||
                  !!(radioField.name && !form.getFieldValue(radioField.name))
                }
                placeholder={textareaField.placeholder}
              />
              <Effect form={form} onChange={control.onChange} {...inProps} />
            </>
          );
        }}
      </Form.Field>
    </div>
  );
};
